import {
  API,
  graphqlOperation
} from "aws-amplify";
import {
  createPurchase,
  updatePurchase
} from "../graphql/mutations";
import {
  getPurchase,
  getPurchaseByProductId, getPurchaseByQuestionnaireResponseId,
  getPurchaseByStatus,
  getPurchaseBySub
} from "../graphql/queries";
import {
  v4
} from 'uuid';
import {
  PurchasedStatus,
  surveyIds
} from "./constants";

const purchaseService = {
  getPurchaseByProductId: async (productId, sub) => {
    const response = await API.graphql({
      query: getPurchaseByProductId,
      variables: {
        productId
      },
      authMode: "AMAZON_COGNITO_USER_POOLS"
    });
    return response.data?.getPurchaseByProductId?.items || [];
  },
  getPurchaseByStatus: async (status) => {
    const response = await API.graphql({
      query: getPurchaseByStatus,
      variables: {
        status
      },
      authMode: "AMAZON_COGNITO_USER_POOLS"
    });
    return response.data?.getPurchaseByStatus?.items || [];
  },
  
  getPurchaseBySub: async (sub) => {
    const response = await API.graphql({
      query: getPurchaseBySub,
      variables: {
        sub
      },
      authMode: "AMAZON_COGNITO_USER_POOLS"
    });
    return response.data?.getPurchaseBySub?.items || [];
  },


  getPurchaseByQuestionnaireResponseId: async (qrId) => {
    const response = await API.graphql({
      query: getPurchaseByQuestionnaireResponseId,
      variables: {
        questionnaireResponseId: qrId
      },
      authMode: "AMAZON_COGNITO_USER_POOLS"
    });
    return response.data?.getPurchaseByQuestionnaireResponseId?.items || [];
  },

  // Return type - [ currentPlan, usedReports[], extraReports[], extraReportsUsed[] ]
  // Index 0: current Plan
  // Index 1: Array of usedReports for the plan
  // Index 2: Array of extraReports for the plan
  getCurrentPlanAndUsedReportsBySub: async (sub) => {
    const response = await API.graphql({
      query: getPurchaseBySub,
      variables: {
        sub
      },
      authMode: "AMAZON_COGNITO_USER_POOLS"
    });

    const purchases = response.data?.getPurchaseBySub?.items;
    if (purchases?.length) {
      const sortedPurchases = purchases.filter(p => ["PLAN", surveyIds.SMSF001, surveyIds.SMSF001E].includes(p.productType))
        .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
      const currentPlanIndex = sortedPurchases?.findIndex((p) => p.productType === "PLAN")
      // If there is no plan - extra reports data cannot be seen:
      if (currentPlanIndex === -1) {
        return []
      }
      const currentPlan = sortedPurchases && sortedPurchases[currentPlanIndex]

      const {
        usedReports,
        extraReports,
      } = sortedPurchases?.reduce((result, purchase, pIndex) => {
        switch (purchase.productType) {
          case surveyIds.SMSF001:
            if (pIndex < currentPlanIndex && purchase.status === PurchasedStatus.purchased) {
              result.usedReports.push(purchase)
            }
            break;
          case surveyIds.SMSF001E:
            if (purchase.status === PurchasedStatus.purchased) {
              result.extraReports.push(purchase)
            }
            break;
        }
        return result
      }, {
        usedReports: [],
        extraReports: [],
      })

      // Type: [currentPlan, usedReports[], extraReports[]]
      return [currentPlan, usedReports, extraReports]
    }

    return [];
  },

  getPurchaseById: async (puchaseId) => {
    const response = await API.graphql({
      query: getPurchase,
      variables: {
        id: puchaseId
      },
      authMode: "AMAZON_COGNITO_USER_POOLS"
    });
    return response?.data?.getPurchase || undefined;
  },
  addPurchase: async (items) => {
   return API.graphql(graphqlOperation(createPurchase, {
      input: {
        id: v4(),
        productType: items.productType,
        productId: items.productId,
        sub: items.sub,
        activationDate: items.activationDate,
        expiryDate: items.expiryDate,
        questionnaireResponseId: items.questionnaireResponseId,
        edits: 0,
        status: items.status
      },
      authMode: "AMAZON_COGNITO_USER_POOLS"
    }));
  },
  updatePurchaseQRId: async (purchaseId, questionnaireResponseId, status, edits) => {
    return API.graphql(graphqlOperation(updatePurchase, {
      input: {
        id: purchaseId,
        questionnaireResponseId,
        status,
        edits
      },
      authMode: "AMAZON_COGNITO_USER_POOLS"
    }));
  },
  updatePurchaseStatus: async (purchaseId, status) => {
    return (await API.graphql(graphqlOperation(updatePurchase, {
      input: {
        id: purchaseId,
        status
      },
      authMode: "AMAZON_COGNITO_USER_POOLS"
    }))).data?.updatePurchase;
  },
};

export default purchaseService;