import { API, graphqlOperation } from "aws-amplify";
import {
  generateReport,
  generateClassSuperReport,
} from "../graphql/mutations.js";
import moment from "moment";

const documentGenerationService = {
  startReportGeneration: async (
    questionnaireResponseData,
    prefilledData,
    userSub,
    renderRequest,
    initialRequest
  ) => {
    const createBglInvestmentSummariesArray = (startDate, endDate, fundId) => {
      let array = [];
      console.log(
        `Creating Investment summaries from ${startDate} until ${endDate} for fund ${fundId}`
      );

      //limit to 2 years
      for (let i = 0; i < 2; i++) {
        let data = {
          fundId: fundId,
          start: moment(startDate).subtract(i, "years").format("YYYY-MM-DD"),
          end: moment(endDate).subtract(i, "years").format("YYYY-MM-DD"),
          financialYear: moment(endDate).subtract(i, "years").format("YYYY"),
        };
        console.log(data);
        array.push(data);
      }
      return array;
    };
    const result = await API.graphql(
      graphqlOperation(generateReport, {
        data: JSON.stringify({
          httpMethod: "POST",
          body: {
            bglResponses: createBglInvestmentSummariesArray(
              JSON.parse(questionnaireResponseData).startDate,
              JSON.parse(questionnaireResponseData).endDate,
              JSON.parse(questionnaireResponseData).fundId
            ),
            prefillData: prefilledData,
            questionnaireResponse: JSON.parse(questionnaireResponseData),
            cognitoData: userSub,
            renderRequest: renderRequest,
            request: initialRequest,
          },
        }),
      })
    );
    return result.data;
  },
  generateClassSuperReport: async (
    questionnaireResponseData,
    prefilledData,
    userSub,
    renderRequest,
    initialRequest
  ) => {
    const result = await API.graphql(
      graphqlOperation(generateClassSuperReport, {
        data: JSON.stringify({
          httpMethod: "POST",
          body: {
            prefillData: prefilledData,
            questionnaireResponse: JSON.parse(questionnaireResponseData),
            cognitoData: userSub,
            renderRequest: renderRequest,
            request: initialRequest,
          },
        }),
      })
    );
    return result.data;
  },
};

export default documentGenerationService;
