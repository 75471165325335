import * as React from "react";
import { useSelector } from "react-redux";
import "./fundDetails.css";
import BGLFundDetails from "./bgl";
import ClassSuperFundDetails from "./classSuper";

export default function FundDetails() {
  const user = useSelector((state) => state.auth.user);

  return (
    <>
      {user?.consentType === "class_super" ? (
        <>
          {/* Component for class Super fund details */}
          <ClassSuperFundDetails />
        </>
      ) : (
        <>
          {/* Component for BGL fund details */}
          <BGLFundDetails />
        </>
      )}
    </>
  );
}
