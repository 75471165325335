import * as React from "react";
import CircularProgress from "@mui/material/CircularProgress";

import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { Typography, Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useSelector } from "react-redux";
import useMediaQuery from "@mui/material/useMediaQuery";

const useStyles = makeStyles((theme) => ({
  bglConsentBox: {
    // display: 'flex',
    // flexDirection: 'column',
    // alignItems: 'flex-start',
    padding: "32px",
    position: "absolute",
    backgroundColor: "#FFFFFF",
    boxShadow: "4px 4px 6px rgba(0, 0, 0, 0.25)",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
    height: "30%",
    width: "765px",
    maxHeight: "40%",
    overflowX: "hidden",
    overflowY: "auto",
  },
  bglConsentLbl: {
    fontFamily: "Poppins",
    fontWeight: 600,
    fontSize: "24px",
    lineHeight: "32px",
    color: "#000000",
    marginBlock: 0,
    marginBottom: 32,
  },
  textFieldLabel: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "24px",
    color: "#000000",
    marginBlock: 0,
  },
  smallTextFieldLabel: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "9px",
    lineHeight: "16px",
    color: "#000000",
    letterSpacing: "0.1em",
  },
  red: {
    color: "red",
  },
  textField: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    background: "#ECEFF1",
    alignSelf: "stretch",
    height: "44px",
    paddingLeft: 8,
    paddingRight: 8,
  },
  text14: {
    fontFamily: "Poppins",
    fontStyle: "small",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "24px",
  },
  crossIcon: {
    position: "absolute",
    right: "24px",
    cursor: "pointer",
  },
  marginBtm: {
    marginTop: "0px",
    marginBottom: "32px",
  },
  circle: {
    stroke: "url(#linearColors)",
  },
}));
export default function Loader(props) {
  const classes = useStyles();
  const open = useSelector((state) => state.header.spinner);
  const smallDeviceMatch = !useMediaQuery("(min-width:656px)");

  return (
    <Modal
      open={true}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        className={classes.bglConsentBox}
        sx={{ width: `${smallDeviceMatch ? "calc(100% - 32px)" : "638px"}` }}
      >
        <Grid>
          <Grid>
            <svg width="330" height="120">
              <linearGradient id="linearColors" x1="0" y1="0" x2="1" y2="1">
                <stop offset="20%" stopColor="#5CE1C2" />
                <stop offset="90%" stopColor="#BBE789" />
              </linearGradient>
            </svg>
            <CircularProgress
              thickness={8}
              classes={{ circle: classes.circle }}
            />
          </Grid>
          <Grid mt={5} className={classes.text14}>
            <Typography style={{ textAlign: "center" }}>
              {props.string
                ? props.string
                : "Please wait while we save your data."}
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
}
