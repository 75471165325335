import { createTheme } from "@mui/material/styles";
const theme = createTheme({
  shadows: Array(25).fill("none"),
  typography: {
    fontFamily: ["Poppins", "sans-serif"].join(","),
    fontWeight: [400, 500, 600, 700],
    hero: {
      fontWeight: 400,
      fontSize: "64px",
      lineHeight: "96px",
    },
    h1: {
      fontWeight: 700,
      fontSize: "36px",
      lineHeight: "48px",
    },
    h2: {
      fontWeight: 500,
      fontSize: "28px",
      lineHeight: "36px",
    },
    h3: {
      fontWeight: 600,
      fontSize: "24px",
      lineHeight: "32px",
    },
    h4: {
      fontWeight: 700,
      fontSize: "18px",
      lineHeight: "24px",
    },
    big: {
      fontWeight: 400,
      fontSize: "18px",
      lineHeight: "24px",
    },
    bigBold: {
      fontWeight: 700,
      fontSize: "18px",
      lineHeight: "24px",
    },
    medium: {
      fontWeight: 400,
      fontSize: "16px",
      lineHeight: "24px",
    },
    small: {
      fontWeight: 400,
      fontSize: "14px",
      lineHeight: "24px",
    },
    smallBold: {
      fontWeight: 700,
      fontSize: "14px",
      lineHeight: "24px",
    },
    smaller: {
      fontWeight: 400,
      fontSize: "11px",
      lineHeight: "16px",
    },
    smallerBold: {
      fontWeight: 700,
      fontSize: "11px",
      lineHeight: "16px",
    },
    tiny: {
      fontWeight: 400,
      fontSize: "9px",
      lineHeight: "16px",
    },
    smallCaps: {
      fontWeight: 400,
      fontSize: "11px",
      lineHeight: "16px",
      letterSpacing: "0.25em",
      textTransform: "uppercase",
    },
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: "#1A2842 !important",
          boxShadow: "none",
          justifyContent: "center",
          flexDirection: "column",
        },
      },
    },
    MuiButton: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          boxShadow: "none",
          textTransform: "none",
          fontStyle: "normal",
          "&.Mui-disabled": {
            backgroundColor: "#8C94A0",
            color: "#ffffff",
          },
        },
      },
    },
    MuiBadge: {
      styleOverrides: {
        badge: {
          backgroundColor: "#EC0018",
          cursor: "pointer",
        },
        root: {
          cursor: "pointer",
        },
      },
    },
    MuiBackdrop: {
      styleOverrides: {
        root: {
          //'background': 'none',
          backgroundColor: "rgba(0, 0, 0, 0.3)",
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          boxShadow: "none",
          textTransform: "none",
          fontStyle: "normal",
          "&:hover": {
            textDecoration: "none",
          },
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          paddingLeft: "32px",
          paddingRight: "32px",
        },
      },
    },
  },
});

export default theme;
