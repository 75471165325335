import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { makeStyles } from "@mui/styles";
import { Grid, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import useMediaQuery from "@mui/material/useMediaQuery";
import { headerActions } from "../../store/header";
import { X } from "react-feather";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import purchaseService from "../../services/purchase";
import {
  bglConsts,
  bglOperations,
  hasUnlimitedReportsIncluded,
  investmentCategories,
  investmentCodes,
  propertyType,
  PurchasedStatus,
} from "../../services/constants";
import { surveyActions } from "../../store/survey";
import SubscriptionStatistics from "../SubscriptionStatistics";
import { getExtraReportLink } from "../../common/common";
import { NIL } from "uuid";
import questionnaireResponseService from "../../services/questionnaireResponse";
import bglService from "../../services/bgl";
import moment from "moment/moment";
import { useHistory } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import classSuperService from "../../services/superClass";
import ErrorModal from "../ErrorModal/errorModal";

const CancelButton = styled(Button)({
  height: "44px",
  fontSize: "14px",
  fontWeight: 400,
  lineHeight: "24px",
  background: "#FFFFFF",
  border: "1px solid #1A2842",
  boxSizing: "border-box",
  color: "#1A2842",
  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
  borderRadius: "5px",
  fontFamily: ["Poppins"].join(","),
  "&:hover": {
    backgroundColor: "#FFFFFF",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
  },
  "&:active": {
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    backgroundColor: "#FFFFFF",
  },
  "&:focus": {
    backgroundColor: "#FFFFFF",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
  },
});

const ReportConfirmationButton = styled(Button)({
  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
  textTransform: "none",
  fontSize: "14px",
  padding: "10px 20px",
  border: "none",
  fontWeight: 400,
  lineHeight: "24px",
  backgroundColor: "#1A2842",
  color: "white",
  fontFamily: ["Poppins"].join(","),
  "&:hover": {
    backgroundColor: "#1A2842",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
  },
  "&:active": {
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    backgroundColor: "#1A2842",
  },
  "&:focus": {
    backgroundColor: "#1A2842",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
  },
  "&:disabled": {
    color: "white",
    backgroundColor: "#8C94A0;",
  },
});

const useStyles = makeStyles((theme) => ({
  bglConsentBox: {
    // display: 'flex',
    // flexDirection: 'column',
    // alignItems: 'flex-start',
    padding: "32px",
    position: "absolute",
    backgroundColor: "#FFFFFF",
    boxShadow: "4px 4px 6px rgba(0, 0, 0, 0.25)",
    borderRadius: "5px",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
    minHeight: "410px",
    width: "765px",
    maxHeight: "40%",
    overflowX: "hidden",
    overflowY: "auto",
  },
  confirmLbl: {
    fontFamily: "Poppins",
    fontWeight: 600,
    fontSize: "24px",
    lineHeight: "32px",
    color: "#000000",
    marginBlock: 0,
    marginBottom: 32,
  },
  textFieldLabel: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "24px",
    color: "#000000",
    marginBlock: 0,
  },
  smallTextFieldLabel: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "9px",
    lineHeight: "16px",
    color: "#000000",
    letterSpacing: "0.1em",
  },
  red: {
    color: "red",
  },
  textField: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    background: "#ECEFF1",
    alignSelf: "stretch",
    height: "44px",
    paddingLeft: 8,
    paddingRight: 8,
  },
  text14: {
    fontFamily: "Poppins",
    fontStyle: "small",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "24px",
  },
  crossIcon: {
    position: "absolute",
    right: "24px",
    cursor: "pointer",
  },
  marginBtm: {
    marginTop: "0px",
    marginBottom: "32px",
  },
  circle: {
    stroke: "url(#linearColors)",
  },
}));

export default function NewReportConfirmation(props) {
  const totalReportsCount = props.totalReportsCount;
  const usedReportsCount = props.usedReportsCount;
  const extraReports = props.extraReports;
  const extraReportPrice = props.extraReportPrice;
  const maxYear = new Date().getFullYear().toString();
  const product = props.product;
  const fundID = props.fundID;
  const tempRemainingReports = totalReportsCount - usedReportsCount;
  const remainingReports = tempRemainingReports > 0 ? tempRemainingReports : 0;
  const remainingExtraReports = extraReports.length;
  const totalRemainingReports = remainingReports + remainingExtraReports;
  const hasRemainingReports = totalRemainingReports > 0;
  const [surveyResponse, setSurveyResponse] = React.useState(undefined);
  const [MCUStatus, setMCUStatus] = React.useState(undefined);
  const [errorModal, seterrorModal] = React.useState(false);
  const [isCalledGetBglData, setIsCalledGetBglData] = React.useState(false);
  const [isCalledGetClassSuperData, setIsCalledGetClassSuperData] =
    React.useState(false);
  const dispatch = useDispatch();
  const classes = useStyles({ hasRemainingReports });
  const open = useSelector((state) => state.header.openNewReportConfirmation);
  const smallDeviceMatch = !useMediaQuery("(min-width:656px)");
  const user = useSelector((state) => state.auth.user);
  const selectedProduct = JSON.parse(localStorage.getItem("selectedProduct"));
  const firmDetails = useSelector((state) => state.header.firmDetails);
  const history = useHistory();
  const selectedFY = useSelector((state) => state.header.selectedFY);
  const [isButtonDisabled, setIsButtonDisabled] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);

  const getBody = (obj) => {
    return obj.split("body=")[1]?.slice(0, -1);
  };
  const isValidObject = (obj, name = "Unnamed object") => {
    if (obj === undefined || obj === null) return false;
    let containsStatus = obj?.includes("statusCode=");
    let statusValue = +obj.substring(
      obj.indexOf("statusCode=") + "statusCode=".length,
      obj.indexOf(",")
    );
    if (!containsStatus || statusValue !== 200)
      console.log("Object " + name + " is invalid".replace("  ", " "), obj);
    if (containsStatus === false)
      console.log(name + " does not contain a statusCode property");
    if (containsStatus && statusValue !== 200)
      console.log(statusValue + " is not a valid status value");
    console.log(
      "Returning " +
        (containsStatus && statusValue === 200).toString() +
        " for object " +
        name
    );
    return containsStatus && statusValue === 200;
  };

  const onClose = () => {
    dispatch(headerActions.handleCloseNewReportConfirmation());
  };
  const loadDataBySubFundId = async (sub, productId, fundId, selectedFY) => {
    console.trace();
    try {
      if (!fundId) {
        history.replace("/fundsList");
      }
      let result =
        await questionnaireResponseService.getQuestionnaireResponseByFundId(
          fundId
        );
      if (
        result &&
        result.MCUStatus === "done" &&
        productId === result.MCUData.productId
      ) {
        setMCUStatus("done");
      } else if (
        result &&
        result.MCUData &&
        JSON.parse(result.MCUData.data).firmName &&
        productId === result.MCUData.productId
      ) {
        setSurveyResponse(result.MCUData);
      } else {
        if (!surveyResponse && !isCalledGetBglData) {
          setIsCalledGetBglData(true);

          const startDate = selectedFY - 1 + "-07-01";
          const endDate = selectedFY + "-06-30";

          let [
            membersDetails,
            membersStatement,
            entityDetails,
            trustees,
            balanceSheet,
            contributionSummary,
            pensionSummary,
            generalLedger,
            detailedOperatingStatement,
          ] = await Promise.all([
            bglService.getUserBglData(bglOperations.MEMBERDETAILS, {
              fundId: fundId,
              financialYear: selectedFY,
            }),
            bglService.getUserBglData(bglOperations.MEMBERSTATEMENT, {
              fundId: fundId,
              start: startDate,
              end: endDate,
            }),
            bglService.getUserBglData(bglOperations.ENTITYDETAILS, {
              fundId: fundId,
              start: startDate,
              end: endDate,
            }),
            bglService.getUserBglData(bglOperations.TRUSTEES, {
              fundId: fundId,
            }),
            bglService.getUserBglData(bglOperations.BALANCESHEET, {
              fundId: fundId,
              financialYear: selectedFY,
            }),
            bglService.getUserBglData(bglOperations.CONTRIBUTIONSUMMARY, {
              fundId: fundId,
              financialYear: selectedFY,
            }),
            bglService.getUserBglData(bglOperations.PENSIONSUMMARY, {
              fundId: fundId,
              financialYear: selectedFY,
            }),
            bglService.getUserBglData(bglOperations.GENERALLEDGER, {
              fundId: fundId,
              start: startDate,
              end: endDate,
            }),
            bglService.getUserBglData(
              bglOperations.DETAILEDOPERATINGSTATEMENT,
              {
                fundId: fundId,
                financialYear: selectedFY,
              }
            ),
          ]);

          let [
            investmentSummary,
            investmentSummaryWithSource,
            investmentSummary_FY_1,
            investmentSummaryWithSource_FY_1,
            investmentSummary_FY_2,
            investmentSummary_FY_3,
          ] = await Promise.all([
            bglService.getUserBglData(bglOperations.INVESTMENTSUMMARY, {
              fundId: fundId,
              start: selectedFY - 1 + "-07-01",
              end: selectedFY + "-06-30",
            }),
            bglService.getUserBglData(
              bglOperations.INVESTMENTSUMMARYWITHSOURCE,
              {
                fundId: fundId,
                start: selectedFY - 1 + "-07-01",
                end: selectedFY + "-06-30",
              }
            ),
            bglService.getUserBglData(bglOperations.INVESTMENTSUMMARY, {
              fundId: fundId,
              start: selectedFY - 2 + "-07-01",
              end: selectedFY - 1 + "-06-30",
            }),
            bglService.getUserBglData(
              bglOperations.INVESTMENTSUMMARYWITHSOURCE,
              {
                fundId: fundId,
                start: selectedFY - 2 + "-07-01",
                end: selectedFY - 1 + "-06-30",
              }
            ),
            bglService.getUserBglData(bglOperations.INVESTMENTSUMMARY, {
              fundId: fundId,
              start: selectedFY - 3 + "-07-01",
              end: selectedFY - 2 + "-06-30",
            }),
            bglService.getUserBglData(bglOperations.INVESTMENTSUMMARY, {
              fundId: fundId,
              start: selectedFY - 4 + "-07-01",
              end: selectedFY - 3 + "-06-30",
            }),
          ]);

          if (
            !isValidObject(membersDetails, "membersDetails") ||
            !isValidObject(membersStatement, "membersStatement") ||
            !isValidObject(entityDetails, "entityDetails") ||
            !isValidObject(trustees, "trustees") ||
            !isValidObject(balanceSheet, "balanceSheet") ||
            !isValidObject(contributionSummary, "contributionSummary") ||
            !isValidObject(pensionSummary, "pensionSummary") ||
            !isValidObject(generalLedger, "generalLedger") ||
            !isValidObject(
              detailedOperatingStatement,
              "detailedOperatingStatement"
            ) ||
            !isValidObject(investmentSummary, "investmentSummary") ||
            !isValidObject(investmentSummary_FY_1, "investmentSummary_FY_1") ||
            !isValidObject(investmentSummary_FY_2, "investmentSummary_FY_2") ||
            !isValidObject(investmentSummary_FY_3, "investmentSummary_FY_3")
          ) {
            dispatch(
              headerActions.handleReportBtnText({ btnText: "BGL unreachable" })
            );
            dispatch(headerActions.handleOpenBGLHealthCheckModal());
            return undefined;
          } else {
            membersDetails = getBody(membersDetails);
            membersStatement = getBody(membersStatement);
            entityDetails = getBody(entityDetails);
            trustees = getBody(trustees);
            balanceSheet = getBody(balanceSheet);
            contributionSummary = getBody(contributionSummary);
            pensionSummary = getBody(pensionSummary);
            generalLedger = getBody(generalLedger);
            detailedOperatingStatement = getBody(detailedOperatingStatement);
            investmentSummary = getBody(investmentSummary);
            investmentSummaryWithSource = getBody(investmentSummaryWithSource);
            investmentSummary_FY_1 = getBody(investmentSummary_FY_1);
            investmentSummaryWithSource_FY_1 = getBody(
              investmentSummaryWithSource_FY_1
            );
            investmentSummary_FY_2 = getBody(investmentSummary_FY_2);
            investmentSummary_FY_3 = getBody(investmentSummary_FY_3);
          }
          // Fund Balance Calculation
          let fundSmsfBalance = 0,
            smsfBalance = 0,
            annualManagementCost = 0;
          const contribs = [];

          JSON.parse(balanceSheet)?.liabilitiesForAccruedBenefits.map((res) => {
            fundSmsfBalance = fundSmsfBalance + res.amount;
          });

          // Asset Allocation Calculation
          let propertyArray = [],
            propertyArray_1 = [];
          let cash = 0,
            other = 0,
            australian_fixed_interest = 0,
            international_fixed_interest = 0,
            australian_shares = 0,
            international_shares = 0,
            property = 0,
            currentFYRealisedMovement = 0,
            currentFYUnRealisedMovement = 0,
            currentFYCost = 0,
            currentFYMktValueAsPerLedger = 0;
          const currentFYCashPerformanceValue = {
              currentFYRealisedMovement: 0,
              currentFYUnRealisedMovement: 0,
              currentFYCost: 0,
              currentFYMktValueAsPerLedger: 0,
            },
            currentFYOtherPerformanceValue = {
              currentFYRealisedMovement: 0,
              currentFYUnRealisedMovement: 0,
              currentFYCost: 0,
              currentFYMktValueAsPerLedger: 0,
            },
            currentFYAustralianFixedInterestPerformanceValue = {
              currentFYRealisedMovement: 0,
              currentFYUnRealisedMovement: 0,
              currentFYCost: 0,
              currentFYMktValueAsPerLedger: 0,
            },
            currentFYInternationalFixedInterestPerformanceValue = {
              currentFYRealisedMovement: 0,
              currentFYUnRealisedMovement: 0,
              currentFYCost: 0,
              currentFYMktValueAsPerLedger: 0,
            },
            currentFYAustralianSharesPerformanceValue = {
              currentFYRealisedMovement: 0,
              currentFYUnRealisedMovement: 0,
              currentFYCost: 0,
              currentFYMktValueAsPerLedger: 0,
            },
            currentFYInternationalSharesPerformanceValue = {
              currentFYRealisedMovement: 0,
              currentFYUnRealisedMovement: 0,
              currentFYCost: 0,
              currentFYMktValueAsPerLedger: 0,
            },
            currentFYPropertyPerformanceValue = {
              currentFYRealisedMovement: 0,
              currentFYUnRealisedMovement: 0,
              currentFYCost: 0,
              currentFYMktValueAsPerLedger: 0,
            };
          JSON.parse(investmentSummary)?.investmentSummary?.data?.forEach(
            (asset) => {
              // FFS-966: Investment code is not included in investmentSummary response so we need to retrieve it
              const investmentCode = JSON.parse(investmentSummaryWithSource)
                ?.investmentSummaryWithSource?.data.filter((item) => {
                  return item.subChartAccountCode === asset.code;
                })
                .map((item) => item.controlChartAccountCode)[0];
              if (investmentCodes[investmentCode] === "cash") {
                smsfBalance +=
                  asset.mktValueAsPerLedger !== null
                    ? +asset.mktValueAsPerLedger
                    : 0;
                cash +=
                  asset.mktValueAsPerLedger !== null
                    ? +asset.mktValueAsPerLedger
                    : 0;
                currentFYCashPerformanceValue.currentFYRealisedMovement +=
                  asset.realisedMovement !== null ? +asset.realisedMovement : 0;
                currentFYCashPerformanceValue.currentFYUnRealisedMovement +=
                  asset.currentYearUnRealisedMovement !== null
                    ? +asset.currentYearUnRealisedMovement
                    : 0;
                currentFYCashPerformanceValue.currentFYCost +=
                  asset.cost !== null ? +asset.cost : 0;
                currentFYCashPerformanceValue.currentFYMktValueAsPerLedger +=
                  asset.mktValueAsPerLedger !== null
                    ? +asset.mktValueAsPerLedger
                    : 0;
              } else if (investmentCodes[investmentCode] === "other") {
                smsfBalance +=
                  asset.mktValueAsPerLedger !== null
                    ? +asset.mktValueAsPerLedger
                    : 0;
                other +=
                  asset.mktValueAsPerLedger !== null
                    ? +asset.mktValueAsPerLedger
                    : 0;
                currentFYOtherPerformanceValue.currentFYRealisedMovement +=
                  asset.realisedMovement !== null ? +asset.realisedMovement : 0;
                currentFYOtherPerformanceValue.currentFYUnRealisedMovement +=
                  asset.currentYearUnRealisedMovement !== null
                    ? +asset.currentYearUnRealisedMovement
                    : 0;
                currentFYOtherPerformanceValue.currentFYCost +=
                  asset.cost !== null ? +asset.cost : 0;
                currentFYOtherPerformanceValue.currentFYMktValueAsPerLedger +=
                  asset.mktValueAsPerLedger !== null
                    ? +asset.mktValueAsPerLedger
                    : 0;
              } else if (
                investmentCodes[investmentCode] === "australian_fixed_interest"
              ) {
                smsfBalance +=
                  asset.mktValueAsPerLedger !== null
                    ? +asset.mktValueAsPerLedger
                    : 0;
                australian_fixed_interest +=
                  asset.mktValueAsPerLedger !== null
                    ? +asset.mktValueAsPerLedger
                    : 0;
                currentFYAustralianFixedInterestPerformanceValue.currentFYRealisedMovement +=
                  asset.realisedMovement !== null ? +asset.realisedMovement : 0;
                currentFYAustralianFixedInterestPerformanceValue.currentFYUnRealisedMovement +=
                  asset.currentYearUnRealisedMovement !== null
                    ? +asset.currentYearUnRealisedMovement
                    : 0;
                currentFYAustralianFixedInterestPerformanceValue.currentFYCost +=
                  asset.cost !== null ? +asset.cost : 0;
                currentFYAustralianFixedInterestPerformanceValue.currentFYMktValueAsPerLedger +=
                  asset.mktValueAsPerLedger !== null
                    ? +asset.mktValueAsPerLedger
                    : 0;
              } else if (
                investmentCodes[investmentCode] ===
                "international_fixed_interest"
              ) {
                smsfBalance +=
                  asset.mktValueAsPerLedger !== null
                    ? +asset.mktValueAsPerLedger
                    : 0;
                international_fixed_interest +=
                  asset.mktValueAsPerLedger !== null
                    ? +asset.mktValueAsPerLedger
                    : 0;
                currentFYInternationalFixedInterestPerformanceValue.currentFYRealisedMovement +=
                  asset.realisedMovement !== null ? +asset.realisedMovement : 0;
                currentFYInternationalFixedInterestPerformanceValue.currentFYUnRealisedMovement +=
                  asset.currentYearUnRealisedMovement !== null
                    ? +asset.currentYearUnRealisedMovement
                    : 0;
                currentFYInternationalFixedInterestPerformanceValue.currentFYCost +=
                  asset.cost !== null ? +asset.cost : 0;
                currentFYInternationalFixedInterestPerformanceValue.currentFYMktValueAsPerLedger +=
                  asset.mktValueAsPerLedger !== null
                    ? +asset.mktValueAsPerLedger
                    : 0;
              } else if (
                investmentCodes[investmentCode] === "australian_shares"
              ) {
                smsfBalance +=
                  asset.mktValueAsPerLedger !== null
                    ? +asset.mktValueAsPerLedger
                    : 0;
                australian_shares +=
                  asset.mktValueAsPerLedger !== null
                    ? +asset.mktValueAsPerLedger
                    : 0;
                currentFYAustralianSharesPerformanceValue.currentFYRealisedMovement +=
                  asset.realisedMovement !== null ? +asset.realisedMovement : 0;
                currentFYAustralianSharesPerformanceValue.currentFYUnRealisedMovement +=
                  asset.currentYearUnRealisedMovement !== null
                    ? +asset.currentYearUnRealisedMovement
                    : 0;
                currentFYAustralianSharesPerformanceValue.currentFYCost +=
                  asset.cost !== null ? +asset.cost : 0;
                currentFYAustralianSharesPerformanceValue.currentFYMktValueAsPerLedger +=
                  asset.mktValueAsPerLedger !== null
                    ? +asset.mktValueAsPerLedger
                    : 0;
              } else if (
                investmentCodes[investmentCode] === "international_shares"
              ) {
                smsfBalance +=
                  asset.mktValueAsPerLedger !== null
                    ? +asset.mktValueAsPerLedger
                    : 0;
                international_shares +=
                  asset.mktValueAsPerLedger !== null
                    ? +asset.mktValueAsPerLedger
                    : 0;
                currentFYInternationalSharesPerformanceValue.currentFYRealisedMovement +=
                  asset.realisedMovement !== null ? +asset.realisedMovement : 0;
                currentFYInternationalSharesPerformanceValue.currentFYUnRealisedMovement +=
                  asset.currentYearUnRealisedMovement !== null
                    ? +asset.currentYearUnRealisedMovement
                    : 0;
                currentFYInternationalSharesPerformanceValue.currentFYCost +=
                  asset.cost !== null ? +asset.cost : 0;
                currentFYInternationalSharesPerformanceValue.currentFYMktValueAsPerLedger +=
                  asset.mktValueAsPerLedger !== null
                    ? +asset.mktValueAsPerLedger
                    : 0;
              } else if (investmentCodes[investmentCode] === "property") {
                smsfBalance +=
                  asset.mktValueAsPerLedger !== null
                    ? +asset.mktValueAsPerLedger
                    : 0;
                property +=
                  asset.mktValueAsPerLedger !== null
                    ? +asset.mktValueAsPerLedger
                    : 0;
                currentFYPropertyPerformanceValue.currentFYRealisedMovement +=
                  asset.realisedMovement !== null ? +asset.realisedMovement : 0;
                currentFYPropertyPerformanceValue.currentFYUnRealisedMovement +=
                  asset.currentYearUnRealisedMovement !== null
                    ? +asset.currentYearUnRealisedMovement
                    : 0;
                currentFYPropertyPerformanceValue.currentFYCost +=
                  asset.cost !== null ? +asset.cost : 0;
                currentFYPropertyPerformanceValue.currentFYMktValueAsPerLedger +=
                  asset.mktValueAsPerLedger !== null
                    ? +asset.mktValueAsPerLedger
                    : 0;
                propertyArray.push(asset);
              }
              // For 12 month Fund performance
              currentFYRealisedMovement +=
                asset.realisedMovement !== null ? +asset.realisedMovement : 0;
              currentFYUnRealisedMovement +=
                asset.currentYearUnRealisedMovement !== null
                  ? +asset.currentYearUnRealisedMovement
                  : 0;
              currentFYCost += asset.cost !== null ? +asset.cost : 0;
              currentFYMktValueAsPerLedger +=
                asset.mktValueAsPerLedger !== null
                  ? +asset.mktValueAsPerLedger
                  : 0;
            }
          );
          // Current FY -1 fund cost
          let currentFY_1_RealisedMovement = 0,
            currentFY_1_UnRealisedMovement = 0,
            currentFY_1_Cost = 0,
            currentFY_1_MktValueAsPerLedger = 0;
          const currentFY_1_CashPerformanceValue = {
              currentFYRealisedMovement: 0,
              currentFYUnRealisedMovement: 0,
              currentFYCost: 0,
              currentFYMktValueAsPerLedger: 0,
            },
            currentFY_1_OtherPerformanceValue = {
              currentFYRealisedMovement: 0,
              currentFYUnRealisedMovement: 0,
              currentFYCost: 0,
              currentFYMktValueAsPerLedger: 0,
            },
            currentFY_1_AustralianFixedInterestPerformanceValue = {
              currentFYRealisedMovement: 0,
              currentFYUnRealisedMovement: 0,
              currentFYCost: 0,
              currentFYMktValueAsPerLedger: 0,
            },
            currentFY_1_InternationalFixedInterestPerformanceValue = {
              currentFYRealisedMovement: 0,
              currentFYUnRealisedMovement: 0,
              currentFYCost: 0,
              currentFYMktValueAsPerLedger: 0,
            },
            currentFY_1_AustralianSharesPerformanceValue = {
              currentFYRealisedMovement: 0,
              currentFYUnRealisedMovement: 0,
              currentFYCost: 0,
              currentFYMktValueAsPerLedger: 0,
            },
            currentFY_1_InternationalSharesPerformanceValue = {
              currentFYRealisedMovement: 0,
              currentFYUnRealisedMovement: 0,
              currentFYCost: 0,
              currentFYMktValueAsPerLedger: 0,
            },
            currentFY_1_PropertyPerformanceValue = {
              currentFYRealisedMovement: 0,
              currentFYUnRealisedMovement: 0,
              currentFYCost: 0,
              currentFYMktValueAsPerLedger: 0,
            };

          JSON.parse(investmentSummary_FY_1)?.investmentSummary?.data?.forEach(
            (asset) => {
              // FFS-966: Investment code is not included in investmentSummary response so we need to retrieve it
              const investmentCode = JSON.parse(
                investmentSummaryWithSource_FY_1
              )
                ?.investmentSummaryWithSource?.data.filter((item) => {
                  return item.subChartAccountCode === asset.code;
                })
                .map((item) => item.controlChartAccountCode)[0];
              if (investmentCodes[investmentCode] === "cash") {
                currentFY_1_CashPerformanceValue.currentFYRealisedMovement +=
                  asset.realisedMovement !== null ? +asset.realisedMovement : 0;
                currentFY_1_CashPerformanceValue.currentFYUnRealisedMovement +=
                  asset.currentYearUnRealisedMovement !== null
                    ? +asset.currentYearUnRealisedMovement
                    : 0;
                currentFY_1_CashPerformanceValue.currentFYCost +=
                  asset.cost !== null ? +asset.cost : 0;
                currentFY_1_CashPerformanceValue.currentFYMktValueAsPerLedger +=
                  asset.mktValueAsPerLedger !== null
                    ? +asset.mktValueAsPerLedger
                    : 0;
              } else if (investmentCodes[investmentCode] === "other") {
                currentFY_1_OtherPerformanceValue.currentFYRealisedMovement +=
                  asset.realisedMovement !== null ? +asset.realisedMovement : 0;
                currentFY_1_OtherPerformanceValue.currentFYUnRealisedMovement +=
                  asset.currentYearUnRealisedMovement !== null
                    ? +asset.currentYearUnRealisedMovement
                    : 0;
                currentFY_1_OtherPerformanceValue.currentFYCost +=
                  asset.cost !== null ? +asset.cost : 0;
                currentFY_1_OtherPerformanceValue.currentFYMktValueAsPerLedger +=
                  asset.mktValueAsPerLedger !== null
                    ? +asset.mktValueAsPerLedger
                    : 0;
              } else if (
                investmentCodes[investmentCode] === "australian_fixed_interest"
              ) {
                currentFY_1_AustralianFixedInterestPerformanceValue.currentFYRealisedMovement +=
                  asset.realisedMovement !== null ? +asset.realisedMovement : 0;
                currentFY_1_AustralianFixedInterestPerformanceValue.currentFYUnRealisedMovement +=
                  asset.currentYearUnRealisedMovement !== null
                    ? +asset.currentYearUnRealisedMovement
                    : 0;
                currentFY_1_AustralianFixedInterestPerformanceValue.currentFYCost +=
                  asset.cost !== null ? +asset.cost : 0;
                currentFY_1_AustralianFixedInterestPerformanceValue.currentFYMktValueAsPerLedger +=
                  asset.mktValueAsPerLedger !== null
                    ? +asset.mktValueAsPerLedger
                    : 0;
              } else if (
                investmentCodes[investmentCode] ===
                "international_fixed_interest"
              ) {
                currentFY_1_InternationalFixedInterestPerformanceValue.currentFYRealisedMovement +=
                  asset.realisedMovement !== null ? +asset.realisedMovement : 0;
                currentFY_1_InternationalFixedInterestPerformanceValue.currentFYUnRealisedMovement +=
                  asset.currentYearUnRealisedMovement !== null
                    ? +asset.currentYearUnRealisedMovement
                    : 0;
                currentFY_1_InternationalFixedInterestPerformanceValue.currentFYCost +=
                  asset.cost !== null ? +asset.cost : 0;
                currentFY_1_InternationalFixedInterestPerformanceValue.currentFYMktValueAsPerLedger +=
                  asset.mktValueAsPerLedger !== null
                    ? +asset.mktValueAsPerLedger
                    : 0;
              } else if (
                investmentCodes[investmentCode] === "australian_shares"
              ) {
                currentFY_1_AustralianSharesPerformanceValue.currentFYRealisedMovement +=
                  asset.realisedMovement !== null ? +asset.realisedMovement : 0;
                currentFY_1_AustralianSharesPerformanceValue.currentFYUnRealisedMovement +=
                  asset.currentYearUnRealisedMovement !== null
                    ? +asset.currentYearUnRealisedMovement
                    : 0;
                currentFY_1_AustralianSharesPerformanceValue.currentFYCost +=
                  asset.cost !== null ? +asset.cost : 0;
                currentFY_1_AustralianSharesPerformanceValue.currentFYMktValueAsPerLedger +=
                  asset.mktValueAsPerLedger !== null
                    ? +asset.mktValueAsPerLedger
                    : 0;
              } else if (
                investmentCodes[investmentCode] === "international_shares"
              ) {
                currentFY_1_InternationalSharesPerformanceValue.currentFYRealisedMovement +=
                  asset.realisedMovement !== null ? +asset.realisedMovement : 0;
                currentFY_1_InternationalSharesPerformanceValue.currentFYUnRealisedMovement +=
                  asset.currentYearUnRealisedMovement !== null
                    ? +asset.currentYearUnRealisedMovement
                    : 0;
                currentFY_1_InternationalSharesPerformanceValue.currentFYCost +=
                  asset.cost !== null ? +asset.cost : 0;
                currentFY_1_InternationalSharesPerformanceValue.currentFYMktValueAsPerLedger +=
                  asset.mktValueAsPerLedger !== null
                    ? +asset.mktValueAsPerLedger
                    : 0;
              } else if (investmentCodes[investmentCode] === "property") {
                currentFY_1_PropertyPerformanceValue.currentFYRealisedMovement +=
                  asset.realisedMovement !== null ? +asset.realisedMovement : 0;
                currentFY_1_PropertyPerformanceValue.currentFYUnRealisedMovement +=
                  asset.currentYearUnRealisedMovement !== null
                    ? +asset.currentYearUnRealisedMovement
                    : 0;
                currentFY_1_PropertyPerformanceValue.currentFYCost +=
                  asset.cost !== null ? +asset.cost : 0;
                currentFY_1_PropertyPerformanceValue.currentFYMktValueAsPerLedger +=
                  asset.mktValueAsPerLedger !== null
                    ? +asset.mktValueAsPerLedger
                    : 0;
                propertyArray_1.push(asset);
              }

              // For 12 month Fund performance
              currentFY_1_RealisedMovement +=
                asset.realisedMovement !== null ? +asset.realisedMovement : 0;
              currentFY_1_UnRealisedMovement +=
                asset.currentYearUnRealisedMovement !== null
                  ? +asset.currentYearUnRealisedMovement
                  : 0;
              currentFY_1_Cost += asset.cost !== null ? +asset.cost : 0;
              currentFY_1_MktValueAsPerLedger +=
                asset.mktValueAsPerLedger !== null
                  ? +asset.mktValueAsPerLedger
                  : 0;
            }
          );

          // Current FY -2 fund cost
          let currentFY_2_RealisedMovement = 0,
            currentFY_2_UnRealisedMovement = 0,
            currentFY_2_Cost = 0,
            currentFY_2_MktValueAsPerLedger = 0;
          JSON.parse(investmentSummary_FY_2)?.investmentSummary?.data?.forEach(
            (asset) => {
              currentFY_2_RealisedMovement +=
                asset.realisedMovement !== null ? +asset.realisedMovement : 0;
              currentFY_2_UnRealisedMovement +=
                asset.currentYearUnRealisedMovement !== null
                  ? +asset.currentYearUnRealisedMovement
                  : 0;
              currentFY_2_Cost += asset.cost !== null ? +asset.cost : 0;
              currentFY_2_MktValueAsPerLedger +=
                asset.mktValueAsPerLedger !== null
                  ? +asset.mktValueAsPerLedger
                  : 0;
            }
          );

          // Current FY -3 fund cost
          let currentFY_3_RealisedMovement = 0,
            currentFY_3_UnRealisedMovement = 0,
            currentFY_3_Cost = 0,
            currentFY_3_MktValueAsPerLedger = 0;
          JSON.parse(investmentSummary_FY_3)?.investmentSummary?.data?.forEach(
            (asset) => {
              currentFY_3_RealisedMovement +=
                asset.realisedMovement !== null ? +asset.realisedMovement : 0;
              currentFY_3_UnRealisedMovement +=
                asset.currentYearUnRealisedMovement !== null
                  ? +asset.currentYearUnRealisedMovement
                  : 0;
              currentFY_3_Cost += asset.cost !== null ? +asset.cost : 0;
              currentFY_3_MktValueAsPerLedger +=
                asset.mktValueAsPerLedger !== null
                  ? +asset.mktValueAsPerLedger
                  : 0;
            }
          );

          let bring_forward_rule = "No";
          const bring_forward_rule_member = [];
          let taxable = "No";
          let isBdbn = "Yes";
          // Member object creation
          const members = [];
          JSON.parse(membersDetails)?.map((res, index) => {
            if (res.accounts.length) {
              let memberObj = {
                member_number: index + 1,
                member_first_name: res.firstName,
                member_last_name: res.lastName,
                member_dob: res.dob,
              };

              //  Cash flow bring forward rule calculation
              JSON.parse(contributionSummary)?.contributions.forEach(
                async (contributions) => {
                  contributions?.concessionalContributions.forEach(
                    async (element) => {
                      if (
                        selectedFY > element.financialYear &&
                        element.financialYear > selectedFY - 2
                      ) {
                        if (element.carryForwardOrigins.length > 0) {
                          bring_forward_rule = "Yes";
                          const exists = bring_forward_rule_member.includes(
                            index + 1
                          );
                          if (!exists) {
                            bring_forward_rule_member.push(index + 1);
                          }
                        }
                      }
                    }
                  );
                }
              );

              JSON.parse(membersStatement)?.members?.forEach((memberData) => {
                if (res.peopleId === memberData.peopleId) {
                  let totalClosingBalance = 0;
                  memberData?.accounts?.forEach((accountData) => {
                    totalClosingBalance += accountData.balanceClosing;
                    if (accountData.taxFreeProportion === null) {
                      taxable = "Yes";
                    }
                    if (isBdbn === "Yes") {
                      isBdbn =
                        accountData.nominationBinding === false ? "No" : "Yes";
                    }
                    memberObj["bdbn_questions"] = [
                      {
                        bdbn:
                          accountData.nominationBinding === false
                            ? "No"
                            : "Yes",
                        bdbn_lapsing: "",
                        bdbn_review_date:
                          accountData.nominationEndDate !== null
                            ? moment(accountData.nominationEndDate).format(
                                "YYYY-MM-DD"
                              )
                            : "",
                        bdbn_review_diffDays: 0,
                      },
                    ];
                  });
                  memberObj.member_balance = totalClosingBalance;
                  memberObj.member_percent =
                    fundSmsfBalance > 0
                      ? +(
                          (totalClosingBalance / fundSmsfBalance) *
                          100
                        ).toFixed(2)
                      : 0;
                }
              });

              // Preparing concessional question list
              JSON.parse(contributionSummary)?.contributions?.forEach(
                (contributionData) => {
                  let contributions = {};
                  if (res.peopleId === contributionData.peopleId) {
                    // Concessional
                    if (
                      contributionData?.concessionalContributions?.length > 0
                    ) {
                      contribs.indexOf("Concessional") === -1
                        ? contribs.push("Concessional")
                        : console.log("");
                    }

                    const concessionalData =
                      contributionData?.concessionalContributions?.sort(
                        (a, b) => {
                          return b.financialYear - a.financialYear;
                        }
                      );
                    const concessional = {};
                    concessionalData?.forEach((contribution, index) => {
                      if (index > 0) {
                        concessional[`FY_Current_-${index}`] =
                          contribution.amount;
                      } else {
                        concessional["FY_Current"] = contribution.amount;
                      }
                    });
                    contributions["Concessional"] = concessional;

                    // Non - Concessional
                    if (
                      contributionData?.nonConcessionalContributions?.length > 0
                    ) {
                      contribs.indexOf("Non-Concessional") === -1
                        ? contribs.push("Non-Concessional")
                        : console.log("");
                    }

                    const nonConcessionalData =
                      contributionData?.nonConcessionalContributions?.sort(
                        (a, b) => {
                          return b.financialYear - a.financialYear;
                        }
                      );
                    const nonConcessional = {};
                    nonConcessionalData?.forEach((contribution, index) => {
                      if (index > 0) {
                        nonConcessional[`FY_Current_-${index}`] =
                          contribution.amount;
                      } else {
                        nonConcessional["FY_Current"] = contribution.amount;
                      }
                    });
                    contributions["Non-Concessional"] = nonConcessional;

                    memberObj.contrib_questions = contributions;
                  }
                }
              );
              members.push(memberObj);
            }
          });

          // Property object formation
          const propertyData = [];
          const addressTypes = [
            "boulevard",
            "circle",
            "circuit",
            "court",
            "cove",
            "crossing",
            "drive",
            "lane",
            "place",
            "road",
            "rd",
            "route",
            "trail",
            "walk",
            "way",
            "wharf",
            "street",
            "st",
          ];
          propertyArray.forEach(async (property) => {
            let propertyDetail = {
              property_address1: "",
              property_address2: "",
              property_value: property.mktPrice,
              property_type: propertyType[property.investmentGroup],
              property_pcode:
                property.investmentName.match(/\d{4}/g) !== null
                  ? +property.investmentName.match(/\d{4}/g)[0] <= 9999
                    ? +property.investmentName.match(/\d{4}/g)[0]
                    : ""
                  : "",
              currentYearUnRealisedMovement:
                property.currentYearUnRealisedMovement !== null
                  ? property.currentYearUnRealisedMovement
                  : 0,
              property_income: "No",
            };
            let splitString = "";
            for (let i = 0; i < addressTypes.length; i++) {
              let regex = new RegExp("\\b" + addressTypes[i] + "\\b", "i");
              if (regex.test(property.investmentName)) {
                splitString = addressTypes[i];
              }
            }
            if (splitString !== "") {
              let regex = new RegExp("\\b" + splitString + "\\b", "i");
              let propertyAddress = property.investmentName.split(regex);
              propertyDetail["property_address1"] =
                propertyAddress[0].trim() + ` ${splitString},`;
              propertyDetail["property_address2"] = propertyAddress[1]
                .replace(",", "")
                .trim();
            } else {
              propertyDetail["property_address1"] = property.investmentName;
              propertyDetail["property_address2"] = "";
            }

            // calculating property growth performance
            propertyArray_1.forEach(async (property_1) => {
              if (property_1.code === property.code) {
                propertyDetail["propertyGrowthPerformance"] =
                  property.realisedMovement !== 0 ||
                  property.currentYearUnRealisedMovement !== 0
                    ? +(
                        ((property.realisedMovement +
                          property.currentYearUnRealisedMovement) /
                          (property.cost -
                            property_1.cost +
                            property_1.mktValueAsPerLedger)) *
                        100
                      ).toFixed(2)
                    : 0;
              }
            });

            // Property Rental Income parameters
            JSON.parse(detailedOperatingStatement)?.income?.forEach(
              async (propertyIncome) => {
                if (propertyIncome.title === bglConsts.PROPERTYINCOME) {
                  propertyIncome?.items.forEach(async (propDetails) => {
                    if (propDetails.code === property.code) {
                      propertyDetail["property_income"] = "Yes";
                      propertyDetail["property_income_amt"] =
                        propDetails.amount;
                      propertyDetail["property_frequency"] = "Annually";
                    }
                  });
                }
              }
            );

            // Property value date calc
            JSON.parse(
              investmentSummaryWithSource
            )?.investmentSummaryWithSource?.data?.forEach(
              async (propertyDetails) => {
                if (propertyDetails.securityCode === property.code) {
                  propertyDetail["property_value_date"] =
                    propertyDetails.marketPriceDate !== null
                      ? moment(propertyDetails.marketPriceDate).format(
                          "YYYY-MM-DD"
                        )
                      : "";
                }
              }
            );

            // LRBA calculation
            propertyDetail["lrba"] = "No";
            propertyDetail["lrba_amt"] = 0;
            JSON.parse(balanceSheet)?.liabilities.map((res) => {
              if (res.name === bglConsts.BORROWINGARRANGEMENTS) {
                res?.subEntries.forEach(async (propertyLiabilities) => {
                  if (propertyLiabilities.accountCode === property.code) {
                    propertyDetail["lrba"] = "Yes";
                    propertyDetail["lrba_amt"] = Math.abs(res.amount);
                  }
                });
              }
            });
            propertyData.push({ property_questions: [propertyDetail] });
          });

          // Annual management cost of the fund
          let fundFeesData = [];
          JSON.parse(detailedOperatingStatement)?.expenses?.forEach(
            async (managementCost) => {
              managementCost?.items.forEach(async (expenses) => {
                if (investmentCategories[expenses.name] === "fees") {
                  fundFeesData.push({
                    expenseType: expenses.name,
                    cost: +expenses.amount.toFixed(2),
                  });
                  annualManagementCost += +expenses.amount;
                }
              });
            }
          );

          // pension Calculations
          let pension_year = "No",
            pension_amount = 0;
          JSON.parse(pensionSummary)?.pensions?.forEach((pensionData) => {
            pensionData?.accounts.forEach((element) => {
              if (pension_year !== "Yes") {
                if (element.startDate !== null) {
                  pension_year = "Yes";
                }
              }
              pension_amount += +element.netPensionPayments;
            });
          });

          // Asset Performance Calculation
          let assetPerformance = [];
          JSON.parse(investmentSummary)?.investmentSummary?.data?.forEach(
            (currentFYAsset) => {
              let currentFY_1Asset = JSON.parse(
                investmentSummary_FY_1
              )?.investmentSummary?.data.find(
                (asset) => asset.code === currentFYAsset.code
              );
              let result = currentFY_1Asset
                ? currentFYAsset.realisedMovement !== 0 ||
                  currentFYAsset.currentYearUnRealisedMovement !== 0
                  ? +(
                      ((currentFYAsset.realisedMovement +
                        currentFYAsset.currentYearUnRealisedMovement) /
                        (currentFYAsset.cost -
                          currentFY_1Asset.cost +
                          currentFY_1Asset.mktValueAsPerLedger)) *
                      100
                    ).toFixed(2)
                  : 0
                : currentFYAsset.realisedMovement !== 0 ||
                  currentFYAsset.currentYearUnRealisedMovement !== 0
                ? +(
                    ((currentFYAsset.realisedMovement +
                      currentFYAsset.currentYearUnRealisedMovement) /
                      currentFYAsset.cost) *
                    100
                  ).toFixed(2)
                : 0;
              if (result !== 0 && result !== undefined)
                assetPerformance.push({
                  asset: currentFYAsset.investmentName,
                  performance: result,
                });
            }
          );
          assetPerformance = assetPerformance.filter(
            (asset) => asset.performance !== null
          );
          assetPerformance.sort(function (a, b) {
            return b.performance - a.performance;
          });

          // Final Object Formation
          const finalSurveyObj = {
            productSurveyId: product.surveyId,
            productId: product.id,
            clickedPageIndex: 0,
            data: {
              selectedFY: selectedFY,
              startDate: startDate,
              fundId: fundId,
              endDate: endDate,
              firmName: firmDetails?.businessName || "Unknown firm",
              smsf_members_number: members.length,
              smsf_member_questions: members,
              smsf_name: JSON.parse(entityDetails)?.name,
              smsf_trustee: JSON.parse(trustees).trusteeType + " Trustee",
              corp_directors: JSON.parse(trustees).trusteeOrDirector.length,
              smsf_balance: fundSmsfBalance.toFixed(2),
              deed_review_date:
                JSON.parse(entityDetails)?.trustDeedLastModifiedDate !== null
                  ? JSON.parse(entityDetails)?.trustDeedLastModifiedDate
                  : "",
              bdbn: isBdbn,
              taxable: taxable,
              strat: "No",
              fundRunningCost: {
                currentFY: {
                  realisedMovement: currentFYRealisedMovement,
                  currentYearUnRealisedMovement: currentFYUnRealisedMovement,
                  cost: currentFYCost,
                  mktValueAsPerLedger: currentFYMktValueAsPerLedger,
                },
                "currentFY-1": {
                  realisedMovement: currentFY_1_RealisedMovement,
                  currentYearUnRealisedMovement: currentFY_1_UnRealisedMovement,
                  cost: currentFY_1_Cost,
                  mktValueAsPerLedger: currentFY_1_MktValueAsPerLedger,
                },
                "currentFY-2": {
                  realisedMovement: currentFY_2_RealisedMovement,
                  currentYearUnRealisedMovement: currentFY_2_UnRealisedMovement,
                  cost: currentFY_2_Cost,
                  mktValueAsPerLedger: currentFY_2_MktValueAsPerLedger,
                },
                "currentFY-3": {
                  realisedMovement: currentFY_3_RealisedMovement,
                  currentYearUnRealisedMovement: currentFY_3_UnRealisedMovement,
                  cost: currentFY_3_Cost,
                  mktValueAsPerLedger: currentFY_3_MktValueAsPerLedger,
                },
              },
              smsf_allocation: {
                asset_aus: {
                  latest_allocation:
                    australian_shares && smsfBalance > 0
                      ? +((australian_shares / smsfBalance) * 100).toFixed(2)
                      : 0,
                  latest_value: australian_shares,
                  target_allocation_low: 0,
                  target_allocation_high: 0,
                },
                asset_intl: {
                  latest_allocation:
                    international_shares && smsfBalance > 0
                      ? +((international_shares / smsfBalance) * 100).toFixed(2)
                      : 0,
                  latest_value: international_shares,
                  target_allocation_low: 0,
                  target_allocation_high: 0,
                },
                asset_prop: {
                  latest_allocation:
                    property && smsfBalance > 0
                      ? +((property / smsfBalance) * 100).toFixed(2)
                      : 0,
                  latest_value: property,
                  target_allocation_low: 0,
                  target_allocation_high: 0,
                },
                asset_aus_fi: {
                  latest_allocation:
                    australian_fixed_interest && smsfBalance > 0
                      ? +(
                          (australian_fixed_interest / smsfBalance) *
                          100
                        ).toFixed(2)
                      : 0,
                  latest_value: australian_fixed_interest,
                  target_allocation_low: 0,
                  target_allocation_high: 0,
                },
                asset_intl_fi: {
                  latest_allocation:
                    international_fixed_interest && smsfBalance > 0
                      ? +(
                          (international_fixed_interest / smsfBalance) *
                          100
                        ).toFixed(2)
                      : 0,
                  latest_value: international_fixed_interest,
                  target_allocation_low: 0,
                  target_allocation_high: 0,
                },
                asset_cash: {
                  latest_allocation:
                    cash && smsfBalance > 0
                      ? +((cash / smsfBalance) * 100).toFixed(2)
                      : 0,
                  latest_value: cash,
                  target_allocation_low: 0,
                  target_allocation_high: 0,
                },
                asset_other: {
                  latest_allocation:
                    other && smsfBalance > 0
                      ? +((other / smsfBalance) * 100).toFixed(2)
                      : 0,
                  latest_value: other,
                  target_allocation_low: 0,
                  target_allocation_high: 0,
                },
              },
              contrib: contribs,
              pension:
                JSON.parse(pensionSummary)?.pensions?.length > 0 ? "Yes" : "No",
              pension_year: pension_year,
              pension_annual_amt: pension_amount.toFixed(2),
              overall_fund_cost: annualManagementCost.toFixed(2),
              bfr: bring_forward_rule,
              bfr_member: bring_forward_rule_member,
              property_invest: property > 0 ? "Yes" : "No",
              property_number: propertyArray.length,
              property_panel: propertyData,
              perf_review: "",
              perf_asset_questions: {
                perf_aus: {
                  perf_asset_classes_percent:
                    currentFYAustralianSharesPerformanceValue.currentFYRealisedMovement !==
                      0 ||
                    currentFYAustralianSharesPerformanceValue.currentFYUnRealisedMovement !==
                      0
                      ? +(
                          ((currentFYAustralianSharesPerformanceValue.currentFYRealisedMovement +
                            currentFYAustralianSharesPerformanceValue.currentFYUnRealisedMovement) /
                            (currentFYAustralianSharesPerformanceValue.currentFYCost -
                              currentFY_1_AustralianSharesPerformanceValue.currentFYCost +
                              currentFY_1_AustralianSharesPerformanceValue.currentFYMktValueAsPerLedger)) *
                          100
                        ).toFixed(2)
                      : 0,
                },
                perf_intl: {
                  perf_asset_classes_percent:
                    currentFYInternationalSharesPerformanceValue.currentFYRealisedMovement !==
                      0 ||
                    currentFYInternationalSharesPerformanceValue.currentFYUnRealisedMovement !==
                      0
                      ? +(
                          ((currentFYInternationalSharesPerformanceValue.currentFYRealisedMovement +
                            currentFYInternationalSharesPerformanceValue.currentFYUnRealisedMovement) /
                            (currentFYInternationalSharesPerformanceValue.currentFYCost -
                              currentFY_1_InternationalSharesPerformanceValue.currentFYCost +
                              currentFY_1_InternationalSharesPerformanceValue.currentFYMktValueAsPerLedger)) *
                          100
                        ).toFixed(2)
                      : 0,
                },
                perf_prop: {
                  perf_asset_classes_percent:
                    currentFYPropertyPerformanceValue.currentFYRealisedMovement !==
                      0 ||
                    currentFYPropertyPerformanceValue.currentFYUnRealisedMovement !==
                      0
                      ? +(
                          ((currentFYPropertyPerformanceValue.currentFYRealisedMovement +
                            currentFYPropertyPerformanceValue.currentFYUnRealisedMovement) /
                            (currentFYPropertyPerformanceValue.currentFYCost -
                              currentFY_1_PropertyPerformanceValue.currentFYCost +
                              currentFY_1_PropertyPerformanceValue.currentFYMktValueAsPerLedger)) *
                          100
                        ).toFixed(2)
                      : 0,
                },
                perf_aus_fi: {
                  perf_asset_classes_percent:
                    currentFYAustralianFixedInterestPerformanceValue.currentFYRealisedMovement !==
                      0 ||
                    currentFYAustralianFixedInterestPerformanceValue.currentFYUnRealisedMovement !==
                      0
                      ? +(
                          ((currentFYAustralianFixedInterestPerformanceValue.currentFYRealisedMovement +
                            currentFYAustralianFixedInterestPerformanceValue.currentFYUnRealisedMovement) /
                            (currentFYAustralianFixedInterestPerformanceValue.currentFYCost -
                              currentFY_1_AustralianFixedInterestPerformanceValue.currentFYCost +
                              currentFY_1_AustralianFixedInterestPerformanceValue.currentFYMktValueAsPerLedger)) *
                          100
                        ).toFixed(2)
                      : 0,
                },
                perf_intl_fi: {
                  perf_asset_classes_percent:
                    currentFYInternationalFixedInterestPerformanceValue.currentFYRealisedMovement !==
                      0 ||
                    currentFYInternationalFixedInterestPerformanceValue.currentFYUnRealisedMovement !==
                      0
                      ? +(
                          ((currentFYInternationalFixedInterestPerformanceValue.currentFYRealisedMovement +
                            currentFYInternationalFixedInterestPerformanceValue.currentFYUnRealisedMovement) /
                            (currentFYInternationalFixedInterestPerformanceValue.currentFYCost -
                              currentFY_1_InternationalFixedInterestPerformanceValue.currentFYCost +
                              currentFY_1_InternationalFixedInterestPerformanceValue.currentFYMktValueAsPerLedger)) *
                          100
                        ).toFixed(2)
                      : 0,
                },
                perf_cash: {
                  perf_asset_classes_percent:
                    currentFYCashPerformanceValue.currentFYRealisedMovement !==
                      0 ||
                    currentFYCashPerformanceValue.currentFYUnRealisedMovement !==
                      0
                      ? +(
                          ((currentFYCashPerformanceValue.currentFYRealisedMovement +
                            currentFYCashPerformanceValue.currentFYUnRealisedMovement) /
                            (currentFYCashPerformanceValue.currentFYCost -
                              currentFY_1_CashPerformanceValue.currentFYCost +
                              currentFY_1_CashPerformanceValue.currentFYMktValueAsPerLedger)) *
                          100
                        ).toFixed(2)
                      : 0,
                },
                perf_other: {
                  perf_asset_classes_percent:
                    currentFYOtherPerformanceValue.currentFYRealisedMovement !==
                      0 ||
                    currentFYOtherPerformanceValue.currentFYUnRealisedMovement !==
                      0
                      ? +(
                          ((currentFYOtherPerformanceValue.currentFYRealisedMovement +
                            currentFYOtherPerformanceValue.currentFYUnRealisedMovement) /
                            (currentFYOtherPerformanceValue.currentFYCost -
                              currentFY_1_OtherPerformanceValue.currentFYCost +
                              currentFY_1_OtherPerformanceValue.currentFYMktValueAsPerLedger)) *
                          100
                        ).toFixed(2)
                      : 0,
                },
              },
              assetPerformance: assetPerformance,
              fundFeesData: fundFeesData,
            },
          };

          return finalSurveyObj;
        }
      }
    } catch (e) {
      console.log(e);
    }
  };
  // Function to load data from class Super
  const loadClassSuperDataBySubFundId = async (
    sub,
    productId,
    fundId,
    selectedFY
  ) => {
    console.trace();
    try {
      if (!fundId) {
        history.replace("/fundsList");
      }
      let result =
        await questionnaireResponseService.getQuestionnaireResponseByFundId(
          fundId
        );
      if (
        result &&
        result.MCUStatus === "done" &&
        productId === result.MCUData.productId
      ) {
        setMCUStatus("done");
      } else if (
        result &&
        result.MCUData &&
        JSON.parse(result.MCUData.data).firmName &&
        productId === result.MCUData.productId
      ) {
        setSurveyResponse(result.MCUData);
      } else {
        if (!surveyResponse && !isCalledGetClassSuperData) {
          setIsCalledGetClassSuperData(true);
          let finalSurveyObj;
          try {
            let csFormRes = await classSuperService.getClassSuperFormData(
              fundId,
              firmDetails?.businessName || "Unknown Firm",
              selectedFY
            );

            const csFormResParse = JSON.parse(csFormRes);

            console.log(">>>>>>>>>>>>>>>>", csFormResParse);

            // Final Object Formation

            finalSurveyObj = { data: csFormResParse.body.survey };
          } catch (error) {
            console.log("CatchError", error);
            if (error.errors[0].errorType === "Lambda:Unhandled") {
              seterrorModal(true);
            }
          }
          return finalSurveyObj;
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  const shouldUseExtraReports = () =>
    remainingReports < 1 && remainingExtraReports > 0;

  const _hasUnlimitedReportsIncluded =
    hasUnlimitedReportsIncluded(totalReportsCount);
  const addPurchase = async () => {
    const date = new Date();
    await purchaseService.addPurchase({
      createdAt: date,
      updatedAt: date,
      productType: selectedProduct.surveyId,
      productId: selectedProduct.id,
      sub: user?.sub,
      activationDate: date.getTime(),
      questionnaireResponseId: NIL,
      expiryDate: date.setFullYear(date.getFullYear() + 1),
      status: PurchasedStatus.pending,
    });
  };

  const handleReportConfirmation = async () => {
    dispatch(surveyActions.setIsExtraReportUsed(false));

    if (shouldUseExtraReports()) {
      const extraReportPurchase = extraReports.pop();
      await purchaseService.updatePurchaseStatus(
        extraReportPurchase.id,
        PurchasedStatus.used
      );
      dispatch(surveyActions.setIsExtraReportUsed(true));
    }
    //disable the ReportConfirmationButton button
    //after clicking on it to prevent multiple clicks
    setIsButtonDisabled(true);
    setIsLoading(true);

    await addPurchase();

    let response;
    if (user.consentType === "class_super") {
      response = await loadClassSuperDataBySubFundId(
        user?.sub,
        product.id,
        fundID,
        selectedFY
      );
    } else {
      response = await loadDataBySubFundId(
        user?.sub,
        product.id,
        fundID,
        selectedFY
      );
    }

    if (response?.data !== undefined) {
      dispatch(surveyActions.handleLoadSurveyData(response.data));
      dispatch(headerActions.handleCloseNewReportConfirmation());
      dispatch(surveyActions.handleOpenSurveyForm(true));
    } else {
      console.log("loadDataBySubFundId has undefined response");
      seterrorModal(true);
    }
    setIsLoading(false);
  };

  function getAvailableFYs() {
    let yearOptions = [];

    const month = new Date().getMonth();
    const endYear =
      month < 6 ? new Date().getFullYear() - 1 : new Date().getFullYear();
    for (let i = 2022; i <= endYear; i++) {
      yearOptions.push(
        <option value={i}>
          FY {i} (From 1 July {i - 1} to 30 June {i})
        </option>
      );
    }

    // yearOptions.push(<option value="2022">FY 2022 (From 1 July 2021 to 30 June 2022)</option>);

    return yearOptions;
  }

  const updateSelectedFY = (event) => {
    dispatch(headerActions.handleSelectedFY(event.target.value));
  };

  return (
    <>
      {errorModal && (
        <ErrorModal
          modalState={errorModal}
          seterrorModal={() => seterrorModal(false)}
          fetchData={() => {
            handleReportConfirmation();
          }}
        />
      )}
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div>
          <Box
            className={classes.bglConsentBox}
            sx={{
              width: `${smallDeviceMatch ? "calc(100% - 32px)" : "638px"}`,
            }}
          >
            <X className={classes.crossIcon} onClick={onClose} />
            <Grid mt={3}>
              <Typography
                className={classes.confirmLbl}
                style={{ textAlign: "center" }}
              >
                Confirm new report creation
              </Typography>
              <Typography
                className={classes.text14}
                style={{ textAlign: "center", marginBottom: "20px" }}
              >
                Please select the Financial Year to use for this report:&nbsp;
                <select onChange={updateSelectedFY} defaultValue={selectedFY}>
                  {getAvailableFYs().map((opt) => opt)}
                </select>
              </Typography>
              <Typography
                className={classes.text14}
                style={{ textAlign: "center", marginBottom: "20px" }}
              >
                {hasRemainingReports
                  ? "This Report will be generated using one of your available report credits included in your current subscription plan."
                  : "You have consumed all the report credits included in your current subscription plan."}
              </Typography>
              <Grid container justifyContent="center" my={2}>
                <Box sx={{ width: "50%", marginTop: "2px" }}>
                  {!_hasUnlimitedReportsIncluded && (
                    <SubscriptionStatistics
                      totalReportsCount={totalReportsCount}
                      usedReportsCount={usedReportsCount}
                      extraReportsCount={remainingExtraReports}
                    />
                  )}
                </Box>
              </Grid>
              <Typography
                className={classes.text14}
                style={{ textAlign: "center" }}
              >
                {_hasUnlimitedReportsIncluded ? (
                  <span>Your plan contains unlimited reports.</span>
                ) : hasRemainingReports ? (
                  <span>
                    Once this report has been generated, there will be{" "}
                    <b>{totalRemainingReports - 1}</b> remaining report credits
                    in your subscription.
                  </span>
                ) : (
                  <span>
                    In order to continue, you will need to purchase an
                    additional Report in the next step for{" "}
                    <b>${extraReportPrice}</b>.
                    <br />
                    The first extra report purchased will be activated
                    immediately after purchase, but you can complete it whenever
                    it suits you.
                  </span>
                )}
              </Typography>
            </Grid>
            <Grid mt={2} style={{ textAlign: "center" }}>
              {hasRemainingReports ? (
                <ReportConfirmationButton
                  variant="contained"
                  style={{ marginRight: "8px" }}
                  disabled={isButtonDisabled}
                  onClick={() => handleReportConfirmation()}
                >
                  {hasUnlimitedReportsIncluded
                    ? "Continue"
                    : "Use Credit and Continue"}
                </ReportConfirmationButton>
              ) : (
                <ReportConfirmationButton
                  variant="contained"
                  style={{ marginRight: "8px" }}
                  disabled={isButtonDisabled}
                  onClick={() => {
                    localStorage.setItem("selectedFundID", fundID);
                    window.location = getExtraReportLink(user.sub, user.email);
                  }}
                >
                  Purchase Additional Report
                </ReportConfirmationButton>
              )}

              <CancelButton variant="contained" onClick={() => onClose()}>
                Cancel
              </CancelButton>
            </Grid>
          </Box>
          <div
            style={{
              position: "absolute",
              left: "50%",
              top: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            {isLoading && <CircularProgress />}
          </div>
        </div>
      </Modal>
    </>
  );
}
