import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';

const BorderLinearProgressStyled = styled(LinearProgress)(() => ({
    height: 10,
    borderRadius: 5,
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 5,
        backgroundImage: 'linear-gradient(to right, #2162A5 , #912AFF)'
    },
}));

export default function BorderLinearProgress({ value }) {

    return (
        <>
            <BorderLinearProgressStyled variant="determinate" value={value} />
        </>
    )
}