import { API } from "aws-amplify";
import { getBglData, getBglDetails } from "../graphql/queries";

const bglService = {
    getUserBglData: async (operation, parameters) => {
        console.log("[BGL] Requesting BGL data for " + operation +" with the following parameters:");
        console.log(JSON.stringify(parameters));
        const response = await API.graphql({
            query: getBglData,
            variables: { operation, parameters: JSON.stringify(parameters) },
            authMode: "AMAZON_COGNITO_USER_POOLS"
        });
        console.log("Response for " +operation, response.data.getBglData);
        return response.data.getBglData;
    },
    getBglDetails: async () => {
        const response = await API.graphql({
            query: getBglDetails,
            variables: { },
            authMode: "AWS_IAM"
        });
        return JSON.parse(response.data.getBglDetails) || [];
    },
    getBglDetailsUP: async () => {
        const response = await API.graphql({
            query: getBglDetails,
            variables: { },
            authMode: "AMAZON_COGNITO_USER_POOLS"
        });
        return JSON.parse(response.data.getBglDetails) || [];
    }
};

export default bglService;