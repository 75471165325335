import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import { makeStyles } from "@mui/styles";
import { styled } from "@mui/material/styles";
import { Typography, Grid } from "@mui/material";
import Link from "@mui/material/Link";
import { X } from "react-feather";
import useMediaQuery from "@mui/material/useMediaQuery";

const FirmDetailsButton = styled(Button)({
  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
  textTransform: "none",
  fontSize: "14px",
  padding: "10px 20px",
  border: "none",
  fontWeight: 400,
  lineHeight: "24px",
  backgroundColor: "#1A2842",
  color: "white",
  fontFamily: ["Poppins"].join(","),
  "&:hover": {
    backgroundColor: "#1A2842",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
  },
  "&:active": {
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    backgroundColor: "#1A2842",
  },
  "&:focus": {
    backgroundColor: "#1A2842",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
  },
  "&:disabled": {
    color: "white",
    backgroundColor: "#8C94A0;",
  },
});

const useStyles = makeStyles((theme) => ({
  firmDetailsBox: {
    // display: 'flex',
    // flexDirection: 'column',
    // alignItems: 'flex-start',
    padding: "32px",
    position: "absolute",
    backgroundColor: "#FFFFFF",
    boxShadow: "4px 4px 6px rgba(0, 0, 0, 0.25)",
    borderRadius: "5px",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
    height: "calc(100% - 32px)",
    maxHeight: "830px",
    overflowX: "hidden",
    overflowY: "auto",
  },
  firmDetailsLbl: {
    fontFamily: "Poppins",
    fontWeight: 600,
    fontSize: "24px",
    lineHeight: "32px",
    color: "#000000",
    marginBlock: 0,
    marginBottom: 32,
  },
  errorHeading: {
    fontFamily: "Poppins",
    fontWeight: 600,
    fontSize: "24px",
    lineHeight: "32px",
    color: "#d50000",
    marginBlock: 0,
    marginBottom: 32,
  },
  textFieldLabel: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "24px",
    color: "#000000",
    marginBlock: 0,
  },
  smallTextFieldLabel: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "9px",
    lineHeight: "16px",
    color: "#000000",
    letterSpacing: "0.1em",
  },
  red: {
    color: "red",
  },
  textField: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    background: "#ECEFF1",
    alignSelf: "stretch",
    height: "44px",
    paddingLeft: 8,
    paddingRight: 8,
  },
  text14: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "24px",
  },
  crossIcon: {
    position: "absolute",
    right: "24px",
    cursor: "pointer",
  },
  marginBtm: {
    marginTop: "0px",
    marginBottom: "32px",
  },
  bglConsentBox: {
    // display: 'flex',
    // flexDirection: 'column',
    // alignItems: 'flex-start',
    padding: "32px",
    position: "absolute",
    backgroundColor: "#FFFFFF",
    boxShadow: "4px 4px 6px rgba(0, 0, 0, 0.25)",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
    height: "40%",
    width: "765px",
    maxHeight: "40%",
    overflowX: "hidden",
    overflowY: "auto",
  },
  circle: {
    stroke: "url(#linearColors)",
  },
}));

export default function ErrorModal(props) {
  const classes = useStyles();

  const smallDeviceMatch = !useMediaQuery("(min-width:656px)");

  return (
    <>
      <Modal
        open={props.modalState}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          className={classes.firmDetailsBox}
          sx={{
            width: `${smallDeviceMatch ? "calc(100% - 32px)" : "638px"}`,
          }}
        >
          <X
            className={classes.crossIcon}
            onClick={() => props.seterrorModal(false)}
          />
          <Typography className={classes.errorHeading}>ERROR !!!</Typography>

          <Box>
            <Grid>
              <Typography variant="smaller" className="profileHeading">
                Something went wrong. Please fill in the data yourself or fetch
                the data again.
              </Typography>
            </Grid>
            <Typography mt={5} sx={{ textAlign: "end" }}>
              <Link
                onClick={() => props.seterrorModal(false)}
                underline="always"
                color="inherit"
                className={classes.text14}
                style={{
                  cursor: "pointer",
                  textUnderlineOffset: "8pt",
                  color: "black",
                  marginLeft: smallDeviceMatch ? 0 : 150,
                }}
              >
                Cancel
              </Link>
              <FirmDetailsButton
                variant="contained"
                style={{ marginLeft: 34 }}
                type="button"
                onClick={() => {
                  props.fetchData();
                }}
              >
                Fetch Data
              </FirmDetailsButton>
            </Typography>
          </Box>
        </Box>
      </Modal>
    </>
  );
}
