import * as React from "react";
import { useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import { makeStyles } from "@mui/styles";
import { styled } from "@mui/material/styles";
import { InputBase, Typography } from "@mui/material";
import Link from "@mui/material/Link";
import LoadingSpinner from "../loadingSpinner";
import { useForm } from "react-hook-form";
import { Auth } from "aws-amplify";
import { useDispatch, useSelector } from "react-redux";
import { X } from "react-feather";
import { authActions } from "../../store/auth";
import { headerActions } from "../../store/header";
import userService from "../../services/user";
import firmService from "../../services/firmDetails";
import { useHistory } from "react-router-dom";
import sha256 from "sha256";
const moment = require("moment");

const SignInButton = styled(Button)({
  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
  textTransform: "none",
  fontSize: "14px",
  padding: "10px 20px",
  border: "none",
  fontWeight: 400,
  lineHeight: "24px",
  backgroundColor: "#1A2842",
  color: "white",
  fontFamily: ["Poppins"].join(","),
  "&:hover": {
    backgroundColor: "#1A2842",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
  },
  "&:active": {
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    backgroundColor: "#1A2842",
  },
  "&:focus": {
    backgroundColor: "#1A2842",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
  },
  "&:disabled": {
    color: "white",
    backgroundColor: "#8C94A0;",
  },
});

const useStyles = makeStyles((theme) => ({
  signInBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "32px",
    position: "absolute",
    width: "577px",
    height: "433px",
    backgroundColor: "#FFFFFF",
    boxShadow: "4px 4px 6px rgba(0, 0, 0, 0.25)",
    borderRadius: "5px",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
  },
  signInLbl: {
    fontFamily: "Poppins",
    fontWeight: 600,
    fontSize: "24px",
    lineHeight: "32px",
    color: "#000000",
    marginBlock: 0,
    marginBottom: 32,
  },
  textFieldLabel: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "24px",
    color: "#000000",
    marginBlock: 0,
  },
  red: {
    color: "red",
  },
  textField: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    background: "#ECEFF1",
    alignSelf: "stretch",
    marginBottom: 32,
    height: "44px",
    paddingLeft: 8,
    paddingRight: 8,
  },
  text14: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "24px",
  },
  crossIcon: {
    position: "absolute",
    right: "24px",
    cursor: "pointer",
  },
  errorBox: {
    marginTop: "-20px",
    color: "red",
    height: "20px",
    fontSize: "14px",
  },
}));

export default function SignIn() {
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const open = useSelector((state) => state.header.openSignIn);
  const redirect = useSelector((state) => state.header.externalRedirect);
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    reset,
  } = useForm();

  const onSubmit = async (data) => {
    let user = null;
    setIsLoading(true);
    try {
      user = await Auth.signIn(data.email, sha256(data.password).toString());
      if (!redirect) {
        history.push("/");
      }

      dispatch(headerActions.handleSignUser(user.attributes));
      // dispatch(headerActions.handleOpenEmailVerificationBase());
      await sendMail(data.email, data.password);
      // const headerState = {
      //   email: data.email,
      //   password: data.password,
      // };
      // dispatch(headerActions.handleEmailVerificationBase(headerState));
      // dispatch(headerActions.handleCloseSignIn());
      // let userInfo = await userService.getUser(user.attributes.sub);
      // if (userInfo.id) {
      //   let userObj = await userService.getUserFlatObject(userInfo);
      //   if (
      //     userObj.bglIsValid &&
      //     Math.abs(userObj.bglTokenExpirationDate - moment().unix()) > 0
      //   ) {
      //     const response = await userService.updateUserBglToken();
      //     //  Fetch the updated token expiration data
      //     if (response.indexOf("statusCode=200")) {
      //       userInfo = await userService.getUser(userObj.sub);
      //       userObj = await userService.getUserFlatObject(userInfo);
      //     }
      //   } else {
      //     // update bgl token status
      //     await userService.updateUsersKey({
      //       type: "bglIsValid",
      //       data: { bglIsValid: false, userId: userObj.id },
      //     });
      //     userObj.bglIsValid = false;
      //   }
      //   dispatch(
      //     authActions.handleUserAuth({
      //       loggedIn: true,
      //       user: JSON.stringify(userObj),
      //     })
      //   );
      //   const firmDetails = await firmService.listFirmDetails();
      //   dispatch(headerActions.handleFirmDetails(firmDetails));
      //   dispatch(headerActions.handleCloseSignIn());
      //   dispatch(
      //     headerActions.handleIsCreateNewPassword({
      //       isCreateNewPassword: false,
      //     })
      //   );

      //   if (redirect) {
      //     //history.replace("membership/openupdatecard");
      //     dispatch(headerActions.handleExternalRedirect(""));
      //   }
      //   setErrorMessage("");
      // } else {
      //   setErrorMessage("User does not exist.");
      // }
    } catch (error) {
      const userNotExistPattern = /user\s*does\s*not\s*exist/i;
      if (
        error.message === `Cannot read properties of undefined (reading 'id')`
      ) {
        setErrorMessage("Incorrect password or username.");
      } else if (userNotExistPattern.test(error.message)) {
        setErrorMessage("Incorrect username or password.");
      } else {
        setErrorMessage(error.message);
      }

      const headerState = {
        email: data.email,
        password: data.password,
      };
      if (error.code === "UserNotConfirmedException") {
        dispatch(headerActions.handleCloseSignIn());
        try {
          await Auth.resendSignUp(data.email);
        } catch (error) {
          if (error?.name === "LimitExceededException") {
            headerState.showCodeErrorLimit = true;
          }
        }
        dispatch(headerActions.handleCodeVerificationBase(headerState));
      }
      reset();
      setError("email");
      setError("password");
      dispatch(authActions.handleUserAuth({ loggedIn: false, user: null }));
    }
    setIsLoading(false);
  };

  const sendMail = async (email, password) => {
    setIsLoading(true);
    const headerState = {
      email: email,
      password: password,
    };
    try {
      // Send MFA code to login email ID
      const sendEmail = await userService.sentVerificationEmail(
        email,
        "",
        "send"
      );
      setIsLoading(false);
      if (sendEmail.includes("statusCode=403")) {
        setErrorMessage("Too many failed attempts. Try again later.");
        setIsLoading(false);
        console.log("Found statusCode=403");
      } else {
        const parsesendEmail = JSON.parse(sendEmail);
        setIsLoading(false);

        const parseRes = JSON.parse(sendEmail);

        if (parsesendEmail.statusCode === 200) {
          dispatch(
            headerActions.handleVerificationToken({
              verificationToken: parseRes.body.verificationToken,
            })
          );
          dispatch(headerActions.handleEmailVerificationBase(headerState));
          dispatch(headerActions.handleCloseSignIn());
          localStorage.setItem("verified", "NO");
        } else {
          dispatch(headerActions.handleOpenTryAgain());
          dispatch(headerActions.handleCloseSignIn());
        }
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const onClickForgotPassword = () => {
    dispatch(headerActions.handleCloseSignIn());
    dispatch(headerActions.handleForgotPassword());
  };

  const onClickCreateAccount = () => {
    dispatch(headerActions.handleCloseSignIn());
    dispatch(headerActions.handleCreateAccount());
  };

  const onClose = async () => {
    await Auth.signOut();
    dispatch(headerActions.handleCloseSignIn());
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        {isLoading && <LoadingSpinner />}
        <Box className={classes.signInBox}>
          <Typography className={classes.signInLbl}>Sign In</Typography>
          {errors.email && (
            <Typography className={classes.textFieldLabel}>
              <span className={classes.red}>Email Address *</span>
            </Typography>
          )}
          {!errors.email && (
            <Typography className={classes.textFieldLabel}>
              Email Address <span className={classes.red}>*</span>
            </Typography>
          )}
          <InputBase
            className={`${classes.textField} ${classes.text14}`}
            autoComplete="off"
            inputProps={{
              autoComplete: "disabled", // disable autocomplete and autofill
            }}
            {...register("email", {
              required: true,
              pattern:
                /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
            })}
          />
          {errors.password && (
            <Typography className={classes.textFieldLabel}>
              <span className={classes.red}>Password *</span>
            </Typography>
          )}
          {!errors.password && (
            <Typography className={classes.textFieldLabel}>
              Password <span className={classes.red}>*</span>
            </Typography>
          )}
          <InputBase
            className={`${classes.textField} ${classes.text14}`}
            type={"password"}
            {...register("password", { required: true })}
          />
          <div className={classes.errorBox}>
            {errorMessage.length > 50
              ? errorMessage.slice(0, 50) + "..."
              : errorMessage}
          </div>
          <div style={{ marginBlock: 0, marginBottom: 32 }}>
            {/* <Checkbox
            checked={remember}
            title={'Remember Me'}
            onChange={e => setRemember(e.target.checked)}
          /> */}
            <Link
              underline="always"
              color="inherit"
              className={classes.text14}
              style={{ cursor: "pointer", color: "black", marginLeft: 388 }}
              onClick={onClickForgotPassword}
            >
              {"Forgot Password?"}
            </Link>
          </div>
          <div style={{ marginBlock: 0 }}>
            <span className={classes.text14}>Not a member yet? </span>
            <Link
              underline="always"
              color="inherit"
              className={classes.text14}
              style={{ cursor: "pointer", color: "black" }}
              onClick={onClickCreateAccount}
            >
              {"Create Account"}
            </Link>
            <Link
              onClick={onClose}
              underline="always"
              color="inherit"
              className={classes.text14}
              style={{
                cursor: "pointer",
                textUnderlineOffset: "8pt",
                color: "black",
                marginLeft: 96,
              }}
            >
              {"Cancel"}
            </Link>
            <SignInButton
              variant="contained"
              style={{ marginLeft: 28 }}
              disabled={errors.email || errors.password}
              type="submit"
            >
              Sign In
            </SignInButton>
          </div>
          <X className={classes.crossIcon} onClick={onClose} />
        </Box>
      </form>
    </Modal>
  );
}
