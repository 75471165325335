import * as React from "react";
import Box from "@mui/material/Box";
import { useEffect } from "react";
import { makeStyles } from "@mui/styles";
import { Typography, Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import ConsentExpired from "./consent-expired.svg";
import ConsentApproved from "./consent-approved.svg";
import { TertiaryBtnDark20 } from "../../assets/primitives/buttons";
import { headerActions } from "../../store/header";
import classSuperService from "../../services/superClass";
import SuperClassConsentSpinner from "../superClassConsentSpinner";
const moment = require("moment");

const useStyles = makeStyles((theme) => ({
  consentBox: {
    // position: "absolute",
    width: "232px",
    height: "187.5px",
    left: "650px",
    top: "484px",
    display: "flex",
  },
  createAccountBox: {
    marginTop: "150px",
    marginBottom: "150px",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "16px",
    width: "800px",
    height: "auto",
    marginLeft: "auto",
  },
  textAlign: {
    marginLeft: "auto",
    display: "table-cell",
    width: "250px",
    height: "200px",
    padding: "10px",
    border: "3px dashed #1c87c9",
    verticalAlign: "middle",
    textAlign: "center",
  },
  text28: {
    textAlign: "center",
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "28px",
    lineHeight: "32px",
  },
  text14: {
    width: "250px",
    overflowWrap: "break-word",
    wordWrap: "break-word",
    wordBreak: "break-word",
    fontSize: "14px",
    textAlign: "center",
  },
  text12: {
    width: "250px",
    overflowWrap: "break-word",
    wordWrap: "break-word",
    wordBreak: "break-word",
    fontSize: "12px",
    textAlign: "center",
  },
}));

export default function ClassSuperConsentStatus(props) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const isUserLoggedIn = useSelector((state) => state.auth.loggedIn);
  const user = useSelector((state) => state.auth.user);
  const [redirectUri, setRedirectUri] = React.useState("");
  const [superClassClientId, setSuperClassClientId] = React.useState("");
  const [integrationsUri, setIntegrationsUri] = React.useState("");

  const openInNewTab = async (url) => {
    //await userService.addDataToUserState(user.id, user.state)

    dispatch(headerActions.handleOpenSuperClassTokenUpdateFlow());
    dispatch(headerActions.handleOpenSuperClassConsentSpinner());
    let height = 600;
    let width = 900;
    let left = (window.screen.width - width) / 2;
    let top = (window.screen.height - height) / 2;
    window.open(
      url,
      "_blank",
      `location=yes,top=${top},left=${left},width=${width},height=${height}`
    );
  };

  async function fetchData() {
    try {
      if (!redirectUri) {
        let response = await classSuperService.getClassSuperDetailsUP();

        const { clientId, redirectUri, integrationsUri, scope } =
          response?.body;

        setRedirectUri(redirectUri);
        setSuperClassClientId(clientId);
        setIntegrationsUri(integrationsUri);

        openInNewTab(
          `${integrationsUri}/connect/authorize?response_type=code&client_id=${clientId}&redirect_uri=${redirectUri}&scope=${scope}`
          //LOCALURL
          // `${integrationsUri}/connect/authorize?response_type=code&client_id=${clientId}&redirect_uri=http://localhost:3000/redirect&scope=${scope}`
        );
      }
    } catch (e) {
      //console.log(e);
    }
  }

  return (
    <>
      {isUserLoggedIn && user && user.email && (
        <Box
          // className={classes.createAccountBox}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            paddingTop: "5%",
            paddingBottom: "5%",
            width: "100%",
          }}
        >
          {!user.classSuperIsValid && (
            <Grid
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Grid container>
                <a href="/">
                  <img
                    src={ConsentExpired}
                    style={{ height: 75, width: 150 }}
                    alt="consent_expired"
                  />
                </a>
              </Grid>
              <Grid container mt={0} spacing={6}>
                <Grid
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    mt={2}
                    className={classes.text28}
                    style={{ textAlign: "center" }}
                  >
                    Consent Expired
                  </Typography>
                  <Typography
                    className={classes.text14}
                    style={{ color: "#EC0018", textAlign: "center" }}
                  >
                    Take a moment to consent so you can access your list of
                    funds
                  </Typography>
                  <Box
                    sx={{ marginTop: "20px" }}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Box variant="small">
                      <div>
                        <TertiaryBtnDark20
                          // onClick={() =>
                          //   openInNewTab(
                          //     `${integrationsUri}/u/oauth/authorize?response_type=code&client_id=${superClassClientId}&scope=investment&redirect_uri=${redirectUri}&state=${user.state}`
                          //   )
                          // }
                          onClick={() => {
                            fetchData();
                          }}
                          value={"Login and Consent"}
                        />
                      </div>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          )}
          {user.classSuperIsValid &&
          // Math.abs(user.classSuperTokenExpirationDate - moment().unix())
          // Math.ceil(
          //   Math.abs(
          //     new Date(user.classSuperTokenExpirationDate).getMinutes()
          //   )
          // ) > 0 && (
          Date.now() > user.classSuperRefreshExpirationDate ? (
            <Grid
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Grid container>
                <a href="/">
                  <img
                    src={ConsentExpired}
                    style={{ height: 75, width: 150 }}
                    alt="consent_approved"
                  />
                </a>
              </Grid>
              <Grid container mt={0} spacing={6}>
                <Grid
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    mt={2}
                    className={classes.text28}
                    style={{ textAlign: "center" }}
                  >
                    Consent Expired
                  </Typography>
                  <Typography
                    className={classes.text14}
                    style={{ color: "#EC0018", textAlign: "center" }}
                  >
                    Take a moment to consent so you can access your list of
                    funds
                  </Typography>
                  <Box
                    sx={{ marginTop: "20px" }}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Box variant="small">
                      <div>
                        <TertiaryBtnDark20
                          // onClick={() =>
                          //   openInNewTab(
                          //     `${integrationsUri}/u/oauth/authorize?response_type=code&client_id=${superClassClientId}&scope=investment&redirect_uri=${redirectUri}&state=${user.state}`
                          //   )
                          // }
                          onClick={() => {
                            fetchData();
                          }}
                          value={"Login and Consent"}
                        />
                      </div>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          ) : (
            <Grid>
              <Grid
                container
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <a href="/">
                  <img
                    src={ConsentApproved}
                    style={{ height: 75, width: 150 }}
                    alt="consent_approved"
                  />
                </a>
              </Grid>
              <Grid
                container
                mt={0}
                // spacing={6}
                style={{
                  display: "flex",
                  justifyContent: "center",

                  alignItems: "center",
                }}
              >
                <Grid
                  container
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    mt={2}
                    style={{ textAlign: "center" }}
                    className={classes.text28}
                  >
                    Your Class Super Account is linked
                  </Typography>
                </Grid>
                <Grid
                  style={{
                    display: "flex",
                    justifyContent: "center",

                    alignItems: "center",
                  }}
                >
                  <Typography
                    className={classes.text14}
                    style={{ color: "#2AD000" }}
                  >
                    (expires in{" "}
                    {moment(user?.classSuperRefreshExpirationDate).diff(
                      moment(),
                      "days"
                    )}{" "}
                    days)
                  </Typography>
                </Grid>
              </Grid>
              {
                <Box
                  sx={{ marginTop: "50px" }}
                  style={{
                    display: "flex",
                    justifyContent: "center",

                    alignItems: "center",
                  }}
                >
                  <Box
                    variant="small"
                    ml={0}
                    style={{ display: "flex", textAlign: "center" }}
                  >
                    <Box variant="small">
                      <div>
                        <TertiaryBtnDark20
                          // onClick={() =>
                          //   openInNewTab(
                          //     `${integrationsUri}/u/oauth/authorize?response_type=code&client_id=${superClassClientId}&scope=investment&redirect_uri=${redirectUri}&state=${user.state}`
                          //   )
                          // }
                          onClick={() => {
                            fetchData();
                          }}
                          value={"Login and Consent"}
                        />
                      </div>
                    </Box>
                  </Box>
                </Box>
              }
            </Grid>
          )}
          <SuperClassConsentSpinner />
        </Box>
      )}
    </>
  );
}
