import * as React from "react";
import Box from "@mui/material/Box";
import { makeStyles } from "@mui/styles";
import Modal from "@mui/material/Modal";

const useStyles = makeStyles((theme) => ({
    bglConsentBox: {
        position: "absolute",
        backgroundColor: "#FFFFFF",
        height: "100%",
        width: "100%",
        overflowX: "hidden",
        overflowY: "auto",
    },
}));

export default function HandleRedirectUri() {
    const classes = useStyles();
    const open = true;
    window.close();
    return (
        <Modal
            open={open}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box
                className={classes.bglConsentBox}
            >
            </Box>
        </Modal >
    );
}