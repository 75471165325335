import { API, graphqlOperation } from 'aws-amplify';
import { getDocumentByProductId, getDocumentByQRId } from '../graphql/queries';
import { updateGeneratedDocument } from "../graphql/mutations";

const documentService = {
  getDocumentByProductId: async (productId) => {
    const response = await API.graphql({
      query: getDocumentByProductId,
      variables: { productId },
      authMode: "AMAZON_COGNITO_USER_POOLS"
    });
    if (response.data?.getDocumentByProductId?.items?.length > 0) {
      return response.data?.getDocumentByProductId?.items?.reduce((a, b) => {
        return new Date(a.createdDate).getTime() > new Date(b.createdDate).getTime() ? a : b;
      });
    } else {
      return undefined;
    }
  },
  getDocumentByQRId: async (QuestionnaireResponseId) => {
    try {
      const response = await API.graphql({
        query: getDocumentByQRId,
        variables: { QuestionnaireResponseId },
        authMode: "AMAZON_COGNITO_USER_POOLS"
      });
      return response.data?.getDocumentByQRId?.items || [];
    } catch (e) {
      // console.log("e", e);
    }
  },
  updateDocumentIsNew: async () => {
    let documents = await documentService.getDocument();
    documents = documents?.filter(item => item.isNew === true);
    for (const document of documents) {
      await API.graphql(graphqlOperation(updateGeneratedDocument, {
        input: {
          id: document.id,
          isNew: false
        }
      }));
    }
  }
}

export default documentService;