import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Link from "@mui/material/Link";
import Button from "@mui/material/Button";
import { useDispatch, useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import { purpleLogo } from "../../assets/svg/svg";
import "./cardModal.css";
import { headerActions } from "../../store/header";
import { productTypes, surveyIds } from "../../services/constants";
import { X } from "react-feather";
import { useHistory } from "react-router-dom";
import FullDialog from "./FullDialog/fullDialog";
import Timer from "./Timer";
import DOMPurify from "dompurify";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { Storage } from "aws-amplify";
import { useEffect } from "react";
import bglService from "../../services/bgl";
import userService from "../../services/user";
import classSuperService from "../../services/superClass";

const moment = require("moment");

const CancelButton = styled(Button)({
  height: "44px",
  fontSize: "14px",
  fontWeight: 400,
  lineHeight: "24px",
  background: "#FFFFFF",
  border: "1px solid #1A2842",
  boxSizing: "border-box",
  color: "#1A2842",
  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
  borderRadius: "5px",
  fontFamily: ["Poppins"].join(","),
  "&:hover": {
    backgroundColor: "#FFFFFF",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
  },
  "&:active": {
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    backgroundColor: "#FFFFFF",
  },
  "&:focus": {
    backgroundColor: "#FFFFFF",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
  },
});

const AddToCartButton = styled(Button)({
  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
  textTransform: "none",
  fontSize: "14px",
  padding: "10px 20px",
  border: "none",
  fontWeight: 400,
  lineHeight: "24px",
  backgroundColor: "#1A2842",
  color: "white",
  fontFamily: ["Poppins"].join(","),
  "&:hover": {
    backgroundColor: "#1A2842",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
  },
  "&:active": {
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    backgroundColor: "#1A2842",
  },
  "&:focus": {
    backgroundColor: "#1A2842",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
  },
  "&:disabled": {
    color: "white",
    backgroundColor: "#8C94A0;",
  },
});

export default function CardModal({
  open,
  handleClose,
  product,
  mcuProductId,
}) {
  const history = useHistory();
  const dispatch = useDispatch();
  const isUserLoggedIn = useSelector((state) => state.auth.loggedIn);
  const user = useSelector((state) => state.auth.user);
  const [openSMSF, setOpenSMSF] = React.useState(false);
  const [fileUrl, setFileUrl] = React.useState("");
  const [redirectUri, setRedirectUri] = React.useState("");
  const [bglClientId, setBglClientId] = React.useState("");
  const [integrationsUri, setIntegrationsUri] = React.useState("");

  const [superClassClientId, setSuperClassClientId] = React.useState("");

  React.useEffect(() => {
    if (product?.imageDetail) {
      Storage.get(product?.imageDetail)
        .then((data) => setFileUrl(data))
        .catch((err) => console.log("error fetching image"));
    } else {
      Storage.get(product?.image)
        .then((data) => setFileUrl(data))
        .catch((err) => console.log("error fetching image"));
    }
  }, [product]);

  useEffect(() => {}, [
    redirectUri,
    bglClientId,
    superClassClientId,
    integrationsUri,
  ]);

  async function fetchData() {
    if (user?.consentType === "class_super") {
      try {
        if (!redirectUri) {
          let response = await classSuperService.getClassSuperDetails();

          const { clientId, redirectUri, integrationsUri, scope } =
            response?.body;

          setRedirectUri(redirectUri);
          setSuperClassClientId(clientId);
          setIntegrationsUri(integrationsUri);

          openInNewTab(
            `${integrationsUri}/connect/authorize?response_type=code&client_id=${clientId}&redirect_uri=${redirectUri}&scope=${scope}`
            // LOCALURL
            // `${integrationsUri}/connect/authorize?response_type=code&client_id=${clientId}&redirect_uri=http://localhost:3000/redirect&scope=${scope}`
          );
        }
      } catch (e) {
        //console.log(e);
      }
    } else {
      try {
        if (redirectUri === "") {
          let response = await bglService.getBglDetails();
          setRedirectUri(response.body.redirectUri);
          setBglClientId(response.body.clientId);
          setIntegrationsUri(response.body.integrationsUri);
          openInNewTab(
            `${integrationsUri}/u/oauth/authorize?response_type=code&client_id=${bglClientId}&scope=investment&redirect_uri=${redirectUri}&state=${user.state}`
          );
        }
      } catch (e) {
        //console.log(e);
      }
    }
  }

  const openInNewTab = async (url) => {
    if (user?.consentType === "class_super") {
      if (
        !user.classSuperIsValid ||
        moment(user?.classSuperRefreshExpirationDate).diff(moment(), "days") < 0
      ) {
        await userService.addDataToUserState(user.id, user.state);
        dispatch(headerActions.handleOpenSuperClassTokenUpdateFlow());
        dispatch(headerActions.handleOpenSuperClassConsentSpinner());
        dispatch(headerActions.handleEnableServiceClassSuper());

        let height = 600;
        let width = 900;
        let left = (window.screen.width - width) / 2;
        let top = (window.screen.height - height) / 2;
        window.open(
          url,
          "_blank",
          `location=yes,top=${top},left=${left},width=${width},height=${height}`
        );
      }
    } else {
      if (!user.bglIsValid) {
        await userService.addDataToUserState(user.id, user.state);
        dispatch(headerActions.handleOpenBglTokenUpdateFlow());
        dispatch(headerActions.handleOpenBGLConsentSpinner());
        let height = 600;
        let width = 900;
        let left = (window.screen.width - width) / 2;
        let top = (window.screen.height - height) / 2;
        window.open(
          url,
          "_blank",
          `location=yes,top=${top},left=${left},width=${width},height=${height}`
        );
      }
    }
  };

  const handleSignIn = () => {
    handleClose();
    dispatch(headerActions.handleOpenSignIn());
  };

  const onLearnMore = (event, url) => {
    // if (product?.url) window.open(product?.url);
    window.open(url, "_blank");
  };

  const onViewList = async () => {
    localStorage.setItem("selectedProduct", JSON.stringify(product));
    dispatch(headerActions.setSelectedProduct({ selectedProduct: product }));
    history.push("/fundsList");
  };

  const showCurrencyFormat = (value) => {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    }).format(value);
  };

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          className="modal-box"
          style={{ height: 860, width: 764, outline: 0 }}
        >
          <X
            className="cross-icon"
            onClick={handleClose}
            style={{ top: 20, right: 16 }}
          />
          <Typography id="modal-modal-title" variant="smallCaps" mt={3}>
            {product?.category}
          </Typography>
          <Grid container direction="row">
            <Grid item xs={8}>
              <Typography id="modal-modal-description" variant="h3">
                {product?.title}
              </Typography>
            </Grid>
            <Grid item xs={4} display="flex" justifyContent="flex-end">
              <Typography variant="small" style={{ marginRight: 8 }}>
                {/* {isUserLoggedIn && 
                                priceByMembership !== product?.priceBase && !product?.comingSoon && <><span className="discountedCostModal" style={{marginRight: 6}} >{`${showCurrencyFormat(priceByMembership)}`}</span><span className="originalCostModal" style={{position: 'relative', top: -8}}>{`${showCurrencyFormat(product?.priceBase)}`}</span></>}

                                {isUserLoggedIn &&
                                priceByMembership === product?.priceBase && <><span className="text18">{(product?.priceBase > 0 && !product?.comingSoon) ? `${showCurrencyFormat(product?.priceBase)}` : ((product?.type === productTypes.SERVICE || product?.type === productTypes.BLOG || product?.type === productTypes.PODCAST || product?.type === productTypes.WEBINAR || product?.comingSoon === true) ? '' : <span className="discountedCostModal">Free</span>)}</span></>}
                                 */}
                {isUserLoggedIn && (
                  <span className="text18">
                    {product?.priceBase > 0 && !product?.comingSoon ? (
                      `${showCurrencyFormat(product?.priceBase)}`
                    ) : product?.type === productTypes.SMSF ||
                      product?.type === productTypes.SERVICE ||
                      product?.type === productTypes.BLOG ||
                      product?.type === productTypes.PODCAST ||
                      product?.type === productTypes.WEBINAR ||
                      product?.comingSoon === true ? (
                      ""
                    ) : (
                      <span className="discountedCostModal">Free</span>
                    )}
                  </span>
                )}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            direction="row"
            sx={{
              mt: 2,
              mb: 2,
              position: "relative",
              width: "702px",
              height: "300px",
            }}
          >
            {!(
              isUserLoggedIn &&
              !product?.comingSoon &&
              product?.type === productTypes.SERVICE &&
              product?.surveyId === surveyIds.AGE001
            ) && (
              <img
                style={{ width: "702px", height: "300px" }}
                src={fileUrl}
                alt="Card_details_image"
                loading="lazy"
              />
            )}
            {isUserLoggedIn &&
              !product?.comingSoon &&
              product?.type === productTypes.SERVICE &&
              product?.surveyId === surveyIds.AGE001 && (
                <Box
                  style={{
                    width: "702px",
                    height: "300px",
                    backgroundColor: "black",
                  }}
                />
              )}
          </Grid>
          <Grid container direction="row">
            <Grid item xs={7}>
              <Stack>
                <Grid container spacing={2}>
                  <Grid item xs={9}>
                    <Typography id="modal-modal-description" variant="h4">
                      Description
                    </Typography>
                  </Grid>
                  <Grid item xs={3} style={{ marginTop: 0 }}>
                    {product?.estimatedTime && (
                      <Timer estimatedTime={product?.estimatedTime} />
                    )}
                  </Grid>
                </Grid>
                {product?.action === "View List" && (
                  <Box className="apply-ellipsis_modal">
                    {product?.description &&
                      product?.description
                        .split("<newline>")
                        .map((item, index) => {
                          let marginTop = 0;
                          if (item === "") {
                            marginTop += 16;
                          }
                          let cleanHTML = DOMPurify.sanitize(item, {
                            USE_PROFILES: { html: true },
                          }); // Enabling anchor tags to be rendered
                          if (index === 0) {
                            return (
                              <Typography
                                key={index}
                                style={{ fontSize: "14px" }}
                              >
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html: cleanHTML,
                                  }}
                                ></span>
                              </Typography>
                            );
                          } else {
                            return (
                              <Typography
                                key={index}
                                style={{ fontSize: "14px", marginTop }}
                              >
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html: cleanHTML,
                                  }}
                                ></span>
                              </Typography>
                            );
                          }
                        })}
                  </Box>
                )}
                {product?.action !== "View List" && (
                  <Box className="apply-listing_modal">
                    {product?.description &&
                      product?.description
                        .split("<newline>")
                        .map((item, index) => {
                          let marginTop = 0;
                          if (item === "") {
                            marginTop += 16;
                          }
                          let cleanHTML = DOMPurify.sanitize(item, {
                            USE_PROFILES: { html: true },
                          }); // Enabling anchor tags to be rendered
                          if (index === 0) {
                            return (
                              <Typography
                                key={index}
                                style={{ fontSize: "14px" }}
                              >
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html: cleanHTML,
                                  }}
                                ></span>
                              </Typography>
                            );
                          } else {
                            return (
                              <Typography
                                key={index}
                                style={{ fontSize: "14px", marginTop }}
                              >
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html: cleanHTML,
                                  }}
                                ></span>
                              </Typography>
                            );
                          }
                        })}
                  </Box>
                )}
              </Stack>
            </Grid>
            <Grid item xs={1}></Grid>
            <Grid item xs={4} display="flex" justifyContent="flex-end">
              <Stack>
                {!isUserLoggedIn &&
                  (product?.priceBase >= 0 ||
                    (product?.priceBase === 0 &&
                      product?.type === productTypes.MCU)) &&
                  product?.action === "View List" && (
                    <Box
                      sx={{
                        padding: "8px 16px 16px",
                        background: "#ECEFF1",
                        display: "flex",
                        flexDirection: "column",
                        textAlign: "center",
                      }}
                    >
                      <Typography variant="h4" style={{ textAlign: "center" }}>
                        Action Needed
                      </Typography>
                      <img
                        src="images/accountantsGPS_product_card_logo.png"
                        style={{
                          width: "70px",
                          alignSelf: "center",
                          marginTop: 8,
                        }}
                      />
                      <Typography variant="smaller" style={{ marginTop: 24 }}>
                        <li>Get access to this item and more</li>
                      </Typography>
                      <Link
                        href="#"
                        variant="smaller"
                        style={{
                          fontWeight: 600,
                          color: "black",
                          textDecorationColor: "black",
                          marginTop: 16,
                        }}
                        underline="always"
                        onClick={handleSignIn}
                      >
                        Sign In now
                      </Link>
                    </Box>
                  )}
                {!isUserLoggedIn &&
                  !product?.comingSoon &&
                  product?.type === productTypes.SERVICE &&
                  product?.priceBase === 0 &&
                  product?.action === "View List" && (
                    <Box
                      sx={{
                        padding: "8px 16px 16px",
                        background: "#ECEFF1",
                        display: "flex",
                        flexDirection: "column",
                        textAlign: "center",
                      }}
                    >
                      <Typography variant="h4" style={{ textAlign: "center" }}>
                        Action Needed
                      </Typography>
                      <img
                        src="images/accountantsGPS_product_card_logo.png"
                        style={{
                          width: "70x",
                          alignSelf: "center",
                          marginTop: 8,
                        }}
                      />
                      <Typography variant="smaller" style={{ marginTop: 24 }}>
                        <li>Get access to this service partner and more</li>
                      </Typography>
                      <Link
                        href="#"
                        variant="smaller"
                        style={{
                          fontWeight: 600,
                          color: "black",
                          textDecorationColor: "black",
                          marginTop: 16,
                        }}
                        underline="always"
                        onClick={handleSignIn}
                      >
                        Sign In now
                      </Link>
                    </Box>
                  )}

                {isUserLoggedIn &&
                  product?.type !== productTypes.SERVICE &&
                  product?.type !== productTypes.BLOG &&
                  product?.type !== productTypes.PODCAST &&
                  product?.type !== productTypes.WEBINAR &&
                  !product?.comingSoon &&
                  product?.action === "View List" && (
                    <Box
                      sx={{
                        padding: "8px 16px 16px",
                        background: "#ECEFF1",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <Typography variant="h4" sx={{ textAlign: "center" }}>
                        Action Needed
                      </Typography>
                      <Typography variant="smaller">
                        Before you can access this product you are required to:
                        <Box sx={{ textAlign: "left" }}>
                          <div className="marginTop8px">
                            <Grid container>
                              <Grid item xs={1.5}>
                                <Link
                                  onClick={() => {
                                    setOpenSMSF(true);
                                    handleClose();
                                  }}
                                >
                                  {user?.consentType === "class_super" && (
                                    <>
                                      {user?.classSuperIsValid &&
                                        moment(
                                          user?.classSuperRefreshExpirationDate
                                        ).diff(moment(), "days") > 0 && (
                                          <TaskAltIcon
                                            style={{
                                              fontSize: "18px",
                                              color: "#2AD000",
                                            }}
                                          />
                                        )}
                                      {(!user?.classSuperIsValid ||
                                        moment(
                                          user?.classSuperRefreshExpirationDate
                                        ).diff(moment(), "days") <= 0) && (
                                        <ErrorOutlineIcon
                                          style={{
                                            fontSize: "18px",
                                            color: "#EC0018",
                                          }}
                                        />
                                      )}
                                    </>
                                  )}

                                  {user?.consentType !== "class_super" && (
                                    <>
                                      {user?.bglIsValid &&
                                        Math.abs(
                                          user.bglTokenExpirationDate -
                                            moment().unix()
                                        ) > 0 && (
                                          <TaskAltIcon
                                            style={{
                                              fontSize: "18px",
                                              color: "#2AD000",
                                            }}
                                          />
                                        )}
                                      {!user?.bglIsValid && (
                                        <ErrorOutlineIcon
                                          style={{
                                            fontSize: "18px",
                                            color: "#EC0018",
                                          }}
                                        />
                                      )}
                                    </>
                                  )}
                                </Link>
                              </Grid>
                              <Grid item xs={10.5}>
                                <span style={{ verticalAlign: "middle" }}>
                                  <b>
                                    <span
                                      style={{
                                        color: "#000000",
                                        cursor: "pointer",
                                      }}
                                      onClick={() => fetchData()}
                                    >
                                      Provide
                                    </span>
                                  </b>{" "}
                                  your
                                  {user?.consentType === "class_super"
                                    ? " Class Super "
                                    : " BGL "}
                                  Consent
                                </span>
                              </Grid>
                              {user?.classSuperIsValid &&
                                moment(
                                  user?.classSuperRefreshExpirationDate
                                ).diff(moment(), "days") > 0 && (
                                  <Grid
                                    xs={12}
                                    ml={3.5}
                                    style={{
                                      textAlign: "left",
                                      color: "#0099FF",
                                    }}
                                  >
                                    (expires in{" "}
                                    {moment(
                                      user?.classSuperRefreshExpirationDate
                                    ).diff(moment(), "days")}{" "}
                                    days)
                                  </Grid>
                                )}
                              {user?.bglIsValid && (
                                <Grid
                                  xs={12}
                                  ml={3.5}
                                  style={{
                                    textAlign: "left",
                                    color: "#0099FF",
                                  }}
                                >
                                  (expires in{" "}
                                  {Math.ceil(
                                    Math.abs(
                                      user.bglTokenExpirationDate -
                                        moment().unix()
                                    ) /
                                      (60 * 60 * 24)
                                  )}{" "}
                                  days)
                                </Grid>
                              )}
                            </Grid>
                          </div>
                        </Box>
                      </Typography>
                    </Box>
                  )}
              </Stack>
            </Grid>
          </Grid>
          <Grid container direction="row" style={{ textAlign: "right" }}>
            <Grid item xs={12} style={{ textAlign: "right" }}>
              <CancelButton
                variant="contained"
                style={{
                  position: "absolute",
                  bottom: 24,
                  right:
                    (isUserLoggedIn && product?.action?.length) > 10
                      ? 200
                      : !isUserLoggedIn
                      ? 136
                      : product?.action !== ""
                      ? 170
                      : 30,
                }}
                onClick={handleClose}
              >
                Cancel
              </CancelButton>
              {isUserLoggedIn &&
                user?.consentType !== "class_super" &&
                (product?.priceBase > 0 ||
                  (product?.priceBase === 0 &&
                    product?.comingSoon &&
                    product?.type === productTypes.MCU) ||
                  product?.type === productTypes.SMSF) && (
                  <AddToCartButton
                    variant="contained"
                    style={{ position: "absolute", bottom: 24, right: 40 }}
                    onClick={onViewList}
                    disabled={!user?.bglIsValid}
                  >
                    View List
                  </AddToCartButton>
                )}
              {isUserLoggedIn &&
                user?.consentType === "class_super" &&
                (product?.priceBase > 0 ||
                  (product?.priceBase === 0 &&
                    product?.comingSoon &&
                    product?.type === productTypes.MCU) ||
                  product?.type === productTypes.SMSF) && (
                  <AddToCartButton
                    variant="contained"
                    style={{ position: "absolute", bottom: 24, right: 40 }}
                    onClick={onViewList}
                    disabled={!user?.classSuperIsValid}
                  >
                    View List
                  </AddToCartButton>
                )}
              {isUserLoggedIn &&
                product?.type !== productTypes.SMSF &&
                product?.priceBase === 0 &&
                product?.action !== "" && (
                  <AddToCartButton
                    variant="contained"
                    style={{ position: "absolute", bottom: 24, right: 40 }}
                    onClick={(event) => onLearnMore(event, product?.url)}
                  >
                    {product?.action}
                  </AddToCartButton>
                )}
              {!isUserLoggedIn && (
                <AddToCartButton
                  variant="contained"
                  style={{ position: "absolute", bottom: 24, right: 40 }}
                  onClick={handleSignIn}
                >
                  Sign In
                </AddToCartButton>
              )}
            </Grid>
          </Grid>
        </Box>
      </Modal>
      {openSMSF && (
        <FullDialog
          productSurveyId={surveyIds.SMSF001}
          product={product}
          open={openSMSF}
          closeSurvey={() => setOpenSMSF(false)}
        />
      )}
    </>
  );
}
