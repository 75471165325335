import { configureStore } from "@reduxjs/toolkit";

import authAction from "./auth";
import headerAction from "./header";
import surveyAction from "./survey";
import notificationAction from "./notification";

const store = configureStore({
  // root reducer for app
  reducer: {
    auth: authAction.reducer,
    header: headerAction.reducer,
    survey: surveyAction.reducer,
    notification: notificationAction.reducer,
  },
});

export default store;
