import {
  API
} from 'aws-amplify';
import {
  getSubscriptionByName,
  getSubscriptionPlans
} from '../graphql/queries';

const subscriptionService = {
  getSubscriptionByName: async (name) => {
    const response = await API.graphql({
      query: getSubscriptionByName,
      authMode: 'API_KEY',
      variables: {
        name: name
      }
    });
    return response.data?.getSubscriptionByName?.items[0];
  },
  getSubscriptionPlan: async (planId) => {
    const response = await API.graphql({
      query: getSubscriptionPlans,
      authMode: 'API_KEY',
      variables: {
        planId
      }
    });
    return response.data?.getSubscriptionPlans;
  },
}

export default subscriptionService;