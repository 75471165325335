import * as React from "react";
import { Box, CircularProgress, Grid, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@mui/styles";
import Footer from "../Footer";
import Toolbar from "@mui/material/Toolbar";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import { useHistory } from "react-router-dom";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import { ChevronDown } from "react-feather";
import "./fundDetails.css";
import MaterialTable from "material-table";
import ArrowDownward from "@mui/icons-material/ArrowDropDown";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import { FileText } from "react-feather";
import { headerActions } from "../../store/header";
import NewReportConfirmation from "../newReportConfirmation";
import questionnaireResponseService from "../../services/questionnaireResponse";
import moment from "moment";
import {
  questionnaireResponseStatus,
  PurchasedStatus,
  hasUnlimitedReportsIncluded,
  classSuperOperations,
} from "../../services/constants";
import { surveyIds } from "../../services/constants";
import FullDialog from "../MainBody/FullDialog/fullDialog";
import purchaseService from "../../services/purchase";
import documentService from "../../services/document";
import subscriptionService from "../../services/subscriptionPlan";
import { surveyActions } from "../../store/survey";
import BorderLinearProgress from "../BorderLinearProgress";
import productService from "../../services/product";
import ConfigContext from "../../context/ConfigContext";
import SubscriptionStatistics from "../SubscriptionStatistics";
import { getPurchaseByQuestionnaireResponseId } from "../../graphql/queries";
import classSuperService from "../../services/superClass";

const tableIcons = {
  Check: React.forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: React.forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: React.forwardRef((props, ref) => (
    <DeleteOutline {...props} ref={ref} />
  )),
  DetailPanel: React.forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Filter: React.forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: React.forwardRef((props, ref) => (
    <FirstPage {...props} ref={ref} />
  )),
  LastPage: React.forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: React.forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  PreviousPage: React.forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  SortArrow: React.forwardRef((props, ref) => (
    <ArrowDownward {...props} ref={ref} />
  )),
};
const useStyles = makeStyles((theme) => ({
  createAccountBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "16px",
    width: "800px",
    height: "auto",
  },
  createAccountLbl: {
    fontFamily: "Poppins",
    fontWeight: 600,
    fontSize: "24px",
    lineHeight: "32px",
    color: "#000000",
    marginBlock: 0,
    marginBottom: 32,
  },
  textFieldLabel: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "24px",
    color: "#000000",
    marginBlock: 0,
  },
  smallTextFieldLabel: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "9px",
    lineHeight: "16px",
    color: "#000000",
    letterSpacing: "0.1em",
  },
  red: {
    color: "red",
  },
  textField: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    background: "#ECEFF1",
    alignSelf: "stretch",
    height: "44px",
    paddingLeft: 8,
    paddingRight: 8,
  },
  text24: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "24px",
    lineHeight: "24px",
  },
  text14: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "24px",
    lineHeight: "24px",
  },
  textSummary: {
    padding: "0px 20px",
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "24px",
  },
  crossIcon: {
    position: "absolute",
    right: "24px",
    cursor: "pointer",
  },
  heading: {
    fontFamily: "Poppins",
    fontSize: "36px",
    lineHeight: "48px",
  },
  statusButton: {
    borderRadius: "50px",
    border: "0px",
    padding: "4px 16px",
    color: "#FFFFFF",
    fontFamily: "Poppins",
  },
}));

const CancelButton = styled(Button)({
  height: "44px",
  fontSize: "14px",
  fontWeight: 400,
  lineHeight: "24px",
  background: "#FFFFFF",
  border: "1px solid #1A2842",
  boxSizing: "border-box",
  color: "#1A2842",
  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
  borderRadius: "5px",
  fontFamily: ["Poppins"].join(","),
  "&:hover": {
    backgroundColor: "#FFFFFF",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
  },
  "&:active": {
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    backgroundColor: "#FFFFFF",
  },
  "&:focus": {
    backgroundColor: "#FFFFFF",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
  },
});
const ActionButton = styled(Button)({
  fontSize: "14px",
  fontWeight: 400,
  lineHeight: "24px",
  color: "#1A2842",
  textDecoration: "underline",
  fontFamily: "Poppins",
});

const CreateReportButton = styled(Button)({
  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
  textTransform: "none",
  fontSize: "14px",
  padding: "10px 20px",
  border: "none",
  fontWeight: 400,
  lineHeight: "24px",
  backgroundColor: "#1A2842",
  color: "white",
  fontFamily: ["Poppins"].join(","),
  "&:hover": {
    backgroundColor: "#1A2842",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
  },
  "&:active": {
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    backgroundColor: "#1A2842",
  },
  "&:focus": {
    backgroundColor: "#1A2842",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
  },
  "&:disabled": {
    color: "white",
    backgroundColor: "#8C94A0;",
  },
});
const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  borderBottom: "1px solid #D1D4D9",
  // '&:not(:last-child)': {
  //   borderBottom: 0,
  // },
  "&:before": {},
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary expandIcon={<ChevronDown size={24} />} {...props} />
))(({ theme }) => ({
  padding: "8px",
  minHeight: "auto",
  flexDirection: "row",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(180deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(1),
  borderTop: "1px solid #D1D4D9",
}));

export default function ClassSuperFundDetails() {
  const MAX_EDITS = 3;
  const config = React.useContext(ConfigContext);
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const isUserLoggedIn = useSelector((state) => state.auth.loggedIn);
  const user = useSelector((state) => state.auth.user);
  const selectedFund = useSelector((state) => state.header.selectedFund);

  const isReportBtnDisabled = useSelector(
    (state) => state.header.isReportBtnDisabled
  );
  const openSMSFCheckup = useSelector((state) => state.survey.openSurveyForm);
  const openNewReportConfirmation = useSelector(
    (state) => state.header.openNewReportConfirmation
  );
  const [fundsActivityList, setFundsActivityList] = React.useState([]);
  const [rowsPerPage, setRowsPerPage] = React.useState(500);
  const [nextToken, setNextToken] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const reportBtnText = useSelector((state) => state.header.reportBtnText);
  const [isReportInProgress, setIsReportInProgress] = React.useState(false);
  const selectedProduct = JSON.parse(localStorage.getItem("selectedProduct"));
  const [viewResponseMode, setViewResponseMode] = React.useState(false);
  const [editResponseMode, setEditResponseMode] = React.useState(false);
  const [selectedQuestionnaireResponse, setSelectedQuestionnaireResponse] =
    React.useState(null);

  const [hasSubscription, setHasSubscription] = React.useState(null);
  const [extraReportPrice, setExtraReportPrice] = React.useState(null);

  const [usedReportsCount, setUsedReportsCount] = React.useState(0);
  const [subscriptionDetails, setSubscriptionDetails] = React.useState(null);
  const [isLoadingSubscriptionData, setIsLoadingSubscriptionData] =
    React.useState(false);
  const [membersDetail, setMembersDetail] = React.useState([]);

  let maxYear = 2022; //UNCOMMENT WHEN P4 IS COMPLETED new Date().getMonth() > 6 ? new Date().getFullYear() : new Date().getFullYear() - 1;
  const getExtraReportProductId = () => config?.extraReportProductId;

  React.useEffect(() => {
    fetchData();
    fetchSubscriptionData();
    fetchMembers();
  }, []);

  const fetchMembers = async () => {
    let dataList = [];
    let responseData = await classSuperService.getClassSuperData(
      classSuperOperations.FUND_MEMBERS,
      {
        fundCode: selectedFund.Code,
        balanceDate: new Date(),
      }
    );

    responseData = JSON.parse(responseData.split("body=")[1].slice(0, -1));
    if (!responseData.Errors.length) {
      dataList = dataList.concat(responseData.Data?.Members);
      setMembersDetail(dataList);
    } else {
      console.log("ClassSuper Lambda Error");
    }
  };
  // Function to fetch class Super data
  const fetchData = async () => {
    try {
      setIsLoading(true);
      questionnaireResponseService
        .getAllQuestionnaireResponseByFund(
          selectedFund.Code,
          rowsPerPage,
          nextToken
        )
        .then(async (result) => {
          setNextToken(result.nextToken);
          const responseStatus = result?.items?.some((reponse) => {
            return reponse.status === questionnaireResponseStatus.INPROGRESS;
          });

          if (responseStatus) {
            dispatch(
              headerActions.handleReportBtnText({ btnText: "Continue Report" })
            );
            setIsReportInProgress(true);
          } else {
            purchaseService
              .getPurchaseByStatus(PurchasedStatus.pending)
              .then((result) => {
                if (
                  result.length &&
                  result.find((item) => item.fundId === selectedFund.Code)
                ) {
                  dispatch(
                    headerActions.handleReportBtnText({
                      btnText: "Continue Report",
                    })
                  );
                  setIsReportInProgress(true);
                } else {
                  dispatch(
                    headerActions.handleReportBtnText({
                      btnText: "Create new Report",
                    })
                  );
                }
              });
          }
          const data = result?.items.sort((a, b) => {
            return new Date(b.updatedAt) - new Date(a.updatedAt);
          });
          for (let item of data) {
            await purchaseService
              .getPurchaseByQuestionnaireResponseId(item.id)
              .then((purchaseRecord) => {
                item.edits = purchaseRecord?.[0]?.edits || 1; //if no data set to 1 to match new report logic (3 edits max, 1 used upon first completion)
              });
          }

          setFundsActivityList(data);
        });
      setIsLoading(false);
    } catch (error) {
      console.log("error", error);
    }
  };

  async function fetchSubscriptionData() {
    try {
      setIsLoadingSubscriptionData(true);

      await getAndSetExtraReportPrice();

      const [currentPlan, usedReports, extraReports] =
        await purchaseService.getCurrentPlanAndUsedReportsBySub(user.sub);

      const hasSubscription = currentPlan?.expiryDate > new Date().getTime();

      setHasSubscription(hasSubscription);
      setUsedReportsCount(usedReports?.length || 0);

      const planId = currentPlan?.product?.planId;
      const s = await subscriptionService.getSubscriptionPlan(planId);
      setSubscriptionDetails({
        ...s,
        extraReports,
      });
    } catch (e) {
      // TODO: notify user
    } finally {
      setIsLoadingSubscriptionData(false);
    }
  }

  async function getAndSetExtraReportPrice() {
    const extraReportProduct = await productService.getProductById(
      getExtraReportProductId()
    );
    console.log("extraReportProduct", extraReportProduct); //UNDEFINED
    setExtraReportPrice(extraReportProduct?.priceBase);
  }

  const getExtraReports = () => {
    return subscriptionDetails?.extraReports;
  };

  const getRemainingExtraReports = () => {
    return getExtraReports()?.length || 0;
  };

  const getTotalReports = () => subscriptionDetails?.reportsIncluded;

  const _hasUnlimitedReportsIncluded = hasUnlimitedReportsIncluded(
    getTotalReports()
  );

  const handleCancel = () => {
    history.push("/fundsList");
  };
  const handleCreateReport = () => {
    localStorage.setItem("noOfReports", "null");

    if (isReportInProgress) {
      // setOpenSMSFCheckup(true);
      dispatch(surveyActions.handleOpenSurveyForm(true));
      setViewResponseMode(false);
      setSelectedQuestionnaireResponse(null);
    } else {
      dispatch(headerActions.handleOpenNewReportConfirmation());
    }
  };

  const onViewReport = async (questionnaireResponse) => {
    const documentsSub = await documentService.getDocumentByQRId(
      questionnaireResponse?.id
    );

    let copyObj = documentsSub;

    copyObj.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

    if (copyObj) {
      const pdfDocument = copyObj?.find((item) =>
        item?.documentLocation?.endsWith(".pdf")
      );
      const htmlDocument = copyObj?.find((item) =>
        item?.documentLocation?.endsWith(".html")
      );
      dispatch(headerActions.setPdfDocument({ pdfDocument }));
      dispatch(headerActions.setHtmlDocument({ htmlDocument }));
    }
    dispatch(headerActions.handleOpenViewReportMode());
    dispatch(
      headerActions.setSelectedProduct({ selectedProduct: selectedProduct })
    );
    dispatch(
      headerActions.setOpenHtmlReportViewer({ openHtmlReportViewer: true })
    );
  };

  const onViewResponse = async (questionnaireResponse) => {
    setViewResponseMode(true);
    dispatch(surveyActions.handleOpenSurveyForm(true));
    setSelectedQuestionnaireResponse(questionnaireResponse);
  };

  const onAmendResponse = async (questionnaireResponse) => {
    const documentsSub = await documentService.getDocumentByQRId(
      questionnaireResponse?.id
    );
    localStorage.setItem("noOfReports", JSON.stringify(documentsSub.length));

    setEditResponseMode(true);
    dispatch(surveyActions.handleOpenSurveyForm(true));
    setSelectedQuestionnaireResponse(questionnaireResponse);
  };
  const onClose = () => {
    setViewResponseMode(false);
    setSelectedQuestionnaireResponse(null);
    dispatch(surveyActions.handleCloseSurveyForm(true));
  };

  const getEmail = (arr) => {
    let email = "";
    const emailObj = arr.filter((e) => e.Name === "Email");
    if (emailObj.length > 0) {
      email = emailObj[0].Value;
    }
    return email;
  };

  return (
    <>
      {isUserLoggedIn && user && user.email && user?.classSuperIsValid && (
        <Box
          style={{ background: "#FAFAFA" }}
          sx={{ padding: "106px 32px", width: "100%", pb: 0 }}
        >
          <Grid>
            <Box
              variant="small"
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <div>
                <CancelButton
                  variant="contained"
                  onClick={() => handleCancel(false)}
                >
                  <ArrowBackIosIcon
                    style={{ fontSize: "14px", marginRight: "2px" }}
                  />
                  Back to funds list
                </CancelButton>
              </div>

              <div>
                {isLoadingSubscriptionData ? (
                  <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    sx={{ mt: 2 }}
                  >
                    <CircularProgress />
                  </Grid>
                ) : (
                  hasSubscription && ( //bypass
                    <Grid container>
                      {!_hasUnlimitedReportsIncluded && (
                        <Grid item>
                          <Box sx={{ width: 200, marginTop: "2px" }}>
                            <SubscriptionStatistics
                              totalReportsCount={getTotalReports()}
                              usedReportsCount={usedReportsCount}
                              extraReportsCount={getRemainingExtraReports()}
                            />
                          </Box>
                        </Grid>
                      )}

                      <Grid item ml={4}>
                        <CreateReportButton
                          variant="contained"
                          disabled={isReportBtnDisabled}
                          onClick={() => handleCreateReport()}
                        >
                          {reportBtnText}
                          <FileText
                            style={{
                              fontSize: "18px",
                              marginLeft: "4px",
                              width: "14px",
                            }}
                          />
                        </CreateReportButton>
                      </Grid>
                    </Grid>
                  )
                )}
              </div>
            </Box>
          </Grid>
          <Grid mt={6}>
            <Typography className={classes.heading}>
              {selectedFund.Name}
            </Typography>
          </Grid>
          <Grid mt={2}>
            <Typography className={classes.text14}>Fund Summary</Typography>
            <Box variant="small" mt={3}>
              <table>
                <tr>
                  <td>
                    <Typography
                      className={classes.textSummary}
                      style={{ fontWeight: "700" }}
                    >
                      Fund Code
                    </Typography>
                    <Typography mt={0} className={classes.textSummary}>
                      {selectedFund.Code}
                    </Typography>
                  </td>
                  <td>
                    <Typography
                      className={classes.textSummary}
                      style={{ fontWeight: "700" }}
                    >
                      Fund Name
                    </Typography>
                    <Typography mt={0} className={classes.textSummary}>
                      {selectedFund.Name}
                    </Typography>
                  </td>
                  <td>
                    <Typography
                      className={classes.textSummary}
                      style={{ fontWeight: "700" }}
                    >
                      Email
                    </Typography>
                    <Typography
                      mt={0}
                      className={classes.textSummary}
                      style={{
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {selectedFund.fundEmail}
                    </Typography>
                  </td>
                  <td>
                    <Typography
                      className={classes.textSummary}
                      style={{ fontWeight: "700" }}
                    >
                      ABN
                    </Typography>
                    <Typography mt={0} className={classes.textSummary}>
                      {selectedFund.ABN}
                    </Typography>
                  </td>
                </tr>
              </table>
            </Box>
          </Grid>
          {membersDetail.length && (
            <Grid mt={5}>
              <Typography className={classes.text14}>Fund Members</Typography>
              {membersDetail?.map((memberDetail, index) => {
                return (
                  <Box key={index}>
                    <Accordion style={{ background: "#FAFAFA" }}>
                      <AccordionSummary
                        aria-controls="panel2d-content"
                        id="panel2d-header"
                      >
                        <Typography variant="big">
                          {memberDetail?.DisplayName}
                          {/* +
                              " " +
                              memberDetail?.lastName} */}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Box className={classes.createAccountBox}>
                          <Grid container spacing={6}></Grid>
                        </Box>
                        <Grid item xs={12}>
                          <Box mb={3}>
                            <table style={{ width: "100%" }}>
                              <tr>
                                <td>
                                  <Typography
                                    className={classes.textSummary}
                                    style={{ fontWeight: "700" }}
                                  >
                                    Title
                                  </Typography>
                                  <Typography
                                    mt={0}
                                    className={classes.textSummary}
                                  >
                                    {memberDetail?.Gender === "Male"
                                      ? "Mr"
                                      : memberDetail?.Gender === "Female"
                                      ? "Ms"
                                      : memberDetail?.title}
                                  </Typography>
                                </td>
                                <td>
                                  <Typography
                                    className={classes.textSummary}
                                    style={{
                                      fontWeight: "700",
                                      width: "25%",
                                    }}
                                  >
                                    Gender
                                  </Typography>
                                  <Typography
                                    mt={0}
                                    className={classes.textSummary}
                                  >
                                    {memberDetail?.Gender}
                                  </Typography>
                                </td>
                                <td>
                                  <Typography
                                    className={classes.textSummary}
                                    style={{
                                      fontWeight: "700",
                                      width: "25%",
                                    }}
                                  >
                                    Street
                                  </Typography>
                                  <Typography
                                    mt={0}
                                    className={classes.textSummary}
                                  >
                                    {
                                      memberDetail?.PhysicalAddress
                                        ?.AddressLine1
                                    }
                                    <br />{" "}
                                    {
                                      memberDetail?.PhysicalAddress
                                        ?.AddressLine2
                                    }
                                  </Typography>
                                </td>
                                <td>
                                  <Typography
                                    className={classes.textSummary}
                                    style={{
                                      fontWeight: "700",
                                      width: "25%",
                                    }}
                                  >
                                    Country
                                  </Typography>
                                  <Typography
                                    mt={0}
                                    className={classes.textSummary}
                                  >
                                    {memberDetail?.PhysicalAddress?.Country ===
                                    "AU"
                                      ? "Australia"
                                      : memberDetail?.PhysicalAddress?.Country}
                                  </Typography>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <Typography
                                    className={classes.textSummary}
                                    style={{ fontWeight: "700" }}
                                  >
                                    First Name
                                  </Typography>
                                  <Typography
                                    mt={0}
                                    className={classes.textSummary}
                                  >
                                    {memberDetail?.DisplayName.split(" ")[1]}
                                  </Typography>
                                </td>
                                <td>
                                  <Typography
                                    className={classes.textSummary}
                                    style={{ fontWeight: "700" }}
                                  >
                                    Date of Birth
                                  </Typography>
                                  <Typography
                                    mt={0}
                                    className={classes.textSummary}
                                  >
                                    {moment(memberDetail?.DateOfBirth).format(
                                      "DD/MM/YYYY"
                                    )}
                                  </Typography>
                                </td>
                                <td>
                                  <Typography
                                    className={classes.textSummary}
                                    style={{ fontWeight: "700" }}
                                  >
                                    City
                                  </Typography>
                                  <Typography
                                    mt={0}
                                    className={classes.textSummary}
                                  >
                                    {memberDetail?.PhysicalAddress?.Locality}
                                  </Typography>
                                </td>
                                <td>
                                  <Typography
                                    className={classes.textSummary}
                                    style={{ fontWeight: "700" }}
                                  >
                                    Postcode
                                  </Typography>
                                  <Typography
                                    mt={0}
                                    className={classes.textSummary}
                                  >
                                    {memberDetail?.PhysicalAddress?.Postcode}
                                  </Typography>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <Typography
                                    className={classes.textSummary}
                                    style={{ fontWeight: "700" }}
                                  >
                                    Last Name
                                  </Typography>
                                  <Typography
                                    mt={0}
                                    className={classes.textSummary}
                                  >
                                    {memberDetail?.DisplayName.split(" ").slice(
                                      -1
                                    )}
                                  </Typography>
                                </td>
                                <td>
                                  <Typography
                                    className={classes.textSummary}
                                    style={{ fontWeight: "700" }}
                                  >
                                    Email
                                  </Typography>
                                  <Typography
                                    mt={0}
                                    className={classes.textSummary}
                                  >
                                    {getEmail(memberDetail?.ElectronicContacts)}
                                  </Typography>
                                </td>
                                <td>
                                  <Typography
                                    className={classes.textSummary}
                                    style={{ fontWeight: "700" }}
                                  >
                                    State
                                  </Typography>
                                  <Typography
                                    mt={0}
                                    className={classes.textSummary}
                                  >
                                    {memberDetail?.PhysicalAddress?.State}
                                  </Typography>
                                </td>
                              </tr>
                            </table>
                          </Box>
                        </Grid>
                      </AccordionDetails>
                    </Accordion>
                  </Box>
                );
              })}
            </Grid>
          )}
          <Grid mt={8}>
            <Typography className={classes.heading}>Fund Activity</Typography>
            <MaterialTable
              state={isLoading}
              title=""
              columns={[
                {
                  title: "Product Name",
                  field: "productType",
                  render: (rowData) =>
                    rowData.type === "SMSF001" ? "SMSF Check Up" : rowData.type,
                  sortable: true,
                },
                {
                  title: "Created By",
                  field: "createdBy",
                  render: (rowData) =>
                    rowData.createdBy !== null ? (
                      <div>
                        {rowData.createdBy} <br /> ({rowData.email}){" "}
                      </div>
                    ) : (
                      rowData.email
                    ),
                  sortable: false,
                },
                {
                  title: "Survey Status",
                  field: "status",
                  render: (rowData) =>
                    rowData.status === "FAILED" ? (
                      <button
                        className={classes.statusButton}
                        style={{ backgroundColor: "#EC0018" }}
                      >
                        Failed
                      </button>
                    ) : rowData.status === "COMPLETE" ? (
                      <button
                        className={classes.statusButton}
                        style={{ backgroundColor: "#2AD000" }}
                      >
                        Completed
                      </button>
                    ) : (
                      <button
                        className={classes.statusButton}
                        style={{ backgroundColor: "#0099FF" }}
                      >
                        In Progress
                      </button>
                    ),
                  sortable: true,
                },
                {
                  title: "Report Status",
                  sortable: true,
                  render: (rowData) =>
                    rowData.status === "COMPLETE" ? (
                      <button
                        className={classes.statusButton}
                        style={{ backgroundColor: "#2AD000" }}
                      >
                        Completed
                      </button>
                    ) : (
                      " "
                    ),
                },
                {
                  title: "Date Created",
                  sortable: true,
                  render: (rowData) =>
                    moment(rowData.createdAt).format("DD/MM/YYYY"),
                },
                {
                  title: "Action",
                  sortable: false,
                  render: (rowData) =>
                    rowData.status === "COMPLETE" ? (
                      <div>
                        {rowData.edits < MAX_EDITS ? (
                          <ActionButton
                            onClick={() => onAmendResponse(rowData)}
                          >
                            Amend Report ({MAX_EDITS - rowData.edits} edit
                            {MAX_EDITS - rowData.edits > 1 ? "s" : ""} left)
                          </ActionButton>
                        ) : (
                          ""
                        )}
                        <ActionButton onClick={() => onViewResponse(rowData)}>
                          View Responses
                        </ActionButton>
                        <ActionButton onClick={() => onViewReport(rowData)}>
                          View Report
                        </ActionButton>
                      </div>
                    ) : (
                      ""
                    ),
                },
              ]}
              data={fundsActivityList}
              icons={tableIcons}
              options={{
                isLoading: isLoading,
                paginationType: "stepped",
                draggable: false,
                pageSize: fundsActivityList.length <= 5 ? 5 : 10,
                sorting: true,
                search: false,
                cellStyle: {
                  fontSize: "14px",
                  fontFamily: "Poppins",
                  textAlign: "left",
                  whiteSpace: "nowrap",
                  color: "#000000",
                },
                headerStyle: {
                  backgroundColor: "#FFFFFF",
                  padding: "15px 10px",
                  fontSize: "13px",
                  fontWeight: "700",
                  textAlign: "left",
                  borderTop: "1px solid #C1C1C1",
                  color: "#000000",
                  paddingLeft: "15px",
                },
                rowStyle: (x) => {
                  if (x.tableData.id % 2) {
                    return { backgroundColor: "#F2F3F3" };
                  }
                },
                pageSizeOptions: [5, 10, 20, 25, 50],
              }}
            />
          </Grid>
          <Toolbar />
          <Footer />
        </Box>
      )}

      {isUserLoggedIn && selectedProduct.surveyId === surveyIds.SMSF001 && (
        <FullDialog
          productSurveyId={surveyIds.SMSF001}
          product={selectedProduct}
          open={openSMSFCheckup}
          viewResponseMode={viewResponseMode}
          editResponseMode={editResponseMode}
          selectedQuestionnaire={selectedQuestionnaireResponse}
          closeSurvey={() => onClose()}
        />
      )}
      {isUserLoggedIn && selectedProduct.surveyId === "TEST001" && (
        <FullDialog
          productSurveyId={"TEST001"}
          product={selectedProduct}
          open={openSMSFCheckup}
          viewResponseMode={viewResponseMode}
          editResponseMode={editResponseMode}
          selectedQuestionnaire={selectedQuestionnaireResponse}
          closeSurvey={() => onClose()}
        />
      )}
      {openNewReportConfirmation && (
        <NewReportConfirmation
          usedReportsCount={usedReportsCount}
          totalReportsCount={getTotalReports()}
          extraReports={getExtraReports()}
          extraReportPrice={extraReportPrice}
          maxYear={maxYear}
          product={selectedProduct}
          fundID={selectedFund.Code}
        />
      )}
    </>
  );
}
