import * as React from "react";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { ChevronDown } from "react-feather";
import { makeStyles } from "@mui/styles";
import { useController } from "react-hook-form";
import Typography from "@mui/material/Typography";

const ITEM_HEIGHT = 44;
const ITEM_PADDING_TOP = 8;

const useStyles = makeStyles((theme) => ({
  text14: {
    fontFamily: "Poppins !important",
    fontStyle: "normal !important",
    fontWeight: "400 !important",
    fontSize: "14px !important",
    lineHeight: "24px !important",
  },
  textFieldLabel: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "24px",
    color: "#000000",
    marginBlock: 0,
    textAlign: "left",
  },
  red: {
    color: "red",
  },
}));

export default function SelectPlaceholder({
  placeHolderText,
  width = 142,
  list = ["01", "02"],
  name = "GENDER",
  errors = {},
  setValue,
  value,
  control,
  disabled,
}) {
  const classes = useStyles();
  const { field } = useController({
    control,
    name,
    defaultValue: "",
  });
  const handleChange = (event) => {
    const changedValue = event.target.value;
    setValue(changedValue);
    field.onChange(changedValue);
  };

  React.useEffect(() => {
    if (value) {
      setValue(value);
      field.onChange(value);
    }
  }, [value, field.value]);

  console.log(",,..,.,.,.", value, "value>>>", field.value);

  return (
    <FormControl
      variant="outlined"
      sx={{
        "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
          border: "0px",
          borderRadius: "0px 0px 0 0",
        },
        "& .MuiOutlinedInput-notchedOutline": {
          border: "0px",
          borderRadius: "0px 0px 0 0",
        },
        "& .MuiSelect-select": {
          paddingLeft: "8px",
        },
        "& .MuiSelect-iconOutlined": {
          top: "auto",
          color: "black",
        },
      }}
    >
      {errors[name] && (
        <Typography className={classes.textFieldLabel}>
          <span className={classes.red}>{name} *</span>
        </Typography>
      )}
      {!errors[name] && (
        <Typography className={classes.textFieldLabel}>
          {name} <span className={classes.red}>*</span>
        </Typography>
      )}
      <Select
        IconComponent={ChevronDown}
        displayEmpty
        value={value || field.value || ""}
        disabled={disabled}
        onChange={handleChange}
        renderValue={(selected) => {
          if (selected && selected.length === 0) {
            return placeHolderText || "Please select";
          }

          return selected;
        }}
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
              width,
            },
          },
        }}
        inputProps={{ "aria-label": "Without label" }}
        sx={{
          textAlign: "left",
          height: 44,
          width,
          background: "rgb(236, 239, 241)",
          borderRadius: 0,
        }}
        className={`${classes.text14}`}
        // notchedOutline
        defaultValue={""}
      >
        <MenuItem disabled value="" className={classes.text14}>
          {placeHolderText || "Please select"}
        </MenuItem>
        {list &&
          list.map((name) => (
            <MenuItem className={classes.text14} key={name} value={name}>
              {name}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
}
