import * as React from "react";
import { Box, Grid, Snackbar, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { makeStyles } from "@mui/styles";
import Footer from "../Footer";
import Toolbar from "@mui/material/Toolbar";
import MaterialTable from "material-table";
import Alert from "@mui/material/Alert";
import ArrowDownward from "@mui/icons-material/ArrowDropDown";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Search from "@material-ui/icons/Search";
import CircleIcon from "@mui/icons-material/Circle";
import { useHistory, useLocation } from "react-router-dom";
import { headerActions } from "../../store/header";
import bglService from "../../services/bgl";
import { bglOperations, classSuperOperations } from "../../services/constants";
import questionnaireReponseService from "../../services/questionnaireResponse";
import userService from "../../services/user";
import BGLHealthCheckModal from "../bglHealthCheckModal";
import { useEffect, useState } from "react";
import { data } from "browserslist";
import classSuperService from "../../services/superClass";
import ClassSuperHealthCheckModal from "../classSuperHealthCheckModal";

const tableIcons = {
  Check: React.forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: React.forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: React.forwardRef((props, ref) => (
    <DeleteOutline {...props} ref={ref} />
  )),
  DetailPanel: React.forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Filter: React.forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: React.forwardRef((props, ref) => (
    <FirstPage {...props} ref={ref} />
  )),
  LastPage: React.forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: React.forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  PreviousPage: React.forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: React.forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: React.forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: React.forwardRef((props, ref) => (
    <ArrowDownward {...props} ref={ref} />
  )),
};

const useStyles = makeStyles((theme) => ({
  createAccountBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "16px",
    width: "800px",
    height: "auto",
  },
  createAccountLbl: {
    fontFamily: "Poppins",
    fontWeight: 600,
    fontSize: "24px",
    lineHeight: "32px",
    color: "#000000",
    marginBlock: 0,
    marginBottom: 32,
  },
  textFieldLabel: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "24px",
    color: "#000000",
    marginBlock: 0,
  },
  smallTextFieldLabel: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "9px",
    lineHeight: "16px",
    color: "#000000",
    letterSpacing: "0.1em",
  },
  red: {
    color: "red",
  },
  textField: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    background: "#ECEFF1",
    alignSelf: "stretch",
    height: "44px",
    paddingLeft: 8,
    paddingRight: 8,
  },
  text14: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "24px",
  },
  text12: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "24px",
  },
  phoneField: {
    padding: "0px 0px 0px 4px !important",
    height: "0px",
    display: "inline-block",
    fontSize: "14px",
  },
  crossIcon: {
    position: "absolute",
    right: "24px",
    cursor: "pointer",
  },
  heading: {
    fontFamily: "Poppins",
    fontWeight: "bold",
    fontSize: "32px",
    lineHeight: "48px",
  },
  legend: {
    fontFamily: "Poppins",
    fontSize: "12px",
    lineHeight: "24px",
    display: "flex",
    justifyContent: "space-between",
    width: "32%",
    marginLeft: "68%",
  },
}));

function useQueryParams() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export default function FundsList() {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const [fundsList, setFundsList] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [lastCheckedAt, setLastCheckedAt] = React.useState("");
  const [max, setMax] = React.useState(50);
  const [isBglUnreachable, setIsBglUnreachable] = React.useState(false);
  const [isClassSuperUnreachable, setIsClassSuperUnreachable] =
    React.useState(false);
  const [invalidTokenResponse, setInvalidTokenResponse] = React.useState(false);
  const isUserLoggedIn = useSelector((state) => state.auth.loggedIn);
  const user = useSelector((state) => state?.auth?.user);
  const selectedProduct = JSON.parse(localStorage.getItem("selectedProduct"));

  const fetchFundList = async (max) => {
    setIsLoading(true);
    let totalRecordsFetched = 0;
    let offset = 0;
    let dataList = [];
    while (true) {
      let responseData = await bglService.getUserBglData(
        bglOperations.ENTITYLISTWITHMEMBERS,
        { offset, max }
      );
      responseData = JSON.parse(responseData.split("body=")[1].slice(0, -1));
      console.log("RESSSS", responseData);
      console.log("responseData.error", responseData.error);

      if (responseData.error) {
        if (user) {
          // update bgl token status
          await userService.updateUsersKey({
            type: "bglIsValid",
            data: { bglIsValid: false, userId: user.id },
          });
        }
        setInvalidTokenResponse(true);
        break;
      }
      totalRecordsFetched += max;

      offset = totalRecordsFetched;
      dataList = dataList.concat(responseData.fundsWithMembers);
      if (totalRecordsFetched >= responseData.total) {
        break;
      }
    }
    if (
      Object.keys(dataList) === "status" &&
      (dataList.status > 400 || dataList.status < 600)
    ) {
      setLastCheckedAt(moment().format("hh:mm"));
      setIsBglUnreachable(true);
      console.log("dataList", JSON.stringify(dataList));
      dispatch(headerActions.handleOpenBGLHealthCheckModal());
      setFundsList(null);
      setIsLoading(false);
      return;
    }
    let finalList = [];
    const questionnaireResponse =
      await questionnaireReponseService.getAllLatestCompleteQRByEmailByProduct(
        selectedProduct.id
      );
    if (questionnaireResponse !== undefined) {
      finalList = dataList?.map((e, i) => {
        let filteredRes = questionnaireResponse.filter(
          (element) => element.fundId === e.fundID
        );
        if (filteredRes.length > 0) {
          let data = filteredRes?.reduce((a, b) => {
            return new Date(a.updatedAt) > new Date(b.updatedAt) ? a : b;
          });
          e.reportDate = moment(data.updatedAt).format("DD/MM/YYYY");
        }
        return e;
      });
    } else {
      dataList?.map((element, index) => {
        return (dataList[index].reportDate = "");
      });
      finalList = dataList;
    }
    console.log("fina", finalList);
    setFundsList(finalList);

    if (query.get("extraReportPurchaseComplete") === "1") {
      const selectedFundID = localStorage.getItem("selectedFundID");
      const selectedFund = finalList.find(
        (fund) => fund.fundID === selectedFundID
      );
      dispatch(headerActions.handleSelectedFund(selectedFund));
      localStorage.removeItem("selectedFundID");
      if (selectedFundID) {
        history.push("/fundDetails");
      }
    }

    localStorage.removeItem("selectedFundID");
    setIsLoading(false);
  };
  // Function to fetch class Super List
  const fetchFundListClassSuper = async (max) => {
    setIsLoading(true);
    let totalRecordsFetched = 0;
    let offset = 0;
    let dataList = [];
    // while (true) {
    let responseData = await classSuperService.getClassSuperData(
      classSuperOperations.FUND_LIST,
      { offset, max }
    );

    responseData = JSON.parse(responseData.split("body=")[1].slice(0, -1));
    if (!responseData.Errors.length) {
      const obj = {
        fundsWithMembers: responseData.Data?.FundSummaries,
        total: responseData.Data?.TotalCount,
        offset: 0,
        max: 50,
      };

      if (responseData.error) {
        if (user) {
          await userService.updateUsersKey({
            type: "classSuperIsValid",
            data: { classSuperIsValid: false, userId: user.id },
          });
        }
        setInvalidTokenResponse(true);
        // break;
      }
      totalRecordsFetched += max;
      offset = totalRecordsFetched;
      dataList = dataList.concat(responseData.Data?.FundSummaries);

      if (totalRecordsFetched >= responseData.Data?.TotalCount) {
        // break;
      }
      // }

      if (
        Object.keys(dataList) === "status" &&
        (dataList.status > 400 || dataList.status < 600)
      ) {
        setLastCheckedAt(moment().format("hh:mm"));
        setIsClassSuperUnreachable(true);

        dispatch(headerActions.handleOpenClassSuperHealthCheckModal());
        setFundsList(null);
        setIsLoading(false);
        return;
      }
      let finalList = [];
      const questionnaireResponse =
        await questionnaireReponseService.getAllLatestCompleteQRByEmailByProduct(
          selectedProduct.id
        );

      if (questionnaireResponse !== undefined) {
        finalList = dataList?.map((e, i) => {
          let filteredRes = questionnaireResponse.filter(
            (element) => element.fundId === e.Code
          );

          if (filteredRes.length > 0) {
            let data = filteredRes?.reduce((a, b) => {
              return new Date(a.updatedAt) > new Date(b.updatedAt) ? a : b;
            });
            e.reportDate = moment(data.updatedAt).format("DD/MM/YYYY");
          }
          return e;
        });
      } else {
        dataList?.map((element, index) => {
          return (dataList[index].reportDate = "");
        });
        finalList = dataList;
      }

      const filterList = finalList.filter((e) => {
        return e.LegalEntityType === "SMSF";
      });

      setFundsList(filterList);

      if (query.get("extraReportPurchaseComplete") === "1") {
        const selectedFundID = localStorage.getItem("selectedFundID");
        const selectedFund = filterList.find(
          (fund) => fund.fundID === selectedFundID
        );
        dispatch(headerActions.handleSelectedFund(selectedFund));
        localStorage.removeItem("selectedFundID");
        if (selectedFundID) {
          history.push("/fundDetails");
        }
      }

      localStorage.removeItem("selectedFundID");
      setIsLoading(false);
    } else {
      console.log("ClassSuper Lambda Error");
    }
  };

  React.useEffect(() => {
    try {
      if (user) {
        if (user?.consentType === "class_super") {
          fetchFundListClassSuper(max);
        } else {
          fetchFundList(max);
        }
      }
    } catch (error) {
      console.log("error", error);
    }
  }, []);

  const handleRowClick = async (event, rowData) => {
    dispatch(headerActions.handleSelectedFund(rowData));
    history.push("/fundDetails");
  };

  const query = useQueryParams();
  const [openToast, setOpenToast] = useState(false);
  const handleCloseToast = () => {
    setOpenToast(false);
    window.history.pushState({}, "", "/fundsList");
  };

  const [extraReportPurchaseMessage, setExtraReportPurchaseMessage] =
    useState(false);
  useEffect(() => {
    if (query.get("extraReportPurchaseComplete") === "1") {
      setExtraReportPurchaseMessage(true);
      setOpenToast(true);
    }
  }, [query, extraReportPurchaseMessage]);

  return (
    <>
      {extraReportPurchaseMessage && (
        <Snackbar
          open={openToast}
          autoHideDuration={6000}
          onClose={handleCloseToast}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert
            onClose={handleCloseToast}
            severity="success"
            sx={{ width: "100%" }}
          >
            Successfully purchased extra reports
          </Alert>
        </Snackbar>
      )}
      {isUserLoggedIn && user && user.email && user.bglIsValid && (
        <Box
          bgcolor="#FAFAFA"
          sx={{ padding: "106px 32px", width: "100%", pb: 0 }}
        >
          <Grid style={{ display: "flex", justifyContent: "space-between" }}>
            <Typography className={classes.heading}>SMSF Funds List</Typography>
            {isBglUnreachable && (
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div>
                  <img
                    src="/broken-connection.jpg"
                    style={{ height: 45, width: 45 }}
                  />
                </div>
                <div
                  className={classes.text12}
                  style={{
                    textAlign: "center",
                    color: "#EC0018",
                    marginLeft: "4px",
                  }}
                >
                  {" "}
                  BGL unreachable <br />
                  (last checked at: {lastCheckedAt})
                </div>
              </div>
            )}
          </Grid>
          <Grid mt={2}>
            <Typography className={classes.legend}>
              <div>
                <strong>Legend:</strong>
              </div>
              <CircleIcon
                style={{
                  color: "#2AD000",
                  fontSize: "medium",
                  marginTop: "5px",
                  marginLeft: "10px",
                }}
              />{" "}
              Done within 12 months
              <CircleIcon
                style={{
                  color: "#FF9900",
                  fontSize: "medium",
                  marginTop: "5px",
                  marginLeft: "10px",
                }}
              />{" "}
              Done outside of 12 months
              <CircleIcon
                style={{
                  color: "#EC0018",
                  fontSize: "medium",
                  marginTop: "5px",
                  marginLeft: "10px",
                }}
              />{" "}
              Outstanding
            </Typography>
          </Grid>
          <MaterialTable
            state={isLoading}
            onRowClick={handleRowClick}
            title=""
            columns={[
              {
                title: "Fund Code",
                field: "fundCode",
                sortable: true,
              },
              {
                title: "Fund Name",
                field: "fundName",
                sortable: true,
              },
              {
                title: "Email Address",
                field: "fundEmail",
                sortable: true,
                style: {
                  width: "250px",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                },
              },
              {
                title: "ABN",
                field: "ABN",
                sortable: true,
              },
              {
                title: "Report date",
                field: "reportDate",
                sortable: true,
              },
              {
                title: "Status",
                field: "status",
                render: (rowData) =>
                  !rowData.reportDate ? (
                    <CircleIcon
                      style={{ color: "#EC0018", fontSize: "medium" }}
                    />
                  ) : Math.abs(
                      moment().diff(
                        moment(rowData.reportDate, "DD/MM/YYYY"),
                        "days"
                      )
                    ) < 365 ? (
                    <CircleIcon
                      style={{ color: "#2AD000", fontSize: "medium" }}
                    />
                  ) : (
                    <CircleIcon
                      style={{ color: "#FF9900", fontSize: "medium" }}
                    />
                  ),
                sortable: false,
                style: {
                  textAlign: "center",
                },
              },
            ]}
            data={fundsList}
            icons={tableIcons}
            options={{
              isLoading: true,
              paginationType: "stepped",
              draggable: false,
              pageSize: fundsList.length <= 5 ? 5 : 10,
              search: true,
              sorting: true,
              cellStyle: {
                fontSize: "14px",
                fontFamily: "Poppins",
                textAlign: "left",
                whiteSpace: "nowrap",
                color: "#000000",
              },
              headerStyle: {
                backgroundColor: "#FFFFFF",
                padding: "15px 10px",
                fontSize: "13px",
                fontWeight: "700",
                textAlign: "left",
                borderTop: "1px solid #C1C1C1",
                color: "#000000",
                paddingLeft: "15px",
              },
              searchFieldAlignment: "left",
              searchFieldVariant: "outlined",
              searchFieldStyle: {
                backgroundColor: "#FFFFFF",
                marginBottom: "20px",
                marginLeft: "-40px",
              },
              rowStyle: (x) => {
                if (x.tableData.id % 2) {
                  return { backgroundColor: "#F2F3F3" };
                }
              },
              pageSizeOptions: [5, 10, 20, 25, 50],
            }}
          />
          {invalidTokenResponse && (
            <Alert severity="error">
              Invalid BGL token.{" "}
              <a href="/profile/bglConsentStatus"> Click here to reconnect </a>.
            </Alert>
          )}
          <Toolbar />
          <Footer />
        </Box>
      )}
      {/* Table for class Super Data */}
      {isUserLoggedIn && user && user.email && user.classSuperIsValid && (
        <Box
          bgcolor="#FAFAFA"
          sx={{ padding: "106px 32px", width: "100%", pb: 0 }}
        >
          <Grid style={{ display: "flex", justifyContent: "space-between" }}>
            <Typography className={classes.heading}>SMSF Funds List</Typography>
            {isClassSuperUnreachable && (
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div>
                  <img
                    src="/broken-connection.jpg"
                    style={{ height: 45, width: 45 }}
                  />
                </div>
                <div
                  className={classes.text12}
                  style={{
                    textAlign: "center",
                    color: "#EC0018",
                    marginLeft: "4px",
                  }}
                >
                  {" "}
                  Class Super unreachable <br />
                  (last checked at: {lastCheckedAt})
                </div>
              </div>
            )}
          </Grid>
          <Grid mt={2}>
            <Typography className={classes.legend}>
              <div>
                <strong>Legend:</strong>
              </div>
              <CircleIcon
                style={{
                  color: "#2AD000",
                  fontSize: "medium",
                  marginTop: "5px",
                  marginLeft: "10px",
                }}
              />{" "}
              Done within 12 months
              <CircleIcon
                style={{
                  color: "#FF9900",
                  fontSize: "medium",
                  marginTop: "5px",
                  marginLeft: "10px",
                }}
              />{" "}
              Done outside of 12 months
              <CircleIcon
                style={{
                  color: "#EC0018",
                  fontSize: "medium",
                  marginTop: "5px",
                  marginLeft: "10px",
                }}
              />{" "}
              Outstanding
            </Typography>
          </Grid>
          <MaterialTable
            state={isLoading}
            onRowClick={handleRowClick}
            title=""
            columns={[
              {
                title: "Fund Code",
                field: "Code",
                sortable: true,
              },
              {
                title: "Fund Name",
                field: "Name",
                sortable: true,
              },
              {
                title: "Email Address",
                field: "fundEmail",
                sortable: true,
                style: {
                  width: "250px",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                },
              },
              {
                title: "ABN",
                field: "ABN",
                sortable: true,
              },
              {
                title: "Report date",
                field: "ProcessedToDate",
                sortable: true,
              },
              {
                title: "Status",
                field: "status",
                render: (rowData) =>
                  !rowData.reportDate ? (
                    <CircleIcon
                      style={{ color: "#EC0018", fontSize: "medium" }}
                    />
                  ) : Math.abs(
                      moment().diff(
                        moment(rowData.reportDate, "DD/MM/YYYY"),
                        "days"
                      )
                    ) < 365 ? (
                    <CircleIcon
                      style={{ color: "#2AD000", fontSize: "medium" }}
                    />
                  ) : (
                    <CircleIcon
                      style={{ color: "#FF9900", fontSize: "medium" }}
                    />
                  ),
                sortable: false,
                style: {
                  textAlign: "center",
                },
              },
            ]}
            data={fundsList}
            icons={tableIcons}
            options={{
              isLoading: true,
              paginationType: "stepped",
              draggable: false,
              pageSize: fundsList.length <= 5 ? 5 : 10,
              search: true,
              sorting: true,
              cellStyle: {
                fontSize: "14px",
                fontFamily: "Poppins",
                textAlign: "left",
                whiteSpace: "nowrap",
                color: "#000000",
              },
              headerStyle: {
                backgroundColor: "#FFFFFF",
                padding: "15px 10px",
                fontSize: "13px",
                fontWeight: "700",
                textAlign: "left",
                borderTop: "1px solid #C1C1C1",
                color: "#000000",
                paddingLeft: "15px",
              },
              searchFieldAlignment: "left",
              searchFieldVariant: "outlined",
              searchFieldStyle: {
                backgroundColor: "#FFFFFF",
                marginBottom: "20px",
                marginLeft: "-40px",
              },
              rowStyle: (x) => {
                if (x.tableData.id % 2) {
                  return { backgroundColor: "#F2F3F3" };
                }
              },
              pageSizeOptions: [5, 10, 20, 25, 50],
            }}
          />
          {invalidTokenResponse && (
            <Alert severity="error">
              Invalid Class Super token.{" "}
              <a href="/profile/classSuperConsentStatus">
                {" "}
                Click here to reconnect{" "}
              </a>
              .
            </Alert>
          )}
          <Toolbar />
          <Footer />
        </Box>
      )}
      <BGLHealthCheckModal />
      <ClassSuperHealthCheckModal />
    </>
  );
}
