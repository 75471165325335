import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import CardMedia from '@mui/material/CardMedia';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import DOMPurify from 'dompurify';

export default function MediaCard({ top, data }) {
  return (
    <Box sx={{
      width: 320,
      backgroundColor: '#ECEFF1',
      position: 'absolute',
      right: 24,
      top,
      cursor: 'pointer',
      float: 'right',
      zIndex: 99
    }}>
      <Stack sx={{ justifyContent: "flex-center" }}>
        <Typography gutterBottom variant="smallCaps" sx={{ mt: 3, ml: 4 }}>
          More Info
        </Typography>
        <Typography gutterBottom sx={{ ml: 4, mb: 2, mr: 3, fontSize: 16, fontWeight: 700 }}>
          {data.heading}
        </Typography>
        <Card sx={{ mx: 4, justifyContent: "flex-center", backgroundColor: '#ECEFF1', borderRadius: 0 }}>
          {data.image && <CardMedia
            component="img"
            height="137"
            width="209"
            image={data.image}
            alt="more info"
          />}
          <Box>
            {data.description.split('<newline>').map((item, index) => {
                let marginTop = data.image ? 16 : 0;
                if (item === '') {
                    marginTop += 16;
                }
                if (index === 0) {
                    return (
                        <Typography key={index} style={{fontSize: '14px', lineHeight: '24px'}} >
                            <span dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(item)}}></span>
                        </Typography>
                    )
                } else {
                    return (
                        <Typography key={index} style={{fontSize: '14px', marginTop, lineHeight: '24px'}} >
                            <span dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(item)}}></span>
                        </Typography>
                    )
                }
            })}
          </Box>
          <Link
            rel="noopener noreferrer" 
            target="_blank" 
            href={data.url} 
            underline="always" 
            style={{ fontSize: 14, lineHeight: '24px', color: 'black', fontWeight: 700, textDecoration: 'underline', textDecorationColor: 'black' }}
          >
            {data.linkName}
          </Link>
          <Typography sx={{ mb: 3 }} />
        </Card>
      </Stack>
    </Box>
  );
}