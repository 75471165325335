import { API } from "aws-amplify";
import { createFirmDetails, updateFirmDetails } from "../graphql/mutations";
import { listFirmDetails } from "../graphql/queries";

const firmService = {
  add: async (firmData) => {
    const res = await API.graphql({
      query: createFirmDetails,
      variables: {
        input: {
          sub: firmData.sub,
          email: firmData.email,
          businessName: firmData.businessName,
          keyContact: firmData.keyContact,
          phone: firmData.phone,
          addressLine1: firmData.addressLine1,
          addressLine2: firmData.addressLine2,
          postCode: firmData.postCode,
          city: firmData.city,
          state: firmData.state,
          country: firmData.country,
        },
      },
      authMode: "AMAZON_COGNITO_USER_POOLS",
    });
    return res.data?.createFirmDetails;
  },
  listFirmDetails: async () => {
    const res = await API.graphql({
      query: listFirmDetails,
      authMode: "AMAZON_COGNITO_USER_POOLS",
    });
    return res.data?.listFirmDetails?.items[0];
  },
  updateFirmDetails: async (firmData) => {
    const updateFirm = await API.graphql({
      query: updateFirmDetails,
      variables: {
        input: {
          id: firmData.id,
          email: firmData.businessEmail,
          businessName: firmData.businessName,
          keyContact: firmData.keyContact,
          phone: firmData.phone,
          addressLine1: firmData.addressLine1,
          addressLine2: firmData.addressLine2,
          postCode: firmData.postCode,
          city: firmData.city,
          state: firmData.state,
        },
      },
      authMode: "AMAZON_COGNITO_USER_POOLS",
    });
    return updateFirm?.data?.updateFirmDetails;
  },
};

export default firmService;
