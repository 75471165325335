import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import { makeStyles } from "@mui/styles";
import { styled } from "@mui/material/styles";
import { Typography, Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import useMediaQuery from "@mui/material/useMediaQuery";
import { headerActions } from "../../store/header";
import AccountantsGPSLogo from "./accountantsGPS.svg";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

const CreateAccountButton = styled(Button)({
  textTransform: "none",
  fontSize: "11px",
  padding: "10px 20px",
  border: "none",
  fontWeight: 400,
  lineHeight: "20px",
  fontFamily: ["Poppins"].join(","),
  color: "black",
});

const useStyles = makeStyles(() => ({
  createAccountConsentBox: {
    padding: "32px",
    position: "absolute",
    backgroundColor: "#FFFFFF",
    boxShadow: "4px 4px 6px rgba(0, 0, 0, 0.25)",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
    height: "calc(100% - 32px)",
    maxHeight: "830px",
    overflowX: "hidden",
    overflowY: "auto",
  },
  text18: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "18px",
    lineHeight: "24px",
    fontWeight: "bolder",
  },
  text12: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "13px",
    lineHeight: "22px",
  },
  marginBtm: {
    marginTop: "0px",
    marginBottom: "32px",
  },
  marginTop: {
    marginTop: "32px",
    marginBottom: "0px",
  },
  testSpacing: {
    marginTop: "22px",
    marginBottom: "0px",
  },
  ul: {
    listStyleType: "- ",
  },
}));

export default function CreateAccountConsent() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const open = useSelector((state) => state.header.openCreateAccountConsent);
  const user = useSelector((state) => state.auth.user);
  const [consentType, setconsentType] = useState("");

  const smallDeviceMatch = !useMediaQuery("(min-width:656px)");
  const onClose = async () => {
    document.cookie = `isUserCreateFlow=`;
    document.cookie = `userSub=`;
    document.cookie = `userData=`;
    document.cookie = `code=`;
    document.cookie = `bglIsValid=true`;
    document.cookie = `isUserCreateFlow=`;
    dispatch(headerActions.handleCloseCreateAccountConsent());
  };

  useEffect(() => {
    if (user && user.id) {
      // set constentType on basis of class super and BGL
      if (user.consentType === "class_super") {
        setconsentType("Class Super");
      } else {
        setconsentType("BGL");
      }
    }
  }, [user]);

  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        className={classes.createAccountConsentBox}
        sx={{ width: `${smallDeviceMatch ? "calc(100% - 32px)" : "638px"}` }}
      >
        <Grid item xs={3} className="footer-justify-even align-items-center">
          <img
            src={AccountantsGPSLogo}
            style={{ height: 60, width: 250 }}
            alt="accountants_gps_create_account_consent"
          />
        </Grid>

        <Box mt={3} pl={3} pr={3}>
          <Grid>
            <Typography className={classes.text18}>
              Dear {user?.given_name},
            </Typography>
          </Grid>
          <Grid>
            <Typography mt={1} className={classes.text12}>
              <strong>accountantsGPS</strong> and <strong>{consentType}</strong>{" "}
              welcome you to Australia's newest digital platform created to
              deliver tailored services to your SMSF clients. Our services is
              designed to support you taking a leadership position with your
              SMSF clients, to ensure they maximise the wealth potential at the
              same time.
            </Typography>
            <Typography mt={2} className={classes.text12}>
              Importantly, you do not need to be licenced to offer our services.
            </Typography>
            <Typography mt={2} className={classes.text12}>
              We have developed Australia's first digital and AI led SMSF
              Check-Up (factual) report, which is designed to:
            </Typography>
            <Typography mt={2} className={classes.text12}>
              <ul>
                <li>
                  Review a funds financial position today and provide a detailed
                  analysis to identify, both:
                  <ul className={classes.ul}>
                    <li>
                      Potential opportunities for the Trustee and Members,
                    </li>
                    <li>Where attention is required in specific areas,</li>
                  </ul>
                </li>
                <li>
                  Provide access to specialist providers with no obligation for
                  the Trustees to use any of the nominated parties,
                </li>
                <li>
                  Assist the accountant in their regular fund review – both from
                  a compliance and financial perspective,
                </li>
                <li>
                  Offer both technical and communication support to the
                  accountant as part of a broader business strategy,
                </li>
                <li>
                  Undertake a formal review each year or when circumstances
                  changes,
                </li>
                <li>
                  Provide generous revenue sharing arrangements for all services
                  accessed.
                </li>
              </ul>
            </Typography>
            <Typography mt={2} className={classes.text12}>
              We invite you to review our SMSF Check-Up Report onboarding
              process, and start your new journey with accountantsGPS and{" "}
              {consentType}.
            </Typography>
            <Typography
              className={[classes.text12, classes.testSpacing]}
              style={{ display: "flex" }}
            >
              <div>
                <strong>George Haramis </strong>
              </div>
              {user && user.id && user.consentType !== "class_super" && (
                <div style={{ display: "flex", paddingLeft: "30px" }}>
                  <strong>Ron Lesh </strong>
                </div>
              )}
            </Typography>
          </Grid>
          <Typography mt={2} sx={{ textAlign: "end" }} mr={5}>
            <CreateAccountButton variant="text" type="button" onClick={onClose}>
              SKIP FOR NOW &nbsp;
              <ArrowForwardIosIcon style={{ fontSize: "small" }} />
            </CreateAccountButton>
          </Typography>
        </Box>
      </Box>
    </Modal>
  );
}
