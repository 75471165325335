import "./loadingSpinner.css";
import CircularProgress from "@mui/material/CircularProgress";
function LoadingSpinner() {
  return (
    <div className="background">
      <CircularProgress className="spinner"></CircularProgress>
    </div>
  );
}

export default LoadingSpinner;
