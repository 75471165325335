import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import { makeStyles } from '@mui/styles';
import { styled } from '@mui/material/styles';
import { InputBase, Typography, Grid } from '@mui/material';
import Link from '@mui/material/Link';
import { useForm } from 'react-hook-form';
import { X } from 'react-feather';
import { headerActions } from "../../store/header";
import { useDispatch, useSelector } from 'react-redux';
import { Auth } from 'aws-amplify';
import sha256 from 'sha256';

const CreateNewPasswordButton = styled(Button)({
  boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
  textTransform: 'none',
  fontSize: '14px',
  padding: '10px 20px',
  border: 'none',
  fontWeight: 400,
  lineHeight: '24px',
  backgroundColor: '#1A2842',
  color: 'white',
  fontFamily: [
    'Poppins'
  ].join(','),
  '&:hover': {
    backgroundColor: '#1A2842',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
  },
  '&:active': {
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    backgroundColor: '#1A2842',
  },
  '&:focus': {
    backgroundColor: '#1A2842',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
  },
  '&:disabled': {
    color: 'white',
    backgroundColor: '#8C94A0;',
  },
});

const useStyles = makeStyles(theme => ({
  createNewPaswordBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: '32px',
    position: 'absolute',
    width: '577px',
    height: '393px',
    backgroundColor: '#FFFFFF',
    boxShadow: '4px 4px 6px rgba(0, 0, 0, 0.25)',
    borderRadius: '5px',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
  },
  createNewPasswordLbl: {
    fontFamily: 'Poppins',
    fontWeight: 600,
    fontSize: '24px',
    lineHeight: '32px',
    color: '#000000',
    marginBlock: 0,
  },
  textFieldLabel: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '24px',
    color: '#000000',
    marginBlock: 0,
  },
  red: {
    color: 'red',
  },
  textField: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    background: '#ECEFF1',
    alignSelf: 'stretch',
    height: '44px',
    paddingLeft: 8,
    paddingRight: 8,
  },
  smallTextFieldLabel: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '9px',
    lineHeight: '16px',
    color: '#000000',
    letterSpacing: '0.1em',
  },
  text14: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '24px',
  },
  crossIcon: {
    position: 'absolute',
    right: '24px',
    cursor: 'pointer',
  }
}));

export default function CreateNewPassword() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const open = useSelector(state => state.header.openCreateNewPassword);
  const email = useSelector(state => state.header.email);
  // const newPassword = useSelector(state => state.header.newPassword);
  const { register, handleSubmit, formState: { errors }, watch, control, reset } = useForm();

  const password = React.useState({});
  password.current = watch("password", "");

  const onSubmit = async (data) => {
    if (!errors.password || !errors.retypepassword) {
      dispatch(headerActions.handleCloseCreateNewPassword());
      const headerState = {
        email,
        password: sha256(data.retypepassword).toString(),
        resetPassword: true
      };
      try {
        const forgotPassword = await Auth.forgotPassword(email);
      } catch (error) {
        if (error?.name === "LimitExceededException") {
          headerState.showCodeErrorLimit = true;
        }
      }
      dispatch(
        headerActions.handleCodeVerificationBase(headerState)
      );
      //dispatch(headerActions.handleCodeVerificationBase({ email, password: data.retypepassword, resetPassword: true }));
      dispatch(headerActions.handleIsCreateNewPassword({ isCreateNewPassword: true }));
    }
  };

  const onError = (errors, e) => { //console.log(errors, e);
  }

  const onClickCreateAccount = () => {
    dispatch(headerActions.handleCloseCreateNewPassword());
    dispatch(headerActions.handleCreateAccount());
  }

  const onClose = () => {
    dispatch(headerActions.handleCloseCreateNewPassword());
  }

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <form onSubmit={handleSubmit(onSubmit, onError)}>
        <Box className={classes.createNewPaswordBox}>
          <Typography className={classes.createNewPasswordLbl} style={{ marginBlock: 0 }}>
            Create New Password
          </Typography>
          <Typography className={classes.textFieldLabel} style={{ marginTop: 32 }}>
            <span style={{ color: errors.password ? 'red' : 'black' }}>New Password</span> <span className={classes.red}>*</span>
          </Typography>
          <InputBase
            type="password"
            className={`${classes.textField} ${classes.text14}`}
            {...register("password", { required: true, pattern: /^(?=.*\d).{8,}$/ })}
          />
          <Typography className={classes.smallTextFieldLabel}
            style={{ marginBlock: 0 }}
          >
            Must be more than 8 characters and contain at least 1 number
          </Typography>
          <Typography className={classes.textFieldLabel} style={{ marginTop: 24 }}>
            <span style={{ color: errors.retypepassword ? 'red' : 'black' }}>Re-type Password</span> <span className={classes.red}>*</span>
          </Typography>
          <InputBase
            type="password"
            className={`${classes.textField} ${classes.text14}`}
            {...register("retypepassword", {
              required: true,
              validate: value =>
                value === password.current
            })}
          />
          <Grid container style={{ marginTop: 44 }}>
            <Grid item xs={6}>
              <Typography className={classes.text14} style={{ marginBlock: 0 }}>
                Not a member yet?
              </Typography>
              <Link underline="always" color="inherit" className={classes.text14} style={{ cursor: 'pointer', color: 'black' }} onClick={onClickCreateAccount}>
                {'Create Account'}
              </Link>
            </Grid>
            <Grid item xs={6}>
              <Link onClick={onClose} underline="always" color="inherit" className={classes.text14} style={{ cursor: 'pointer', textUnderlineOffset: '8pt', color: 'black', marginLeft: 11 }}>
                {'Cancel'}
              </Link>
              <CreateNewPasswordButton variant="contained" style={{ marginLeft: 28 }} type="submit" disabled={errors && (errors.password || errors.retypepassword)}>Change Password</CreateNewPasswordButton>
            </Grid>
          </Grid>
          <X className={classes.crossIcon} onClick={onClose} />
        </Box>
      </form>
    </Modal>
  );
}
