import { createSlice } from "@reduxjs/toolkit";

const auth = createSlice({
  name: "auth",
  initialState: { loggedIn: false, user: {}, isUserGroupAdmin: false },
  reducers: {
    handleUserAuth(state, actions) {
      state.loggedIn = actions.payload.loggedIn;
      state.user = actions.payload.user
        ? JSON.parse(actions.payload.user)
        : null;
      state.isUserGroupAdmin =
        actions.payload.isUserGroupAdmin || state.isUserGroupAdmin;
    },
    updateUserLogin(state, actions) {
      state.loggedIn = actions.payload.loggedIn;
    },
    updateUserAdmin(state, actions) {
      state.isUserGroupAdmin = actions.payload.isUserGroupAdmin;
    },
  },
});

export const authActions = auth.actions;
export default auth;
