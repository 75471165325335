import Grid from '@mui/material/Grid';
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import { X } from 'react-feather';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
    text14: {
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '24px',
    },
    text18: {
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: '18px',
      lineHeight: '24px',
    },
    crossIcon: {
      cursor: 'pointer',
    },
  }));

export default function NotificationMain(props) {
  const classes = useStyles();
  
  return (
    <Box sx={props.boxStyle || { width:'100%', backgroundColor: props.color, mb: 8}}>
      <Grid container sx={{position: 'relative'}}>
        <Grid style={{marginLeft: 24, marginTop: 24, marginBottom: 24, width: 'calc(100% - 64px)'}}>
          <Typography className={classes.text18}>
            {props.title}
          </Typography>
          <Typography className={classes.text14} >
            {props.description}
          </Typography>
          <Typography>
            <Link href="#" 
              className={classes.text14} 
              style={{fontWeight: 700, color: 'black', textDecoration: 'underline', textDecorationColor: 'black'}}
              onClick={props.doAction} >
                {props.actionText}
            </Link>
          </Typography>
        </Grid>
        <Grid  style={{width: '40px', height: '40px', padding:'8px', position: 'absolute',right:'0px'}}>
          <X size={24} className={classes.crossIcon} onClick={props.closeAction} />
        </Grid>
      </Grid>
    </Box>
  );
}