/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const generateReport = /* GraphQL */ `
  mutation GenerateReport($data: String) {
    generateReport(data: $data)
  }
`;
export const payments = /* GraphQL */ `
  mutation Payments($data: String) {
    payments(data: $data)
  }
`;
export const generateClassSuperReport = /* GraphQL */ `
  mutation GenerateClassSuperReport($data: String) {
    generateClassSuperReport(data: $data)
  }
`;
export const createQuestionnaireResponse = /* GraphQL */ `
  mutation CreateQuestionnaireResponse(
    $input: CreateQuestionnaireResponseInput!
    $condition: ModelQuestionnaireResponseConditionInput
  ) {
    createQuestionnaireResponse(input: $input, condition: $condition) {
      id
      email
      sub
      productId
      fundId
      surveyId
      type
      status
      data
      currentPageNo
      reportLink
      createdDate
      updatedDate
      maxPageNoVisited
      createdAt
      updatedAt
      createdBy
      __typename
    }
  }
`;
export const updateQuestionnaireResponse = /* GraphQL */ `
  mutation UpdateQuestionnaireResponse(
    $input: UpdateQuestionnaireResponseInput!
    $condition: ModelQuestionnaireResponseConditionInput
  ) {
    updateQuestionnaireResponse(input: $input, condition: $condition) {
      id
      email
      sub
      productId
      fundId
      surveyId
      type
      status
      data
      currentPageNo
      reportLink
      createdDate
      updatedDate
      maxPageNoVisited
      createdAt
      updatedAt
      createdBy
      __typename
    }
  }
`;
export const deleteQuestionnaireResponse = /* GraphQL */ `
  mutation DeleteQuestionnaireResponse(
    $input: DeleteQuestionnaireResponseInput!
    $condition: ModelQuestionnaireResponseConditionInput
  ) {
    deleteQuestionnaireResponse(input: $input, condition: $condition) {
      id
      email
      sub
      productId
      fundId
      surveyId
      type
      status
      data
      currentPageNo
      reportLink
      createdDate
      updatedDate
      maxPageNoVisited
      createdAt
      updatedAt
      createdBy
      __typename
    }
  }
`;
export const createProduct = /* GraphQL */ `
  mutation CreateProduct(
    $input: CreateProductInput!
    $condition: ModelProductConditionInput
  ) {
    createProduct(input: $input, condition: $condition) {
      id
      title
      description
      summary
      type
      category
      surveyId
      accountCode
      planId
      priceBase
      invoiceDue
      image
      imageDetail
      action
      createdDate
      createdBy
      updatedDate
      updatedBy
      owner
      url
      comingSoon
      estimatedTime
      sub
      __typename
    }
  }
`;
export const updateProduct = /* GraphQL */ `
  mutation UpdateProduct(
    $input: UpdateProductInput!
    $condition: ModelProductConditionInput
  ) {
    updateProduct(input: $input, condition: $condition) {
      id
      title
      description
      summary
      type
      category
      surveyId
      accountCode
      planId
      priceBase
      invoiceDue
      image
      imageDetail
      action
      createdDate
      createdBy
      updatedDate
      updatedBy
      owner
      url
      comingSoon
      estimatedTime
      sub
      __typename
    }
  }
`;
export const deleteProduct = /* GraphQL */ `
  mutation DeleteProduct(
    $input: DeleteProductInput!
    $condition: ModelProductConditionInput
  ) {
    deleteProduct(input: $input, condition: $condition) {
      id
      title
      description
      summary
      type
      category
      surveyId
      accountCode
      planId
      priceBase
      invoiceDue
      image
      imageDetail
      action
      createdDate
      createdBy
      updatedDate
      updatedBy
      owner
      url
      comingSoon
      estimatedTime
      sub
      __typename
    }
  }
`;
export const createProductType = /* GraphQL */ `
  mutation CreateProductType(
    $input: CreateProductTypeInput!
    $condition: ModelProductTypeConditionInput
  ) {
    createProductType(input: $input, condition: $condition) {
      id
      name
      status
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateProductType = /* GraphQL */ `
  mutation UpdateProductType(
    $input: UpdateProductTypeInput!
    $condition: ModelProductTypeConditionInput
  ) {
    updateProductType(input: $input, condition: $condition) {
      id
      name
      status
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteProductType = /* GraphQL */ `
  mutation DeleteProductType(
    $input: DeleteProductTypeInput!
    $condition: ModelProductTypeConditionInput
  ) {
    deleteProductType(input: $input, condition: $condition) {
      id
      name
      status
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createProductCategory = /* GraphQL */ `
  mutation CreateProductCategory(
    $input: CreateProductCategoryInput!
    $condition: ModelProductCategoryConditionInput
  ) {
    createProductCategory(input: $input, condition: $condition) {
      id
      name
      status
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateProductCategory = /* GraphQL */ `
  mutation UpdateProductCategory(
    $input: UpdateProductCategoryInput!
    $condition: ModelProductCategoryConditionInput
  ) {
    updateProductCategory(input: $input, condition: $condition) {
      id
      name
      status
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteProductCategory = /* GraphQL */ `
  mutation DeleteProductCategory(
    $input: DeleteProductCategoryInput!
    $condition: ModelProductCategoryConditionInput
  ) {
    deleteProductCategory(input: $input, condition: $condition) {
      id
      name
      status
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createSurvey = /* GraphQL */ `
  mutation CreateSurvey(
    $input: CreateSurveyInput!
    $condition: ModelSurveyConditionInput
  ) {
    createSurvey(input: $input, condition: $condition) {
      id
      surveyId
      filename
      status
      versionDate
      __typename
    }
  }
`;
export const updateSurvey = /* GraphQL */ `
  mutation UpdateSurvey(
    $input: UpdateSurveyInput!
    $condition: ModelSurveyConditionInput
  ) {
    updateSurvey(input: $input, condition: $condition) {
      id
      surveyId
      filename
      status
      versionDate
      __typename
    }
  }
`;
export const deleteSurvey = /* GraphQL */ `
  mutation DeleteSurvey(
    $input: DeleteSurveyInput!
    $condition: ModelSurveyConditionInput
  ) {
    deleteSurvey(input: $input, condition: $condition) {
      id
      surveyId
      filename
      status
      versionDate
      __typename
    }
  }
`;
export const createGeneratedDocument = /* GraphQL */ `
  mutation CreateGeneratedDocument(
    $input: CreateGeneratedDocumentInput!
    $condition: ModelGeneratedDocumentConditionInput
  ) {
    createGeneratedDocument(input: $input, condition: $condition) {
      id
      surveyId
      productType
      productId
      status
      documentLocation
      createdDate
      expirationDate
      sub
      purchaseId
      QuestionnaireResponseId
      isNew
      QuestionnaireResponse {
        id
        email
        sub
        productId
        fundId
        surveyId
        type
        status
        data
        currentPageNo
        reportLink
        createdDate
        updatedDate
        maxPageNoVisited
        createdAt
        updatedAt
        createdBy
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateGeneratedDocument = /* GraphQL */ `
  mutation UpdateGeneratedDocument(
    $input: UpdateGeneratedDocumentInput!
    $condition: ModelGeneratedDocumentConditionInput
  ) {
    updateGeneratedDocument(input: $input, condition: $condition) {
      id
      surveyId
      productType
      productId
      status
      documentLocation
      createdDate
      expirationDate
      sub
      purchaseId
      QuestionnaireResponseId
      isNew
      QuestionnaireResponse {
        id
        email
        sub
        productId
        fundId
        surveyId
        type
        status
        data
        currentPageNo
        reportLink
        createdDate
        updatedDate
        maxPageNoVisited
        createdAt
        updatedAt
        createdBy
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteGeneratedDocument = /* GraphQL */ `
  mutation DeleteGeneratedDocument(
    $input: DeleteGeneratedDocumentInput!
    $condition: ModelGeneratedDocumentConditionInput
  ) {
    deleteGeneratedDocument(input: $input, condition: $condition) {
      id
      surveyId
      productType
      productId
      status
      documentLocation
      createdDate
      expirationDate
      sub
      purchaseId
      QuestionnaireResponseId
      isNew
      QuestionnaireResponse {
        id
        email
        sub
        productId
        fundId
        surveyId
        type
        status
        data
        currentPageNo
        reportLink
        createdDate
        updatedDate
        maxPageNoVisited
        createdAt
        updatedAt
        createdBy
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createUserInfo = /* GraphQL */ `
  mutation CreateUserInfo(
    $input: CreateUserInfoInput!
    $condition: ModelUserInfoConditionInput
  ) {
    createUserInfo(input: $input, condition: $condition) {
      id
      sub
      email
      given_name
      family_name
      isActive
      state
      planId
      bglToken
      bglTokenExpirationDate
      bglRefreshExpirationDate
      bglIsValid
      classSuperToken
      classSuperTokenExpirationDate
      classSuperRefreshExpirationDate
      classSuperIsValid
      consentType
      isClassTokenHash
      emailVerifyCode
      emailVerifyHash
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateUserInfo = /* GraphQL */ `
  mutation UpdateUserInfo(
    $input: UpdateUserInfoInput!
    $condition: ModelUserInfoConditionInput
  ) {
    updateUserInfo(input: $input, condition: $condition) {
      id
      sub
      email
      given_name
      family_name
      isActive
      state
      planId
      bglToken
      bglTokenExpirationDate
      bglRefreshExpirationDate
      bglIsValid
      classSuperToken
      classSuperTokenExpirationDate
      classSuperRefreshExpirationDate
      classSuperIsValid
      consentType
      isClassTokenHash
      emailVerifyCode
      emailVerifyHash
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteUserInfo = /* GraphQL */ `
  mutation DeleteUserInfo(
    $input: DeleteUserInfoInput!
    $condition: ModelUserInfoConditionInput
  ) {
    deleteUserInfo(input: $input, condition: $condition) {
      id
      sub
      email
      given_name
      family_name
      isActive
      state
      planId
      bglToken
      bglTokenExpirationDate
      bglRefreshExpirationDate
      bglIsValid
      classSuperToken
      classSuperTokenExpirationDate
      classSuperRefreshExpirationDate
      classSuperIsValid
      consentType
      isClassTokenHash
      emailVerifyCode
      emailVerifyHash
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createStateToUserInfo = /* GraphQL */ `
  mutation CreateStateToUserInfo(
    $input: CreateStateToUserInfoInput!
    $condition: ModelStateToUserInfoConditionInput
  ) {
    createStateToUserInfo(input: $input, condition: $condition) {
      state
      userId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateStateToUserInfo = /* GraphQL */ `
  mutation UpdateStateToUserInfo(
    $input: UpdateStateToUserInfoInput!
    $condition: ModelStateToUserInfoConditionInput
  ) {
    updateStateToUserInfo(input: $input, condition: $condition) {
      state
      userId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteStateToUserInfo = /* GraphQL */ `
  mutation DeleteStateToUserInfo(
    $input: DeleteStateToUserInfoInput!
    $condition: ModelStateToUserInfoConditionInput
  ) {
    deleteStateToUserInfo(input: $input, condition: $condition) {
      state
      userId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createPurchase = /* GraphQL */ `
  mutation CreatePurchase(
    $input: CreatePurchaseInput!
    $condition: ModelPurchaseConditionInput
  ) {
    createPurchase(input: $input, condition: $condition) {
      id
      productId
      productType
      activationDate
      expiryDate
      referralCode
      firmName
      amount
      edits
      properties
      sub
      questionnaireResponseId
      status
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updatePurchase = /* GraphQL */ `
  mutation UpdatePurchase(
    $input: UpdatePurchaseInput!
    $condition: ModelPurchaseConditionInput
  ) {
    updatePurchase(input: $input, condition: $condition) {
      id
      productId
      productType
      activationDate
      expiryDate
      referralCode
      firmName
      amount
      edits
      properties
      sub
      questionnaireResponseId
      status
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deletePurchase = /* GraphQL */ `
  mutation DeletePurchase(
    $input: DeletePurchaseInput!
    $condition: ModelPurchaseConditionInput
  ) {
    deletePurchase(input: $input, condition: $condition) {
      id
      productId
      productType
      activationDate
      expiryDate
      referralCode
      firmName
      amount
      edits
      properties
      sub
      questionnaireResponseId
      status
      product {
        id
        title
        description
        summary
        type
        category
        surveyId
        accountCode
        planId
        priceBase
        invoiceDue
        image
        imageDetail
        action
        createdDate
        createdBy
        updatedDate
        updatedBy
        owner
        url
        comingSoon
        estimatedTime
        sub
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createFirmDetails = /* GraphQL */ `
  mutation CreateFirmDetails(
    $input: CreateFirmDetailsInput!
    $condition: ModelFirmDetailsConditionInput
  ) {
    createFirmDetails(input: $input, condition: $condition) {
      id
      sub
      businessName
      email
      phone
      keyContact
      addressLine1
      addressLine2
      city
      state
      postCode
      country
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateFirmDetails = /* GraphQL */ `
  mutation UpdateFirmDetails(
    $input: UpdateFirmDetailsInput!
    $condition: ModelFirmDetailsConditionInput
  ) {
    updateFirmDetails(input: $input, condition: $condition) {
      id
      sub
      businessName
      email
      phone
      keyContact
      addressLine1
      addressLine2
      city
      state
      postCode
      country
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteFirmDetails = /* GraphQL */ `
  mutation DeleteFirmDetails(
    $input: DeleteFirmDetailsInput!
    $condition: ModelFirmDetailsConditionInput
  ) {
    deleteFirmDetails(input: $input, condition: $condition) {
      id
      sub
      businessName
      email
      phone
      keyContact
      addressLine1
      addressLine2
      city
      state
      postCode
      country
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createSubscriptionPlans = /* GraphQL */ `
  mutation CreateSubscriptionPlans(
    $input: CreateSubscriptionPlansInput!
    $condition: ModelSubscriptionPlansConditionInput
  ) {
    createSubscriptionPlans(input: $input, condition: $condition) {
      planId
      name
      stripeName
      price
      features
      reportsIncluded
      term
      extraReportPrice
      createdDate
      createdBy
      updatedDate
      updatedBy
      __typename
    }
  }
`;
export const updateSubscriptionPlans = /* GraphQL */ `
  mutation UpdateSubscriptionPlans(
    $input: UpdateSubscriptionPlansInput!
    $condition: ModelSubscriptionPlansConditionInput
  ) {
    updateSubscriptionPlans(input: $input, condition: $condition) {
      planId
      name
      stripeName
      price
      features
      reportsIncluded
      term
      extraReportPrice
      createdDate
      createdBy
      updatedDate
      updatedBy
      __typename
    }
  }
`;
export const deleteSubscriptionPlans = /* GraphQL */ `
  mutation DeleteSubscriptionPlans(
    $input: DeleteSubscriptionPlansInput!
    $condition: ModelSubscriptionPlansConditionInput
  ) {
    deleteSubscriptionPlans(input: $input, condition: $condition) {
      planId
      name
      stripeName
      price
      features
      reportsIncluded
      term
      extraReportPrice
      createdDate
      createdBy
      updatedDate
      updatedBy
      __typename
    }
  }
`;
export const createRequestInfo = /* GraphQL */ `
  mutation CreateRequestInfo(
    $input: CreateRequestInfoInput!
    $condition: ModelRequestInfoConditionInput
  ) {
    createRequestInfo(input: $input, condition: $condition) {
      id
      sourceIp
      requestName
      responseStatus
      logDate
      count
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateRequestInfo = /* GraphQL */ `
  mutation UpdateRequestInfo(
    $input: UpdateRequestInfoInput!
    $condition: ModelRequestInfoConditionInput
  ) {
    updateRequestInfo(input: $input, condition: $condition) {
      id
      sourceIp
      requestName
      responseStatus
      logDate
      count
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteRequestInfo = /* GraphQL */ `
  mutation DeleteRequestInfo(
    $input: DeleteRequestInfoInput!
    $condition: ModelRequestInfoConditionInput
  ) {
    deleteRequestInfo(input: $input, condition: $condition) {
      id
      sourceIp
      requestName
      responseStatus
      logDate
      count
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateHubSpotData = /* GraphQL */ `
  mutation UpdateHubSpotData($data: String) {
    updateHubSpotData(data: $data)
  }
`;
