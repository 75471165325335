import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { makeStyles } from "@mui/styles";
import { Typography, Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import useMediaQuery from "@mui/material/useMediaQuery";
import { headerActions } from "../../store/header";
import { CircularProgress } from "@material-ui/core";
import { SecondaryBtn } from "../../assets/primitives/buttons";
import * as common from "../common";
import userService from "../../services/user";
import { Auth } from "aws-amplify";
import { authActions } from "../../store/auth";

const useStyles = makeStyles((theme) => ({
  bglConsentBox: {
    // display: 'flex',
    // flexDirection: 'column',
    // alignItems: 'flex-start',
    padding: "32px",
    position: "absolute",
    backgroundColor: "#FFFFFF",
    boxShadow: "4px 4px 6px rgba(0, 0, 0, 0.25)",
    borderRadius: "5px",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
    height: "30%",
    width: "765px",
    maxHeight: "40%",
    overflowX: "hidden",
    overflowY: "auto",
  },
  bglConsentLbl: {
    fontFamily: "Poppins",
    fontWeight: 600,
    fontSize: "24px",
    lineHeight: "32px",
    color: "#000000",
    marginBlock: 0,
    marginBottom: 32,
  },
  textFieldLabel: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "24px",
    color: "#000000",
    marginBlock: 0,
  },
  smallTextFieldLabel: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "9px",
    lineHeight: "16px",
    color: "#000000",
    letterSpacing: "0.1em",
  },
  red: {
    color: "red",
  },
  textField: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    background: "#ECEFF1",
    alignSelf: "stretch",
    height: "44px",
    paddingLeft: 8,
    paddingRight: 8,
  },
  text14: {
    fontFamily: "Poppins",
    fontStyle: "small",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "24px",
  },
  crossIcon: {
    position: "absolute",
    right: "24px",
    cursor: "pointer",
  },
  marginBtm: {
    marginTop: "0px",
    marginBottom: "32px",
  },
  circle: {
    stroke: "url(#linearColors)",
  },
}));

const getBglToken = async (code, state) => {
  if (code !== null && state !== null) {
    try {
      return await userService.getUserBglToken(code, state);
    } catch (error) {
      console.log("code", code);
      console.log("state", state);
      console.log("err", error);
    }
  } else console.log("state or code cannot be null");
};
const getBglTokenUP = async (code, state) => {
  if (code !== null && state !== null) {
    try {
      return await userService.getUserBglTokenUP(code, state);
    } catch (error) {
      console.log("code", code);
      console.log("state", state);
      console.log("err", error);
    }
  } else console.log("state or code cannot be null");
};
export default function BGLConsentSpinner() {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [isTimeout, setIsTimeout] = React.useState(false);
  const open = useSelector((state) => state.header.openBGLConsentSpinner);
  const smallDeviceMatch = !useMediaQuery("(min-width:656px)");
  const isUserLoggedIn = useSelector((state) => state.auth.loggedIn);
  const bglAuthCode = useSelector((state) => state.header.bglAuthCode);
  const isBglTokenUpdateFlow = useSelector(
    (state) => state.header.isBglTokenUpdateFlow
  );
  const user = useSelector((state) => state.auth.user);
  const userData =
    common.getCookie("userData") && common.getCookie("userData") !== undefined
      ? JSON.parse(common.getCookie("userData"))
      : "";
  let timer = null;

  if (!isUserLoggedIn && open) {
    // Get Bgl Token for New User using IAM
    timer = setTimeout(() => {
      setIsTimeout(true);
    }, 1000 * 60 * 5);
    if (bglAuthCode && !isTimeout) {
      getBglToken(bglAuthCode, userData.state).then(async (res) => {
        if (res.indexOf("statusCode=200")) {
          const user = await Auth.signUp({
            username: userData.email,
            password: userData.password,
            attributes: {
              email: userData.email,
              family_name: userData.lastname,
              given_name: userData.firstname,
            },
          });

          dispatch(headerActions.handleCloseBGLConsentSpinner());
          if (!user.userConfirmed) {
            dispatch(headerActions.handleCloseFirmDetails());
            dispatch(
              headerActions.handleCodeVerificationBase({
                email: userData.email,
                password: userData.password,
              })
            );
            dispatch(
              headerActions.handleIsCreateNewPassword({
                isCreateNewPassword: false,
              })
            );
          }
          document.cookie = `userSub=${user.userSub};Secure;SameSite=Strict;`;
        } else {
          setIsTimeout(true);
        }
      });
    }
  } else {
    // Get New Bgl Token for Existing User using User Pools auth
    if (bglAuthCode && !isTimeout && isBglTokenUpdateFlow && open) {
      getBglTokenUP(bglAuthCode, user.state).then(async (res) => {
        if (res.indexOf("statusCode=200")) {
          console.log("Response Received");
          const userInfo = await userService.getUser(user.sub);
          const userObj = await userService.getUserFlatObject(userInfo);
          dispatch(
            authActions.handleUserAuth({
              loggedIn: true,
              user: JSON.stringify(userObj),
            })
          );
          dispatch(headerActions.handleCloseBGLConsentSpinner());
        } else {
          setIsTimeout(true);
        }
      });
    }
  }

  const onClose = () => {
    clearTimeout(timer);
    dispatch(headerActions.handleCloseBGLConsentSpinner());
  };
  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        className={classes.bglConsentBox}
        sx={{ width: `${smallDeviceMatch ? "calc(100% - 32px)" : "638px"}` }}
      >
        {!isTimeout && (
          <Grid>
            <Grid>
              <svg width="330" height="120">
                <linearGradient id="linearColors" x1="0" y1="0" x2="1" y2="1">
                  <stop offset="20%" stopColor="#5CE1C2" />
                  <stop offset="90%" stopColor="#BBE789" />
                </linearGradient>
              </svg>
              <CircularProgress
                thickness={8}
                classes={{ circle: classes.circle }}
              />
            </Grid>
            <Grid mt={5} className={classes.text14}>
              <Typography style={{ textAlign: "center" }}>
                Please wait while we authorise your BGL Account...
              </Typography>
              <Typography style={{ textAlign: "center" }}>
                Do not close this tab.
              </Typography>
            </Grid>
          </Grid>
        )}
        {isTimeout && (
          <Grid mt={15} style={{ textAlign: "center" }}>
            <Typography style={{ color: "red" }}>
              BGL Authentication failed
            </Typography>
            <Typography mt={2}>Please try again</Typography>
            <Typography mt={5}>
              <SecondaryBtn value={"Close"} onClick={onClose} />
            </Typography>
          </Grid>
        )}
      </Box>
    </Modal>
  );
}
