import {API, graphqlOperation } from 'aws-amplify';
import { updateHubSpotData } from '../graphql/mutations';

const hubSpotService = {
    pushData: async (data) => {
        const response = await API.graphql(graphqlOperation(updateHubSpotData, 
            { data: JSON.stringify(data)}
        ));
        return response;
    }
}

export default hubSpotService;