import React from "react";
import { makeStyles } from '@mui/styles';
import Grid from '@mui/material/Grid';
import './card.css';
import Typography from "@mui/material/Typography";
import { SvgIcon } from "@mui/material";

const useStyles = makeStyles({
    estimatedTime: {
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: 11,
        textTransform: 'uppercase',
        lineHeight: '16px',
        color: '#8C94A0'
    },
});

export default function Timer(props) {
    const classes = useStyles();
    const estimatedTime = props.estimatedTime;
    return (
        <Typography style={{ marginTop: 0 }} >
            <Grid container direction="row" alignSelf="center">
                <Grid item>
                    <SvgIcon fontSize="24" viewBox="0 0 24 24" style={{ marginTop: 5 }} htmlColor="#ffffff">
                        <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="#8C94A0" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M12 6V12L16 14" stroke="#8C94A0" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                    </SvgIcon>
                </Grid>
                <Grid item style={{ marginLeft: 10 }}>
                    <span className={classes.estimatedTime}>{estimatedTime}</span>
                </Grid>
            </Grid>
        </Typography>
    );
}