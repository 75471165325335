import * as React from 'react';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import { makeStyles } from '@mui/styles';
import { styled } from '@mui/material/styles';
import { Typography, Grid } from '@mui/material';
import { X } from 'react-feather';
import { useSelector } from 'react-redux';

const YesButton = styled(Button)({
  minWidth: '184px',
  boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
  textTransform: 'none',
  fontSize: '14px',
  padding: '10px 20px',
  border: 'none',
  fontWeight: 400,
  lineHeight: '24px',
  backgroundColor: '#1A2842',
  color: 'white',
  fontFamily: [
    'Poppins'
  ].join(','),
  '&:hover': {
    backgroundColor: '#1A2842',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
  },
  '&:active': {
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    backgroundColor: '#1A2842',
  },
  '&:focus': {
    backgroundColor: '#1A2842',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
  },
  '&:disabled': {
    color: 'white',
    backgroundColor: '#8C94A0;',
  },
});

const NoButton = styled(Button)({
  minWidth: '184px',
  height: '44px',
  fontSize: '14px',
  fontWeight: 400,
  lineHeight: '24px',
  background: '#FFFFFF',
  border: '1px solid #1A2842',
  boxSizing: 'border-box',
  color: '#1A2842',
  boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
  borderRadius: '5px',
  fontFamily: [
    'Poppins'
  ].join(','),
  '&:hover': {
    backgroundColor: '#FFFFFF',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
  },
  '&:active': {
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    backgroundColor: '#FFFFFF',
  },
  '&:focus': {
    backgroundColor: '#FFFFFF',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
  },
});

const useStyles = makeStyles(theme => ({
  areYouSureBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: '32px',
    position: 'absolute',
    width: '765px',
    height: '350px',
    backgroundColor: '#FFFFFF',
    boxShadow: '4px 4px 6px rgba(0, 0, 0, 0.25)',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
  },
  areYouSureLbl: {
    fontFamily: 'Poppins',
    fontWeight: 600,
    fontSize: '24px',
    lineHeight: '32px',
    color: '#000000',
  },
  text14: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '24px',
    color: '#000000',
  },
  crossIcon: {
    position: 'absolute',
    right: '24px',
    cursor: 'pointer',
  }
}));

export default function ConfirmationModal(props) {
  const classes = useStyles();

  const open = useSelector(state => state.survey.openConfirmationModal);

  const onSubmit = () => {
    props.onSave();
    props.onClose();
  }

  return (
    <Modal
      open={open}
      onClose={props.onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <>
        {(!props.isComplete) &&
          <div className={classes.areYouSureBox} style={{ textAlign: 'center' }}>
            <Typography className={classes.areYouSureLbl} style={{ marginBottom: 16, alignSelf: 'center', marginTop: '35px' }} >
              {props.header}
            </Typography>
            <Typography className={classes.text14} style={{ alignSelf: 'center', width: "500px" }}>
              {props.question}
            </Typography>
            <Typography className={classes.text14} style={{ alignSelf: 'center', marginBottom: 24, width: "500px" }}>
              {props.actionInfo}
            </Typography>
            <YesButton variant="contained" onClick={onSubmit} style={{ marginBottom: 16, alignSelf: 'center' }}>{props.yesButtonTitle}</YesButton>
            <NoButton variant="contained" onClick={props.onClose} style={{ alignSelf: 'center' }}>{props.noButtonTitle}</NoButton>
            <X className={classes.crossIcon} onClick={props.onClose} />
          </div>
        }
        {(props.isComplete) &&
          <div className={classes.areYouSureBox} style={{ textAlign: 'center', height:"286px" }}>
            <Typography className={classes.areYouSureLbl} style={{ marginBottom: 16, alignSelf: 'center', marginTop: '40px' }} >
              {props.header}
            </Typography>
            <Typography className={classes.text14} style={{ alignSelf: 'center', width: "500px" }}>
              {props.question}
            </Typography>
            <Typography className={classes.text14} style={{ alignSelf: 'center', marginBottom: 24, width: "700px" }}>
              {props.actionInfo}
            </Typography>
            <Grid style={{ display: 'flex', alignSelf: 'center' }}>
              <YesButton onClick={onSubmit} style={{ marginBottom: 16, marginRight: 10, minWidth: "110px" }}>{props.yesButtonTitle}</YesButton>
              <NoButton onClick={props.onClose} style={{ minWidth: "110px" }}>{props.noButtonTitle}</NoButton>
            </Grid>
            <X className={classes.crossIcon} onClick={props.onClose} />
          </div>
        }
      </>
    </Modal>
  );
}
