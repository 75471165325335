import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import ListItemText from "@mui/material/ListItemText";
import ListItem from "@mui/material/ListItem";
import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { accountantsGPSLogo } from "../../../assets/svg/svg";
import SurveyComponent from "../../Survey/SurveyComponent";
import SurveyCard from "../SurveyCard/surveyCard";
import Fade from "@mui/material/Fade";
import SurveyDescription from "../SurveyDescription/surveyDescription";
import { X, ChevronRight, ChevronLeft } from "react-feather";
import Box from "@mui/material/Box";
import "./fullDialog.css";
import { styled } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import ConfirmationModal from "../../confirmationModal";
import Congratulations from "../../congratulations";
import Review from "../../review";
import { surveyActions } from "../../../store/survey";
import questionnaireResponseService from "../../../services/questionnaireResponse";
import purchaseService from "../../../services/purchase";
import {
  surveyIds,
  reportGenerationStatus,
  questionnaireResponseStatus,
} from "../../../services/constants";
import documentGenerationService from "../../../services/documentGeneration";
import documentService from "../../../services/document";
import Loading from "../../spinner/spinner";
import hubSpotService from "../../../services/hubspot";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";
import { headerActions } from "../../../store/header";
import { PurchasedStatus } from "../../../services/constants";
import { useHistory } from "react-router-dom";
import BGLHealthCheckModal from "../../bglHealthCheckModal";
import ClassSuperHealthCheckModal from "../../classSuperHealthCheckModal";
import Loader from "../../spinner/spinnerSaveData";
import { NIL } from "uuid";

const BackButton = styled(Button)({
  height: "44px",
  background: "#FFFFFF",
  fontFamily: ["Poppins"].join(","),
  "&:hover": {
    backgroundColor: "#FFFFFF",
  },
  "&:active": {
    backgroundColor: "#FFFFFF",
  },
  "&:focus": {
    backgroundColor: "#FFFFFF",
  },
  "&:disabled": {
    backgroundColor: "#FFFFFF",
    color: "#8C94A0",
  },
});

const SaveButton = styled(Button)({
  height: "44px",
  fontSize: "14px",
  fontWeight: 400,
  lineHeight: "24px",
  background: "#FFFFFF",
  border: "1px solid #1A2842",
  boxSizing: "border-box",
  color: "#1A2842",
  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
  borderRadius: "5px",
  fontFamily: ["Poppins"].join(","),
  "&:hover": {
    backgroundColor: "#FFFFFF",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
  },
  "&:active": {
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    backgroundColor: "#FFFFFF",
  },
  "&:focus": {
    backgroundColor: "#FFFFFF",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
  },
});

const NextButton = styled(Button)({
  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
  textTransform: "none",
  fontSize: "14px",
  padding: "10px 20px",
  border: "none",
  fontWeight: 400,
  lineHeight: "24px",
  backgroundColor: "#1A2842",
  color: "white",
  fontFamily: ["Poppins"].join(","),
  "&:hover": {
    backgroundColor: "#1A2842",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
  },
  "&:active": {
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    backgroundColor: "#1A2842",
  },
  "&:focus": {
    backgroundColor: "#1A2842",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
  },
  "&:disabled": {
    color: "white",
    backgroundColor: "#8C94A0;",
  },
});

const useStyles = makeStyles((theme) => ({
  text14: {
    fontFamily: "Poppins",
    fontWeight: 400,
    fontSize: 14,
    lineHeight: "24px",
  },
  back: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "11px",
    lineHeight: "16px",
    textAlign: "right",
    letterSpacing: "0.25em",
    textTransform: "uppercase",
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Fade ref={ref} {...props} />;
});

export default function FullScreenDialog(props) {
  const dispatch = useDispatch();
  const openConfirmationModal = useSelector(
    (state) => state.survey.openConfirmationModal
  );
  const surveyMap = useSelector((state) => state.survey.surveyMap);
  const isExtraReportUsed = useSelector(
    (state) => state.survey.isExtraReportUsed
  );
  const user = useSelector((state) => state.auth.user);
  const isComplete = useSelector((state) => state.survey.isComplete);
  const isSurveyNotQualify = useSelector(
    (state) => state.survey.isSurveyNotQualify
  );
  const classes = useStyles();
  const [openSurveyDescription, setOpenSurveyDescription] =
    React.useState(true);
  const [activePages, setActivePages] = React.useState(
    Array(1)
      .fill()
      .map((_, idx) => 0 + idx)
  );
  const [currentPageNo, setCurrentPageNo] = React.useState(0);
  const [data, setData] = React.useState({});
  const [prefilledData, setprefilledData] = React.useState({});
  const [congrats, setCongrats] = React.useState(false);
  const [showLoading, setShowLoading] = React.useState(false);
  const [showLoader, setShowLoader] = React.useState(false);
  const [review, setReview] = React.useState(false);
  const [clickedPageIndex, setClickedPageIndex] = React.useState(0);
  const [surveyResponse, setSurveyResponse] = React.useState(undefined);
  const [MCUStatus, setMCUStatus] = React.useState(undefined);
  const [isCompleteTrigger, setIsCompleteTrigger] = React.useState(false);
  const [mcuGoalsQuestions, setMcuGoalsQuestions] = React.useState([]);
  const [mcuQuestions, setMcuQuestions] = React.useState({});
  const [counter, setCounter] = React.useState(200);
  const [reportStatusDialog, setReportStatusDialog] = React.useState(
    reportGenerationStatus.none
  );

  const selectedProduct = JSON.parse(localStorage.getItem("selectedProduct"));

  const surveyJson = surveyMap[props.productSurveyId]?.surveyJson;
  const moreInfos = surveyMap[props.productSurveyId]?.moreInfos;
  const surveyId = surveyMap[props.productSurveyId]?.surveyId;
  const product = props.product;
  const viewResponseMode = props.viewResponseMode;
  const editResponseMode = props.editResponseMode;
  const purchaseId = props.purchaseId;
  const selectedQuestionnaire = props.selectedQuestionnaire;
  const selectedFY = useSelector((state) => {
    return state.header.selectedFY;
  });
  const selectedFund = useSelector((state) => state.header.selectedFund);

  const fundID = selectedFund.fundID ? selectedFund.fundID : selectedFund.Code;

  const history = useHistory();

  const loadDataByFundId = async (productId, fundId) => {
    console.trace();
    try {
      if (!fundId) {
        history.replace("/fundsList");
      }
      let result =
        await questionnaireResponseService.getQuestionnaireResponseByFundId(
          fundId
        );
      if (
        result &&
        result.MCUStatus === "done" &&
        productId === result.MCUData.productId
      ) {
        setMCUStatus("done");
      } else if (
        result &&
        result.MCUData &&
        result.MCUStatus !== "done" &&
        productId === result.MCUData.productId
      ) {
        setSurveyResponse(result.MCUData);
      }
    } catch (e) {
      console.log(e);
    }
  };

  React.useEffect(async () => {
    try {
      if (viewResponseMode || editResponseMode) {
        if (selectedQuestionnaire !== null) {
          setSurveyResponse(selectedQuestionnaire);
        }
      }
    } catch (e) {
      console.log(e);
    }
  }, [editResponseMode, viewResponseMode, selectedQuestionnaire]);

  const loadLatestCompletedMCUResponse = async (
    productId,
    productSurveyId,
    sub
  ) => {
    if (productSurveyId !== surveyIds.SMSF001) {
      const result =
        await questionnaireResponseService.getLatestCompleteQRByEmailByProduct(
          productId,
          sub
        );
      if (result) {
        const mcuResponseData = JSON.parse(result?.data);
      }
    }
  };

  React.useEffect(async () => {
    try {
      await loadLatestCompletedMCUResponse(
        surveyMap[surveyIds.SMSF001]?.productId,
        props.productSurveyId,
        user?.sub
      ).catch(console.error);
    } catch (e) {
      console.log(e);
    }
  }, [
    props.productSurveyId,
    surveyMap[surveyIds.SMSF001]?.productId,
    user?.sub,
  ]);

  const handleClose = () => {
    setSurveyResponse(undefined);
    props.closeSurvey();
    dispatch(surveyActions.setIsComplete({ isComplete: false }));
    setCongrats(false);
  };

  const onNextPage = () => {
    setIsCompleteTrigger(window.survey?.isCompleteTrigger);
    if (window.survey?.isCompleteTrigger) {
      dispatch(
        surveyActions.setIsSurveyNotQualify({ isSurveyNotQualify: true })
      );
      onSubmitClick(true);
    } else {
      const hasCurrentPageErrors = window.survey.hasCurrentPageErrors();
      window.survey.nextPage();
      dispatch(
        surveyActions.setIsSurveyNotQualify({ isSurveyNotQualify: false })
      );
      if (!hasCurrentPageErrors) {
        setPrimaryStates();
      }
    }
  };

  const setPrimaryStates = () => {
    setData(window.survey?.data);
    setCurrentPageNo(window.survey?.currentPageNo);
    if (activePages.length < surveyJson?.pages?.length) {
      setActivePages(
        Array(window.survey.currentPageNo + 1)
          .fill()
          .map((_, idx) => 0 + idx)
      );
    }
  };

  const onPrevPage = () => {
    if (!review) {
      window.survey.prevPage();
      setCurrentPageNo(window.survey.currentPageNo);
    }
    setReview(false);
  };

  const onNavigationClick = (pageNo) => {
    window.survey.currentPageNo = pageNo;
    setCurrentPageNo(pageNo);
    setClickedPageIndex(pageNo);
    setReview(false);
  };

  const onComplete = async () => {
    const res = await onPost(true);
    if (res) {
      window.survey?.completeLastPage();
      const item = localStorage.getItem("noOfReports");
      console.log("item>>>>>", item);
      if (item !== "null") {
        setShowLoader(true);
        await loadAllReports();
        setShowLoader(false);
      }
      setCongrats(true);
      setReview(false);
      dispatch(headerActions.handleDisableReportBtn());
      setSurveyResponse(undefined);
    }
  };

  const loadAllReports = async () => {
    const item = localStorage.getItem("noOfReports");
    if (item !== "null") {
      const noOfReports = JSON.parse(item);
      const documentsSub = await documentService.getDocumentByQRId(
        surveyResponse?.id
      );
      let copyObj = documentsSub;
      let targetLength = noOfReports + 2;

      while (copyObj.length < targetLength) {
        const additionalDocuments = await documentService.getDocumentByQRId(
          surveyResponse?.id
        );
        copyObj = [...additionalDocuments];
      }
    }
  };

  const onSave = async () => {
    const res = await onPost(false);
    if (res) {
      handleClose();
      setSurveyResponse(undefined);
      dispatch(headerActions.handleDisableReportBtn());
      history.replace("/");
    }
  };


  const updatePurchase = async (purchaseId, qrId, edits) => {
    const result = await purchaseService.updatePurchaseQRId(
      purchaseId,
      qrId,
      PurchasedStatus.purchased,
      edits
    );
  };
  // Fucntion to create class Super documents
  const generateDocuments = async (surveyResponse, purchaseId) => {
    await documentGenerationService.startReportGeneration(
      surveyResponse.data,
      prefilledData,
      user,
      {
        template: {
          name: product.surveyId.toLowerCase(),
          recipe: "chrome-pdf",
        },
      },
      { product: product, results: surveyResponse, purchaseId: purchaseId }
    );
    await documentGenerationService.startReportGeneration(
      surveyResponse.data,
      prefilledData,
      user,
      {
        template: {
          name: product.surveyId.toLowerCase(),
          recipe: "html",
        },
      },
      { product: product, results: surveyResponse, purchaseId: purchaseId }
    );
  };
  // Fucntion to create class Super documents
  const generateClassSuperDocuments = async (surveyResponse, purchaseId) => {
    try {
      await documentGenerationService.generateClassSuperReport(
        surveyResponse.data,
        prefilledData,
        user,
        {
          template: {
            name: product.surveyId.toLowerCase(),
            recipe: "chrome-pdf",
          },
        },
        { product: product, results: surveyResponse, purchaseId: purchaseId }
      );
    } catch (e1) {
      console.log("pdf error - ", e1);
    }

    try {
      await documentGenerationService.generateClassSuperReport(
        surveyResponse.data,
        prefilledData,
        user,
        {
          template: {
            name: product.surveyId.toLowerCase(),
            recipe: "html",
          },
        },
        { product: product, results: surveyResponse, purchaseId: purchaseId }
      );
    } catch (e2) {
      console.log("html error - ", e2);
    }
  };

  const delay = (ms) => new Promise((res) => setTimeout(res, ms));

  const addPurchase = async (qrId) => {
    const date = new Date();
    await purchaseService.addPurchase({
      createdAt: date,
      updatedAt: date,
      productType: selectedProduct.surveyId,
      productId: selectedProduct.id,
      sub: user?.sub,
      activationDate: date.getTime(),
      questionnaireResponseId: qrId,
      expiryDate: date.setFullYear(date.getFullYear() + 1),
      status: PurchasedStatus.purchased,
    });
  };

  const onPost = async (doComplete, justSavingQuestionnaire = false) => {
    // Check for updated BDBN responses
    setShowLoader(true);
    if (data.bdbn !== "Yes") {
      data.bdbn =
        data?.smsf_member_questions?.filter((memberData) => {
          return memberData.bdbn_questions[0].bdbn === "Yes";
        }).length > 0
          ? "Yes"
          : "No";
    }
    // calculating the diff days for bdbn review
    let surveyData = data;
    if (surveyData.bdbn === "Yes") {
      surveyData?.smsf_member_questions?.forEach((memberData, i) => {
        memberData?.bdbn_questions?.forEach((memberBdbnData, j) => {
          moment().diff(moment(memberBdbnData.bdbn_review_date), "days");
          surveyData.smsf_member_questions[i].bdbn_questions[
            j
          ].bdbn_review_diffDays =
            moment().diff(moment(memberBdbnData.bdbn_review_date), "days") + 1;
        });
      });
    }
    setData(surveyData);
    // storing data in to db and generating report
    let response;
    const maxPageNoVisited = Math.max(currentPageNo, activePages.length - 1);
    try {
      let questionnaireResponseId, docsFromDB, pdf, html;
      let result =
        await questionnaireResponseService.getQuestionnaireResponseByFundId(
          fundID
        );
      const updatedQR = editResponseMode
        ? selectedQuestionnaire
        : result?.MCUData;
      let pendingPurchase = [];
      if (
        (!updatedQR ||
          updatedQR?.status === questionnaireResponseStatus.COMPLETE) &&
        !editResponseMode
      ) {
        questionnaireResponseId = uuidv4();

        result = await questionnaireResponseService.createQuestionnaireResponse(
          {
            id: questionnaireResponseId,
            productId: product.id,
            fundId: fundID,
            surveyId,
            doComplete,
            data: surveyData,
            currentPageNo,
            maxPageNoVisited,
            email: user.email,
            createdBy: user.given_name + " " + user.family_name,
            type: props.productSurveyId,
            isSurveyNotQualify,
            sub: user?.sub,
          }
        );
        response = result?.data?.createQuestionnaireResponse;

        // Update pending purchase with correct QR code
        // Tobediscuss
        pendingPurchase = await purchaseService.getPurchaseByStatus("pending");
        console.log(pendingPurchase ? "Items Found" : "Items Not found");
        if (pendingPurchase?.[0]?.id) {
          await updatePurchase(
            pendingPurchase[0].id,
            response.id,
            pendingPurchase[0].edits
          );
        } else {
          await addPurchase(response.id);
        }
      } else {
        questionnaireResponseId = updatedQR.id;

        result = await questionnaireResponseService.updateQuestionnaireResponse(
          {
            id: questionnaireResponseId,
            surveyId,
            doComplete,
            data: surveyData,
            currentPageNo,
            maxPageNoVisited,
            isSurveyNotQualify,
          }
        );
        response = result?.data?.updateQuestionnaireResponse;
      }

      if (justSavingQuestionnaire) {
        // will skip the codes below if we're just saving
        // otherwise, the form inputs will become disabled and user can't type in
        setShowLoading(false);
        return true;
      }

      setSurveyResponse(response);
      if (doComplete) {
        try {
          setShowLoader(false);
          setShowLoading(true);
          pendingPurchase =
            await purchaseService.getPurchaseByQuestionnaireResponseId(
              response.id
            );

          await updatePurchase(
            pendingPurchase[0].id,
            response.id,
            pendingPurchase[0].edits + 1
          );

          if (user.consentType === "class_super") {
            generateClassSuperDocuments(response, pendingPurchase[0].id);
          } else {
            generateDocuments(response, pendingPurchase[0].id);
          }

          docsFromDB = await waitAndLoading(response); //TBD

          const data = {
            type: "update",
            sub: user.sub,
          };
          const date = moment(new Date());
          if (product.type.toLowerCase() === "report") {
            data.reports = `${product.title} - ${date.format("DD/MMM/YYYY")}`;
            await hubSpotService.pushData(data);
          } else if (product.type.toLowerCase() === "soa") {
            data.soa = `${product.title} - ${date.format("DD/MMM/YYYY")}`;
            await hubSpotService.pushData(data);
          }
          setShowLoading(false);
        } catch (e) {
          console.log(e);
          setShowLoading(false);
        }
      }
      setShowLoading(false);
      setShowLoader(false);
      return true;
    } catch (e) {
      console.log(e);
      return false;
    }
  };

  React.useEffect(() => {
    if (isExtraReportUsed) {
      //Upon opening the survey, it should be saved immediately, otherwise a credit will already be used
      //but there's still no questionnaire saved in the database.
      async function triggerOnPost() {
        await onPost(false, true);
      }

      triggerOnPost();
    }
  }, [isExtraReportUsed]);

  const waitAndLoading = async (response) => {
    let pdf, html;
    const docsBySub = await documentService.getDocumentByQRId(response?.id);
    if (counter > 0) {
      if (docsBySub.length > 0) {
        pdf = docsBySub.find((item) =>
          item?.documentLocation?.endsWith(".pdf")
        );
        html = docsBySub.find((item) =>
          item?.documentLocation?.endsWith(".html")
        );
        if (pdf) {
          setReportStatusDialog(reportGenerationStatus.hasPdf);
        }
        if (html) {
          setReportStatusDialog(reportGenerationStatus.hasHtml);
        }
        if (!pdf || !html) {
          setCounter(counter - 1);
          await delay(3000);
          await waitAndLoading(response);
        } else {
          setReportStatusDialog(reportGenerationStatus.hasBoth);
          return { pdf, html };
        }
      } else {
        setCounter(counter - 1);
        await delay(3000);
        await waitAndLoading(response);
      }
    }
    return { pdf, html };
  };

  const onSubmitClick = (doComplete) => {
    if (doComplete) {
      dispatch(surveyActions.setIsComplete({ isComplete: true }));
    } else {
      dispatch(surveyActions.setIsComplete({ isComplete: false }));
    }
    dispatch(surveyActions.handleOpenConfirmationModal());
    setPrimaryStates();
  };

  const onReview = () => {
    if (!window.survey?.hasCurrentPageErrors()) {
      setData(window.survey?.data);
      setReview(true);
    }
  };

  const onCloseModal = () => {
    dispatch(surveyActions.handleCloseConfirmationModal());
    dispatch(surveyActions.setIsComplete({ isComplete: false }));
    setPrimaryStates();
  };

  React.useEffect(() => {
    try {
      if (window.survey) {
        window.survey.data = data;
        window.survey.currentPageNo = currentPageNo;
      }
    } catch (e) {
      // console.log(e)
    }
  }, [activePages, currentPageNo]);

  React.useEffect(() => {
    try {
      if (surveyResponse) {
        const pageNo =
          viewResponseMode || editResponseMode
            ? 0
            : surveyResponse.currentPageNo;
        dispatch(headerActions.handleEnableReportBtn());
        setData(JSON.parse(surveyResponse.data));
        setprefilledData(JSON.parse(surveyResponse.data));
        setClickedPageIndex(pageNo);
        setCurrentPageNo(pageNo);
        setActivePages(
          Array(surveyResponse.maxPageNoVisited + 1)
            .fill()
            .map((_, idx) => 0 + idx)
        );
        if (editResponseMode) {
          onNavigationClick(pageNo);
        }
      } else {
        loadDataByFundId(product.id, fundID);
        dispatch(headerActions.handleEnableReportBtn());
      }
    } catch (e) {
      console.log(e);
    }
  }, [viewResponseMode, surveyResponse]);

  return (
    <div>
      <Dialog
        fullScreen
        open={props.open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <Grid container>
          <Grid item xs={12}>
            <AppBar>
              <Toolbar className="headerMCU">
                <Grid container>
                  <Grid item style={{ width: 200, alignSelf: "center" }}>
                    <Box>
                      <a href="/">
                        <img
                          src={accountantsGPSLogo}
                          style={{
                            height: "48px",
                            width: "175px",
                            marginTop: 5,
                            justifyContent: "flex-start",
                            alignItems: "center",
                          }}
                        />
                      </a>
                    </Box>
                  </Grid>
                  <Typography className={"moneyCheckUp-label"}>
                    {surveyJson?.title}
                  </Typography>
                  {!viewResponseMode && (
                    <Grid item>
                      <X
                        className={"crossIcon"}
                        onClick={
                          congrats ? handleClose : () => onSubmitClick(false) //TBD
                        }
                      />
                    </Grid>
                  )}
                  {viewResponseMode && (
                    <Grid item>
                      <X className={"crossIcon"} onClick={handleClose} />
                    </Grid>
                  )}
                </Grid>
              </Toolbar>
            </AppBar>
          </Grid>
          {!congrats && (
            <Grid container>
              <Grid
                item
                style={{
                  width: 267,
                  overflow: "hidden",
                  marginTop: "84px",
                  borderRight: "1px solid #D1D4D9",
                  height: "calc(100vh - 168px)",
                }}
              >
                <Typography sx={{ mt: 3 }}></Typography>
                {(surveyJson?.pages || []).map((page, index) => (
                  <ListItem
                    key={page.name}
                    button
                    disableRipple
                    disabled={!activePages.includes(index)}
                    onClick={() => onNavigationClick(index)}
                  >
                    <ChevronRight style={{ marginRight: "8px" }} />
                    <ListItemText
                      primary={page.name}
                      classes={{
                        primary: classes.text14,
                      }}
                    />
                  </ListItem>
                ))}
              </Grid>
              {!review && (
                <Grid
                  item
                  style={{
                    marginTop: "84px",
                    height: "calc(100vh - 168px)",
                    width: "calc(100% - 267px)",
                    overflow: "auto",
                    background: "#FAFAFA",
                  }}
                >
                  {openSurveyDescription && (
                    <SurveyDescription
                      handleClose={() => setOpenSurveyDescription(false)}
                      description={surveyJson?.description}
                    />
                  )}

                  <SurveyComponent
                    productSurveyId={props.productSurveyId}
                    productId={product.id}
                    clickedPageIndex={clickedPageIndex}
                    data={data}
                    disabled={true}
                    isCompleteTrigger={isCompleteTrigger}
                    mcuGoalsQuestions={mcuGoalsQuestions}
                    mcuQuestions={mcuQuestions}
                    selectedFY={selectedFY}
                  />
                  {moreInfos && moreInfos[currentPageNo] && (
                    <SurveyCard
                      top={openSurveyDescription ? 208 : 168}
                      data={moreInfos[currentPageNo]}
                    />
                  )}
                </Grid>
              )}
              {review && (
                <Grid
                  item
                  style={{
                    marginTop: "84px",
                    height: "calc(100vh - 168px)",
                    width: "calc(100% - 267px)",
                    overflow: "auto",
                    background: "#FAFAFA",
                  }}
                >
                  <Review
                    surveyJson={surveyJson}
                    onNavigationClick={onNavigationClick}
                  />
                </Grid>
              )}
              <Grid
                item
                xs={12}
                style={{ height: "84px", borderTop: "1px solid #D1D4D9" }}
              >
                <Grid container>
                  <Grid item xs={6}>
                    <BackButton
                      disabled={currentPageNo === 0 || review}
                      variant="link"
                      style={{
                        marginTop: "20px",
                        position: "absolute",
                        left: "16px",
                      }}
                      onClick={onPrevPage}
                    >
                      <ChevronLeft style={{ verticalAlign: "middle" }} />
                      <span
                        style={{ verticalAlign: "middle" }}
                        className={classes.back}
                      >
                        BACK
                      </span>
                    </BackButton>
                  </Grid>
                  <Grid item xs={6} style={{ textAlign: "right" }}>
                    <Box style={{ marginRight: 32 }}>
                      {!viewResponseMode && !editResponseMode && (
                        <SaveButton
                          variant="contained"
                          style={{ marginTop: 20, marginRight: 8 }}
                          onClick={() => onSubmitClick(false)}
                        >
                          Save and Continue Later
                        </SaveButton>
                      )}
                      {!window.survey?.isLastPage && (
                        <NextButton
                          variant="contained"
                          style={{ marginTop: "20px" }}
                          onClick={onNextPage}
                        >
                          Next
                          <ChevronRight
                            style={{
                              verticalAlign: "middle",
                              marginLeft: 8,
                              marginRight: -8,
                            }}
                          />
                        </NextButton>
                      )}
                      {window.survey?.isLastPage && viewResponseMode && (
                        <SaveButton
                          variant="contained"
                          style={{ marginTop: 20, marginRight: 8 }}
                          onClick={() => handleClose()}
                        >
                          Close
                        </SaveButton>
                      )}
                      {window.survey?.isLastPage &&
                        !review &&
                        !viewResponseMode && (
                          <NextButton
                            variant="contained"
                            style={{ marginTop: "20px" }}
                            onClick={onReview}
                          >
                            Review
                            <ChevronRight
                              style={{
                                verticalAlign: "middle",
                                marginLeft: 8,
                                marginRight: -8,
                              }}
                            />
                          </NextButton>
                        )}
                      {review && (
                        <NextButton
                          variant="contained"
                          style={{ marginTop: "20px" }}
                          onClick={() => onSubmitClick(true)}
                        >
                          Submit
                          <ChevronRight
                            style={{
                              verticalAlign: "middle",
                              marginLeft: 8,
                              marginRight: -8,
                            }}
                          />
                        </NextButton>
                      )}
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}
          {congrats && (
            <Grid
              item
              xs={12}
              style={{ marginTop: "84px", height: "calc(100vh - 84px)" }}
            >
              <Typography style={{ marginBottom: 56 }} />
              <Congratulations
                onClose={handleClose}
                title={surveyJson?.title}
                MCUStatus={MCUStatus}
                product={product}
                productId={product.id}
                surveyId={surveyId}
                results={surveyResponse}
                reportStatus={reportStatusDialog}
              />
            </Grid>
          )}
        </Grid>
        {!congrats && openConfirmationModal && (
          <ConfirmationModal
            onSave={isComplete ? onComplete : onSave}
            onClose={onCloseModal}
            yesButtonTitle={
              isComplete
                ? "Submit"
                : "Yes! Please save and I will continue later"
            }
            noButtonTitle={
              isComplete
                ? "Cancel"
                : "No, I will continue to finish the survey now"
            }
            question={
              isComplete
                ? ``
                : `Are you sure you want to save your ${surveyJson?.title} and continue later? If yes, we will save it for you and you can always continue your ${surveyJson?.title} later. `
            }
            actionInfo={
              isComplete && !editResponseMode
                ? `Please ensure you are happy with all of the responses in the survey before you submit.`
                : isComplete && editResponseMode
                ? "Please confirm you're ready to submit this amended version, your remaining edits count will be decreased by one. When the count reaches 0, you will not be able to amend this report anymore and will need to purchase a new report if you need further amendments to be made."
                : 0
            }
            header={isComplete ? `Confirm submission` : "Are you sure?"}
            isComplete={isComplete}
          />
        )}
        {showLoading && <Loading />}
        {showLoader && <Loader />}
      </Dialog>
      <BGLHealthCheckModal />
      <ClassSuperHealthCheckModal />
    </div>
  );
}
