import {API } from 'aws-amplify';
import { getProductByType, listProducts, getProduct, getProductByPlanId } from '../graphql/queries';

const productService = {
  getProductByType: async (type) => {
    const response = await API.graphql({
      query: getProductByType,
      authMode: 'API_KEY',
      variables: { type }
    });
    return response;
  },
  listProducts: async (action) => {
    const response = await API.graphql({
      query: listProducts,
      authMode: 'API_KEY'
    });
    if (response.data?.listProducts?.items?.length > 0) {
      return response.data?.listProducts?.items;
    }
    return [];
  },
  getProductById: async (productId) => {
    const response = await API.graphql({
      query: getProduct,
      authMode: 'API_KEY',
      variables: { id: productId }
    });
    return response?.data?.getProduct || undefined;
  },
  getProductByPlanId: async (planId) => {
    const response = await API.graphql({
      query: getProductByPlanId,
      authMode: 'API_KEY',
      variables: { planId: planId }
    });
    return response?.data?.getProductByPlanId?.items[0] || undefined;
  },
  // getDefaultAppointment: async () => {
  //   const response = await API.graphql({
  //     query: getProductAppointment,
  //     authMode: 'API_KEY',
  //     variables: { id: defaultAppointmentID }
  //   });
  //   return response?.data?.getProductAppointment || undefined;
  // },
}

export default productService;