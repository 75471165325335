import * as React from "react";
import { Button, Typography } from "@mui/material";

const TertiaryBtn = (props) => {
  return (
    <Button
      sx={{ borderRadius: 0, color: "#ffffff" }}
      className={props.className}
      onClick={props.onClick}
    >
      <Typography variant="small">{props.value}</Typography>
    </Button>
  );
};

const SecondaryBtn = (props) => {
  return (
    <Button
      sx={{
        border: "1px solid #1A2842",
        borderRadius: "5px",
        color: "#1A2842",
        background: "#ffffff",
        "&:hover": { background: "#ffffff", backgroundColor: "#ffffff" },
        boxSizing: "border-box",
        padding: "10px 20px",
      }}
      onClick={props.onClick}
      className={props.className}
    >
      <Typography variant="small">{props.value}</Typography>
    </Button>
  );
};

const TertiaryBtnDark = (props) => {
  return (
    <Button
      sx={{
        background: "#1A2842",
        color: "#ffffff",
        padding: "10px 20px",
        "&:hover": {
          background: "#ffffff",
          backgroundColor: "#1A2842",
        },
      }}
      disabled={props.disabled}
      className={props.className}
      onClick={props.onClick}
      type={props.type}
    >
      <Typography variant="small">{props.value}</Typography>
    </Button>
  );
};

const TertiaryBtnDark20 = (props) => {
  return (
    <Button
      sx={{
        background: "#1A2842",
        color: "#ffffff",
        padding: "11px 20px",
        "&:hover": {
          background: "#ffffff",
          backgroundColor: "#1A2842",
        },
      }}
      className={props.className}
      onClick={props.onClick}
      type={props.type}
    >
      <Typography variant="small">{props.value}</Typography>
    </Button>
  );
};

const BtnWithIcon = (props) => {
  return (
    <Button
      sx={{ borderRadius: 0, color: "#ffffff", padding: "0px" }}
      endIcon={props.icon}
    >
      <Typography variant="small">{props.value}</Typography>
    </Button>
  );
};

export {
  TertiaryBtn,
  SecondaryBtn,
  TertiaryBtnDark,
  BtnWithIcon,
  TertiaryBtnDark20,
};
