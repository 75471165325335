import Grid from '@mui/material/Grid';
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Lock, X } from 'react-feather';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
    text14: {
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '24px',
    },
    text18: {
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: '18px',
      lineHeight: '24px',
    },
    crossIcon: {
      cursor: 'pointer',
    },
  }));

export default function NotificationBase({title, description, handleCloseNotification}) {
  const classes = useStyles();

  return (
    <Box sx={{ width:'100%', backgroundColor: '#ECEFF1', mb: 8}}>
      <Grid container>
        <Grid item  style={{ width: '72px', marginTop: '24px', textAlign: 'center'}}>
          <Typography>
            <Lock/>
          </Typography>
        </Grid>
        <Grid style={{width: 'calc(100% - 112px)', marginTop: '24px', marginBottom: '24px'}}>
          <Typography className={classes.text18}>
            {title}
          </Typography>
          <Typography className={classes.text14} >
            {description}
          </Typography>
        </Grid>
        <Grid item style={{width: '40px', textAlign: 'right', paddingRight: '8px', paddingTop: '8px'}}>
          <X className={classes.crossIcon} onClick={handleCloseNotification} />
        </Grid>
      </Grid>
    </Box>
  );
}