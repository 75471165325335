import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import { makeStyles } from '@mui/styles';
import { styled } from '@mui/material/styles';
import { InputBase, Typography, Grid } from '@mui/material';
import Link from '@mui/material/Link';
import { useForm } from 'react-hook-form';
import { Auth } from "aws-amplify";
import { X } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import { headerActions } from "../../store/header";

const ForgotPasswordButton = styled(Button)({
  boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
  textTransform: 'none',
  fontSize: '14px',
  padding: '10px 20px',
  border: 'none',
  fontWeight: 400,
  lineHeight: '24px',
  backgroundColor: '#1A2842',
  color: 'white',
  fontFamily: [
    'Poppins'
  ].join(','),
  '&:hover': {
    backgroundColor: '#1A2842',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
  },
  '&:active': {
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    backgroundColor: '#1A2842',
  },
  '&:focus': {
    backgroundColor: '#1A2842',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
  },
  '&:disabled': {
    color: 'white',
    backgroundColor: '#8C94A0;',
  },
});

const useStyles = makeStyles(theme => ({
  forgotPasswordBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: '32px',
    position: 'absolute',
    width: '577px',
    height: '311px',
    backgroundColor: '#FFFFFF',
    boxShadow: '4px 4px 6px rgba(0, 0, 0, 0.25)',
    borderRadius: '5px',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
  },
  forgotPasswordLbl: {
    fontFamily: 'Poppins',
    fontWeight: 600,
    fontSize: '24px',
    lineHeight: '32px',
    color: '#000000',
    marginBlock: 0,
  },
  textFieldLabel: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '24px',
    color: '#000000',
    marginBlock: 0,
  },
  red: {
    color: 'red',
  },
  textField: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    background: '#ECEFF1',
    alignSelf: 'stretch',
    marginBottom: 40,
    height: '44px',
    paddingLeft: 8,
    paddingRight: 8,
  },
  text14: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '24px',
  },
  crossIcon: {
    position: 'absolute',
    right: '24px',
    cursor: 'pointer',
  }
}));

export default function ForgotPassword() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const open = useSelector(state => state.header.openForgotPassword);
  const { register, handleSubmit, formState: { errors }, setError, reset } = useForm();
  const onSubmit = async (data) => {
    if (!errors.email) {
      dispatch(headerActions.handleCloseForgotPassword());
      dispatch(headerActions.handleCreateNewPassword({ email: data.email }));
    }
  };


  const onClickCreateAccount = () => {
    dispatch(headerActions.handleCloseForgotPassword());
    dispatch(headerActions.handleCreateAccount());
  }

  const onClose = () => {
    dispatch(headerActions.handleCloseForgotPassword());
  }

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box className={classes.forgotPasswordBox}>
          <Typography className={classes.forgotPasswordLbl} >
            Forgot Password
          </Typography>
          <Typography className={classes.text14} style={{ marginBlock: 0, marginBottom: 32 }}>
            Enter the email address associated with your account.
          </Typography>
          {errors.email && <Typography className={classes.textFieldLabel} >
            <span className={classes.red}>Email Address *</span>
          </Typography>}
          {!errors.email && <Typography className={classes.textFieldLabel} >
            Email Address <span className={classes.red}>*</span>
          </Typography>}
          <InputBase
            className={`${classes.textField} ${classes.text14}`}
            {...register("email", {
              required: true,
              pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            })}
          />
          <Grid container>
            <Grid item xs={6}>
              <Typography className={classes.text14} style={{ marginBlock: 0 }}>
                Not a member yet?
              </Typography>
              <Link underline="always" color="inherit" className={classes.text14} style={{ cursor: 'pointer', color: 'black' }} onClick={onClickCreateAccount}>
                {'Create Account'}
              </Link>
            </Grid>
            <Grid item xs={6}>
              <Link onClick={onClose} underline="always" color="inherit" className={classes.text14} style={{ cursor: 'pointer', textUnderlineOffset: '8pt', color: 'black', marginLeft: 11 }}>
                {'Cancel'}
              </Link>
              <ForgotPasswordButton variant="contained" style={{ marginLeft: 28 }} type="submit">Change Password</ForgotPasswordButton>
            </Grid>
          </Grid>
          <X className={classes.crossIcon} onClick={onClose} />
        </Box>
      </form>
    </Modal>
  );
}
