import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { X } from 'react-feather';
import { makeStyles } from '@mui/styles';


const useStyles = makeStyles(theme => ({
  crossIcon: {
    cursor: 'pointer',
  },
}));


export default function SurveyDescription({ description, handleClose }) {
  const classes = useStyles();
  return (
    <div>
      <Box sx={{ backgroundColor: '#ECEFF1' }} style={{ margin: '24px 24px 0px 24px' }} >
        <Grid container>
          <Grid style={{ width: 'calc(100% - 72px)', margin: '16px' }}>
            <Typography variant="small" >
              {description}
            </Typography>
          </Grid>
          <Grid item style={{ width: '40px', textAlign: 'right', paddingRight: '16px', paddingTop: '16px' }}>
            <X className={classes.crossIcon} onClick={handleClose} />
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}