import * as React from 'react';
import { Box, Typography, Grid, Toolbar, Link } from '@mui/material';
import Footer from "../Footer";
import './index.css'

export default function AboutUs() {
  return (
    <>
      <Box sx={{width:'100%', p:5, pb: 0, pt:13}} >
        <Typography className='text36'>About Us</Typography>
        <Typography className='text28' style={{ marginTop: 64 }}>Our Team</Typography>
        <Grid container >
          <Grid item sx={{ width: 486, mt: 2 }}>
            <img src='images/OurTeam.png' style={{ height: 253, width: 486 }} alt="ourteam"/>
          </Grid>
          <Grid item sx={{width: 466, ml: 4, mt: 2}} >
            <Typography className='text14'>We are a team of Aussies that thought financial advice should be more straightforward, you know, like using GPS on your phone.</Typography>
            <Typography className='text14' style={{ marginTop: 24 }}>We have one of the most experienced management teams in place, who have a track record of transforming the delivery of financial advice.</Typography>
            <Typography className='text14' style={{ marginTop: 24 }}>Our advice is delivered with scale and in a cost-effective way, for the majority of Australians by aligning the interests of stakeholders and customers.</Typography>
          </Grid>
        </Grid>
        <Typography className='text28' style={{ marginTop: 64 }}>Our Story</Typography>
        <Grid container >
          <Grid item sx={{ width: 550, mt: 2 }} >
            <Typography className='text14'>Founders George Haramis and Drew Fenton have been in the financial services space for longer than they care to remember. They have seen it all.</Typography>
            <Typography className='text14' style={{ marginTop: 24 }}>But what they hadn't seen was financial advice delivered in an affordable way for people who needed it most, everyday Australians.</Typography>
            <Typography className='text14' style={{ marginTop: 24 }}>So they looked at all the brilliant parts of the financial services world and simplified it. They also did an extensive amount of research into what people wanted. Money is a complicated topic. Especially for those who don't understand it or are scared they will make the wrong decisions.</Typography>
            <Typography className='text14' style={{ marginTop: 24 }}>So George and Drew put together a team of tech heads and designers to create something that people felt comfortable and confident using.</Typography>
            <Typography className='text14' style={{ marginTop: 24 }}>After a few long years, a bunch of clever thinking ( and hair-pulling), testing and creating and love, they are proud to say that Australians can have affordable, accessible financial advice at their fingertips — the only compliant digital or online finance 'one-stop-shop' in the country that offers advice that the client controls - and not expensive financial planners.</Typography>
          </Grid>
          <Grid item sx={{width: 486, ml: 4, mt: 2}} >
            <img src='images/OurStory.png' style={{ height: 407, width: 486 }} alt="ourstory"/>
          </Grid>
        </Grid>
        <Typography className='text28' style={{ marginTop: 64 }}>Our People</Typography>
        <Grid container >
          <Grid item sx={{width: 205, mt: 5}} >
            <img src='images/GeorgeHaramis.png' style={{ height: 205, width: 205 }} alt="george"/>
          </Grid>
          <Grid item sx={{width: 831, ml: 4, mt: 5}} >
            <Typography className='text24'>George Haramis</Typography>
            <Typography className='text14'><i>Fiduciary Co-Founder & CEO</i></Typography>
            <Typography className='text14'>George has over 30 years financial services experience in senior executive roles managing some of the country's largest and most successful financial advisory business, including; Perpetual Private Clients, Head of Advice & Investments at ANZ Private Bank, Managing Director of RetireInvest – Australia's only Franchised Advice business, Principal of Mercer Global Consulting and managed Sealcorp's advice business Securitor, as well as establishing the Asgard Corporate division.</Typography>
            <Typography className='text14' style={{ marginTop: 24 }}>BEco (Monash) Dip Fn Serv.</Typography>
            <Typography className='text14'>
              <Link 
                className='text14'
                href="https://www.linkedin.com/in/georgeharamis/" 
                style={{textDecoration: 'underline'}}
                rel="noopener noreferrer"
                target="_blank"
              >
                <b>View Linkedin Profile</b>
              </Link>
            </Typography>
          </Grid>
        </Grid>
        <Grid container >
          <Grid item sx={{width: 205, mt: 5}} >
            <img src='images/DrewFenton.png' style={{ height: 205, width: 205 }} alt="drew"/>
          </Grid>
          <Grid item sx={{width: 831, ml: 4, mt: 5}} >
            <Typography className='text24'>Drew Fenton</Typography>
            <Typography className='text14'><i>Fiduciary Co-Founder</i></Typography>
            <Typography className='text14'>Drew has over 35 years financial services experience. After working for global insurance companies. He started Fenton Green Insurance Broker who provide services to the Australian financial services sector. He is founding director of Myworkpapers an International software company who services the accounting sector.</Typography>
            <Typography className='text14' style={{ marginTop: 24 }}>BBS (Deakin) CPA FANZIIF FNIBA CIP CPIB</Typography>
            <Typography className='text14'>
              <Link 
                className='text14'
                href="https://www.linkedin.com/in/drew-fenton-30b966a/" 
                style={{textDecoration: 'underline'}}
                rel="noopener noreferrer"
                target="_blank"
              >
                <b>View Linkedin Profile</b>
              </Link>
            </Typography>
          </Grid>
        </Grid>
        <Grid container >
          <Grid item sx={{width: 205, mt: 5}} >
            <img src='images/AaronWilliamson.png' style={{ height: 205, width: 205 }} alt="aaron"/>
          </Grid>
          <Grid item xs={9} sx={{width: 831, ml: 4, mt: 5}}>
            <Typography className='text24'>Aaron Williamson</Typography>
            <Typography className='text14'><i>Head of Digital</i></Typography>
            <Typography className='text14' style={{ width: 831 }}>While Aaron can't boast about 30 years' experience, he certainly has targeted experience and youth on his side. Known as the “whizz kid” by the rest of the team, he has combined his passion for all things tech with his background in law and financial advice.</Typography>
            <Typography className='text14' style={{ width: 831, marginTop: 24 }}>Aaron's vision for moneyGPS is to make financial advice accessible to anyone, anytime, anywhere by rethinking the industry's relationship with technology.</Typography>
          </Grid>
        </Grid>
        <Grid container >
          <Grid item sx={{width: 205, mt: 5}} >
            <img src='images/GraceRando.png' style={{ height: 205, width: 205 }} alt="grace"/>
          </Grid>
          <Grid item xs={9} sx={{width: 831, ml: 4, mt: 5}}>
            <Typography className='text24'>Grace Rando</Typography>
            <Typography className='text14'><i>moneyGPS Coach</i></Typography>
            <Typography className='text14' style={{ width: 831 }}>Grace has over 20 years' experience and knowledge within the financial services sector. During that time, Grace has worked as a Financial Adviser, Financial Planning Trainer as well as a Client Service Manager. Grace has assisted clients achieve their financial goals while ensuring the level of care provided to each client was achieved with excellence.</Typography>
            <Typography className='text14' style={{ width: 831, marginTop: 24 }}>As well as managing the moneyGPS Coaching Team, Grace also works with clients to deliver a clear understanding of advice related documents, implements the recommended advice and explains the outcome of our reports.</Typography>
          </Grid>
        </Grid>
        <Grid container >
          <Grid item sx={{width: 205, mt: 5}} >
            <img src='images/OliviaFenton.png' style={{ height: 205, width: 205 }} alt="olivia"/>
          </Grid>
          <Grid item xs={9} sx={{width: 831, ml: 4, mt: 5}}>
            <Typography className='text24'>Olivia Fenton</Typography>
            <Typography className='text14'><i>Social Media</i></Typography>
            <Typography className='text14' style={{ width: 831 }}>Oliva is passionate about all things marketing and social media. We are so lucky to have such a curious mind on the team. Her experience in financial services, accounting, and insurance industries help us bridge the gap between social media and traditional financial services marketing. She recently completed her Bachelors of Marketing at Monash University in 2020 and is excited to continue her career in Social Media Marketing.</Typography>
            <Typography className='text14' style={{ width: 831, marginTop: 24 }}>BMktg BBus(HR) (Monash University)</Typography>
          </Grid>
        </Grid>
        
        <Toolbar />
        <Footer />
      </Box>
    </>
  );
}
