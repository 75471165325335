import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { makeStyles } from "@mui/styles";
import {Typography, Grid, useMediaQuery} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import CustomizedSteppers from '../stepper/index';
import { X } from "react-feather";
import { headerActions } from "../../store/header";

import BGLHealthCheckModal from "../bglHealthCheckModal"
import StripePricingTable from "../stripePricingTable";

const useStyles = makeStyles((theme) => ({
    firmDetailsBox: {
        minWidth: "1000px",
        padding: "32px",
        position: "absolute",
        backgroundColor: "#FFFFFF",
        boxShadow: "4px 4px 6px rgba(0, 0, 0, 0.25)",
        borderRadius: "5px",
        left: "50%",
        top: "50%",
        transform: "translate(-50%, -50%)"
    },
    firmDetailsLbl: {
        fontFamily: "Poppins",
        fontWeight: 600,
        fontSize: "24px",
        lineHeight: "32px",
        color: "#000000",
        marginBlock: 0,
        marginBottom: 32,
    },
    marginBtm: {
        marginTop: "0px",
        marginBottom: "32px",
    },
}));

export default function SelectSubscriptionModal() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const open = useSelector((state) => state.header.openSubscriptionPlan);
    const canClose = useSelector((state) => state.header.canCloseSubscriptionPlanModal);


    // Can close when "true" boolean is passed:
    const getCanClose = () => canClose === true;

    const onClose = () => dispatch(headerActions.handleCloseSubscriptionPlan());

    const showModalScrollbar = useMediaQuery(`@media only screen and (max-height:1000px)`)

    return (
        <>
            <Modal
                open={open}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <form>
                    <Box className={classes.firmDetailsBox}  style={showModalScrollbar ? {height: 700, overflowY: 'auto'} : {}}>
                        {getCanClose() && <Box style={{ textAlign: 'right' }}>
                            <X style={{ cursor: "pointer", }} onClick={onClose} />
                        </Box>}
                        <Typography className={classes.firmDetailsLbl}>
                            Create your Account
                        </Typography>

                        <Grid className={classes.marginBtm}>
                            <CustomizedSteppers step={3}></CustomizedSteppers>
                        </Grid>
                        <Box>
                            <Grid>
                                <Typography variant="smaller" className='profileHeading'>
                                    Subscription
                                </Typography>
                            </Grid>
                        </Box>
                        <Grid container mt={2} mb={4} justifyContent="center">
                            <Grid item>
                                <StripePricingTable />
                            </Grid>
                        </Grid>
                    </Box>
                </form>
            </Modal>
            <BGLHealthCheckModal />
        </>
    );
}
