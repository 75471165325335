import {createSlice} from "@reduxjs/toolkit";

const survey = createSlice({
    name: "survey",
    initialState: {
        isComplete: false,
        openConfirmationModal: false,
        isSurveyNotQualify: false,
        surveyMap: {},
        fundList: [],
        optionMap: {},
        libraryPurchaseList: [],
        otherPurchaseList: [],
        openSurveyForm: false,
        isExtraReportUsed: false,
        data: {}
    },
    reducers: {
        handleOpenConfirmationModal(state, actions) {
            state.openConfirmationModal = true;
        },
        handleCloseConfirmationModal(state, actions) {
            state.openConfirmationModal = false;
        },
        handleOpenSurveyForm(state, actions) {
            state.openSurveyForm = true;
        },
        handleCloseSurveyForm(state, actions) {
            state.openSurveyForm = false;
        },
        handleLoadSurveyData(state, actions) {
            state.data = actions.payload;
        },
        setIsComplete(state, actions) {
            state.isComplete = actions.payload.isComplete;
        },
        setIsSurveyNotQualify(state, actions) {
            state.isSurveyNotQualify = actions.payload.isSurveyNotQualify;
        },
        setSurveyMap(state, actions) {
            state.surveyMap = {
                ...state.surveyMap,
                ...JSON.parse(actions.payload.surveyMap),
            };
        },
        setFundList(state, actions) {
            state.fundList = JSON.parse(actions.payload.fundList);
        },
        setOptionMap(state, actions) {
            state.optionMap = JSON.parse(actions.payload.optionMap);
        },
        setLibraryPurchaseList(state, actions) {
            state.libraryPurchaseList = actions.payload.libraryPurchaseList;
        },
        setOtherPurchaseList(state, actions) {
            state.otherPurchaseList = actions.payload.otherPurchaseList;
        },
        setIsExtraReportUsed(state, actions) {
            state.isExtraReportUsed = actions.payload;
        },
    },
});

export const surveyActions = survey.actions;
export default survey;
