import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { useSelector, useDispatch } from "react-redux";
import {
  Drawer,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Link,
} from "@mui/material";
import Toolbar from "@mui/material/Toolbar";
import productService from "../../services/product";
import { actions, productTypes } from "../../services/constants";
import {
  User,
  Home,
  FileText,
} from "react-feather";
import documentService from "../../services/document";
import { headerActions } from "../../store/header";
import { notificationActions } from "../../store/notification";
import { Storage } from 'aws-amplify';

import "./index.css"
// const drawerWidth = 272;

// const useStyles = makeStyles((theme) => ({
//   drawer: {
//     marginTop: "72px",
//     width: drawerWidth,
//     flexShrink: 0,
//     position: "relative",
//   },
//   drawerPaper: {
//     width: drawerWidth,
//     //height: 'calc(100vh - 72px)',
//     position: "fixed",
//     backgroundColor: "#ECEFF1",
//     border: "none",
//   },
//   listItem: {
//     width: 240,
//     height: 40,
//     marginLeft: 16,
//     marginTop: 8,
//     marginBottom: 12,
//     padding: 0,
//   },
//   listItemModifier: {
//     marginBottom: 0,
//   },
//   listItemSelected: {
//     backgroundColor: "rgba(0, 0, 0, 0.05) !important",
//   },
//   listItemIcon: {
//     padding: 8,
//     minWidth: 0,
//   },
//   itemBetween: {
//     height: 20,
//   },
//   text14: {
//     fontFamily: "Poppins",
//     fontWeight: 400,
//     fontSize: 14,
//     lineHeight: "24px",
//   },
//   text11: {
//     fontFamily: "Poppins",
//     fontWeight: 400,
//     fontSize: 11,
//     lineHeight: "16px",
//   },
//   link: {
//     marginLeft: 16,
//     display: "flex",
//     color: "black",
//     marginBottom: 8,
//     verticalAlign: "bottom",
//   },
//   spaceToBottom: {
//     height: "calc(100vh - 756px)",
//   },
//   text11Mofifier: {
//     fontFamily: "Poppins",
//     fontWeight: 400,
//     fontSize: 11,
//     letterSpacing: "0.25em",
//     textTransform: "uppercase",
//     lineHeight: "16px",
//   },
// }));

export default function Sidebar(props) {
  const history = useHistory();
  const dispatch = useDispatch();
  const isUserLoggedIn = useSelector((state) => state.auth.loggedIn);
  const user = useSelector((state) => state.auth.user);
  const [activeItem, setActiveItem] = useState("");
  const [fileUrl, setFileUrl] = useState("");
  const [hasReports, setHasReport] = useState(false);
  const [hasNewDocuments, setHasNewDocuments] = useState(false);
  const [hasNewPurchases, setHasNewPurchases] = useState(false);

  const listProducts = async () => {
    try {
      const response = await productService.listProducts(actions.SHOW);
      setHasReport(
        response.some((product) =>
          [productTypes.MCU, productTypes.REPORT].includes(product.type)
        )
      );
    } catch (e) {
      //console.log(e);
    }
  };

  useEffect(() => {
    listProducts().catch(console.error);
  }, []);

  // useEffect(() => {
  //   getNewDocsAndPurs().catch(console.error);
  // }, []);

  // const getNewDocsAndPurs = async () => {
  //   try {
  //     const documents = await documentService.getDocument();
  //     if (documents)
  //       setHasNewDocuments(
  //         documents.filter((doc) => doc.isNew === true).length > 0
  //       );
  //   } catch (e) {
  //     //console.log(e);
  //   }
  // };

  useEffect(() => {
    Storage.get("documents/Fiduciary-accountantsGPS_Privacy-Policy-Statement_April-2023.pdf")
      .then((data) => setFileUrl(data))
      .catch((err) => console.log('error fetching file'));
    if (hasNewDocuments || hasNewPurchases)
      dispatch(notificationActions.setShowLibraryDot({ showLibraryDot: true }));
  }, [hasNewDocuments, hasNewPurchases]);

  const selectListItem = async (listItemName) => {
    setActiveItem(listItemName);
    dispatch(headerActions.setSelectedMenu(listItemName));
  };

  const togglePanel = (panel, state) => {
    dispatch(
      headerActions.handlePanelState({
        profileMenu: false,
        shopping: false,
        [panel]: state,
      })
    );
  };

  const handleSelect = (item) => {
    switch (item) {
      case "Profile":
        togglePanel("profileMenu", true);
        break;
      case "Library":
        history.push("/library");
        break;
      default:
    }
  };

  const scrollWithOffset = (el) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -80;
    window.scrollTo({ top: yCoordinate + yOffset, behavior: "smooth" });
  };

  return (
    <>
      <Drawer
        className={"drawer"}
        variant="permanent"
        classes={{
          paper: "drawerPaper",
        }}
        anchor="left"
      >
        <Toolbar style={{ marginBottom: 24 }} />
        <List>
          <ListItem
            disableRipple
            className={"listItem"}
            button
            selected={activeItem === "Home"}
            onClick={() => {
              selectListItem("Home");
            }}
            key="Home"
            classes={{ selected: "listItemSelected" }}
            component={HashLink}
            to="/#"
            scroll={(el) => el.scrollIntoView({ behavior: "smooth" })}
          >
            <ListItemIcon className={"listItemIcon"}>
              <Home />
            </ListItemIcon>
            <ListItemText
              classes={{
                primary: "text14",
              }}
              primary="Home"
            />
          </ListItem>
          <ListItem
            disableRipple
            className={"listItem"}
            button
            selected={activeItem === "Profile"}
            onClick={() => {
              selectListItem("Profile");
              handleSelect("Profile");
            }}
            key="Account Settings"
            classes={{ selected: "listItemSelected" }}
            disabled={!isUserLoggedIn}
          >
            <ListItemIcon className={"listItemIcon"}>
              <User />
            </ListItemIcon>
            <ListItemText
              classes={{
                primary: "text14",
              }}
              primary="Account Settings"
            />
          </ListItem>

          <ListItem className={"itemBetween"} />
          <ListItem
            className={`listItem listItemModifier`}
          >
            <ListItemText
              primary="PRODUCTS"
              className={"listItemIcon"}
              classes={{
                primary: "text11Mofifier",
              }}
            />
          </ListItem>
          <ListItem
            disableRipple
            className={"listItem"}
            button
            selected={activeItem === "Reports"}
            onClick={() => {
              selectListItem("Reports");
            }}
            key="Reports"
            classes={{ selected: "listItemSelected" }}
            component={HashLink}
            smooth
            to="/#anchor-report"
            scroll={(el) => scrollWithOffset(el)}
          >
            <ListItemIcon className={"listItemIcon"}>
              <FileText />
            </ListItemIcon>
            <ListItemText
              classes={{
                primary: "text14",
              }}
              primary="Reports"
            />
          </ListItem>
        </List>
        <Link
          href="#"
          underline="none"
          style={{ position: "absolute", bottom: "38px" }}
          className={`link text11`}
          onClick={() => {
            history.push("/contact");
            dispatch(
              notificationActions.setMessageReportedInContact({
                messageReportedInContact: "",
              })
            );
            dispatch(
              notificationActions.setIsMessageReported({
                isMessageReported: false,
              })
            );
          }}
        >
          {"Contact Us"}
        </Link>
        <Link
          href={fileUrl}
          underline="none"
          style={{ position: "absolute", bottom: "16px" }}
          className={`link text11`}
          rel="noopener noreferrer"
          target="_blank"
        >
          {"Privacy"}
        </Link>
      </Drawer>
    </>
  );
}
