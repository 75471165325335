import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import ListItem from "@mui/material/ListItem";
import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";

import { useSelector } from "react-redux";
import Typography from "@mui/material/Typography";
import { accountantsGPSLogo } from "../../../assets/svg/svg";
import reportToc from "./data";
import TalkToExpertCard from "./TalkToExpertCard/talkToExpert";
import Fade from "@mui/material/Fade";
import { X, ChevronRight } from "react-feather";
import Box from "@mui/material/Box";
import "./htmlReportViewer.css";
import { styled } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { Storage } from "aws-amplify";
import { useHistory } from "react-router-dom";
import { headerActions } from "../../../store/header";
import { useDispatch } from "react-redux";
import Loader from "../../spinner/spinnerSaveData";

const SaveButton = styled(Button)({
  height: "44px",
  fontSize: "14px",
  fontWeight: 400,
  lineHeight: "24px",
  background: "#FFFFFF",
  border: "1px solid #1A2842",
  boxSizing: "border-box",
  color: "#1A2842",
  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
  borderRadius: "5px",
  fontFamily: ["Poppins"].join(","),
  "&:hover": {
    backgroundColor: "#FFFFFF",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
  },
  "&:active": {
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    backgroundColor: "#FFFFFF",
  },
  "&:focus": {
    backgroundColor: "#FFFFFF",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
  },
});

const NextButton = styled(Button)({
  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
  textTransform: "none",
  fontSize: "14px",
  padding: "10px 20px",
  border: "none",
  fontWeight: 400,
  lineHeight: "24px",
  backgroundColor: "#1A2842",
  color: "white",
  fontFamily: ["Poppins"].join(","),
  "&:hover": {
    backgroundColor: "#1A2842",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
  },
  "&:active": {
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    backgroundColor: "#1A2842",
  },
  "&:focus": {
    backgroundColor: "#1A2842",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
  },
  "&:disabled": {
    color: "white",
    backgroundColor: "#8C94A0;",
  },
});

const useStyles = makeStyles((theme) => ({
  text14: {
    fontFamily: "Poppins",
    fontWeight: 400,
    fontSize: 14,
    lineHeight: "24px",
  },
  back: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "11px",
    lineHeight: "16px",
    textAlign: "right",
    letterSpacing: "0.25em",
    textTransform: "uppercase",
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Fade ref={ref} {...props} />;
});

export default function HtmlReportViewer(props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const surveyMap = useSelector((state) => state.survey.surveyMap);
  const viewReportMode = useSelector((state) => state.header.viewReportMode);
  const [reportHtml, setReportHtml] = React.useState("");
  const pdfDocument = props.pdfDocument;
  const htmlDocument = props.htmlDocument;
  const surveyJson = surveyMap[htmlDocument?.surveyId]?.surveyJson;
  const [reportDate, setReportDate] = React.useState("");
  const [showLoader, setShowLoader] = React.useState(false);
  const formatDate = (value) => {
    if (value && value !== "N/A") {
      const date = new Date(value * 1000);
      return date.toLocaleDateString("en-GB", {
        day: "numeric",
        month: "long",
        year: "numeric",
      });
    }
    return "N/A";
  };

  const getS3HtmlReport = async (htmlDocument) => {
    try {
      const fileURL = await Storage.get(htmlDocument.documentLocation, {
        level: "public",
        download: true,
      });
      const htmlFromS3 = await fileURL.Body.text();
      setReportHtml(htmlFromS3);
    } catch (e) {
      console.log(e);
    }
  };

  React.useEffect(() => {
    if (htmlDocument) {
      setReportDate(formatDate(htmlDocument.createdDate));
      getS3HtmlReport(htmlDocument).catch(console.error);
    }
  }, [htmlDocument]);

  const onDownload = async () => {
    setShowLoader(true);
    if (pdfDocument) {
      let resultPdf = await Storage.get(pdfDocument.documentLocation, {
        level: "public",
        download: true,
      });
      let pdfUrl = URL.createObjectURL(resultPdf.Body);

      const link = document.createElement("a");
      link.href = pdfUrl;
      link.download = pdfDocument.documentLocation.substring(
        pdfDocument.documentLocation.indexOf("/") + 1
      );
      link.click();
      URL.revokeObjectURL(pdfUrl);
    }
    setShowLoader(false);
  };

  const handleClose = () => {
    props.closeSurvey();
    dispatch(headerActions.handleCloseViewReportMode());
    console.log(
      "🚀 ~ file: htmlReportViewer.jsx:163 ~ handleClose ~ viewReportMode:",
      viewReportMode
    );
    if (!viewReportMode) {
      history.push("/fundsList");
    }
  };

  return (
    <div>
      <Dialog
        fullScreen
        open={props.open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <Grid container>
          <Grid item xs={12}>
            <AppBar>
              <Toolbar className="headerMCU">
                <Grid container>
                  <Grid item style={{ width: 200, alignSelf: "center" }}>
                    <Box>
                      <a href="/">
                        <img
                          src={accountantsGPSLogo}
                          style={{
                            height: "48px",
                            width: "175px",
                            marginTop: 5,
                            justifyContent: "flex-start",
                            alignItems: "center",
                          }}
                        />
                      </a>
                    </Box>
                  </Grid>
                  <Typography className={"moneyCheckUp-label"}>
                    {surveyJson?.title}
                  </Typography>
                  <Grid item>
                    <X className={"crossIcon"} onClick={handleClose} />
                  </Grid>
                </Grid>
              </Toolbar>
            </AppBar>
          </Grid>
          <Grid
            container
            justifyContent="flex-start"
            direction="row"
            alignItems="center"
          >
            <Grid
              item
              style={{
                width: 255,
                overflow: "hidden",
                marginTop: "84px",
                borderRight: "1px solid #D1D4D9",
                height: "calc(100vh - 168px)",
              }}
            >
              <Box
                sx={{ mt: 3, mr: 2, ml: 2 }}
                style={{
                  fontSize: "14px",
                  fontFamily: "Poppins",
                  backgroundColor: "#FFCDD2",
                  height: 40,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                Issued: {reportDate}
              </Box>
              <Typography sx={{ mt: 3 }}></Typography>
              {reportToc.reports
                .filter((r) => {
                  return r?.name === props?.product?.surveyId?.toLowerCase();
                })[0]
                ?.pages?.map((page, index) => (
                  <ListItem
                    key={page.anchor}
                    button
                    disableRipple
                    onClick={() => {
                      return "#" + page.anchor;
                    }}
                  >
                    <ChevronRight style={{ marginRight: "8px" }} />
                    <a
                      href={"#" + page.anchor}
                      style={{ "text-decoration": "none", color: "black" }}
                    >
                      {" "}
                      {page.title}
                    </a>
                  </ListItem>
                ))}
            </Grid>
            <Grid
              item
              style={{
                marginTop: "84px",
                height: "calc(100vh - 168px)",
                width: "calc(100% - 255px)",
                overflow: "auto",
              }}
            >
              <div
                style={{ width: "calc(100% - 232px)", padding: "15px" }}
                dangerouslySetInnerHTML={{ __html: reportHtml }}
              ></div>
              {/* <TalkToExpertCard product={props.product} /> */}
            </Grid>
            <Grid
              item
              xs={12}
              style={{ height: "84px", borderTop: "1px solid #D1D4D9" }}
            >
              <Grid container>
                <Grid item xs={12} style={{ textAlign: "right" }}>
                  <Box style={{ marginRight: 32 }}>
                    <SaveButton
                      variant="contained"
                      style={{ marginTop: 20, marginRight: 8 }}
                      onClick={handleClose}
                    >
                      Close
                    </SaveButton>
                    <NextButton
                      variant="contained"
                      style={{ marginTop: "20px" }}
                      onClick={onDownload}
                    >
                      Download Print Version
                    </NextButton>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {showLoader && (
          <Loader string={"Please wait while your report is downloads."} />
        )}
      </Dialog>
    </div>
  );
}
