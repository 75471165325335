import * as React from 'react';
import Card from "./Card";
import Footer from "../Footer";
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { useParams, BrowserRouter as Router, useRouteMatch } from 'react-router-dom';

import NotificationBase from '../notification';
import NotificationMain from '../notification/notification';
import { useState, useEffect } from "react";
import productService from '../../services/product';
import { actions, productTypes, userNotificationAction, surveyIds } from '../../services/constants';
import { notificationActions } from "../../store/notification";
import { useDispatch, useSelector } from 'react-redux';
import { headerActions } from '../../store/header';
import { useHistory, useLocation } from 'react-router-dom';
import FullDialog from '../MainBody/FullDialog/fullDialog';
import { Storage } from 'aws-amplify';

import CardModal from './CardModal';

export default function MainBody({ passwordUpdated, handleCloseNotification }) {
    const dispatch = useDispatch();
    const history = useHistory();
    const { search } = useLocation();
    const query = new URLSearchParams(search);
    const redirectPage = query.get('redirectUrl');
    const subscriptionPurchased = query.get('subscriptionPurchased');
    const surveyMap = useSelector(state => state.survey.surveyMap);
    const isUserLoggedIn = useSelector(state => state.auth.loggedIn);
    const activeNotification = useSelector(state => state.notification.activeNotification);
    const notificationList = useSelector(state => state.notification.notificationList);
    const mainPageNotificationList = useSelector(state => state.notification.mainPageNotificationList);
    const [reportList, setReportList] = useState([]);
    const [superList, setSuperList] = useState([]);
    const [serviceProviders, setServiceProviders] = useState([]);
    const [openNotification, setOpenNotification] = useState(false);
    const [openProductCardModal, setOpenProductCardModal] = useState(true);
    const [openMCU, setOpenMCU] = React.useState(false);
    const [mcuProduct, setMcuProduct] = React.useState(undefined);
    const [actionListClosed, setActionListClosed] = React.useState([]);
    const [selectedProduct, setSelectedProduct] = React.useState(undefined);
    const { productId } = useParams();
    const [imageUrl, setImageUrl] = React.useState('');
    const getProductData = async () => {
        try {
            let response = await productService?.listProducts();
            if (response) {
                const mcuProd = response.filter((product) => product.type === productTypes.SMSF)[0];
                const reportProds = response.filter((product) => product.type === productTypes.REPORT || product.type === 'TEST')?.sort((a, b) => Number(a.comingSoon) - Number(b.comingSoon));
                const superProds = response.filter((product) => product.type === productTypes.SUPERANNUATION || product.type === 'TEST')?.sort((a, b) => Number(a.comingSoon) - Number(b.comingSoon));
                const serviceProds = response.filter((product) => product.type === productTypes.SERVICE || product.type === 'TEST')?.sort((a, b) => Number(a.comingSoon) - Number(b.comingSoon));
                let reports = [];
                if (mcuProd) {
                    setMcuProduct(mcuProd);
                    reports.push(mcuProd);
                }

                if (reportProds.length > 0) {
                    reports = [...reports, ...reportProds];
                }

                setReportList(reports);
                // TODO: Change to dynamic superlist once data available in db 
                setSuperList(superProds);
                setServiceProviders(serviceProds);

                if (productId) {
                    setSelectedProduct(response.find((product) => product.id === productId));
                }
            }

            response = await productService?.getDefaultAppointment();
            dispatch(headerActions.setDefaultAppointment({
                defaultAppointment: response
            }));
        } catch (e) {
            //console.log(e);
        }
    };

    useEffect(() => {
        getProductData().catch(console.error);

        if (subscriptionPurchased === 'true') {
            dispatch(headerActions.handleOpenCreateAccountConsent());
        }
    }, []);

    useEffect(() => {
        if (mainPageNotificationList && mainPageNotificationList.length > 0) {
            const notification = mainPageNotificationList.find((item) => !actionListClosed.includes(item.action));
            if (notification) {
                setMainNotification(notification);
                if (activeNotification) {
                    setOpenNotification(true);
                }
            }
        }
    }, [mainPageNotificationList]);

    const calcDiffDays = (value, isBefore) => {
        if (value && value !== 'N/A') {
            const now = Math.floor(Date.now() / 1000);
            const differenceInTime = isBefore ? new Date(value).getTime() / 1000 - now : now - new Date(value).getTime() / 1000;
            return differenceInTime / (3600 * 24) < 1 ? 0 : differenceInTime / (3600 * 24);
        } else {
            return 0;
        }
    }

    const init = () => {
        Storage.get("images/smsf_homepage_logo.png").then((data) => {
            setImageUrl(data);
        })
            .catch((err) => console.log('error fetching image', err));
    }
    init();
    const setMainNotification = (notification) => {
        const createdAt = notification.createdAt ? new Date(notification.createdAt) : undefined;
        const diffDays = calcDiffDays(createdAt, false);

        switch (notification.action) {
            case userNotificationAction.mcu_not_started:
                if (diffDays % 3 >= 0 && diffDays % 3 < 1) {
                    dispatch(notificationActions.setActiveNotification({
                        activeNotification: notification
                    }));
                }
                break;
            case userNotificationAction.mcu_in_progress:
                if (diffDays % 3 >= 0 && diffDays % 3 < 1) {
                    dispatch(notificationActions.setActiveNotification({
                        activeNotification: notification
                    }));
                }
                break;
            case userNotificationAction.mcu_complete_more_3months:
                if (diffDays % 3 >= 0 && diffDays % 3 < 1) {
                    dispatch(notificationActions.setActiveNotification({
                        activeNotification: notification
                    }));
                }
                break;
            case userNotificationAction.mcu_completed_receive_7days_flexi:
                dispatch(notificationActions.setActiveNotification({
                    activeNotification: notification
                }));
                break;
            case userNotificationAction.membership_expires_in_weeks:
                if (diffDays % 7 >= 0 && diffDays % 7 < 1) {
                    dispatch(notificationActions.setActiveNotification({
                        activeNotification: notification
                    }));
                }
                break;
            case userNotificationAction.membership_expires_in_days:
                dispatch(notificationActions.setActiveNotification({
                    activeNotification: notification
                }));
                break;
            case userNotificationAction.membership_expired:
                dispatch(notificationActions.setActiveNotification({
                    activeNotification: notification
                }));
                break;
            case userNotificationAction.membership_payment_failed:
                dispatch(notificationActions.setActiveNotification({
                    activeNotification: notification
                }));
                break;
            case userNotificationAction.membership_cancelled:
                dispatch(notificationActions.setActiveNotification({
                    activeNotification: notification
                }));
                break;
            case userNotificationAction.membership_upgraded:
                dispatch(notificationActions.setActiveNotification({
                    activeNotification: notification
                }));
                break;
            case userNotificationAction.id_check_not_perform:
                if (diffDays % 10 >= 0 && diffDays % 10 < 1) {
                    dispatch(notificationActions.setActiveNotification({
                        activeNotification: notification
                    }));
                }
                break;
            default:
        }

    }

    if (redirectPage) {
        if (isUserLoggedIn) {
            history.push(redirectPage);
        } else {
            dispatch(headerActions.handleOpenSignIn());
            dispatch(headerActions.handleExternalRedirect(redirectPage));
        }
    }

    const doAction = (action) => {
        switch (action) {
            case userNotificationAction.mcu_not_started:
                setOpenMCU(true);
                break;
            case userNotificationAction.mcu_in_progress:
                setOpenMCU(true);
                break;
            case userNotificationAction.mcu_complete_more_3months:
                setOpenMCU(true);
                break;
            default:
        }
        setOpenNotification(false);
    }

    const handleCloseNotificationMain = (action) => {
        const tempArray = actionListClosed || [];
        tempArray.push(action);
        setActionListClosed(tempArray);
        setOpenNotification(false);

        if (mainPageNotificationList && mainPageNotificationList.length > 0) {
            const notification = mainPageNotificationList.find((item) => !actionListClosed.includes(item.action));
            if (notification) {
                setMainNotification(notification);
                setOpenNotification(true);
            }
        }
    }

    return (
        <>
            <Box component="main" bgcolor="#FAFAFA" sx={{ flexGrow: 1, p: 4, pb: 0 }}>
                <Toolbar />
                <Typography paragraph variant="h1" sx={{ mb: 0 }}>
                    The Accountants Pathway to Deliver SMSF Guidance & Advice - without needing a Licence.
                </Typography>
                <Toolbar />
                <Grid container sx={{ mb: 4 }} >
                    <div style={{ display: "flex" }}>
                        <div ><img src={imageUrl} style={{ height: "80px", marginTop: 22 }}></img></div>
                        <div style={{ marginLeft: 30, width: "600px", marginTop: -20 }}><p><b>accountantsGPS are a proud partner of the SMSF Association.</b></p>
                            <p>The SMSF Association is the independent, professional body representing Australia's self-managed super fund sector. They strive to raise the standard of advice provided by all SMSF professionals, and educate trustees to make informed decisions for their retirement.</p></div>
                    </div>
                </Grid>

                {openNotification && isUserLoggedIn && <NotificationMain
                    color={activeNotification?.colorLandingPage}
                    title={activeNotification?.heading}
                    description={activeNotification?.body}
                    actionText={activeNotification?.actionText}
                    doAction={() => doAction(activeNotification?.action)}
                    closeAction={() => handleCloseNotificationMain(activeNotification?.action)} />}

                {passwordUpdated && <NotificationBase
                    title={'Password updated!'}
                    description={'Your password has been updated successfully.'}
                    handleCloseNotification={handleCloseNotification}
                />}

                {reportList?.length > 0 &&
                    <>
                        <Grid container sx={{ mb: 4 }} id='anchor-report'>
                            <Grid item>
                                <Stack>
                                    <Typography variant="big">
                                        Reports
                                    </Typography>
                                </Stack>
                            </Grid>
                        </Grid>
                        <Grid container spacing={3} >
                            {(reportList || []).map((product, index) => (
                                <Grid key={`product${index}`} item >
                                    <Card product={product} />
                                </Grid>
                            ))}
                        </Grid>
                        <Grid container sx={{ mt: 2, width: "80%", fontStyle: "italic" }} >
                            <Typography variant="small">
                                *By using the SMSF Check Up, you confirm that you have read and accepted the SMSF Client Licence Terms, and the Fiduciary Privacy Policy. You are responsible for obtaining client consent to provide information to us, and by providing it you confirm that they have consented.
                            </Typography>
                        </Grid>
                        <Toolbar />
                    </>
                }

                {superList?.length > 0 &&
                    <>
                        <Grid container sx={{ mb: 3 }} id='anchor-super'>
                            <Grid item>
                                <Stack>
                                    <Typography variant="big">
                                        Superannuation
                                    </Typography>
                                </Stack>
                            </Grid>
                        </Grid>
                        <Grid container spacing={3} >
                            {(superList || []).map((product, index) => (
                                <Grid key={`product${index}`} item >
                                    <Card product={product} />
                                </Grid>
                            ))}
                        </Grid>
                        <Toolbar />
                    </>
                }

                {serviceProviders?.length > 0 &&
                    <>
                        <Grid container sx={{ mb: 3 }} id='anchor-services'>
                            <Grid item>
                                <Stack>
                                    <Typography variant="big">
                                        Service Providers
                                    </Typography>
                                </Stack>
                            </Grid>
                        </Grid>
                        <Grid container spacing={3} >
                            {(serviceProviders || []).map((product, index) => (
                                <Grid key={`product${index}`} item >
                                    <Card product={product} />
                                </Grid>
                            ))}
                        </Grid>
                        <Grid container sx={{ mt: 2, width: "80%", fontStyle: "italic" }} >
                            <Typography variant="small">
                                *You confirm that you have read and accepted the Fiduciary Privacy Policy.  You are responsible for obtaining client consent to provide information to us and the Service Provider, and by providing it you confirm that they have consented.
                            </Typography>
                        </Grid>
                        <Toolbar />
                    </>
                }

                <Footer />
            </Box >
            {openMCU && <FullDialog productSurveyId={mcuProduct?.surveyId} product={mcuProduct} open={openMCU} closeSurvey={() => setOpenMCU(false)} />}
            {productId && selectedProduct && <CardModal product={selectedProduct} mcuProductId={surveyMap[surveyIds.SMSF001]?.productId} open={openProductCardModal} handleClose={() => setOpenProductCardModal(false)} />}

        </>
    );
}
