export const actions = {
  SHOW: "SHOW",
  HIDE: "HIDE",
};

export const reportGenerationStatus = {
  none: "none",
  hasPdf: "hasPdf",
  hasHtml: "hasHtml",
  hasBoth: "hasBoth",
};

export const productTypes = {
  MCU: "MCU",
  SMSF: "SMSF",
  REPORT: "REPORT",
  SERVICE: "SERVICE",
  SUPERANNUATION: "SUPERANNUATION",
};

export const surveyIds = {
  MCU001: "MCU001",
  PROP001: "PROP001",
  SAAA001: "SAAA001",
  SSA001: "SSA001",
  SMSF001: "SMSF001",
  SMSF001E: "SMSF001E",
  SIA001: "SIA001",
  PODC001: "PODC001",
  AGE001: "AGE001",
};

export const questionnaireResponseStatus = {
  NOTSTARTED: "NOTSTARTED",
  INPROGRESS: "INPROGRESS",
  COMPLETE: "COMPLETE",
  DOESNOTQUALIFY: "DOESNOTQUALIFY",
};

export const reportStatus = {
  INPROGRESS: "INPROGRESS",
  COMPLETED: "COMPLETED",
  FAILED: "FAILED",
};

export const MCUStatus = {
  inProgress: "inProgress",
  completedGreater90Days: "completedGreater90Days",
  completedLesser90Days: "completedLesser90Days",
};

export const IDStatus = {
  valid: "valid",
  failed: "failed",
  notStarted: "notStarted",
};

export const PurchasedStatus = {
  moreThan3Months: "moreThan3Months",
  lessThan3Months: "lessThan3Months",
  purchased: "purchased",
  pending: "pending",
  cancelled: "cancelled",
  refunded: "refunded",
  done: "done",
  used: "used",
};

export const SubscriptionTerm = {
  monthly: "monthly",
  annual: "annual",
};

export const UnlimitedReportsIncluded = 999999;

export const hasUnlimitedReportsIncluded = (totalReports) =>
  totalReports >= UnlimitedReportsIncluded;

export const templates = {
  LOE: "LOE",
};

export const userNotificationStatus = {
  SHOW: "SHOW",
  HIDE: "HIDE",
};

export const userNotificationAction = {
  mcu_not_started: "mcu_not_started",
  mcu_in_progress: "mcu_in_progress",
  mcu_complete_more_3months: "mcu_complete_more_3months",
  password_updated: "password_updated",
  mcu_completed_receive_7days_flexi: "mcu_completed_receive_7days_flexi",
  membership_expires_in_weeks: "membership_expires_in_weeks",
  membership_expires_in_days: "membership_expires_in_days",
  membership_expired: "membership_expired",
  membership_payment_failed: "membership_payment_failed",
  membership_cancelled: "membership_cancelled",
  membership_upgraded: "membership_upgraded",
  id_check_not_perform: "id_check_not_perform",
  contact_us: "contact_us",
};

export const userNotificationFrequency = {
  ONCE: "ONCE",
  DAYS3: "DAYS3",
  DAYS7: "DAYS7",
  DAYS10: "DAYS10",
  WEEKS2: "WEEKS2",
};

export const libraryCardStatus = {
  STARTSURVEYNOW: "START SURVEY NOW",
  CONTINUESURVEY: "CONTINUE SURVEY",
  SURVEYCOMPLETE: "SURVEY COMPLETE",
  DOESNOTQUALIFY: "DOES NOT QUALIFY",
  REPORTINPROGRESS: "REPORT IN PROGRESS",
  REPORTFAILED: "REPORT FAILED",
};

export const bglOperations = {
  ACCOUNTDETAILS: "accountDetails",
  MEMBERDETAILS: "membersDetails",
  MEMBERSTATEMENT: "membersStatement",
  ENTITYDETAILS: "entityDetails",
  TRUSTEES: "trustees",
  BALANCESHEET: "balanceSheet",
  INVESTMENTSUMMARY: "investmentSummary",
  INVESTMENTSUMMARYWITHSOURCE: "investmentSummaryWithSource",
  CONTRIBUTIONSUMMARY: "contributionSummary",
  PENSIONSUMMARY: "pensionSummary",
  GENERALLEDGER: "generalLedger",
  ENTITYLISTWITHMEMBERS: "entityListWithMembers",
  DETAILEDOPERATINGSTATEMENT: "detailedOperatingStatement",
  TRIALBALANCE: "trialBalance",
};

export const classSuperOperations = {
  FUND_LIST: "fundList",
  FUND_DETAIL: "fundDetail",
  FUND_MEMBERS: "fundMembers",
  // FUND_MEMBERS_OLD: "fundMembersOld",
  MEMBER_DETAIL: "fundMemberDetail",
  BUSINESS_INFO: "businessInfo",
};

export const investmentCategories = {
  "Amounts owing by Other Persons": "other",
  "Bank Accounts": "cash",
  "Term Deposits": "cash",
  "Cash/Bank Accounts": "cash",
  "Contributions Receivable": "cash",
  "Distributions Receivable": "cash",
  "Dividends Receivable": "cash",
  "Dividend Reinvestment - Residual Account": "other",
  "Reinvestment Residual Account": "other",
  "Formation Expenses": "other",
  "Interest Receivable": "cash",
  "Prepaid Expenses": "cash",
  "Sundry Debtors": "cash",
  Cryptocurrency: "other",
  "Collectables (Coins, Stamps, Wine and Other Personal Use Assets)": "other",
  "Debt Securities (Bonds, Bills of Exchange, Promissory Notes)": "other",
  "Derivatives (Options, Hybrids, Future Contracts)": "other",
  "Farming Property": "other",
  "Fixed Interest Securities (Australian)": "australian_fixed_interest",
  "Fixed Interest Securities (Australian) - Unitised":
    "australian_fixed_interest",
  "Fixed Interest Securities (Overseas)": "international_fixed_interest",
  "Fixed Interest Securities (Overseas) - Unitised":
    "international_fixed_interest",
  "Fixtures and Fittings (at written down value)": "other",
  "Fixtures and Fittings (at written down value) - Unitised": "other",
  "Government Securities (Australian)": "australian_fixed_interest",
  "Government Securities (Overseas)": "international_fixed_interest",
  "Hybrid Securities with Debt (Swaps, futures contracts, Options.)":
    "international_fixed_interest",
  "Interests in Partnerships (Australian)": "other",
  "Interests in Partnerships (Overseas)": "other",
  "Leased Assets": "other",
  "Leased Assets - Unitised": "other",
  "Insurance Policies": "n_a",
  "Insurance Policies - Unitised": "n_a",
  "Loans to Associated Entities (In house loans)": "other",
  "Loans to Associated Entities (In house loans) - Unitised": "other",
  "Managed Investments (Australian)": "australian_shares",
  "Managed Investments (Overseas)": "international_shares",
  "Mortgage Loans (Australian)": "other",
  "Mortgage Loans (Overseas)": "other",
  "Motor Vehicles (at written down value)": "other",
  "Motor Vehicles (at written down value) - Unitised": "other",
  "Other Assets": "other",
  "Other Investments": "other",
  "Farming Equipment": "other",
  "Plant and Equipment (at written down value)": "other",
  "Plant and Equipment (at written down value) - Unitised": "other",
  "Pooled Superanutaion Trust": "other",
  "Real Estate Properties (Australian)": "property",
  "Real Estate Properties (Australian - Residential)": "property",
  "Real Estate Properties ( Australian - Residential)": "property",
  "Real Estate Properties (Australian - Non Residential)": "property",
  "Real Estate Properties (Overseas)": "property",
  "Real Estate Properties (Overseas - Residential)": "property",
  "Real Estate Properties (Overseas - Non Residential)": "property",
  "Shares in Listed Companies (Australian)": "australian_shares",
  "Shares in Listed Companies (Overseas)": "international_shares",
  "Shares in Unlisted Private Companies (Australian)": "australian_shares",
  "Shares in Unlisted Private Companies (Overseas)": "international_shares",
  "Stapled Securities": "other",
  "Units in Listed Unit Trusts (Australian)": "australian_shares",
  "Units in Listed Unit Trusts (Overseas)": "international_shares",
  "Units in Unlisted Unit Trusts (Australian)": "australian_shares",
  "Units in Unlisted Unit Trusts (Overseas)": "international_shares",
  "Amounts owing to other persons": "n_a",
  "Interest Accrued": "n_a",
  "Investment Liabilities": "n_a",
  "GST Payable/Refundable": "n_a",
  "Income in Advance": "n_a",
  "Income Tax Payable": "n_a",
  "Limited Recourse Borrowing Arrangements": "n_a",
  "PAYG Payable": "n_a",
  "Sundry Creditors": "n_a",
  "Deferred Tax Liability": "n_a",
  "Migration Suspense Account": "n_a",
  "Bank Data Clearing Account": "other",
  "Investment Income Data Clearing Account": "other",
  "Investment Movement Data Clearing Account": "other",
  "Member Data Clearing Account": "other",
  "Pension Data Clearing Account": "other",
  "Unspecified Data Clearing Account": "other",
  "Manual Suspense Account": "other",
  "Accountancy Fees": "fees",
  "Administration Costs": "fees",
  "ATO Supervisory Levy": "fees",
  "Auditor's Remuneration": "fees",
  "ASIC Fees": "fees",
  "Advisor Fees": "fees",
  "Bank Charges": "fees",
  Commission: "fees",
  "General Expenses": "fees",
  Fines: "fees",
  "Legal Fees": "fees",
  "Portfolio Management Fees": "fees",
};

export const investmentCodes = {
  60100: "other",
  60400: "cash",
  60800: "cash",
  //"Cash/Bank Accounts": "cash",
  61600: "cash",
  61800: "cash",
  62000: "cash",
  62500: "other",
  62550: "other",
  64000: "other",
  65000: "cash",
  66000: "cash",
  68000: "cash",
  70040: "other",
  71000: "other",
  72000: "other",
  72300: "other",
  72350: "other",
  72400: "australian_fixed_interest",
  72450: "australian_fixed_interest",
  72500: "international_fixed_interest",
  72550: "international_fixed_interest",
  72600: "other",
  72650: "other",
  72800: "australian_fixed_interest",
  72900: "international_fixed_interest",
  73000: "international_fixed_interest",
  73200: "other",
  73300: "other",
  73800: "other",
  73850: "other",
  74000: "n_a",
  74050: "n_a",
  74200: "other",
  74250: "other",
  74700: "australian_shares",
  74800: "international_shares",
  75000: "other",
  75100: "other",
  75500: "other",
  75550: "other",
  76000: "other",
  76100: "other",
  76200: "other",
  76500: "other",
  76550: "other",
  76800: "other",
  77200: "property",
  77250: "property",
  77300: "property",
  77350: "property",
  77600: "australian_shares",
  77700: "international_shares",
  77800: "australian_shares",
  77900: "international_shares",
  78000: "other",
  78200: "australian_shares",
  78300: "international_shares",
  78400: "australian_shares",
  78500: "international_shares",
  // "Amounts owing to other persons": "n_a",
  // "Interest Accrued": "n_a",
  // "Investment Liabilities": "n_a",
  // "GST Payable/Refundable": "n_a",
  // "Income in Advance": "n_a",
  // "Income Tax Payable": "n_a",
  // "Limited Recourse Borrowing Arrangements": "n_a",
  // "PAYG Payable": "n_a",
  // "Sundry Creditors": "n_a",
  // "Deferred Tax Liability": "n_a",
  // "Migration Suspense Account": "n_a",
  // "Bank Data Clearing Account": "other",
  // "Investment Income Data Clearing Account": "other",
  // "Investment Movement Data Clearing Account": "other",
  // "Member Data Clearing Account": "other",
  // "Pension Data Clearing Account": "other",
  // "Unspecified Data Clearing Account": "other",
  // "Manual Suspense Account": "other",
  // "Accountancy Fees": "fees",
  // "Administration Costs": "fees",
  // "ATO Supervisory Levy": "fees",
  // "Auditor's Remuneration": "fees",
  // "ASIC Fees": "fees",
  // "Advisor Fees": "fees",
  // "Bank Charges": "fees",
  // "Commission": "fees",
  // "General Expenses": "fees",
  // "Fines": "fees",
  // "Legal Fees": "fees",
  // "Portfolio Management Fees": "fees",
};

export const propertyType = {
  "Real Estate Properties ( Australian - Residential)": "Residential",
  "Real Estate Properties (Australian - Non Residential)": "Commercial",
  "Real Estate Properties (Overseas - Residential)": "property",
  "Real Estate Properties (Overseas - Non Residential)": "Residential",
};

export const bglConsts = {
  PROPERTYINCOME: "Property Income",
  BORROWINGARRANGEMENTS: "Limited Recourse Borrowing Arrangements",
  EXPENSEACCOUNTVALUES: "Expense Account Values",
};
