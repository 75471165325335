  import { createSlice } from '@reduxjs/toolkit';

const notification = createSlice({
  name: 'notification',
  initialState: {
    notificationList: [],
    mainPageNotificationList: [],
    activeNotification: undefined,
    showIDVerificationDot: false,
    showProfileDetailsDot: false,
    showMyMembershipDot: false,
    showLibraryDot: false,
    messageReportedInContact: '',
    isMessageReported: false
  },
  reducers:{
    setNotificationList(state, actions){
      state.notificationList = actions.payload.notificationList;
    },
    setMainPageNotificationList(state, actions){
      state.mainPageNotificationList = actions.payload.mainPageNotificationList;
    },
    setMessageReportedInContact(state, actions){
      state.messageReportedInContact = actions.payload.messageReportedInContact;
    },
    setIsMessageReported(state, actions){
      state.isMessageReported = actions.payload.isMessageReported;
    },
    setActiveNotification(state, actions){
      state.activeNotification = actions.payload.activeNotification;
    },
    addToNotificationList(state, actions){
      state.notificationList = [...state.notificationList, ...[actions.payload.notification]];
    },
    setShowIDVerificationDot(state, actions){
      state.showIDVerificationDot = actions.payload.showIDVerificationDot;
    },
    setShowProfileDetailsDot(state, actions){
      state.showProfileDetailsDot = actions.payload.showProfileDetailsDot;
    },
    setShowMyMembershipDot(state, actions){
      state.showMyMembershipDot = actions.payload.showMyMembershipDot;
    },
    setShowLibraryDot(state, actions){
      state.showLibraryDot = actions.payload.showLibraryDot;
    },
  }
})

export const notificationActions = notification.actions;
export default notification;