import * as React from "react";
import { useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import { makeStyles } from "@mui/styles";
import { styled } from "@mui/material/styles";
import { InputBase, Typography, Grid } from "@mui/material";
import Link from "@mui/material/Link";
import LoadingSpinner from "../loadingSpinner";
import { useForm } from "react-hook-form";
import { X } from "react-feather";
import { useDispatch, useSelector } from "react-redux";
import useMediaQuery from "@mui/material/useMediaQuery";
import { headerActions } from "../../store/header";
import CustomizedSteppers from "../stepper/index";
import userService from "../../services/user";
import { authActions } from "../../store/auth";
import { v4 as uuidv4 } from "uuid";
import sha256 from "sha256";

const CreateAccountButton = styled(Button)({
  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
  textTransform: "none",
  fontSize: "14px",
  padding: "10px 20px",
  border: "none",
  fontWeight: 400,
  lineHeight: "24px",
  backgroundColor: "#1A2842",
  color: "white",
  fontFamily: ["Poppins"].join(","),
  "&:hover": {
    backgroundColor: "#1A2842",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
  },
  "&:active": {
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    backgroundColor: "#1A2842",
  },
  "&:focus": {
    backgroundColor: "#1A2842",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
  },
  "&:disabled": {
    color: "white",
    backgroundColor: "#8C94A0;",
  },
});

const useStyles = makeStyles((theme) => ({
  createAccountBox: {
    // display: 'flex',
    // flexDirection: 'column',
    // alignItems: 'flex-start',
    padding: "32px",
    position: "absolute",
    backgroundColor: "#FFFFFF",
    boxShadow: "4px 4px 6px rgba(0, 0, 0, 0.25)",
    borderRadius: "5px",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
    height: "calc(100% - 32px)",
    maxHeight: "830px",
    overflowX: "hidden",
    overflowY: "auto",
    [theme.breakpoints.down("sm")]: {
      maxHeight: "100%",
      overflowY: "scroll",
    },
  },
  createAccountLbl: {
    fontFamily: "Poppins",
    fontWeight: 600,
    fontSize: "24px",
    lineHeight: "32px",
    color: "#000000",
    marginBlock: 0,
    marginBottom: 32,
  },
  textFieldLabel: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "24px",
    color: "#000000",
    marginBlock: 0,
  },
  smallTextFieldLabel: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "9px",
    lineHeight: "16px",
    color: "#000000",
    letterSpacing: "0.1em",
  },
  red: {
    color: "red",
  },
  textField: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    background: "#ECEFF1",
    alignSelf: "stretch",
    height: "44px",
    paddingLeft: 8,
    paddingRight: 8,
  },
  text14: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "24px",
  },
  crossIcon: {
    position: "absolute",
    right: "24px",
    cursor: "pointer",
  },
  marginBtm: {
    marginTop: "0px",
    marginBottom: "32px",
  },
  errorBox: {
    marginTop: "5px",
    marginBottom: "-25px",
    color: "red",
    height: "20px",
    fontSize: "14px",
  },
}));

export default function CreateAccount() {
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const classes = useStyles();
  const dispatch = useDispatch();

  const open = useSelector((state) => state.header.openCreateAccount);
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setError,
    reset,
  } = useForm({
    defaultValues: {
      firstname: "",
      lastname: "",
      password: "",
      retypepassword: "",
      email: "",
    },
  });

  const password = React.useState({});
  const [emailExistError, setEmailExistError] = React.useState(false);
  const smallDeviceMatch = !useMediaQuery("(min-width:656px)");
  password.current = watch("password", "");

  const onSubmit = async (data) => {
    setIsLoading(true);
    try {
      const emailExists = await userService.userEmailExists(data.email);
      console.log("Email exists is " + emailExists);
      if (emailExists === false) {
        setEmailExistError(false);

        // store data into db
        data.state = uuidv4();
        data.state = data.state.replaceAll("-", "");
        const res = await userService.add({
          email: data.email,
          given_name: data.firstname,
          family_name: data.lastname,
          state: data.state,
          sub: "PENDING",
        });
        data.id = res.id;
        data.password = sha256(data.password).toString();
        data.retypepassword = sha256(data.retypepassword).toString();
        document.cookie = `userData= ${JSON.stringify(
          data
        )};Secure;SameSite=Strict;`;
        dispatch(headerActions.handleCloseCreateAccount());
        dispatch(headerActions.handleOpenBGLConsent());
        reset();
      } else {
        setError("email");
        setEmailExistError(true);
      }
      setErrorMessage("");
    } catch (error) {
      setErrorMessage(error.message);
      setError("email");
      // console.log(error);
      setEmailExistError(true);
      dispatch(authActions.handleUserAuth({ loggedIn: false, user: null }));
    }
    setIsLoading(false);
  };

  const onClose = () => {
    reset();
    setEmailExistError(false);
    dispatch(headerActions.handleCloseCreateAccount());
  };

  const onEmailChange = () => {
    setEmailExistError(false);
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box
          className={classes.createAccountBox}
          sx={{ width: `${smallDeviceMatch ? "calc(100% - 32px)" : "638px"}` }}
        >
          {isLoading && <LoadingSpinner />}
          <X className={classes.crossIcon} onClick={onClose} />
          <Typography className={classes.createAccountLbl}>
            Create your Account
          </Typography>

          <Grid>
            <CustomizedSteppers step={0}></CustomizedSteppers>
          </Grid>
          <Grid mt={5} style={{ position: "absolute", left: "23.5%" }}>
            <Box className={classes.marginBtm}>
              <Grid>
                <Typography variant="smaller" className="profileHeading">
                  USER DETAILS
                </Typography>
              </Grid>
            </Box>
            <Grid>
              <Grid item xs={12} sm={12}>
                {errors.firstname && (
                  <Typography className={classes.textFieldLabel}>
                    <span className={classes.red}>First Name *</span>
                  </Typography>
                )}
                {!errors.firstname && (
                  <Typography className={classes.textFieldLabel}>
                    {errors.firstname}
                    First Name <span className={classes.red}>*</span>
                  </Typography>
                )}
                <InputBase
                  className={`${classes.textField} ${classes.text14}`}
                  sx={{
                    width: `${smallDeviceMatch ? "auto" : "351px"}`,
                  }}
                  {...register("firstname", { required: true, maxLength: 100 })}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sx={{
                  paddingTop: `${
                    smallDeviceMatch ? "32px !important" : "16px"
                  }`,
                }}
              >
                {errors.lastname && (
                  <Typography className={classes.textFieldLabel}>
                    <span className={classes.red}>Last Name *</span>
                  </Typography>
                )}
                {!errors.lastname && (
                  <Typography className={classes.textFieldLabel}>
                    Last Name <span className={classes.red}>*</span>
                  </Typography>
                )}
                <InputBase
                  className={`${classes.textField} ${classes.text14}`}
                  sx={{
                    width: `${smallDeviceMatch ? "auto" : "351px"}`,
                  }}
                  {...register("lastname", { required: true, maxLength: 100 })}
                />
              </Grid>
              <Typography mt={2}>
                {errors.email && (
                  <Typography className={classes.textFieldLabel}>
                    <span className={classes.red}>Email Address *</span>
                  </Typography>
                )}
                {!errors.email && (
                  <Typography className={classes.textFieldLabel}>
                    Email Address <span className={classes.red}>*</span>
                  </Typography>
                )}
                <InputBase
                  className={`${classes.textField} ${classes.text14}`}
                  sx={{ width: `${smallDeviceMatch ? "auto" : "351px"}` }}
                  onChange={onEmailChange}
                  {...register("email", {
                    required: true,
                    pattern:
                      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                  })}
                />
                {emailExistError && (
                  <span className={classes.red}>
                    <Typography variant="small">
                      An account with the given email already exists.
                    </Typography>
                  </span>
                )}
              </Typography>
              <Typography mt={4}>
                {errors.password && (
                  <Typography className={classes.textFieldLabel}>
                    <span className={classes.red}> Create Password *</span>
                  </Typography>
                )}
                {!errors.password && (
                  <Typography className={classes.textFieldLabel}>
                    Create Password <span className={classes.red}>*</span>
                  </Typography>
                )}
                <InputBase
                  type="password"
                  className={`${classes.textField} ${classes.text14}`}
                  sx={{
                    width: `${smallDeviceMatch ? "auto" : "351px"}`,
                  }}
                  {...register("password", {
                    required: true,
                    pattern: /^(?=.*\d).{8,}$/,
                  })}
                />
              </Typography>
              <Typography
                mt={2}
                sx={{
                  marginTop: `${smallDeviceMatch ? "32px !important" : "16px"}`,
                }}
              >
                {errors.retypepassword && (
                  <Typography className={classes.textFieldLabel}>
                    <span className={classes.red}>Re-type Password *</span>
                  </Typography>
                )}
                {!errors.retypepassword && (
                  <Typography className={classes.textFieldLabel}>
                    Re-type Password <span className={classes.red}>*</span>
                  </Typography>
                )}
                <InputBase
                  type="password"
                  className={`${classes.textField} ${classes.text14}`}
                  sx={{ width: `${smallDeviceMatch ? "auto" : "351px"}` }}
                  {...register("retypepassword", {
                    required: true,
                    validate: (value) => value === password.current,
                  })}
                />
              </Typography>
            </Grid>
            {/* <div className={classes.errorBox}>
              {errorMessage.length > 30
                ? errorMessage.slice(0, 30) + "..."
                : errorMessage}
            </div> */}
            <Typography mt={5} sx={{ textAlign: "end" }}>
              <Link
                onClick={onClose}
                underline="always"
                color="inherit"
                className={classes.text14}
                style={{
                  cursor: "pointer",
                  textUnderlineOffset: "8pt",
                  color: "black",
                  marginLeft: smallDeviceMatch ? 0 : 150,
                }}
              >
                {"Cancel"}
              </Link>
              <CreateAccountButton
                variant="contained"
                style={{ marginLeft: 34 }}
                type="submit"
                disabled={
                  errors.password ||
                  errors.email ||
                  errors.firstname ||
                  errors.lastname ||
                  errors.retypepassword
                }
              >
                Next
              </CreateAccountButton>
            </Typography>
          </Grid>
        </Box>
      </form>
    </Modal>
  );
}
