import * as React from 'react';
import { Container, Typography } from "@mui/material";
import Grid from '@mui/material/Grid';
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import { HashLink } from 'react-router-hash-link';
import { Linkedin, Instagram, Facebook } from 'react-feather';
import AccountantsGPSFooter from './accountantsGPS.svg';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import ChangePassword from '../changePassword/changePassword';
import { headerActions } from "../../store/header";
import SignIn from '../signIn';
import './footer.css';
import { notificationActions } from "../../store/notification";
import { Storage } from 'aws-amplify';

export default function Footer() {
    const history = useHistory();
    const dispatch = useDispatch();
    const signIn = useSelector(state => state.header.signIn);
    const isUserLoggedIn = useSelector(state => state.auth.loggedIn);
    const [changePasswordModal, setChangePasswordModal] = React.useState("");
    const [privacyPolicyUrl, setPrivacyPolicyUrl] = React.useState(false);
    const [tnCUrl, setTnCUrl] = React.useState("");

    React.useEffect(() => {
        Storage.get("documents/Fiduciary-accountantsGPS_Privacy-Policy-Statement_April-2023.pdf")
            .then((data) => setPrivacyPolicyUrl(data))
            .catch((err) => console.log('error fetching file'));

        Storage.get("documents/Fiduciary_FFG_Website-Terms-Of-Use.pdf")
            .then((data) => setTnCUrl(data))
            .catch((err) => console.log('error fetching file'));
    }, []);
    const scrollWithOffset = (el) => {
        const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
        const yOffset = -80;
        window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
    }

    const onPersonalDetailsClick = () => {
        if (isUserLoggedIn) {
            history.push('/profile');
            window.scrollTo(0, 0);
        } else {
            dispatch(headerActions.handleOpenSignIn());
        }
    }

    const onBillingDetailsClick = () => {
        if (isUserLoggedIn) {
            history.push('/profile/billingDetails');
            window.scrollTo(0, 0);
        } else {
            dispatch(headerActions.handleOpenSignIn());
        }
    }

    const onBglConsentClick = () => {
        if (isUserLoggedIn) {
            history.push('/profile/bglConsentStatus');
            window.scrollTo(0, 0);
        } else {
            dispatch(headerActions.handleOpenSignIn());
        }
    }


    const onChangePasswordClick = () => {
        if (isUserLoggedIn) {
            setChangePasswordModal(!changePasswordModal);
        } else {
            dispatch(headerActions.handleOpenSignIn());
        }
    }

    const onAboutUsClick = () => {
        history.push('/aboutUs');
        window.scrollTo(0, 0);
    }

    return (<Box sx={{ backgroundColor: "rgba(0, 0, 0, 0.05)", mx: -5 }}>
        <Container maxWidth="xl">
            <Grid container >
                <Grid item sx={{ ml: 8, mt: 4 }} >
                    <Link target="_blank" rel="noreferrer" href="https://au.linkedin.com/company/fiduciary-advice" color="inherit">
                        <Linkedin />
                    </Link>
                </Grid>
                <Grid item sx={{ ml: 5, mt: 4 }}>
                    <Link target="_blank" rel="noreferrer" href="https://www.instagram.com/moneygpsadvice/?hl=en" color="inherit">
                        <Instagram />
                    </Link>
                </Grid>
                <Grid item sx={{ ml: 5, mt: 4 }}>
                    <Link target="_blank" rel="noreferrer" href="http://facebook.com/ffsdigital" color="inherit">
                        <Facebook />
                    </Link>
                </Grid>
            </Grid>
            <Grid container sx={{ mt: 5 }} >
                <Grid item xs={2} sx={{ ml: 8 }} className="footer-justify-start"  >
                    <Box>
                        <Typography className="text-title">Profile</Typography>
                        <Typography sx={{ mt: 1 }}>
                            <Link className="link-text14" color="inherit" underline="none" onClick={onPersonalDetailsClick}>
                                Account Details
                            </Link>
                        </Typography>
                        <Typography sx={{ mt: 1 }}>
                            <Link className="link-text14" color="inherit" underline="none" onClick={onBillingDetailsClick}>
                                Billing Details
                            </Link>
                        </Typography>
                        <Typography sx={{ mt: 1 }}>
                            <Link className="link-text14" color="inherit" underline="none" onClick={onChangePasswordClick}>
                                Change Password
                            </Link>
                        </Typography>
                        <Typography sx={{ mt: 1 }}>
                            <Link className="link-text14" color="inherit" underline="none" onClick={onBglConsentClick}>
                                Consent
                            </Link>
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={2} className="footer-justify-even">
                    <Box>
                        <Typography className="text-title">Site Menu</Typography>
                        <Typography sx={{ mt: 1 }}>
                            <HashLink smooth to="/#anchor-report" scroll={(el) => scrollWithOffset(el)} className="link-text14">
                                Reports
                            </HashLink>
                        </Typography>
                        <Typography sx={{ mt: 1 }}>
                            <HashLink smooth to="/#anchor-services" scroll={(el) => scrollWithOffset(el)} variant="small" className="link-text14">
                                Services
                            </HashLink>
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={3} className="footer-justify-even">
                    <Box>
                        <Typography className="text-title">About Us</Typography>
                        <Typography sx={{ mt: 1 }}>
                            <Link className="link-text14" color="inherit" underline="none" onClick={onAboutUsClick}>
                                Corporate Information
                            </Link>
                        </Typography>
                        <Typography sx={{ mt: 1 }}>
                            <Link className="link-text14" color="inherit" underline="none" href="https://accountantsgps.com.au/wp-content/uploads/2024/04/Fiduciary-AFSL_moneyGPS-Platform_accountantsGPS-SMSF-Platform_Subscriber-Licence-Terms_01042024.pdf">
                                Licencing Terms & Conditions
                            </Link>
                        </Typography>
                        <Typography sx={{ mt: 1 }}>
                            <Link className="link-text14" color="inherit" underline="none" href="https://moneygps.com.au/wp-content/uploads/2023/08/Accountants-Referral-Arrangements-Discussion-Paper_V1.00_July-2023.pdf">
                                Financial Services Referral Arrangements
                            </Link>
                        </Typography>
                        <Typography sx={{ mt: 1 }}>
                            <Link className="link-text14" color="inherit" underline="none" href="https://moneygps.com.au/wp-content/uploads/2023/08/GPS-Subscriptions_Value-Document_Final_01082023.pdf">
                                Adding Value To Your Practice
                            </Link>
                        </Typography>
                    </Box>
                </Grid>

            </Grid>
            <Grid container >
                <Grid item xs={5} sx={{ ml: 8, mt: 4, mb: 4 }} className='footer-justify-start text9 align-items-center'>
                    &copy; {new Date().getFullYear()} moneyGPS all rights reserved
                </Grid>
                <Grid item xs={5} sx={{ ml: 12, mt: 4, mb: 4 }} className="footer-justify-start text11">
                    <Grid container >
                        <Grid item xs={3} className="footer-justify-even align-items-center">
                            <Link href="https://moneygps.com.au/wp-content/uploads/2023/08/SMSF_accountantsGPS-FAQs_01082023.pdf"
                                color="inherit" underline="none"
                                rel="noopener noreferrer"
                                target="_blank"
                            >
                                FAQs
                            </Link>
                        </Grid>
                        <Grid item xs={3} className="footer-justify-even align-items-center">
                            <Link href="#" color="inherit" underline="none" onClick={() => {
                                history.push('/contact');
                                dispatch(notificationActions.setMessageReportedInContact({
                                    messageReportedInContact: ''
                                }));
                                dispatch(notificationActions.setIsMessageReported({
                                    isMessageReported: false
                                }));
                            }}>
                                Contact Us
                            </Link>
                        </Grid>
                        <Grid item xs={3} className="footer-justify-even align-items-center">
                            <Link href={tnCUrl}
                                color="inherit" underline="none"
                                rel="noopener noreferrer"
                                target="_blank"
                            >
                                Terms and Conditions
                            </Link>
                        </Grid>
                        <Grid item xs={3} className="footer-justify-even align-items-center">
                            <Link href={privacyPolicyUrl}
                                color="inherit" underline="none"
                                rel="noopener noreferrer"
                                target="_blank"
                            >
                                Privacy Policy
                            </Link>
                        </Grid>
                        <Grid item xs={3} className="footer-justify-even align-items-center" >
                            <a href="/"><img src={AccountantsGPSFooter} style={{ height: 35, width: 150 }} alt="accountants_gps_footer" /></a>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            {changePasswordModal && <ChangePassword onClose={onChangePasswordClick} />}
            {signIn && <SignIn />}

        </Container>
    </Box>)
}