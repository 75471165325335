import { useContext, useEffect, useState } from 'react';
import BorderLinearProgress from '../BorderLinearProgress';
import { Typography } from '@mui/material';

export default function SubscriptionStatistics({ totalReportsCount, usedReportsCount, extraReportsCount }) {

    const getRemainingReports = () => {
        const remaining = totalReportsCount - usedReportsCount;
        if (remaining > 0) {
            return remaining
        }

        return 0;
    }

    const getUsedReportsPercentage = () => {
        if (usedReportsCount > totalReportsCount) {
            return 100;
        }

        return (usedReportsCount / ((totalReportsCount) || 0)) * 100
    }

    return (
        <>
            <BorderLinearProgress value={getUsedReportsPercentage()} />
            <Typography style={{ color: '#2162A5' }} sx={{ mt: 1 }}>
                {usedReportsCount} used
            </Typography>
            <Typography>
                {getRemainingReports()} left in your plan
            </Typography>
            {extraReportsCount > 0 && <Typography>
                {extraReportsCount} extra remaining
            </Typography>}
        </>
    );
}
