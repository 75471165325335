import { createSlice } from "@reduxjs/toolkit";

const header = createSlice({
  name: "header",
  initialState: {
    email: "",
    password: "",
    newPassword: "",
    referral: "",
    signIn: false,
    openTryAgain: false,
    openSignIn: false,
    forgotPassword: false,
    openForgotPassword: false,
    createAccount: false,
    bglConsentStatus: false,
    classSuperConsentStatus: false,
    bglAuthCode: "",
    superClassAuthCode: "",
    selectedFY: 2022,
    selectedFund: {},
    firmDetails: {},
    openCreateAccount: false,
    abnVerified: false,
    openBGLConsent: false,
    openBGLConsentSpinner: false,
    openSuperClassConsentSpinner: false,
    openSuperClassAccessDenied: false,
    isServiceClassSuper: false,
    openFirmDetails: false,
    openCreateAccountConsent: false,
    openNewReportConfirmation: false,
    codeVerificationBase: false,
    emailVerificationBase: false,
    verificationToken: "",
    passwordUpdatedModal: false,
    openCodeVerificationBase: false,
    openEmailVerificationBase: false,
    createNewPassword: false,
    openCreateNewPassword: false,
    isCreateNewPassword: false,
    resetPassword: false,
    welcome: false,
    openWelcome: false,
    mobileWelcome: false,
    openMobileWelcome: false,
    mcu: false,
    openMCU: false,
    panelState: { shopping: false, profileMenu: false, notification: false },
    openNotification: false,
    membership: "freemium",
    openHtmlReportViewer: false,
    openFullDialog: false,
    selectedProduct: undefined,
    redirect: {
      to: null,
      membershipType: null,
    },
    selectedMenu: "",
    externalRedirect: "",
    pdfDocument: undefined,
    htmlDocument: undefined,
    defaultAppointment: undefined,
    showCodeErrorLimit: false,
    bglConsent: false,
    isReportBtnDisabled: true,
    isBglTokenUpdateFlow: false,
    isSuperClassTokenUpdateFlow: false,
    BGLHealthCheckModal: false,
    ClassSuperHealthCheckModal: false,
    reportBtnText: "Preparing data...",
    viewReportMode: false,
    openSubscriptionPlan: false,
    canCloseSubscriptionPlanModal: false,
    user: {},
  },
  reducers: {
    handleOpenSignIn(state, actions) {
      state.openSignIn = true;
    },
    handleCloseSignIn(state, actions) {
      state.openSignIn = false;
    },
    handleOpenTryAgain(state, actions) {
      state.openTryAgain = true;
    },
    handleCloseTryAgain(state, actions) {
      state.openTryAgain = false;
    },
    handleOpenForgotPassword(state, actions) {
      state.openForgotPassword = true;
    },
    handleCloseForgotPassword(state, actions) {
      state.openForgotPassword = false;
    },
    handleOpenCreateAccount(state, actions) {
      state.openCreateAccount = true;
      state.redirect = actions.payload?.redirect || null;
    },
    handleCloseCreateAccount(state, actions) {
      state.openCreateAccount = false;
    },
    handleEnableReportBtn(state, actions) {
      state.isReportBtnDisabled = false;
    },
    handleDisableReportBtn(state, actions) {
      state.isReportBtnDisabled = true;
    },
    handleOpenBGLConsent(state, actions) {
      state.openBGLConsent = true;
      state.redirect = actions.payload?.redirect || null;
    },
    handleCloseBGLConsent(state, actions) {
      state.openBGLConsent = false;
    },
    handleOpenFirmDetails(state, actions) {
      state.openFirmDetails = true;
      state.redirect = actions.payload?.redirect || null;
    },
    handleCloseFirmDetails(state, actions) {
      state.openFirmDetails = false;
    },
    handleOpenCreateAccountConsent(state, actions) {
      state.openCreateAccountConsent = true;
      state.redirect = actions.payload?.redirect || null;
    },
    handleCloseCreateAccountConsent(state, actions) {
      state.openCreateAccountConsent = false;
    },
    handleOpenBGLConsentSpinner(state, actions) {
      state.openBGLConsentSpinner = true;
    },

    handleCloseBGLConsentSpinner(state, actions) {
      state.openBGLConsentSpinner = false;
    },
    handleOpenSuperClassConsentSpinner(state, actions) {
      state.openSuperClassConsentSpinner = true;
    },
    handleCloseSuperClassConsentSpinner(state, actions) {
      state.openSuperClassConsentSpinner = false;
    },

    handleOpenSuperClassAccessDenied(state, actions) {
      state.openSuperClassAccessDenied = true;
    },
    handleCloseSuperClassAccessDenied(state, actions) {
      state.openSuperClassAccessDenied = false;
    },
    handleEnableServiceClassSuper(state, actions) {
      state.isServiceClassSuper = true;
    },
    handleDisableServiceClassSuper(state, actions) {
      state.isServiceClassSuper = false;
    },
    handleOpenNewReportConfirmation(state, actions) {
      state.openNewReportConfirmation = true;
    },
    handleCloseNewReportConfirmation(state, actions) {
      state.openNewReportConfirmation = false;
    },
    handleOpenSubscriptionPlan(state, actions) {
      state.openSubscriptionPlan = true;
      state.canCloseSubscriptionPlanModal = actions.payload?.canClose || false;
    },
    handleCloseSubscriptionPlan(state, actions) {
      state.openSubscriptionPlan = false;
    },
    handleOpenCodeVerificationBase(state, actions) {
      state.openCodeVerificationBase = true;
    },
    handleCloseCodeVerificationBase(state, actions) {
      state.openCodeVerificationBase = false;
    },
    handleOpenEmailVerificationBase(state, actions) {
      state.openEmailVerificationBase = true;
    },
    handleVerificationToken(state, actions) {
      state.verificationToken = actions.payload.verificationToken;
    },

    handleCloseEmailVerificationBase(state, actions) {
      state.openEmailVerificationBase = false;
    },
    handleOpenPasswordUpdatedModal(state, actions) {
      state.passwordUpdatedModal = true;
    },
    handleClosePasswordUpdatedModal(state, actions) {
      state.passwordUpdatedModal = false;
    },
    handleOpenCreateNewPassword(state, actions) {
      state.openCreateNewPassword = true;
    },
    handleCloseCreateNewPassword(state, actions) {
      state.openCreateNewPassword = false;
    },
    handleOpenWelcome(state, actions) {
      state.openWelcome = true;
    },
    handleCloseWelcome(state, actions) {
      state.openWelcome = false;
    },
    handleOpenMCU(state, actions) {
      state.openMCU = true;
    },
    handleCloseMCU(state, actions) {
      state.openMCU = false;
    },
    handleForgotPassword(state, actions) {
      state.forgotPassword = true;
      state.openForgotPassword = true;
    },
    handleIsCreateNewPassword(state, actions) {
      state.isCreateNewPassword = actions.payload.isCreateNewPassword;
    },
    handleCreateAccount(state, actions) {
      state.createAccount = true;
      state.openCreateAccount = true;
    },
    handleBglAuthCode(state, actions) {
      state.bglAuthCode = actions.payload.code;
    },
    handleSuperClassAuthCode(state, actions) {
      state.superClassAuthCode = actions.payload.code;
    },
    handleSelectedFund(state, actions) {
      state.selectedFund = actions.payload;
    },
    handleFirmDetails(state, actions) {
      state.firmDetails = actions.payload;
    },
    handleSignIn(state, actions) {
      state.signIn = true;
      state.openSignIn = true;
    },
    handleCreateNewPassword(state, actions) {
      state.createNewPassword = true;
      state.email = actions.payload.email;
      state.openCreateNewPassword = true;
    },
    handleCodeVerificationBase(state, actions) {
      state.codeVerificationBase = true;
      state.email = actions.payload.email;
      state.password = actions.payload.password;
      state.resetPassword = actions.payload.resetPassword;
      state.openCodeVerificationBase = true;
      state.showCodeErrorLimit = actions.payload.showCodeErrorLimit || false;
    },
    handleEmailVerificationBase(state, actions) {
      state.emailVerificationBase = true;
      state.email = actions.payload.email;
      state.password = actions.payload.password;
      state.openEmailVerificationBase = true;
      state.showCodeErrorLimit = actions.payload.showCodeErrorLimit || false;
    },
    handleCodeLimitError(state, actions) {
      state.showCodeErrorLimit = actions.payload.showCodeErrorLimit;
    },
    handleWelcome(state, actions) {
      state.welcome = true;
      state.openWelcome = true;
    },
    handleMobileWelcome(state, actions) {
      state.mobileWelcome = actions.payload;
      state.openMobileWelcome = actions.payload;
    },
    handleMCU(state, actions) {
      state.mcu = true;
      state.openMCU = true;
    },
    handlePanelState(state, actions) {
      state.panelState = actions.payload;
    },
    setSelectedMenu(state, actions) {
      state.selectedMenu = actions.payload;
    },
    handleExternalRedirect(state, actions) {
      state.externalRedirect = actions.payload;
    },
    setOpenHtmlReportViewer(state, actions) {
      state.openHtmlReportViewer = actions.payload.openHtmlReportViewer;
    },
    setSelectedProduct(state, actions) {
      state.selectedProduct = actions.payload.selectedProduct;
    },
    setOpenFullDialog(state, actions) {
      state.openFullDialog = actions.payload.openFullDialog;
    },
    setPdfDocument(state, actions) {
      state.pdfDocument = actions.payload.pdfDocument;
    },
    setHtmlDocument(state, actions) {
      state.htmlDocument = actions.payload.htmlDocument;
    },
    setDefaultAppointment(state, actions) {
      state.defaultAppointment = actions.payload.defaultAppointment;
    },
    setActiveStep(state, actions) {
      state.activeStep = actions.payload.activeStep;
    },
    handleOpenBglTokenUpdateFlow(state, actions) {
      state.isBglTokenUpdateFlow = true;
    },
    handleCloseBglTokenUpdateFlow(state, actions) {
      state.isBglTokenUpdateFlow = false;
    },
    handleOpenSuperClassTokenUpdateFlow(state, actions) {
      state.isSuperClassTokenUpdateFlow = true;
    },
    handleCloseSuperClassTokenUpdateFlow(state, actions) {
      state.isSuperClassTokenUpdateFlow = false;
    },
    handleOpenBGLHealthCheckModal(state, actions) {
      state.BGLHealthCheckModal = true;
    },
    handleCloseBGLHealthCheckModal(state, actions) {
      state.BGLHealthCheckModal = false;
    },
    handleOpenClassSuperHealthCheckModal(state, actions) {
      state.ClassSuperHealthCheckModal = true;
    },
    handleCloseClassSuperHealthCheckModal(state, actions) {
      state.ClassSuperHealthCheckModal = false;
    },
    handleReportBtnText(state, actions) {
      state.reportBtnText = actions.payload.btnText;
    },
    handleOpenViewReportMode(state, actions) {
      state.viewReportMode = true;
    },
    handleCloseViewReportMode(state, actions) {
      state.viewReportMode = false;
    },
    handleSelectedFY(state, actions) {
      state.selectedFY = actions.payload;
    },
    handleSignUser(state, actions) {
      state.user = actions.payload;
    },
  },
});

export const headerActions = header.actions;
export default header;
