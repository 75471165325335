import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Paper from '@mui/material/Paper';
import { SurveyQuestionDropdown } from "survey-react-ui";
import { ChevronDown } from 'react-feather';
import "./styles/extra.scss";

class SurveySelect extends SurveyQuestionDropdown {
  constructor(props) {
    super(props);
    const selectedValue = this.options.filter((o) => o.value === this.getValueCore())[0]?.value;
    this.state = {value: selectedValue, inputValue: selectedValue};
  }

  get options() {
    return this.question.visibleChoices.map((c) => {
        return {value: c.value, label: c.value }
    });
  }

  isOptionEqualToValue = (option, value) => {
    if (value.value && option.value === value.value) {
      this.setValueCore(value.value);
    }
    return option.value === value.value;
  }

  renderElement() {
    return (
      <Autocomplete
        value={this.state.value}
        onClose={(event, reason) => {
          this.setValueCore(event.target.innerText);
        }}
        onChange={(event, newValue) => {
          this.setValueCore(newValue?.value);
          this.setState({
            value: newValue
          });
        }}
        inputValue={this.state.inputValue}
        onInputChange={(event, newInputValue) => {
          this.setValueCore(newInputValue);
          this.setState({
            inputValue: newInputValue
          });
        }}
        classes={{ input: 'autoComplete-text', option: 'autoComplete-text'}}
        PaperComponent={({ children }) => (
          <Paper style={{ background: "#ECEFF1" }}>{children}</Paper>
        )}
        popupIcon={<ChevronDown style={{color: 'black', }} />}
        options={this.options}
        style={{fontSize: '14px'}}
        sx={{
          width: 240,
          background: 'rgb(236, 239, 241)',
          "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
            border: "0px",
            borderRadius: "0px 0px 0 0"
          },
          "& .MuiOutlinedInput-notchedOutline": {
            border: "0px",
            borderRadius: "0px 0px 0 0"
          },
          "& .MuiSelect-select": {
            paddingLeft: "8px"
          },
          "& .MuiSelect-iconOutlined": {
            top: "auto",
            color: "black"
          },
        }}
        disableClearable
        isOptionEqualToValue={(option, value) => this.isOptionEqualToValue(option, value)}
        renderInput={(params) => 
          <TextField 
            {...params}
            placeholder="Choose..."
            sx={{background: 'rgb(236, 239, 241)'}}
            inputProps={{ ...params.inputProps, style: { height: 16 } }}
          />
        }
      />
    );
  }
}

export default SurveySelect;