import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { makeStyles } from "@mui/styles";
import { X } from "react-feather";
import { Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { headerActions } from "../../store/header";
import { useHistory } from "react-router-dom";
import { Lock } from 'react-feather';
import { authActions } from "../../store/auth";

const useStyles = makeStyles((theme) => ({
  codeVerificationBox: {
    display: "flex",
    flexDirection: "column",
    position: "absolute",
    width: "auto",
    backgroundColor: "#FFFFFF",
    boxShadow: "4px 4px 6px rgba(0, 0, 0, 0.25)",
    borderRadius: "5px",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
    outline: 0,
    justifyContent: "center",
  },
  codeVerificationLbl: {
    fontFamily: "Poppins",
    fontWeight: 600,
    fontSize: "24px",
    lineHeight: "32px",
    color: "#000000",

  },
  textField: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    background: "#ECEFF1",
    alignSelf: "stretch",
    height: "44px",
    paddingLeft: 8,
    paddingRight: 8,
  },
  text14: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "24px",
  },
  crossIcon: {

    right: "24px",
    top: "24px",
    cursor: "pointer",
  },
  inputBorder: {
    border: "1px solid red",
  },
  areYouSureLbl: {
    fontFamily: "Poppins",
    fontWeight: 700,
    fontSize: "18px",
    lineHeight: "24px",
    color: "#000000",
    alignItems: "center",
  },
  lockLbl: {
    padding: "0px 16px 15px"
  }
}));
export default function PasswordUpdatedModal() {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const open = useSelector((state) => state.header.passwordUpdatedModal);

  const onClose = () => {
    dispatch(
      authActions.updateUserLogin({
        loggedIn: true,
      })
    );
    dispatch(headerActions.handleClosePasswordUpdatedModal());
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        className={classes.codeVerificationBox}
        style={{ height: 228, width: 690 }}
        sx={{ padding: "32px" }}
      >
        <div style={{ display: "flex", justifyContent: "space-between", marginTop: "-29px" }}>
          <div>
            <Typography className={classes.codeVerificationLbl}>
              Code Verification
            </Typography>
          </div>
          <div>
            <X className={classes.crossIcon} onClick={onClose} />
          </div>
        </div>


        <Box
          mt={4}
          className={classes.createNewPaswordBox}
          sx={{ alignItems: "center", background: "#ECEFF1", width: 516, display: "flex", padding: "20px", marginLeft: "9%" }}
        >
          <div className={classes.lockLbl}>
            <Lock />
          </div>
          <div>
            <div className={classes.areYouSureLbl}> Password Updated!</div>
            <div className={classes.text14}>
              Your password has been updated successfully.
            </div>

          </div>

        </Box>

      </Box>
    </Modal>
  );
}