import React from "react";
import { makeStyles } from '@mui/styles';
import Card from '@mui/material/Card';
import CardModal from "./CardModal";
import Grid from '@mui/material/Grid';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import './card.css';
import Typography from "@mui/material/Typography";
import { productTypes, surveyIds } from "../../services/constants";
import Timer from './Timer';
import { Storage } from 'aws-amplify';

const useStyles = makeStyles({
    actionArea: {
        "&:hover $focusHighlight": {
            opacity: 0
        }
    },
    focusHighlight: {},
    text11: {
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: 11,
        letterSpacing: '0.25em',
        textTransform: 'uppercase',
        lineHeight: '16px',
        color: '#8C94A0'
    },
    estimatedTime: {
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: 11,
        textTransform: 'uppercase',
        lineHeight: '16px',
        color: '#8C94A0'
    },
    right: {
        position: 'absolute',
        right: '16px'
    }
});

export default function MoneyGpsCard(props) {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [imageUrl, setImageUrl] = React.useState('');
    const isUserLoggedIn = useSelector(state => state.auth.loggedIn);
    const surveyMap = useSelector(state => state.survey.surveyMap);
    const defaultAppointment = useSelector((state) => state.header.defaultAppointment);
    const product = props.product;

    const handleOpen = () => {
        if (product.type !== productTypes.APPOINTMENT) {
            setOpen(true);
        } else {
            if (!product?.comingSoon) {
                if (product && product?.appointmentId && product?.appointment) {
                    window.open(product?.appointment?.link);
                } else {
                    window.open(defaultAppointment?.link);
                }
            }
        }
    }
    const handleClose = () => setOpen(false);

    const init = () => {
        let productImage = product.image || product.imageDetail;
        Storage.get(productImage).then((data) => {
            setImageUrl(data);
        })
        .catch((err) => console.log('error fetching image', err));
    }
    init();

    const showCurrencyFormat = (value) => {
        return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(value);
    }

    return (
        <Card variant="outlined" className="card">
            <CardActionArea
                disableRipple
                classes={{
                    root: classes.actionArea,
                    focusHighlight: classes.focusHighlight
                }}
                onClick={handleOpen}
            >
                <Box sx={{ position: 'relative' }}>
                    <CardMedia
                        component="img"
                        height="220"
                        image={imageUrl}
                        alt="card_image"
                    />

                    {product?.comingSoon === true && <Box sx={{ background: '#3559B5', color: '#fff', padding: '8px', position: 'absolute', bottom: '0px', right: '0px', letterSpacing: '0.25em' }}>
                        <Typography gutterBottom variant="smaller">COMING SOON</Typography>
                    </Box>}
                </Box>

                <CardContent>
                    <Grid container sx={{ mx: "auto" }}>
                        <Grid item >
                            <Typography  >
                                <span style={{ verticalAlign: 'middle' }} className={classes.text11}>{product.category}</span>

                                {/* {isUserLoggedIn && user['custom:membership'] !== 'freemium' && 
                                priceByMembership !== product.priceBase && !product?.comingSoon && <span className={classes.right}><span className="discountedCost" style={{verticalAlign: 'middle'}}>{`${showCurrencyFormat(priceByMembership)}`}</span>&nbsp;<span className="originalCost" style={{verticalAlign: 'top'}}>{`${showCurrencyFormat(product.priceBase)}`}</span></span>}

                                {isUserLoggedIn && user['custom:membership'] !== 'freemium' && 
                                priceByMembership === product.priceBase && <span className={classes.right}><span style={{fontSize: 14, verticalAlign: 'middle'}}>{(product.priceBase > 0 && !product?.comingSoon) ? `${showCurrencyFormat(product.priceBase)}` : ((product.type === productTypes.SERVICES || product?.type === productTypes.BLOG || product?.type === productTypes.PODCAST || product?.type === productTypes.WEBINAR || product?.comingSoon === true) ? '' : <span className="discountedCost">Free</span>)}</span></span>}
                                 */}
                                {(isUserLoggedIn) &&
                                    <span className={classes.right}><span style={{ fontSize: 14, verticalAlign: 'middle' }}>
                                        {(product.priceBase > 0 && !product?.comingSoon) ? `${showCurrencyFormat(product.priceBase)}` :
                                            ((product?.type === productTypes.SMSF || product?.type === productTypes.SERVICE || product?.type === productTypes.BLOG || product?.type === productTypes.PODCAST || product?.type === productTypes.WEBINAR || product?.comingSoon === true) ? '' :
                                                <span className="discountedCost">Free</span>)}</span></span>}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Typography mt={1} variant="body2" className="apply-ellipsis">
                        {product.title}
                    </Typography>
                    {product.estimatedTime && <Typography style={{ marginTop: 5 }}>
                        <Timer estimatedTime={product.estimatedTime} />
                    </Typography>}

                </CardContent>
            </CardActionArea>
            {(!isUserLoggedIn || (isUserLoggedIn && product.type)) &&
                <CardModal product={product} mcuProductId={surveyMap[surveyIds.SMSF001]?.productId} open={open} handleClose={handleClose} />
            }
            {/* {isUserLoggedIn && product.surveyId === surveyIds.SMSF001 &&
                <FullDialog productSurveyId={surveyIds.SMSF001} product={product} open={open}
                    closeSurvey={() => setOpen(false)} />
            }
            {isUserLoggedIn && product.surveyId === 'TEST001' &&
                <FullDialog productSurveyId={'TEST001'} product={product} open={open}
                    closeSurvey={() => setOpen(false)} />
            } */}
        </Card >
    );
}