import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { makeStyles } from "@mui/styles";
import { Typography, Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import useMediaQuery from "@mui/material/useMediaQuery";
import { headerActions } from "../../store/header";
import { SecondaryBtn } from "../../assets/primitives/buttons";

const useStyles = makeStyles((theme) => ({
  bglConsentBox: {
    // display: 'flex',
    // flexDirection: 'column',
    // alignItems: 'flex-start',
    padding: "32px",
    position: "absolute",
    backgroundColor: "#FFFFFF",
    boxShadow: "4px 4px 6px rgba(0, 0, 0, 0.25)",
    borderRadius: "5px",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
    height: "30%",
    width: "765px",
    maxHeight: "40%",
    overflowX: "hidden",
    overflowY: "auto",
  },
  bglConsentLbl: {
    fontFamily: "Poppins",
    fontWeight: 600,
    fontSize: "24px",
    lineHeight: "32px",
    color: "#000000",
    marginBlock: 0,
    marginBottom: 32,
  },
  textFieldLabel: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "24px",
    color: "#000000",
    marginBlock: 0,
  },
  smallTextFieldLabel: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "9px",
    lineHeight: "16px",
    color: "#000000",
    letterSpacing: "0.1em",
  },
  red: {
    color: "red",
  },
  textField: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    background: "#ECEFF1",
    alignSelf: "stretch",
    height: "44px",
    paddingLeft: 8,
    paddingRight: 8,
  },
  text14: {
    fontFamily: "Poppins",
    fontStyle: "small",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "24px",
  },
  crossIcon: {
    position: "absolute",
    right: "24px",
    cursor: "pointer",
  },
  marginBtm: {
    marginTop: "0px",
    marginBottom: "32px",
  },
  circle: {
    stroke: "url(#linearColors)",
  },
}));

// const getSuperClassToken = async (code, state) => {
//   if (code !== null && state !== null) {
//     try {
//       return await userService.getUserSuperClassToken(code, state);
//     } catch (error) {
//       console.log("code", code);
//       console.log("state", state);
//       console.log("err", error);
//     }
//   } else console.log("state or code cannot be null");
// };
// const getSuperClassTokenUP = async (code, state) => {
//   if (code !== null && state !== null) {
//     try {
//       return await userService.getUserSuperClassTokenUP(code, state);
//     } catch (error) {
//       console.log("code", code);
//       console.log("state", state);
//       console.log("err", error);
//     }
//   } else console.log("state or code cannot be null");
// };
export default function SuperClassAccessDenied() {
  const dispatch = useDispatch();
  const classes = useStyles();

  const open = useSelector(
    (state) => state.header.openSuperClassAccessDenied
  );

  const smallDeviceMatch = !useMediaQuery("(min-width:656px)");


  const onClose = () => {
    dispatch(headerActions.handleCloseSuperClassAccessDenied());
  };
  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        className={classes.bglConsentBox}
        sx={{ width: `${smallDeviceMatch ? "calc(100% - 32px)" : "638px"}` }}
      >

        <Grid>
          {/* <Grid>
            <svg width="330" height="120">
              <linearGradient id="linearColors" x1="0" y1="0" x2="1" y2="1">
                <stop offset="20%" stopColor="#5CE1C2" />
                <stop offset="90%" stopColor="#BBE789" />
              </linearGradient>
            </svg>
          </Grid> */}
          <Grid mt={5} className={classes.text14}>
            <Typography style={{ textAlign: "center" }}>
              Authorization process was cancelled by the user.
              Please try again later.
            </Typography>

          </Grid>
        </Grid>


        <Grid style={{ textAlign: "center" }}>
          <Typography mt={5}>
            <SecondaryBtn value={"Close"} onClick={onClose} />
          </Typography>
        </Grid>

      </Box>
    </Modal>
  );
}
