import { createContext } from "react";

export const loadConfig = () => {
  // Defines the parameters available in the config: 
  // TODO: Move all the required variable from app to here and remove the comment:
  // Read process.env and validates it.
  const {
    REACT_APP_STRIPE_PRICING_TABLE_ID,
    REACT_APP_STRIPE_PUBLISHABLE_KEY,
    REACT_APP_EXTRA_DB_REPORT_PRODUCT_ID,
    REACT_APP_STRIPE_SUBSCRIPTION_MANAGEMENT_LINK
  } = process.env

  const config = {
    extraReportProductId: REACT_APP_EXTRA_DB_REPORT_PRODUCT_ID,
    pricingTableId: REACT_APP_STRIPE_PRICING_TABLE_ID,
    publishableKey: REACT_APP_STRIPE_PUBLISHABLE_KEY,
    stripeSubscriptionManagementLink: REACT_APP_STRIPE_SUBSCRIPTION_MANAGEMENT_LINK
  }

  if (Object.values(config).some((configValue) => !configValue)) {
    throw new Error("Missing env variable - Additional details: " + JSON.stringify(config));
  }

  return config;
};

/**
 * Context to provide the config to the app with default values
 */
export const ConfigContext = createContext({
  extraReportProductId: '',
  pricingTableId: '',
  publishableKey: '',
  stripeSubscriptionManagementLink: ''
});

// Create the context provider which can be diretly used in the JSX
export const ConfigContextProvider = (props) => {
  const config = loadConfig();
  return (
    <ConfigContext.Provider value={config}>
      {props.children}
    </ConfigContext.Provider>
  );
};

export default ConfigContext;
