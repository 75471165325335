import * as React from "react";
import { API, graphqlOperation } from "aws-amplify";

import { useEffect, useState } from "react";
import {
  Box,
  Grid,
  InputBase,
  Typography,
  TextareaAutosize,
  Toolbar,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@mui/styles";
import { useForm } from "react-hook-form";
import { TertiaryBtnDark } from "../../assets/primitives/buttons";
import { emailService } from "../../graphql/queries";
import NotificationMain from "../notification/notification";
import { notificationActions } from "../../store/notification";
import Footer from "../Footer";

const useStyles = makeStyles((theme) => ({
  createAccountBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    width: "638px",
    height: "auto",
  },
  createAccountLbl: {
    fontFamily: "Poppins",
    fontWeight: 600,
    fontSize: "24px",
    lineHeight: "32px",
    color: "#000000",
    marginBlock: 0,
    marginBottom: 32,
  },
  textFieldLabel: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "24px",
    color: "#000000",
    marginBlock: 0,
  },
  smallTextFieldLabel: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "9px",
    lineHeight: "16px",
    color: "#000000",
    letterSpacing: "0.1em",
  },
  red: {
    color: "red",
  },
  textField: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    background: "#ECEFF1",
    alignSelf: "stretch",
    height: "44px",
    paddingLeft: 8,
    paddingRight: 8,
  },
  text14: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "24px",
  },
  phoneField: {
    padding: "0px 0px 0px 4px !important",
    height: "0px",
    display: "inline-block",
    fontSize: "14px",
  },
  crossIcon: {
    position: "absolute",
    right: "24px",
    cursor: "pointer",
  },
}));

export default function Contact() {
  const dispatch = useDispatch();
  const classes = useStyles();
  const isUserLoggedIn = useSelector((state) => state.auth.loggedIn);
  const user = useSelector((state) => state.auth.user);
  const [disabledSendMsg, setDisableSendMsg] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const messageReportedInContact = useSelector(
    (state) => state.notification.messageReportedInContact
  );
  const isMessageReported = useSelector(
    (state) => state.notification.isMessageReported
  );
  const [areaTextValue, setAreaTextValue] = useState(messageReportedInContact);

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    control,
    setError,
    setValue,
    clearErrors,
  } = useForm({
    defaultValues: {
      firstname: user?.given_name || "",
      lastname: user?.family_name || "",
      email: user?.email || "",
      message: "",
    },
  });

  const watchAll = watch();

  useEffect(() => {
    if (
      isMessageReported &&
      watchAll.message === "" &&
      messageReportedInContact !== ""
    ) {
      setAreaTextValue(messageReportedInContact);
      watchAll.message = messageReportedInContact;
    } else {
      setAreaTextValue(watchAll.message);
      dispatch(
        notificationActions.setMessageReportedInContact({
          messageReportedInContact: "",
        })
      );
      dispatch(
        notificationActions.setIsMessageReported({
          isMessageReported: false,
        })
      );
    }

    if (
      watchAll.message === "" ||
      watchAll.lastname === "" ||
      watchAll.firstname === ""
    ) {
      setDisableSendMsg(true);
    } else {
      setDisableSendMsg(false);
    }
  }, [watchAll]);

  const onSubmit = async (data) => {
    data.subject = "accountantsGPS Feedback";
    const response = await API.graphql({
      query: emailService,
      variables: { data: JSON.stringify(data) },
      authMode: "API_KEY",
    });

    // const response = await API.graphql(
    //   graphqlOperation(emailService, { data: JSON.stringify(data) })
    // );
    if (response) {
      setValue("message", "");
      setShowNotification(true);
    }
    return response;
  };

  const closeAction = () => {
    setShowNotification(false);
  };

  return (
    <>
      <Box sx={{ padding: "106px 32px", width: "100%", flexGrow: 1, pb: 0 }}>
        <Typography variant="h1">Contact Us</Typography>
        <Typography paragraph variant="small">
          If you have any questions, comments, or are just keen to get in touch, please fill out the contact form below and a member of our friendly team will contact you shortly.
        </Typography>
        <br></br>
        {showNotification && (
          <NotificationMain
            boxStyle={{
              width: "100%",
              backgroundColor: "#ECEFF1",
              marginBottom: 6,
            }}
            title={"Thank You!"}
            description={
              "Thank you for sending us a message. We will review and get back to you soon."
            }
            actionText={""}
            closeAction={closeAction}
          />
        )}

        <form onSubmit={handleSubmit(onSubmit)}>
          <Box className={classes.createAccountBox}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                {errors.firstname && (
                  <Typography className={classes.textFieldLabel}>
                    <span className={classes.red}>First Name *</span>
                  </Typography>
                )}
                {!errors.firstname && (
                  <Typography className={classes.textFieldLabel}>
                    {errors.firstname}
                    First Name <span className={classes.red}>*</span>
                  </Typography>
                )}
                <InputBase
                  className={`${classes.textField} ${classes.text14}`}
                  sx={{ width: "277px" }}
                  {...register("firstname", {
                    required: true,
                    maxLength: 100,
                  })}
                />
              </Grid>
              <Grid item xs={6}>
                {errors.lastname && (
                  <Typography className={classes.textFieldLabel}>
                    <span className={classes.red}>Last Name *</span>
                  </Typography>
                )}
                {!errors.lastname && (
                  <Typography className={classes.textFieldLabel}>
                    Last Name <span className={classes.red}>*</span>
                  </Typography>
                )}
                <InputBase
                  className={`${classes.textField} ${classes.text14}`}
                  sx={{ width: "277px" }}
                  {...register("lastname", {
                    required: true,
                    maxLength: 100,
                  })}
                />
              </Grid>
            </Grid>

            <Typography mt={4}>
              {errors.email && (
                <Typography className={classes.textFieldLabel}>
                  <span className={classes.red}>Email Address *</span>
                </Typography>
              )}
              {!errors.email && (
                <Typography className={classes.textFieldLabel}>
                  Email Address <span className={classes.red}>*</span>
                </Typography>
              )}
              <InputBase
                className={`${classes.textField} ${classes.text14}`}
                sx={{ width: "513px" }}
                //onChange={onEmailChange}
                {...register("email", {
                  required: true,
                  pattern:
                    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                })}
              />
            </Typography>

            <Typography mt={4}>
              {errors.email && (
                <Typography className={classes.textFieldLabel}>
                  <span className={classes.red}>Message *</span>
                </Typography>
              )}
              {!errors.email && (
                <Typography className={classes.textFieldLabel}>
                  Message <span className={classes.red}>*</span>
                </Typography>
              )}
              <TextareaAutosize
                minRows={10}
                className={`${classes.textField} ${classes.text14}`}
                style={{ width: "605px", border: "none", outline: "none" }}
                value={areaTextValue}
                {...register("message", {
                  required: true,
                })}
              />
            </Typography>

            <Box sx={{ marginTop: "25px" }}>
              <Typography variant="small">
                <TertiaryBtnDark
                  disabled={disabledSendMsg}
                  type="submit"
                  value={"Send Message"}
                />
                {/* <SecondaryBtn className="marginRight20" value={'Cancel'} onClick={closePanel} /> */}
              </Typography>
            </Box>
            <Typography variant={"small"} sx={{ marginTop: "34px" }}>
              Fiduciary Financial Services Pty Ltd
              <br /> t/a accountantsGPS
              <br /> ACN 003 624 888, AFSL 247344
              <br /> ABN 76 003 624 888
              <br /> Level 6, 607 Bourke Street
              <br /> Melbourne Vic 3000
              <br />
              <br /> E:  <a href="mailto: support@accountantsgps.com.au">support@accountantsgps.com.au</a>
            </Typography>
          </Box>
        </form>
        <Toolbar />
        <Footer />
      </Box>
    </>
  );
}
