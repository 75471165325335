import ReactDOM from "react-dom";
import { StyledEngineProvider } from "@mui/material/styles";
import App from "./App";
import { ThemeProvider } from "@mui/material/styles";
import { Provider } from "react-redux";
import Amplify from "aws-amplify";

import "./index.css";
import theme from "./assets/theme/theme";
import store from "./store/index";
import aws_exports from "./aws-exports";
import { BrowserRouter } from "react-router-dom";

import { ConfigContextProvider } from "./context/ConfigContext";

let script = document.createElement("script");
script.type = "text/javascript";
script.src = `https://www.googletagmanager.com/gtag/js?id=${process.env.REACT_APP_ANALYTICS_ID}`;
script.nonce = "a9f04fd1-06cf-4948-9d67-ea306e581896";
document.getElementsByTagName("head")[0].appendChild(script);

let stripeScript = document.createElement("script");
stripeScript.async = true;
stripeScript.src = `https://js.stripe.com/v3/pricing-table.js`;
stripeScript.nonce = "a9f04fd1-06cf-4948-9d67-ea306e581896";
document.getElementsByTagName("head")[0].appendChild(stripeScript);

window.dataLayer = window.dataLayer || [];
function gtag() {
  window.dataLayer.push(arguments);
}
gtag("js", new Date());

gtag("config", process.env.REACT_APP_ANALYTICS_ID);

Amplify.configure(aws_exports);

ReactDOM.render(
  <BrowserRouter>
    <Provider store={store}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <ConfigContextProvider>
            <App />
          </ConfigContextProvider>
        </ThemeProvider>
      </StyledEngineProvider>
    </Provider>
  </BrowserRouter>,
  document.querySelector("#root")
);
