import { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { headerActions } from "../../store/header";

import { TertiaryBtnDark20 } from "../../assets/primitives/buttons";

import subscriptionService from "../../services/subscriptionPlan";
import purchaseService from "../../services/purchase";
import {
  hasUnlimitedReportsIncluded,
  PurchasedStatus,
  SubscriptionTerm,
} from "../../services/constants";
import productService from "../../services/product";

import {
  Alert,
  AlertTitle,
  CircularProgress,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import Link from "@mui/material/Link";
import { makeStyles } from "@mui/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import CheckCircle from "@mui/icons-material/CheckCircleOutline";
import { FileText } from "react-feather";
import { styled } from "@mui/material/styles";
import AllInclusiveIcon from "@mui/icons-material/AllInclusive";

import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { X } from "react-feather";
import useMediaQuery from "@mui/material/useMediaQuery";
import ConfigContext from "../../context/ConfigContext";
import SubscriptionStatistics from "../SubscriptionStatistics";
import { getExtraReportLink } from "../../common/common";

const CancelButton = styled(Button)({
  height: "44px",
  fontSize: "14px",
  fontWeight: 400,
  lineHeight: "24px",
  background: "#FFFFFF",
  border: "1px solid #1A2842",
  boxSizing: "border-box",
  color: "#1A2842",
  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
  borderRadius: "5px",
  fontFamily: ["Poppins"].join(","),
  "&:hover": {
    backgroundColor: "#FFFFFF",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
  },
  "&:active": {
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    backgroundColor: "#FFFFFF",
  },
  "&:focus": {
    backgroundColor: "#FFFFFF",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
  },
});

const ConfirmSubscriptionCancellationButton = styled(Button)({
  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
  textTransform: "none",
  fontSize: "14px",
  padding: "10px 20px",
  border: "none",
  fontWeight: 400,
  lineHeight: "24px",
  backgroundColor: "#1A2842",
  color: "white",
  fontFamily: ["Poppins"].join(","),
  "&:hover": {
    backgroundColor: "#1A2842",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
  },
  "&:active": {
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    backgroundColor: "#1A2842",
  },
  "&:focus": {
    backgroundColor: "#1A2842",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
  },
  "&:disabled": {
    color: "white",
    backgroundColor: "#8C94A0;",
  },
});

export default function MySubscription(props) {
  const dispatch = useDispatch();
  const config = useContext(ConfigContext);
  const user = useSelector((state) => state.auth.user);

  const [alertIsOpened, setAlertIsOpened] = useState(true);
  const [currentPlan, setCurrentPlan] = useState(null);
  const [usedReportsCount, setUsedReportsCount] = useState(0);
  const [subscription, setSubscription] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalUpgradePlanIsOpen, setModalUpgradePlanIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [extraReportPrice, setExtraReportPrice] = useState(null);
  const [extraReportsCount, setExtraReportsCount] = useState(null);

  const smallDeviceMatch = !useMediaQuery("(min-width:656px)");

  const useStyles = makeStyles((theme) => ({
    moneyGPSAlert: {
      backgroundColor: "#ECEFF1",
    },
    moneyGPSAlertTitle: {
      fontWeight: "bold",
      fontSize: "16px",
    },
    subSummaryColumn: {
      color: "#2162A5",
      fontWeight: "bold",
    },
    subSummaryColumnValue: {
      textTransform: "capitalize",
      fontSize: "24px",
      fontWeight: "bold",
      marginTop: theme.spacing(1),
    },
    tableHeader: {
      fontWeight: "bold",
      backgroundColor: "#FAFAFA",
    },
    checkCircle: {
      fontSize: "28px",
      color: "#2AD000",
    },
    referralCodeTitle: {
      fontWeight: "bold",
    },
    referralCode: {
      fontWeight: "bold",
      color: "#2162A5",
      fontSize: "26px",
    },
    usedReports: {
      color: "#2162A5",
    },
    // TODO: Make generic confirm modal dialog and move the styles to the new component:
    // [START] Modal styles
    bglConsentBox: {
      padding: "32px",
      position: "absolute",
      backgroundColor: "#FFFFFF",
      boxShadow: "4px 4px 6px rgba(0, 0, 0, 0.25)",
      borderRadius: "5px",
      left: "50%",
      top: "50%",
      transform: "translate(-50%, -50%)",
      minHeight: "286px",
      width: "765px",
      maxHeight: "40%",
      overflowX: "hidden",
      overflowY: "auto",
    },
    confirmLbl: {
      fontFamily: "Poppins",
      fontWeight: 600,
      fontSize: "24px",
      lineHeight: "32px",
      color: "#000000",
      marginBlock: 0,
      marginBottom: 32,
    },
    text14: {
      fontFamily: "Poppins",
      fontStyle: "small",
      fontWeight: 400,
      fontSize: "14px",
      lineHeight: "24px",
    },
    crossIcon: {
      position: "absolute",
      right: "24px",
      cursor: "pointer",
    },
    // [END] Modal styles
  }));
  const classes = useStyles();

  const closePanel = () => {
    props.func();
  };

  const onClose = () => {
    setAlertIsOpened(false);
  };

  const getStripeSubscriptionManagementLink = () =>
    config?.stripeSubscriptionManagementLink;
  const getExtraReportProductId = () => config?.extraReportProductId;

  const goToManageStripeSubscription = () => {
    setModalUpgradePlanIsOpen(true);
  };

  const exceededReportsThreshold = () =>
    usedReportsCount >= subscription?.reportsIncluded;
  const exceededReportsThresholdAlert = () =>
    alertIsOpened && exceededReportsThreshold();

  // TODO: Move hasSubscription to back-end to not use client time but use server time:
  const hasSubscription = () => currentPlan?.expiryDate > new Date().getTime();

  const isActiveSubscription = () =>
    currentPlan?.status === PurchasedStatus.purchased;

  const getTotalReportsCount = () => subscription?.reportsIncluded;

  const getParticipationLevel = () =>
    !hasSubscription() ? "-" : currentPlan?.product?.title || "";
  const getSubscriptionTerm = () =>
    !hasSubscription() ? "-" : subscription?.term || "";
  const getSubscriptionPrice = () =>
    !hasSubscription() ? "-" : currentPlan?.product?.priceBase || "";
  const getNextPaymentDate = () => {
    if (hasSubscription() && isActiveSubscription()) {
      const ed = currentPlan && currentPlan.expiryDate;
      const d = new Date(ed);
      const month = d.toLocaleString("default", { month: "short" });

      const day = d.getUTCDate();
      const year = d.getUTCFullYear();

      return `${day} ${month} ${year}`;
    }
    return "-";
  };
  const getBillingCyclePrice = () =>
    !hasSubscription()
      ? "-"
      : `$${getSubscriptionPrice()} ${getSubscriptionTerm()}`;
  const getTableRows = () =>
    !hasSubscription()
      ? []
      : (subscription?.features && JSON.parse(subscription.features)) || [];

  const getReferralCode = () =>
    !hasSubscription() ||
    currentPlan?.product.description.includes("smsf-select")
      ? "-"
      : currentPlan?.referralCode || "";

  async function cancelCurrentSubscription() {
    try {
      setModalIsOpen(false);
      setIsLoading(true);
      const updatedCurrentPlan = await purchaseService.updatePurchaseStatus(
        currentPlan.id,
        PurchasedStatus.cancelled
      );
      setCurrentPlan(updatedCurrentPlan);
    } catch (error) {
      // TODO: Notify user
    } finally {
      setIsLoading(false);
    }
  }

  async function fetchData() {
    try {
      setIsLoading(true);

      getAndSetExtraReportPrice();

      const [cp, usedReports, extraReports] =
        await purchaseService.getCurrentPlanAndUsedReportsBySub(user.sub);

      setExtraReportsCount(extraReports?.length);

      const planId = cp?.product?.planId;
      const s = await subscriptionService.getSubscriptionPlan(planId);
      setCurrentPlan(cp);
      setUsedReportsCount(usedReports?.length);
      setSubscription(s);
    } catch (e) {
      // TODO: notify user
    } finally {
      setIsLoading(false);
    }
  }

  async function getAndSetExtraReportPrice() {
    const extraReportProduct = await productService.getProductById(
      getExtraReportProductId()
    );
    setExtraReportPrice(extraReportProduct?.priceBase);
  }

  function buyNewSubscription() {
    dispatch(headerActions.handleOpenSubscriptionPlan({ canClose: true }));
  }

  useEffect(() => {
    fetchData();
  }, [user]);

  const [reportsCount, setReportsCount] = useState({
    hasUnlimitedReportsIncluded: false,
    hasRemainingReports: false,
  });
  useEffect(() => {
    async function getReportCounts() {
      const [currentPlan, usedReports, extraReports] =
        await purchaseService.getCurrentPlanAndUsedReportsBySub(user.sub);
      const planId = currentPlan?.product?.planId;
      const s = await subscriptionService.getSubscriptionPlan(planId);
      const subscriptionDetails = {
        ...s,
        extraReports,
      };

      const totalReportsCount = subscriptionDetails?.reportsIncluded;

      const _hasUnlimitedReportsIncluded =
        hasUnlimitedReportsIncluded(totalReportsCount);

      const usedReportsCount = usedReports.length;

      const tempRemainingReports = totalReportsCount - usedReportsCount;
      const remainingReports =
        tempRemainingReports > 0 ? tempRemainingReports : 0;
      const remainingExtraReports = extraReports.length;
      const totalRemainingReports = remainingReports + remainingExtraReports;
      const hasRemainingReports = totalRemainingReports > 0;

      setReportsCount({
        hasUnlimitedReportsIncluded: _hasUnlimitedReportsIncluded,
        hasRemainingReports,
      });
    }
    getReportCounts();
  }, []);

  return (
    <>
      {isLoading ? (
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          sx={{ mt: 2 }}
        >
          <CircularProgress />
        </Grid>
      ) : (
        <>
          {!hasSubscription() ? (
            <Alert
              icon={false}
              onClose={() => onClose()}
              className={classes.moneyGPSAlert}
            >
              <AlertTitle className={classes.moneyGPSAlertTitle}>
                Your subscription has expired
              </AlertTitle>
              <Typography>
                To explore subscription packages&nbsp;
                <Link
                  onClick={() => buyNewSubscription()}
                  underline="always"
                  style={{
                    color: "black",
                    textDecorationColor: "black",
                    fontWeight: "bold",
                    cursor: "pointer",
                  }}
                >
                  click here
                </Link>
              </Typography>
            </Alert>
          ) : null}

          {hasSubscription() && exceededReportsThresholdAlert() ? (
            <Alert
              icon={false}
              onClose={() => onClose()}
              className={classes.moneyGPSAlert}
            >
              <AlertTitle className={classes.moneyGPSAlertTitle}>
                You have exceeded the free reports threshold
              </AlertTitle>
              <Typography>
                You will be charged for your next MCU report generation. To
                explore other subscription packages click here
              </Typography>
            </Alert>
          ) : null}

          <h1>Subscription Summary</h1>

          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item>
              <div className={classes.subSummaryColumn}>
                Current Subscription
              </div>
              <div className={classes.subSummaryColumnValue}>
                {getParticipationLevel()}
              </div>
            </Grid>
            <Grid item>
              <div className={classes.subSummaryColumn}>Billing Cycle</div>
              <div className={classes.subSummaryColumnValue}>
                {getSubscriptionTerm()}
              </div>
            </Grid>
            <Grid item>
              <div className={classes.subSummaryColumn}>Price</div>
              <div className={classes.subSummaryColumnValue}>
                {getBillingCyclePrice()}
              </div>
            </Grid>
          </Grid>
          <Divider sx={{ mt: 2 }} />
          <Grid
            container
            justifyContent="flex-end"
            alignItems="center"
            sx={{ mt: 1 }}
          >
            <Grid item>
              <Typography
                className={classes.subSummaryColumn}
                style={{ display: "inline-block" }}
              >
                Next payment date:
              </Typography>
              <Typography style={{ display: "inline-block" }} sx={{ ml: 2 }}>
                {getNextPaymentDate()}
              </Typography>
            </Grid>
          </Grid>

          <TableContainer component={Paper} sx={{ mt: 4 }}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell className={classes.tableHeader}>
                    Included products
                  </TableCell>
                  <TableCell className={classes.tableHeader}>Price</TableCell>
                  <TableCell className={classes.tableHeader} align="right">
                    Included
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {getTableRows().map((row) => (
                  <TableRow
                    key={row.name}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {row.name}
                    </TableCell>
                    <TableCell>{row.price}</TableCell>
                    <TableCell align="right">
                      <CheckCircle className={classes.checkCircle} />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            sx={{ mt: 4 }}
          >
            <Grid item>
              <Typography className={classes.referralCodeTitle}>
                MGPS Referral code
              </Typography>
            </Grid>
            <Grid item>
              <Typography className={classes.referralCode}>
                {getReferralCode()}
              </Typography>
            </Grid>
          </Grid>

          <Typography sx={{ mt: 4 }}>
            SMSF Check-up (SCU) Report usage summary{" "}
          </Typography>
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            sx={{ mt: 2, p: 2 }}
            style={{ backgroundColor: "#fff" }}
          >
            <Grid item>
              <Grid container justifyContent="space-between">
                <Grid item style={{ width: "50px" }}>
                  <FileText />
                </Grid>
                <Grid item>
                  <Typography style={{ fontWeight: "bold" }}>
                    Reports
                  </Typography>
                  {hasSubscription() && exceededReportsThreshold() && (
                    <Grid sx={{ mt: 1 }}>
                      <Typography>
                        You have reached the limit of your included reports for
                        this{" "}
                        {getSubscriptionTerm() === SubscriptionTerm.monthly
                          ? "month"
                          : "year"}
                        .
                      </Typography>
                      <Typography>
                        You can either wait for the plan to renew on{" "}
                        <b>{getNextPaymentDate()}</b> or purchase additional
                        reports as needed (each additional report is{" "}
                        <b>${extraReportPrice}</b>).
                      </Typography>
                      <Typography>
                        To view other subscription options or upgrade your
                        subscription,&nbsp;
                        <Link
                          href={getStripeSubscriptionManagementLink()}
                          underline="always"
                          style={{
                            color: "#2162A5",
                            textDecorationColor: "#2162A5",
                            fontWeight: "bold",
                          }}
                        >
                          click here.
                        </Link>
                      </Typography>
                    </Grid>
                  )}
                  {hasUnlimitedReportsIncluded(getTotalReportsCount()) && (
                    <Typography>
                      Your plan contains unlimited reports. You have created{" "}
                      <b>{usedReportsCount}</b> reports since you subscribed.
                    </Typography>
                  )}
                </Grid>
              </Grid>
            </Grid>
            {hasSubscription() && (
              <Grid item>
                <Box sx={{ width: 350 }}>
                  {(hasUnlimitedReportsIncluded(getTotalReportsCount()) && (
                    <Box style={{ textAlign: "right" }}>
                      <AllInclusiveIcon fontSize="large" />
                    </Box>
                  )) || (
                    <SubscriptionStatistics
                      totalReportsCount={getTotalReportsCount()}
                      usedReportsCount={usedReportsCount}
                      extraReportsCount={extraReportsCount}
                    />
                  )}
                </Box>
              </Grid>
            )}
          </Grid>

          <Grid
            container
            justifyContent="flex-end"
            alignItems="center"
            sx={{ mt: 4 }}
          >
            {hasSubscription() && isActiveSubscription() && (
              <Grid item>
                <Typography
                  sx={{
                    textDecoration: "underline",
                    textUnderlineOffset: "11px",
                    cursor: "pointer",
                  }}
                  onClick={() => setModalIsOpen(true)}
                >
                  Cancel subscription
                </Typography>
              </Grid>
            )}
            {!(
              reportsCount.hasUnlimitedReportsIncluded ||
              reportsCount.hasRemainingReports
            ) && (
              <>
                <Grid item style={{ width: "30px" }}></Grid>
                <TertiaryBtnDark20
                  variant="contained"
                  style={{ marginRight: "8px" }}
                  onClick={() => {
                    window.location = getExtraReportLink(user.sub, user.email);
                  }}
                  value={"Purchase Additional Report"}
                />
              </>
            )}
            <Grid item style={{ width: "30px" }}></Grid>
            <Grid item>
              <TertiaryBtnDark20
                onClick={() =>
                  !hasSubscription()
                    ? buyNewSubscription()
                    : goToManageStripeSubscription()
                }
                value={
                  !hasSubscription()
                    ? "View subscriptions"
                    : exceededReportsThreshold()
                    ? "Upgrade subscription"
                    : "Manage Subscription"
                }
              />
            </Grid>
          </Grid>
        </>
      )}
      <Modal
        open={modalIsOpen}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          className={classes.bglConsentBox}
          sx={{ width: `${smallDeviceMatch ? "calc(100% - 32px)" : "638px"}` }}
        >
          <X
            className={classes.crossIcon}
            onClick={() => setModalIsOpen(false)}
          />
          <Grid mt={3}>
            <Typography
              className={classes.confirmLbl}
              style={{ textAlign: "center" }}
            >
              Cancel Subscription?
            </Typography>
            <Typography
              className={classes.text14}
              style={{ textAlign: "center", marginBottom: "20px" }}
            >
              Your subscription will be active for the remainder of the current
              plan and then expire on <b>{getNextPaymentDate()}</b>. The
              subscription can be reactivated before it expires.
            </Typography>
            <Typography
              className={classes.text14}
              style={{ textAlign: "center", fontWeight: "bold" }}
            >
              Are you sure you want to continue?
            </Typography>
          </Grid>
          <Grid
            container
            justifyContent="space-evenly"
            alignItems="center"
            mt={4}
          >
            <Grid item>
              <CancelButton
                variant="contained"
                onClick={() => setModalIsOpen(false)}
              >
                Cancel
              </CancelButton>
            </Grid>
            <Grid item>
              <ConfirmSubscriptionCancellationButton
                variant="contained"
                style={{ marginRight: "8px" }}
                onClick={() =>
                  (window.location = getStripeSubscriptionManagementLink())
                }
              >
                Continue
              </ConfirmSubscriptionCancellationButton>
            </Grid>
          </Grid>
        </Box>
      </Modal>
      <Modal
        open={modalUpgradePlanIsOpen}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          className={classes.bglConsentBox}
          sx={{ width: `${smallDeviceMatch ? "calc(100% - 32px)" : "638px"}` }}
        >
          <X
            className={classes.crossIcon}
            onClick={() => setModalUpgradePlanIsOpen(false)}
          />
          <Grid mt={3}>
            <Typography
              className={classes.confirmLbl}
              style={{ textAlign: "center" }}
            >
              Upgrading Subscription?
            </Typography>
            <Typography
              className={classes.text14}
              style={{ textAlign: "center", marginBottom: "20px" }}
            >
              Warning: any change in plan will take place immediately. If
              downgrading, this may mean losing features immediately. Please
              contact us at{" "}
              <a href="mailto:support@accountantsgps.com.au">
                support@accountantsgps.com.au
              </a>{" "}
              for help.
            </Typography>
            <Typography
              className={classes.text14}
              style={{ textAlign: "center", fontWeight: "bold" }}
            >
              Are you sure you want to continue?
            </Typography>
          </Grid>
          <Grid
            container
            justifyContent="space-evenly"
            alignItems="center"
            mt={4}
          >
            <Grid item>
              <CancelButton
                variant="contained"
                onClick={() => setModalUpgradePlanIsOpen(false)}
              >
                Cancel
              </CancelButton>
            </Grid>
            <Grid item>
              <ConfirmSubscriptionCancellationButton
                variant="contained"
                style={{ marginRight: "8px" }}
                onClick={() =>
                  (window.location = getStripeSubscriptionManagementLink())
                }
              >
                Confirm upgrade
              </ConfirmSubscriptionCancellationButton>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </>
  );
}
