/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getQuestionnaireResponse = /* GraphQL */ `
  query GetQuestionnaireResponse($id: ID!) {
    getQuestionnaireResponse(id: $id) {
      id
      email
      sub
      productId
      fundId
      surveyId
      type
      status
      data
      currentPageNo
      reportLink
      createdDate
      updatedDate
      maxPageNoVisited
      createdAt
      updatedAt
      createdBy
      __typename
    }
  }
`;
export const listQuestionnaireResponses = /* GraphQL */ `
  query ListQuestionnaireResponses(
    $id: ID
    $filter: ModelQuestionnaireResponseFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listQuestionnaireResponses(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        email
        sub
        productId
        fundId
        surveyId
        type
        status
        data
        currentPageNo
        reportLink
        createdDate
        updatedDate
        maxPageNoVisited
        createdAt
        updatedAt
        createdBy
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getQuestionnaireResponseByProductId = /* GraphQL */ `
  query GetQuestionnaireResponseByProductId(
    $productId: String!
    $sortDirection: ModelSortDirection
    $filter: ModelQuestionnaireResponseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getQuestionnaireResponseByProductId(
      productId: $productId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        email
        sub
        productId
        fundId
        surveyId
        type
        status
        data
        currentPageNo
        reportLink
        createdDate
        updatedDate
        maxPageNoVisited
        createdAt
        updatedAt
        createdBy
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getQuestionnaireResponseByFund = /* GraphQL */ `
  query GetQuestionnaireResponseByFund(
    $fundId: String!
    $sub: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelQuestionnaireResponseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getQuestionnaireResponseByFund(
      fundId: $fundId
      sub: $sub
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        email
        sub
        productId
        fundId
        surveyId
        type
        status
        data
        currentPageNo
        reportLink
        createdDate
        updatedDate
        maxPageNoVisited
        createdAt
        updatedAt
        createdBy
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getProduct = /* GraphQL */ `
  query GetProduct($id: ID!) {
    getProduct(id: $id) {
      id
      title
      description
      summary
      type
      category
      surveyId
      accountCode
      planId
      priceBase
      invoiceDue
      image
      imageDetail
      action
      createdDate
      createdBy
      updatedDate
      updatedBy
      owner
      url
      comingSoon
      estimatedTime
      sub
      __typename
    }
  }
`;
export const listProducts = /* GraphQL */ `
  query ListProducts(
    $id: ID
    $filter: ModelProductFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listProducts(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        title
        description
        summary
        type
        category
        surveyId
        accountCode
        planId
        priceBase
        invoiceDue
        image
        imageDetail
        action
        createdDate
        createdBy
        updatedDate
        updatedBy
        owner
        url
        comingSoon
        estimatedTime
        sub
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getProductByType = /* GraphQL */ `
  query GetProductByType(
    $type: String!
    $sortDirection: ModelSortDirection
    $filter: ModelProductFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getProductByType(
      type: $type
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        description
        summary
        type
        category
        surveyId
        accountCode
        planId
        priceBase
        invoiceDue
        image
        imageDetail
        action
        createdDate
        createdBy
        updatedDate
        updatedBy
        owner
        url
        comingSoon
        estimatedTime
        sub
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getProductByPlanId = /* GraphQL */ `
  query GetProductByPlanId(
    $planId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelProductFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getProductByPlanId(
      planId: $planId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        description
        summary
        type
        category
        surveyId
        accountCode
        planId
        priceBase
        invoiceDue
        image
        imageDetail
        action
        createdDate
        createdBy
        updatedDate
        updatedBy
        owner
        url
        comingSoon
        estimatedTime
        sub
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getProductType = /* GraphQL */ `
  query GetProductType($id: ID!) {
    getProductType(id: $id) {
      id
      name
      status
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listProductTypes = /* GraphQL */ `
  query ListProductTypes(
    $id: ID
    $filter: ModelProductTypeFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listProductTypes(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        name
        status
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getProductCategory = /* GraphQL */ `
  query GetProductCategory($id: ID!) {
    getProductCategory(id: $id) {
      id
      name
      status
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listProductCategories = /* GraphQL */ `
  query ListProductCategories(
    $id: ID
    $filter: ModelProductCategoryFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listProductCategories(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        name
        status
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getSurvey = /* GraphQL */ `
  query GetSurvey($id: ID!) {
    getSurvey(id: $id) {
      id
      surveyId
      filename
      status
      versionDate
      __typename
    }
  }
`;
export const listSurveys = /* GraphQL */ `
  query ListSurveys(
    $id: ID
    $filter: ModelSurveyFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listSurveys(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        surveyId
        filename
        status
        versionDate
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getSurveyBySurveyIdByStatus = /* GraphQL */ `
  query GetSurveyBySurveyIdByStatus(
    $surveyId: String!
    $status: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSurveyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getSurveyBySurveyIdByStatus(
      surveyId: $surveyId
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        surveyId
        filename
        status
        versionDate
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getGeneratedDocument = /* GraphQL */ `
  query GetGeneratedDocument($id: ID!) {
    getGeneratedDocument(id: $id) {
      id
      surveyId
      productType
      productId
      status
      documentLocation
      createdDate
      expirationDate
      sub
      purchaseId
      QuestionnaireResponseId
      isNew
      QuestionnaireResponse {
        id
        email
        sub
        productId
        fundId
        surveyId
        type
        status
        data
        currentPageNo
        reportLink
        createdDate
        updatedDate
        maxPageNoVisited
        createdAt
        updatedAt
        createdBy
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listGeneratedDocuments = /* GraphQL */ `
  query ListGeneratedDocuments(
    $id: ID
    $filter: ModelGeneratedDocumentFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listGeneratedDocuments(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        surveyId
        productType
        productId
        status
        documentLocation
        createdDate
        expirationDate
        sub
        purchaseId
        QuestionnaireResponseId
        isNew
        QuestionnaireResponse {
          id
          email
          sub
          productId
          fundId
          surveyId
          type
          status
          data
          currentPageNo
          reportLink
          createdDate
          updatedDate
          maxPageNoVisited
          createdAt
          updatedAt
          createdBy
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getDocumentByProductId = /* GraphQL */ `
  query GetDocumentByProductId(
    $productId: String!
    $sub: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelGeneratedDocumentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getDocumentByProductId(
      productId: $productId
      sub: $sub
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        surveyId
        productType
        productId
        status
        documentLocation
        createdDate
        expirationDate
        sub
        purchaseId
        QuestionnaireResponseId
        isNew
        QuestionnaireResponse {
          id
          email
          sub
          productId
          fundId
          surveyId
          type
          status
          data
          currentPageNo
          reportLink
          createdDate
          updatedDate
          maxPageNoVisited
          createdAt
          updatedAt
          createdBy
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getDocumentByQRId = /* GraphQL */ `
  query GetDocumentByQRId(
    $QuestionnaireResponseId: ID!
    $sub: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelGeneratedDocumentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getDocumentByQRId(
      QuestionnaireResponseId: $QuestionnaireResponseId
      sub: $sub
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        surveyId
        productType
        productId
        status
        documentLocation
        createdDate
        expirationDate
        sub
        purchaseId
        QuestionnaireResponseId
        isNew
        QuestionnaireResponse {
          id
          email
          sub
          productId
          fundId
          surveyId
          type
          status
          data
          currentPageNo
          reportLink
          createdDate
          updatedDate
          maxPageNoVisited
          createdAt
          updatedAt
          createdBy
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getUserInfo = /* GraphQL */ `
  query GetUserInfo($id: ID!) {
    getUserInfo(id: $id) {
      id
      sub
      email
      given_name
      family_name
      isActive
      state
      planId
      bglToken
      bglTokenExpirationDate
      bglRefreshExpirationDate
      bglIsValid
      classSuperToken
      classSuperTokenExpirationDate
      classSuperRefreshExpirationDate
      classSuperIsValid
      consentType
      isClassTokenHash
      emailVerifyCode
      emailVerifyHash
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listUserInfos = /* GraphQL */ `
  query ListUserInfos(
    $id: ID
    $filter: ModelUserInfoFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listUserInfos(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        sub
        email
        given_name
        family_name
        isActive
        state
        planId
        bglToken
        bglTokenExpirationDate
        bglRefreshExpirationDate
        bglIsValid
        classSuperToken
        classSuperTokenExpirationDate
        classSuperRefreshExpirationDate
        classSuperIsValid
        consentType
        isClassTokenHash
        emailVerifyCode
        emailVerifyHash
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getUserBySub = /* GraphQL */ `
  query GetUserBySub(
    $sub: String!
    $sortDirection: ModelSortDirection
    $filter: ModeluserInfoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getUserBySub(
      sub: $sub
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        sub
        email
        given_name
        family_name
        isActive
        state
        planId
        bglToken
        bglTokenExpirationDate
        bglRefreshExpirationDate
        bglIsValid
        classSuperToken
        classSuperTokenExpirationDate
        classSuperRefreshExpirationDate
        classSuperIsValid
        consentType
        isClassTokenHash
        emailVerifyCode
        emailVerifyHash
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getUserByEmail = /* GraphQL */ `
  query GetUserByEmail(
    $email: String!
    $sortDirection: ModelSortDirection
    $filter: ModeluserInfoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getUserByEmail(
      email: $email
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        sub
        email
        given_name
        family_name
        isActive
        state
        planId
        bglToken
        bglTokenExpirationDate
        bglRefreshExpirationDate
        bglIsValid
        classSuperToken
        classSuperTokenExpirationDate
        classSuperRefreshExpirationDate
        classSuperIsValid
        consentType
        isClassTokenHash
        emailVerifyCode
        emailVerifyHash
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getStateToUserInfo = /* GraphQL */ `
  query GetStateToUserInfo($state: String!) {
    getStateToUserInfo(state: $state) {
      state
      userId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listStateToUserInfos = /* GraphQL */ `
  query ListStateToUserInfos(
    $state: String
    $filter: ModelStateToUserInfoFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listStateToUserInfos(
      state: $state
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        state
        userId
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getPurchase = /* GraphQL */ `
  query GetPurchase($id: ID!) {
    getPurchase(id: $id) {
      id
      productId
      productType
      activationDate
      expiryDate
      referralCode
      firmName
      amount
      edits
      properties
      sub
      questionnaireResponseId
      status
      product {
        id
        title
        description
        summary
        type
        category
        surveyId
        accountCode
        planId
        priceBase
        invoiceDue
        image
        imageDetail
        action
        createdDate
        createdBy
        updatedDate
        updatedBy
        owner
        url
        comingSoon
        estimatedTime
        sub
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listPurchases = /* GraphQL */ `
  query ListPurchases(
    $id: ID
    $filter: ModelPurchaseFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listPurchases(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        productId
        productType
        activationDate
        expiryDate
        referralCode
        firmName
        amount
        edits
        properties
        sub
        questionnaireResponseId
        status
        product {
          id
          title
          description
          summary
          type
          category
          surveyId
          accountCode
          planId
          priceBase
          invoiceDue
          image
          imageDetail
          action
          createdDate
          createdBy
          updatedDate
          updatedBy
          owner
          url
          comingSoon
          estimatedTime
          sub
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getPurchaseByProductId = /* GraphQL */ `
  query GetPurchaseByProductId(
    $productId: ID!
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPurchaseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getPurchaseByProductId(
      productId: $productId
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        productId
        productType
        activationDate
        expiryDate
        referralCode
        firmName
        amount
        edits
        properties
        sub
        questionnaireResponseId
        status
        product {
          id
          title
          description
          summary
          type
          category
          surveyId
          accountCode
          planId
          priceBase
          invoiceDue
          image
          imageDetail
          action
          createdDate
          createdBy
          updatedDate
          updatedBy
          owner
          url
          comingSoon
          estimatedTime
          sub
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getPurchaseBySub = /* GraphQL */ `
  query GetPurchaseBySub(
    $sub: String!
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPurchaseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getPurchaseBySub(
      sub: $sub
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        productId
        productType
        activationDate
        expiryDate
        referralCode
        firmName
        amount
        edits
        properties
        sub
        questionnaireResponseId
        status
        product {
          id
          title
          description
          summary
          type
          category
          surveyId
          accountCode
          planId
          priceBase
          invoiceDue
          image
          imageDetail
          action
          createdDate
          createdBy
          updatedDate
          updatedBy
          owner
          url
          comingSoon
          estimatedTime
          sub
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getPurchaseByQuestionnaireResponseId = /* GraphQL */ `
  query GetPurchaseByQuestionnaireResponseId(
    $questionnaireResponseId: ID!
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPurchaseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getPurchaseByQuestionnaireResponseId(
      questionnaireResponseId: $questionnaireResponseId
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        productId
        productType
        activationDate
        expiryDate
        referralCode
        firmName
        amount
        edits
        properties
        sub
        questionnaireResponseId
        status
        product {
          id
          title
          description
          summary
          type
          category
          surveyId
          accountCode
          planId
          priceBase
          invoiceDue
          image
          imageDetail
          action
          createdDate
          createdBy
          updatedDate
          updatedBy
          owner
          url
          comingSoon
          estimatedTime
          sub
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getPurchaseByStatus = /* GraphQL */ `
  query GetPurchaseByStatus(
    $status: String!
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPurchaseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getPurchaseByStatus(
      status: $status
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        productId
        productType
        activationDate
        expiryDate
        referralCode
        firmName
        amount
        edits
        properties
        sub
        questionnaireResponseId
        status
        product {
          id
          title
          description
          summary
          type
          category
          surveyId
          accountCode
          planId
          priceBase
          invoiceDue
          image
          imageDetail
          action
          createdDate
          createdBy
          updatedDate
          updatedBy
          owner
          url
          comingSoon
          estimatedTime
          sub
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getFirmDetails = /* GraphQL */ `
  query GetFirmDetails($id: ID!) {
    getFirmDetails(id: $id) {
      id
      sub
      businessName
      email
      phone
      keyContact
      addressLine1
      addressLine2
      city
      state
      postCode
      country
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listFirmDetails = /* GraphQL */ `
  query ListFirmDetails(
    $id: ID
    $filter: ModelFirmDetailsFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listFirmDetails(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        sub
        businessName
        email
        phone
        keyContact
        addressLine1
        addressLine2
        city
        state
        postCode
        country
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getFirmBySub = /* GraphQL */ `
  query GetFirmBySub(
    $sub: String!
    $sortDirection: ModelSortDirection
    $filter: ModelfirmDetailsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getFirmBySub(
      sub: $sub
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        sub
        businessName
        email
        phone
        keyContact
        addressLine1
        addressLine2
        city
        state
        postCode
        country
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getSubscriptionPlans = /* GraphQL */ `
  query GetSubscriptionPlans($planId: ID!) {
    getSubscriptionPlans(planId: $planId) {
      planId
      name
      stripeName
      price
      features
      reportsIncluded
      term
      extraReportPrice
      createdDate
      createdBy
      updatedDate
      updatedBy
      __typename
    }
  }
`;
export const listSubscriptionPlans = /* GraphQL */ `
  query ListSubscriptionPlans(
    $planId: ID
    $filter: ModelSubscriptionPlansFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listSubscriptionPlans(
      planId: $planId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        planId
        name
        stripeName
        price
        features
        reportsIncluded
        term
        extraReportPrice
        createdDate
        createdBy
        updatedDate
        updatedBy
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getSubscriptionByName = /* GraphQL */ `
  query GetSubscriptionByName(
    $name: String!
    $sortDirection: ModelSortDirection
    $filter: ModelsubscriptionPlansFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getSubscriptionByName(
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        planId
        name
        stripeName
        price
        features
        reportsIncluded
        term
        extraReportPrice
        createdDate
        createdBy
        updatedDate
        updatedBy
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getSubscriptionByStripeName = /* GraphQL */ `
  query GetSubscriptionByStripeName(
    $stripeName: String!
    $sortDirection: ModelSortDirection
    $filter: ModelsubscriptionPlansFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getSubscriptionByStripeName(
      stripeName: $stripeName
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        planId
        name
        stripeName
        price
        features
        reportsIncluded
        term
        extraReportPrice
        createdDate
        createdBy
        updatedDate
        updatedBy
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getRequestInfo = /* GraphQL */ `
  query GetRequestInfo($id: ID!) {
    getRequestInfo(id: $id) {
      id
      sourceIp
      requestName
      responseStatus
      logDate
      count
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listRequestInfos = /* GraphQL */ `
  query ListRequestInfos(
    $id: ID
    $filter: ModelRequestInfoFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listRequestInfos(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        sourceIp
        requestName
        responseStatus
        logDate
        count
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getRequestInfoByIp = /* GraphQL */ `
  query GetRequestInfoByIp(
    $sourceIp: String!
    $sortDirection: ModelSortDirection
    $filter: ModelrequestInfoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getRequestInfoByIp(
      sourceIp: $sourceIp
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        sourceIp
        requestName
        responseStatus
        logDate
        count
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getBglDetails = /* GraphQL */ `
  query GetBglDetails($data: String) {
    getBglDetails(data: $data)
  }
`;
export const getBglToken = /* GraphQL */ `
  query GetBglToken($code: String, $state: String) {
    getBglToken(code: $code, state: $state)
  }
`;
export const updateBglToken = /* GraphQL */ `
  query UpdateBglToken {
    updateBglToken
  }
`;
export const getBglData = /* GraphQL */ `
  query GetBglData($operation: String, $parameters: String) {
    getBglData(operation: $operation, parameters: $parameters)
  }
`;
export const emailService = /* GraphQL */ `
  query EmailService($data: String) {
    emailService(data: $data)
  }
`;
export const checkEmail = /* GraphQL */ `
  query CheckEmail($email: String) {
    checkEmail(email: $email)
  }
`;
export const getClassSuperDetails = /* GraphQL */ `
  query GetClassSuperDetails($data: String) {
    getClassSuperDetails(data: $data)
  }
`;
export const getClassSuperToken = /* GraphQL */ `
  query GetClassSuperToken($code: String, $state: String) {
    getClassSuperToken(code: $code, state: $state)
  }
`;
export const getClassSuperData = /* GraphQL */ `
  query GetClassSuperData($operation: String, $parameters: String) {
    getClassSuperData(operation: $operation, parameters: $parameters)
  }
`;
export const getClassSuperFormData = /* GraphQL */ `
  query GetClassSuperFormData(
    $fundCode: String
    $firmName: String
    $selectedFY: String
  ) {
    getClassSuperFormData(
      fundCode: $fundCode
      firmName: $firmName
      selectedFY: $selectedFY
    )
  }
`;
export const verifyEmail = /* GraphQL */ `
  query VerifyEmail($email: String, $code: String, $type: String) {
    verifyEmail(email: $email, code: $code, type: $type)
  }
`;
