import { API } from 'aws-amplify';
import { getBglData, getSurveyBySurveyIdByStatus } from '../graphql/queries';

const surveyService = {
  getSurveyBySurveyIdByStatus: async (surveyId, status) => {
    const response = await API.graphql({
      query: getSurveyBySurveyIdByStatus,
      authMode: 'API_KEY',
      variables: { surveyId, status: { eq: status } }
    });
    return response;
  }
}

export default surveyService;