import { useContext } from 'react';
import { useSelector } from "react-redux";
import sha256 from 'crypto-js/sha256';
import { Grid } from '@mui/material';

import ConfigContext from '../../context/ConfigContext';

export default function StripePricingTable() {
    const config = useContext(ConfigContext);
    const user = useSelector(state => state.auth.user);

    const getPricingTableId = () => config?.pricingTableId
    const getPublishableKey = () => config?.publishableKey

    return (
        <>
            <Grid container>
                <Grid item>
                    <script async src="https://js.stripe.com/v3/pricing-table.js"></script>
                    <stripe-pricing-table
                        style={{ 'min-width': '1280px', 'display': 'block' }}
                        pricing-table-id={getPricingTableId()}
                        publishable-key={getPublishableKey()}
                        client-reference-id={sha256(user?.sub).toString()}
                        customer-email={user?.email}>
                    </stripe-pricing-table>
                </Grid>
            </Grid>
        </>
    )
};
