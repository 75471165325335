import { API } from "aws-amplify";
import {
  getClassSuperData,
  getClassSuperDetails,
  getClassSuperFormData,
} from "../graphql/queries";

const classSuperService = {
  getClassSuperDetails: async () => {
    const response = await API.graphql({
      query: getClassSuperDetails,
      variables: {},
      authMode: "AWS_IAM",
    });

    return JSON.parse(response.data.getClassSuperDetails) || [];
  },
  getClassSuperDetailsUP: async () => {
    const response = await API.graphql({
      query: getClassSuperDetails,
      variables: {},
      authMode: "AMAZON_COGNITO_USER_POOLS",
    });

    return JSON.parse(response.data.getClassSuperDetails) || [];
  },
  getClassSuperData: async (operation, parameters) => {
    const response = await API.graphql({
      query: getClassSuperData,
      variables: { operation, parameters: JSON.stringify(parameters) },
      authMode: "AMAZON_COGNITO_USER_POOLS",
    });

    return response.data.getClassSuperData;
  },
  getClassSuperFormData: async (fundCode, firmName, selectedFY) => {
    const response = await API.graphql({
      query: getClassSuperFormData,
      variables: {
        fundCode,
        firmName,
        selectedFY,
      },
      authMode: "AMAZON_COGNITO_USER_POOLS",
    });

    return response.data.getClassSuperFormData;
  },
};

export default classSuperService;
