import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { makeStyles } from "@mui/styles";
import { Typography, Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import useMediaQuery from "@mui/material/useMediaQuery";
import { headerActions } from "../../store/header";
import { X } from "react-feather";
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import purchaseService from '../../services/purchase';
import { PurchasedStatus } from '../../services/constants';
import subscriptionService from '../../services/subscriptionPlan';
import productService from '../../services/product';
import userService from "../../services/user";

const CancelButton = styled(Button)({
    height: '44px',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '24px',
    background: '#FFFFFF',
    border: '1px solid #1A2842',
    boxSizing: 'border-box',
    color: '#1A2842',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    borderRadius: '5px',
    fontFamily: [
        'Poppins'
    ].join(','),
    '&:hover': {
        backgroundColor: '#FFFFFF',
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    },
    '&:active': {
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
        backgroundColor: '#FFFFFF',
    },
    '&:focus': {
        backgroundColor: '#FFFFFF',
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    },
});

const ProfessionalPlanButton = styled(Button)({
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    textTransform: 'none',
    fontSize: '14px',
    padding: '10px 20px',
    border: 'none',
    fontWeight: 400,
    lineHeight: '24px',
    backgroundColor: '#1A2842',
    color: 'white',
    fontFamily: [
        'Poppins'
    ].join(','),
    '&:hover': {
        backgroundColor: '#1A2842',
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    },
    '&:active': {
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
        backgroundColor: '#1A2842',
    },
    '&:focus': {
        backgroundColor: '#1A2842',
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    },
    '&:disabled': {
        color: 'white',
        backgroundColor: '#8C94A0;',
    },
});

const useStyles = makeStyles((theme) => ({
    bglConsentBox: {
        // display: 'flex',
        // flexDirection: 'column',
        // alignItems: 'flex-start',
        padding: "32px",
        position: "absolute",
        backgroundColor: "#FFFFFF",
        boxShadow: "4px 4px 6px rgba(0, 0, 0, 0.25)",
        borderRadius: "5px",
        left: "50%",
        top: "50%",
        transform: "translate(-50%, -50%)",
        minHeight: "286px",
        width: "765px",
        maxHeight: "40%",
        overflowX: "hidden",
        overflowY: "auto",
    },
    confirmLbl: {
        fontFamily: "Poppins",
        fontWeight: 600,
        fontSize: "24px",
        lineHeight: "32px",
        color: "#000000",
        marginBlock: 0,
        marginBottom: 32,
    },
    textFieldLabel: {
        fontFamily: "Poppins",
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "14px",
        lineHeight: "24px",
        color: "#000000",
        marginBlock: 0,
    },
    smallTextFieldLabel: {
        fontFamily: "Poppins",
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "9px",
        lineHeight: "16px",
        color: "#000000",
        letterSpacing: "0.1em",
    },
    red: {
        color: "red",
    },
    textField: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        background: "#ECEFF1",
        alignSelf: "stretch",
        height: "44px",
        paddingLeft: 8,
        paddingRight: 8,
    },
    text14: {
        fontFamily: "Poppins",
        fontStyle: "small",
        fontWeight: 400,
        fontSize: "14px",
        lineHeight: "24px",
    },
    crossIcon: {
        position: "absolute",
        right: "24px",
        cursor: "pointer",
    },
    marginBtm: {
        marginTop: "0px",
        marginBottom: "32px",
    },
    circle: {
        stroke: "url(#linearColors)",
    },
}));

export default function SubscribePlans() {
    const dispatch = useDispatch();
    const classes = useStyles();
    dispatch(headerActions.handleOpenSubscriptionPlan());
    const open = useSelector((state) => state.header.openSubscriptionPlan);
    const smallDeviceMatch = !useMediaQuery("(min-width:656px)");
    const isUserLoggedIn = useSelector(state => state.auth.loggedIn);
    const user = useSelector(state => state.auth.user);
    const onClose = () => {
        dispatch(headerActions.handleCloseSubscriptionPlan());
    };
    
    const addSubscription = async (planName) => {
        const date = new Date();
        const plan = await subscriptionService.getSubscriptionByName(planName)
        if(typeof plan !== 'undefined'){
            const product = await productService.getProductByPlanId(plan.planId)
            const purchase = await purchaseService.addPurchase({
                createdAt: date,
                updatedAt: date,
                productType: product.type,
                productId: product.id,
                sub: user?.sub,
                activationDate: date.getTime(),
                expiryDate: date.setFullYear(date.getFullYear() + 1),
                questionnaireResponseId: "",
                status: PurchasedStatus.pending
            });

            //Stripe call TBA

            //do we need to update status to done??

            await purchaseService.updatePurchaseStatus(purchase?.data?.createPurchase?.id, PurchasedStatus.purchased);

            //update userinfo with planid
            await userService.updateUsersKey({
                type: "planId",
                data: { planId: plan.planId, userId: user.id },
            });

            //TBA do we need to update User state?? to update this planid
    }
    }

    const cancelSubscription = async () => {
        const date = new Date();

        //get user by id/sub is that required??
        const userInfo = await userService.getUser(user?.sub)
        //get product id from plan id
        const product = await productService.getProductByPlanId(userInfo.planId)
        const purchaseList = await purchaseService.getPurchaseByProductId(product.id);
        const purchase = purchaseList?.filter(purchase => purchase.status === PurchasedStatus.purchased);
        
         //Stripe call TBA

        //TBA status refunded or cancelled??
        if(purchase?.length === 1){
            await purchaseService.updatePurchaseStatus(purchase[0].id, PurchasedStatus.cancelled);

            //remove planid against user
            await userService.updateUsersKey({
                type: "planId",
                data: { planId: "NONE", userId: user.id },
            });
        }
    }

    const handlePlanConfirmation = async (planName) => {
        await addSubscription(planName);
        dispatch(headerActions.handleCloseSubscriptionPlan());
    }

    const handlePlanCancellation = async () => {
        await cancelSubscription();
        dispatch(headerActions.handleCloseSubscriptionPlan());
    }

    const handlePlanModification = async(planName) => {
        await cancelSubscription();
        await addSubscription(planName);
        dispatch(headerActions.handleCloseSubscriptionPlan());
    }

    return (
        <Modal
            open={open}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box
                className={classes.bglConsentBox}
                sx={{ width: `${smallDeviceMatch ? "calc(100% - 32px)" : "638px"}` }}
            >
                <X className={classes.crossIcon} onClick={onClose} />
                <Grid mt={2} style={{ textAlign: "center" }}>
                    <ProfessionalPlanButton variant="contained"
                        style={{ marginRight: "8px" }}
                        onClick={() => handlePlanConfirmation("smsf-professional")}>
                        Subscribe to Professional
                    </ProfessionalPlanButton>
                    <CancelButton variant="contained"
                        onClick={() => handlePlanCancellation()}>
                        CancelSubscription
                    </CancelButton>
                </Grid>
            </Box>
        </Modal >

    );
}
