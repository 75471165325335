import * as React from "react";
import { styled } from "@mui/material/styles";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { makeStyles } from '@mui/styles';
import { ChevronDown, Edit } from 'react-feather';

const useStyles = makeStyles(theme => ({
  reviewLbl: {
    fontFamily: 'Poppins',
    fontWeight: 600,
    fontSize: '24px',
    lineHeight: '32px',
    color: '#000000',
    margin: '24px 32px 16px 32px',
  },
  text14: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontSize: '14px',
    lineHeight: '24px',
  },
  text18: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '18px',
    lineHeight: '24px',
  },
}));

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    {...props}
  />
))(({ theme }) => ({
  "&:not(.MuiAccordionSummary-expandIconWrapper.Mui-expanded)": {
    borderBottom: "1px solid #D1D4D9"
  },
  paddingTop: 16
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(3),
}));

export default function Review(props) {
  console.log("🚀 ~ file: index.js:57 ~ Review ~ props", window.survey?.data)
  const dataMapByPage = {};
  const classes = useStyles();
  const surveyJson = props.surveyJson;
  const [data, setData] = React.useState(window.survey?.data);

  const isObject = (value) => {
    return !!(value && typeof value === "object" && !Array.isArray(value));
  };

  const replace = (value) => {
    return value?.replace(/\{[\s\S]*?\}/g, '').replace('  ', ' ');
  }

  const padTo2Digits = (num) => {
    return num.toString().padStart(2, '0');
  }

  const processBoolean = (surveyObj, dataItem) => {
    const object = {};
    if (dataItem === false) {
      object[replace(surveyObj['title'])] = 'No';
    } else if (dataItem === true) {
      object[replace(surveyObj['title'])] = 'Yes';
    } else {
      object[replace(surveyObj['title'])] = dataItem;
    }
    return object;
  }

  const processExpression = (surveyObj, dataItem) => {
    const object = {};
    if (!(surveyObj['visible'] === false)) {
      if (surveyObj['expression'].includes('{rowIndex}')) {
        object[`${replace(surveyObj['title'])}`] = dataItem;
      } else {
        if (!isNaN(dataItem) && surveyObj['displayStyle'] === 'currency') {
          object[replace(surveyObj['title'])] = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(dataItem) || '';
        } else {
          object[replace(surveyObj['title'])] = dataItem || '';
        }
      }
    }
    return object;
  }

  const processRadioGroup = (surveyObj, dataItem) => {
    const object = {};
    let text = surveyObj['choices'].filter((el) => el.value === dataItem)[0]?.text;
    if (!text) {
      text = surveyObj['choices'].filter((el) => el === dataItem)[0];
    }
    object[replace(surveyObj['title'])] = text;
    return object;
  }

  const processMultipleText = (surveyObj, dataItem) => {
    const texts = [];
    for (const item of surveyObj['items']) {
      const object = {};
      if (dataItem[item?.name]) {
        object[item?.title || item?.name] = dataItem[item?.name];
      }
      texts.push(object);
    }
    return texts;
  }

  const processTextBox = (surveyObj, dataItem, idx) => {
    const object = {};
    if (dataItem) {
      if (surveyObj['inputFormat'] === 'dd/mm/yyyy' || surveyObj['inputType'] === 'date') {
        const date = new Date(dataItem);
        object[replace(surveyObj['title'])] = [
          padTo2Digits(date.getDate()),
          padTo2Digits(date.getMonth() + 1),
          date.getFullYear(),
        ].join('/');
      } else if (surveyObj['inputMask'] === 'currency' || surveyObj['type'] === 'nouislider') {
        object[replace(surveyObj['title'])] = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(dataItem);
      } else {
        if (idx >= 0) {
          object[`${replace(surveyObj['title'])}${idx + 1}`] = dataItem;
        } else {
          object[replace(surveyObj['title'])] = dataItem;
        }
      }
    }
    return object;
  }

  const processCheckbox = (surveyObj, dataItem) => {
    const object = {};
    let values = [];
    if (dataItem && dataItem.length > 0) {
      for (const item of dataItem) {
        if (surveyObj['choices']) {
          const text = surveyObj['choices']?.filter((el) => el.value === item)[0]?.text || surveyObj['choices']?.filter((el) => el === item)[0];
          values.push(text);
        } else if (surveyObj['choicesByUrl']) {
          values.push(item);
        }
      }
    }
    if (values.length > 0) {
      object[replace(surveyObj['title'])] = values.join(' / ');
    }
    return object;
  }

  const processMatrixDynamic = (surveyObj, dataItem) => {
    const matrix = [];
    const titleObj = {};
    if (surveyObj['title']) {
      titleObj[surveyObj['title']] = '';
      matrix.push(titleObj);
    }

    for (let i = 0; i < dataItem.length; i++) {
      for (const colElement of surveyObj['columns']) {
        const colElementData = dataItem[i][colElement['name']];
        if (colElementData) {
          const columnObj = redirectProcessing(colElement.cellType, colElement, colElementData);
          matrix.push(columnObj);
        }
      }
    }
    return matrix;
  }

  const processMatrixDropdown = (surveyObj, dataItem) => {
    const matrix = [];

    for (const rowElement of surveyObj['rows']) {
      const rowElementData = dataItem[rowElement['value']];
      if (rowElementData && Object.keys(rowElementData)?.length > 0) {
        const rowTitle = {};
        rowTitle[replace(rowElement['text'])] = '';
        matrix.push(rowTitle)

        for (const colElement of surveyObj['columns']) {
          const cell = {};
          const rowSubElementData = rowElementData[colElement['name']];
          if (colElement.cellType === 'text' && colElement.inputMask === 'currency') {
            cell[replace(colElement['title'])] = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(rowSubElementData);
          } else {
            cell[replace(colElement['title'])] = rowSubElementData;
          }
          matrix.push(cell);
        }
      }
    }
    return matrix;
  }

  const processPanelDynamic = (surveyObj, dataItem) => {
    const panels = [];
    if (dataItem && dataItem[0] && Object.keys(dataItem[0]).length !== 0) {
      const objectTitle = {};
      objectTitle[replace(surveyObj['title'])] = '';
      panels.push(objectTitle);
    }

    for (let i = 0; i < dataItem?.length; i++) {
      if (Object.keys(dataItem[i]).length !== 0) {
        const templateElementData = dataItem[i];
        if (surveyObj['templateTitle']) {
          const objectTemplateTitle = {};
          objectTemplateTitle[`${replace(surveyObj['templateTitle'])}${i + 1}`] = '';
          panels.push(objectTemplateTitle);
        }
        for (let idx = 0; idx < surveyObj['templateElements']?.length; idx++) {
          const templateElement = surveyObj['templateElements'][idx];
          let templateEleObj;
          if (templateElement.title.includes('#')) {
            templateEleObj = redirectProcessing(templateElement.type, templateElement, templateElementData[templateElement.name], idx);
          } else {
            templateEleObj = redirectProcessing(templateElement.type, templateElement, templateElementData[templateElement.name]);
          }
          panels.push(templateEleObj);
        }
      }
    }
    return panels;
  }

  const redirectProcessing = (type, element, elementData, idx) => {
    let object = {};
    switch (type) {
      case 'text':
      case 'comment':
      case 'nouislider':
      case 'dropdown':
        object = processTextBox(element, elementData, idx);
        break;
      case 'boolean':
        object = processBoolean(element, elementData);
        break;
      case 'expression':
        object = processExpression(element, elementData);
        break;
      case 'radiogroup':
        object = processRadioGroup(element, elementData);
        break;
      case 'multipletext':
        object = processMultipleText(element, elementData);
        break;
      case 'checkbox':
        object = processCheckbox(element, elementData);
        break;
      case 'matrixdynamic':
        object = processMatrixDynamic(element, elementData);
        break;
      case 'paneldynamic':
        object = processPanelDynamic(element, elementData);
        break;
      case 'matrixdropdown':
        object = processMatrixDropdown(element, elementData);
        break;
      default:
      // nothing to do for now, but need to implement more for other types
    }
    return object;
  }

    surveyJson.pages.map((page) => {
      page.elements.map((element) => {
        if (data[element.name] !== undefined) {
          const processedObj = redirectProcessing(element.type, element, data[element.name]);
          if ((Array.isArray(processedObj) && processedObj.length > 0) || (isObject(processedObj) && Object.keys(processedObj).length !== 0)) {
            dataMapByPage[page.name] = [...dataMapByPage[page.name] || [], ...[processedObj]];
          }
        }
      });
    });

  for (const page in dataMapByPage) {
    dataMapByPage[page] = dataMapByPage[page].flat(Infinity);
    const half = Math.ceil(dataMapByPage[page].length / 2);
    const firstHalf = dataMapByPage[page].slice(0, half);
    const secondHalf = dataMapByPage[page].slice(half, dataMapByPage[page].length);
    dataMapByPage[page] = [firstHalf, secondHalf];
  }

  return (
    <div style={{ background: "#FAFAFA" }}>
      <Typography className={classes.reviewLbl} >
        Review
      </Typography>
      {surveyJson.pages.map((page, index) => (
        dataMapByPage[page.name] && <Box style={{ marginLeft: 32, marginRight: 32 }}>
          <Accordion style={{ background: "#FAFAFA" }}>
            <AccordionSummary
              expandIcon={<ChevronDown style={{ color: 'black' }} />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={classes.text18}>
                <span style={{ verticalAlign: 'middle' }} >{page.name}</span>
                <Edit style={{ color: 'black', marginLeft: 16, verticalAlign: 'middle' }} onClick={() => props.onNavigationClick(index)} />
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container>
                {dataMapByPage[page.name].map((innerArr, idx) => (
                  <Grid item xs={6} key={`column${idx}`} >
                    {innerArr.map((element, index) => (
                      <Typography style={{ paddingRight: 40 }} key={`row${index}`}>
                        <Typography className={classes.text14} style={{ fontWeight: 700 }}>
                          {Object.keys(element)[0]}
                        </Typography>
                        <Typography className={classes.text14} style={{ marginBottom: index < innerArr.length - 1 ? 16 : -8 }}>
                          {Object.values(element)[0]}
                        </Typography>
                      </Typography>
                    ))}
                  </Grid>
                ))}
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Box>
      ))}
    </div>
  );
}
